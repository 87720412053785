import { instance } from "src/axios/axiosConfig";

interface EventPayload {
    source: string
    detailType: string
    detail: object
}


export async function sendEvent(payload: EventPayload) {
    try {
        const response = await instance.post(`app/events`, payload);
        return response.data.data.data;
    } catch (error) {
        console.log("Event sender error.", error);
    }
}
