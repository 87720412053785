import {CloseableModal, CloseableModalProps} from "../../../../../../../components/ui/modal/closeable-modal.component";
import {IFrame} from "../../../../../../../styles/globalcomponents";
import {PlanFrequency} from "../../../../../../../interfaces/plans";

interface PlanDescriptionModalProps extends Omit<CloseableModalProps, "children">{
    planName: string
    periodicity: PlanFrequency
}

// https://mastertools.com/especificaciones-basic-semestral

export function PlanDescriptionModal({isOpen, closeModal, planName, periodicity}: PlanDescriptionModalProps) {
    return (
        <CloseableModal isOpen={isOpen} closeModal={closeModal}>
            <IFrame src={`https://mastertools.com/especificaciones-${planName.toLowerCase()}-${periodicity.toString().toLowerCase()}`} hasNavbar={false} isModal={true}/>
        </CloseableModal>
    )
}
