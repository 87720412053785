import { BsFillPlayFill } from "react-icons/bs";
import styled from "styled-components";
import { globalstyles } from "../../../../styles/animations/globalstyles";

export const PlayIcon = styled(BsFillPlayFill)`
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
`

export const VideoImage = styled.div`
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
    cursor: pointer;
    position: relative;
`

export const Button = styled.div`
    width: 120px;
    height: 32px;
    border: none;
    border-radius: 16px;
    margin: auto;
    font-weight: bolder;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    background: ${globalstyles.colors.yellow};
    cursor: pointer;
    transition: .5s;

    @media (max-width: ${globalstyles.sizes.mobile}) {
        position: unset;
        margin: 16px auto;
    }

    &:hover {
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, .7)
    }
`

export const VideoCard = styled.div`
    width: 95%;
    height: 90%;
    margin: 4px auto;
    position: relative;
    display: flex;
    overflow: hidden;
    background: ${globalstyles.colors.grayDarkness};
    border-radius: 16px;
    transition: ${globalstyles.animations.low};

    &:hover {
        box-shadow: 0 4px 8px 4px rgba(0,0,0,.5);
    }
`

export const VideoContainer = styled.div`
    width: 100%;
    margin: 8px 0;
    border-radius: 16px;

    @media (max-width: ${globalstyles.sizes.mobile}) {
        height: auto;
    }
`