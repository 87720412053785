import {CloseableModal, CloseableModalProps} from "../../../../../components/ui/modal/closeable-modal.component";
import {ConfirmButton, Container, CustomText} from "../../../../../styles/globalcomponents";
import {config} from "../../../../../config";
import {ToastService} from "../../../../../services/toastService";
import {useEffect} from "react";

interface ChatRedirectModalProps extends Omit<CloseableModalProps, "children">{
}

const toastService = new ToastService()
const confirmPlanRenewalImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-renewal-icon.png`

export function ChatRedirectModal({isOpen, closeModal}: ChatRedirectModalProps) {

    function handleRedirectToChat() {
      window.open(config.urls.support, "_blank");
    }

    useEffect(() => {
        if(isOpen) {
            toastService.warning("Redirigiendo a soporte...")
            setTimeout(() => {
              window.open(config.urls.support, "_blank");
            }, 4000)
        }
    },[isOpen])

    return (
      <>
        <CloseableModal isOpen={isOpen} closeModal={closeModal}>
          <Container
            flexDirection={"column"}
            alignItems={"center"}
            maxWidth={"45rem"}
            padding={"0 .5rem"}
          >
            <img
              src={confirmPlanRenewalImg}
              alt={"MasterTools icon plan renewal"}
              width={"170px"}
              height={"170px"}
            />
            <h2 className={"text-center"}>¡Hola, Tooler!</h2>
            <Container flexDirection={"column"} margin={"0 0 1rem"}>
              <p>
                ¡Eres un caso excepcional! Por lo tanto, te llevaremos con
                nuestro equipo de soporte para conocer más acerca de tu
                solicitud y ayudarte a resolverla ;)
              </p>
              <small>
                En breves segundos se abrirá una pestaña de tu navegador, en
                caso contrario presiona el botón "¡CONTACTAR CON SOPORTE!"
              </small>
            </Container>
            <div>
              <ConfirmButton onClick={handleRedirectToChat}>
                ¡CONTACTAR CON SOPORTE!
              </ConfirmButton>
              <CustomText
                textAlign={"center"}
                onClick={() => closeModal()}
                cursor={"pointer"}
                fontSize={"13px"}
                textDecoration={"underline"}
                margin={"1rem 0"}
              >
                Cancelar
              </CustomText>
            </div>
          </Container>
        </CloseableModal>
      </>
    );
}
