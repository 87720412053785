import { useState } from "react";
import Popup from "../../../../components/popup/popup";
import { config } from "../../../../config";
import { Video } from "../../../../interfaces/tootorials";
import {
  Container,
  ContainerItem,
  Text,
  CustomTitle,
} from "../../../../styles/globalcomponents";
import {
  Button,
  VideoCard,
  VideoContainer,
  VideoImage,
} from "./tootorialVideo.styles";

type props = {
  video: Video;
};

export const TootorialVideo = ({ video }: props) => {
  const [tootorialUrl, setTootorialUrl] = useState<string>();
  const [pvisible, setPVisible] = useState<boolean>(false);

  const openPopup = () => {
    setPVisible(!pvisible);
    setTootorialUrl(video.link);
  };

  const handleModalAction = () => {
    setPVisible(!pvisible);
  };

  return (
    <VideoContainer>
      {pvisible && (
        <Popup
          allowFullScreen
          canClose={true}
          closeType={2}
          urlTootorial={tootorialUrl}
          handleModalAction={handleModalAction}
        />
      )}
      <VideoCard>
        <Container style={{ minHeight: "140px" }}>
          <ContainerItem width="30%" style={{ background: "black" }}>
            <VideoImage
              style={{
                backgroundImage: `url('${config.urls.res}/platform/tootorials/content/${video.image}')`,
              }}
              onClick={() => openPopup()}
            ></VideoImage>
          </ContainerItem>
          <ContainerItem width="40%">
            <Container
              width="80%"
              margin="8px 16px"
              height="100%"
              display="block"
            >
              <CustomTitle textAlign="left" fontSize="16px">
                {video.title}
              </CustomTitle>
              <Text>{video.description}</Text>
            </Container>
          </ContainerItem>
          <ContainerItem width="30%" style={{ position: "relative" }}>
            <Button onClick={() => openPopup()}>Ver clase</Button>
          </ContainerItem>
        </Container>
      </VideoCard>
    </VideoContainer>
  );
};
