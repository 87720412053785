import {instance} from "../axios/axiosConfig";

export async function getStatsByProjectId(projectId: number, startDate: string, finalDate: string, isMonthFilter: boolean) {
    const queryParams = isMonthFilter
        ? `?startDate=${startDate}&finalDate=${finalDate}&groupByMonth=true`
        : `?startDate=${startDate}&finalDate=${finalDate}`
    const response = await instance.get(`/app/stats/${projectId}${queryParams}`);

    if(response.status === 204) {
        return {}
    }

    return response?.data?.data;
}
