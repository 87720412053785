import { openUpPopUp } from "src/styles/animations/globalAnimations";
import { globalstyles } from "../../styles/animations/globalstyles";
import styled from "styled-components";

export const CardButton = styled.div`
  border-top: 1px solid white;
  color: black;
  cursor: pointer;
  font-weight: bolder;
  padding: 8px 0;
  text-align: center;
  transition: 0.2s;
  width: 100%;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    color: black;
  }
`;

export const PopUpText = styled.div`
  color: black;
  font-size: ${globalstyles.fontSize.popUpTitle};
  font-weight: bold;
  margin: 1rem 0;
`;

export const PopUpCard = styled.div`
  animation: ${openUpPopUp} 0.5s reverse both;
  align-items: center;
  background-color: white;
  border-radius: ${globalstyles.borders.popUpMainCard};
  box-shadow: 0 0 15px 2px #0000006b;
  display: flex;
  flex-direction: column;
  max-width: 40%;
  overflow: hidden;
  padding: 2rem;
  position: relative;
  -webkit-animation: ${openUpPopUp} 0.5s reverse both;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 90%;
    max-width: none;
  }

  svg {
    cursor: pointer;
    filter: brightness(0.4);
    filter: contrast(5%);
    height: 25px;
    position: absolute;
    right: 2rem;
    top: 2rem;
    transition: all ease-in-out 0.2s;
    width: 25px;
    z-index: 9999;

    &:hover {
      filter: contrast(60%);
    }
  }
`;

export const GlobalPContainer = styled.div`
  align-items: center;
  display: flex;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  transition: ${globalstyles.animations.low};
  width: 100%;
  z-index: 200;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 2rem;
  margin-top: 1rem;
  width: 100%;
`;
