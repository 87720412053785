import { Searchbar } from "src/components/searchbar/searchbar.component";
import { IntegrationsData } from "src/mocks/integrations.data";
import { Button, Container } from "src/styles/globalcomponents";
import {
  CategoryLink,
  ColumnBottom,
  ComingSoon,
  ComingSoonLayer,
  IntDescription,
  IntegrationIcon,
  IntTitle,
  MainTitle,
} from "./integrations.styles";

const Integrations = () => {
  return (
    <Container
      alignItems="center"
      backgroundColor="#F9F9F9"
      borderRadius="2rem"
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
      margin="120px auto 0 auto"
      padding="2rem 1rem"
      responsive="padding: 0.6rem; width: 97%;"
      width="95%"
    >
      <Container
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        display="flex"
        responsive="span{font-size: 15px; text-align: center; width: 90%;}"
      >
        <span>Integraciones con aplicaciones</span>
        <MainTitle>
          <strong>Todas tus herramientas</strong> favoritas en{" "}
          <strong>un sólo lugar</strong>
        </MainTitle>
        <span>
          Conecta <strong>MasterTools</strong> con las herramientas que
          complementan tu negocio
        </span>
      </Container>

      <Container height="60%" gap="0.6rem" margin="2.5rem 0" width="100%">
        <ColumnBottom
          flexDirection="column"
          alignItems="center"
          animation="left"
          height="300px"
          margin="0 0 0 3rem"
          responsive="margin: unset;"
          width="35%"
        >
          <Searchbar
            margin="1rem auto"
            setValue={() => {}}
            width="85%"
          ></Searchbar>
          <CategoryLink>Gestión logística</CategoryLink>
          <CategoryLink>Videoconferencias</CategoryLink>
        </ColumnBottom>
        <ColumnBottom
          animation="bottom"
          alignItems="baseline"
          backgroundColor="transparent"
          flexWrap
          gap="1rem"
          overflow="scroll"
          width="60%"
        >
          {IntegrationsData.map((data) => {
            return (
              <ColumnBottom
                flexDirection="column"
                padding="0.5rem 0"
                overflow="hidden"
              >
                <ComingSoonLayer>
                  <ComingSoon>
                    <h6>PRÓXIMAMENTE</h6>
                  </ComingSoon>
                </ComingSoonLayer>
                <IntegrationIcon src={data.urlIcon} />
                <IntDescription flexDirection="column">
                  <IntTitle>{data.title}</IntTitle>
                  <span style={{ fontSize: "13px" }}>{data.description}</span>
                </IntDescription>

                <Button
                  borderRadius={"3rem"}
                  margin={"1rem auto"}
                  typeButton={2}
                  width="60%"
                >
                  Instalar
                </Button>
              </ColumnBottom>
            );
          })}
        </ColumnBottom>
      </Container>
    </Container>
  );
};

export default Integrations;
