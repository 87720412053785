import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../hooks/useRedux";
import { Card, Container, ContainerItem, CustomTitle, } from "../../../../styles/globalcomponents";

import {
  AcceptButtonStyled,
  CancelButtonStyled,
  DecreaseButtonStyled,
  DowngradePictureStyled,
  FormCard,
  FormContainer,
  RecommendedPlanCardList,
} from "./unsubscribe.styles";
import { ToastService } from "../../../../services/toastService";
import { CancellationFormAnswer, PlansService } from "../../../../services/plansService";
import { Redirect, useHistory } from "react-router-dom";
import { config } from "src/config";
import { LeftArrowIcon } from "../../../../components/icons";
import { BackButtonStyled } from "../../../../components/ui/backButton/index.styles";
import { ChangeToLitePlanModal } from "./modal/ChangeToLitePlanModal.component";
import { UnsubscribeForm } from "./unsubscribeForm.component";
import { AccountCancellationModal } from "./accountCancellationModal.component";
import { LocalStorageKeys } from "../../../../redux/localStorageKeys";
import { loginReducerInitialState } from "../../../../interfaces/redux";
import { closeSession } from "../../../../redux/slices/auth";
import { sendEvent } from "../../../../services/events";
import { PLANS_PERIODICITY_HIERARCHY } from "../plan/tabs/plans.data";
import { PlanFrequency } from "src/interfaces/plans";
import RecommendedPlanCard from "./downgrade/recommendedPlanCard.component";

export interface UnsubscribeFormState {
  cancelReasons: string;
  isAMigration: boolean;
  migrationReason: string;
  possibilityToReEntry: number;
  mainReason: string;
  feedback: string;
  hasAcceptedTerms: boolean;
}


interface Props {
  onAbortCancellation: () => void
  isEmailCancellation?: boolean
}

const FORM_INITIAL_STATE: UnsubscribeFormState = {
  cancelReasons: "",
  isAMigration: false,
  migrationReason: "",
  possibilityToReEntry: 0,
  mainReason: "",
  feedback: "",
  hasAcceptedTerms: false,
}

const toastService = new ToastService();
const plansService = new PlansService();

export const Unsubscribe = ({ onAbortCancellation, isEmailCancellation = false }: Props) => {
  const dispatch = useAppDispatch()
  const history = useHistory();
  const { auth } = useAppSelector((state) => state);
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const currPlan = dataUser.suscription
  const alreadyHasLitePlanOrIsTrial = dataUser.suscription.name === "Lite" || dataUser.suscription.name === "Trial"
  const darkMode = useAppSelector((state) => state.session.darkMode);
  const [isOpenChangeToLiteModal, setIsOpenChangeToLiteModal] = useState<boolean>(false)
  const [isOpenCancellationModal, setIsOpenCancellationModal] = useState(false);
  const [formState, setFormState] = useState<UnsubscribeFormState>(FORM_INITIAL_STATE);
  const isFormValid = formState.mainReason !== "" && formState.hasAcceptedTerms && formState.cancelReasons !== "" && formState.feedback !== "" && formState.isAMigration !== undefined
  const haveAccountCancelled = dataUser.cancelRequest === 1
  const [isDowngradeDisabled, setIsDowngradeDisabled] = useState<boolean>(true)
  const plans = useAppSelector((state) => state.auth.plans);
  const planHasLowerOptions = PLANS_PERIODICITY_HIERARCHY[dataUser.suscription.typeSuscription as PlanFrequency] > 1

  function closeChangeToLiteModal() {
    setIsOpenChangeToLiteModal(false)
  }

  function closeCancellationModal() {
    setIsOpenCancellationModal(false)
  }

  function openChangePlanModal() {
    setIsOpenChangeToLiteModal(true)
  }

  function setFormData(newState: Partial<UnsubscribeFormState>) {
    setFormState((prev) => ({ ...prev, ...newState }))
  }

  function closeUserSession() {
    localStorage.removeItem(LocalStorageKeys.userLoggedInfo);
    const closeSessionUser = loginReducerInitialState();
    dispatch(closeSession(closeSessionUser));
  }

  function handleRedirectToProfile() {
    setTimeout(() => {
      history.push("/profile#account")
      closeUserSession()
      toastService.warning("Redirigiendo al perfil...")
    }, 3000)
  }

  async function handleUnsubscribeUser() {
    const message = isEmailCancellation ? "¡Herramienta desactivada con éxito!" : "La cancelación de su plan se ha realizado correctamente";

    try {
      if (dataUser.existPaytoolLogic) {
        await plansService.unsubscribeByPaytool({
          email: dataUser.email,
          product: isEmailCancellation ? "Email" : "Mastertools",
        });
      }

      const cancellationForm: CancellationFormAnswer[] = [
        {
          id: 1,
          question: "¿Por qué estás cancelando tu suscripción de MasterTools?",
          answer: formState.cancelReasons,
          type: "MULTIPLE"
        },
        {
          id: 2,
          question: "¿Vas a migrar hacia otra herramienta?",
          answer: [formState.isAMigration ? "Si." : "No.", formState.migrationReason].join(", "),
          type: "MULTIPLE"
        },
        {
          id: 3,
          question: "¿Qué tan probable es que regreses en el futuro? (0-10)",
          answer: String(formState.possibilityToReEntry),
          type: "SINGLE"
        },
        {
          id: 4,
          question: "¿Cuéntanos cuál fue la situación que te llevó a tomar esta decisión?",
          answer: formState.mainReason,
          type: "SINGLE"
        },
        {
          id: 5,
          question: "¿Qué cambio o cambios implementarías en la herramienta que te harían cambiar de opinión?",
          answer: formState.feedback,
          type: "SINGLE"
        },
      ]
      await plansService.sendCancellationForm(cancellationForm, Number(dataUser.id));
      toastService.custom(message);
      toastService.success("Su solicitud de cancelación se ha enviado correctamente");

      const eventForm = cancellationForm.reduce((prev, curr) => ({
        ...prev,
        [`question${curr.id}`]: {
          label: curr.question,
          answer: curr.answer,
          type: curr.type
        }
      }), {})

      sendEvent({
        source: "MASTERTOOLS-SUBSCRIPTION-CANCELLATION",
        detailType: "SCHEDULED-CANCELLATION",
        detail: {
          dataUser,
          form: eventForm,
          currentPlan: isEmailCancellation ? "Secondary - EmailTool" : `Principal - ${dataUser.suscription.name}`,
        }
      }).then(() => console.log("Event sent!"))
    } catch (error) {
      console.log("🚧🚧Error", error)
      toastService.error("Hubo un problema al tratar de cancelar tu plan. Por favor, inténtalo mas tarde.");
    } finally {
      handleRedirectToProfile();
    }

    setIsOpenCancellationModal(false);
  }

  useEffect(() => {
    function checkIfDowngradeIsScheduled() {
      if (alreadyHasLitePlanOrIsTrial) {
        setIsDowngradeDisabled(true)
      } else {
        const isDowngradeScheduled = dataUser.suscription.isDowngradeScheduled
        setIsDowngradeDisabled(isDowngradeScheduled)
      }
    }
    checkIfDowngradeIsScheduled()
  }, [alreadyHasLitePlanOrIsTrial, dataUser.suscription.isDowngradeScheduled]);

  if (haveAccountCancelled) return <Redirect to="/profile#my-subscriptions" />

  console.log(formState)

  return (
    <>
      <Container display={"grid"} gap={"1rem"} gridTemplateColumns={"1fr"} maxWidth={"100%"} responsiveLg={(!isEmailCancellation && !alreadyHasLitePlanOrIsTrial) ? "grid-template-columns: 1fr 65%" : ""}>
        {(!isEmailCancellation && !alreadyHasLitePlanOrIsTrial) &&
          <ContainerItem>
            <Card
              backgroundColor={darkMode ? "#0d0d0d" : "white"}
              padding={"1rem 2rem 2rem"}
              position={"relative"}
            >
              {!isDowngradeDisabled && <Container
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <BackButtonStyled onClick={onAbortCancellation}>
                  <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
                <DowngradePictureStyled src={`${config.urls.res}/platform/mastertools-avatar/apps.png`} width={"150px"} height={"250px"} />
                <CustomTitle color="black" margin="0 auto 0 auto" fontWeight={"800"} fontSize="1.3rem">
                  ¡Aún podemos continuar!
                </CustomTitle>
                <small className={"text-center"}>Toca en el botón y aprovecha esta única oferta</small>
                <DecreaseButtonStyled
                  onClick={() => !isDowngradeDisabled && openChangePlanModal()}
                  disabled={isDowngradeDisabled}
                >
                  <span>CONTINUAR PAGANDO <strong>$15USD</strong></span>
                </DecreaseButtonStyled>
              </Container>}
              {isDowngradeDisabled || planHasLowerOptions && <>
                <hr />
                <Container flexDirection="column" margin="0 0 1rem">
                  <small className={"text-center"}>o tenemos estas otras alternativas:</small>
                </Container>
              </>}
              {isDowngradeDisabled && planHasLowerOptions && <>
                <BackButtonStyled onClick={onAbortCancellation}>
                  <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
                <Container flexDirection="column" margin="0 0 1rem">
                  <CustomTitle color="black" fontWeight={"800"} margin="1rem 0 0">
                    ¡No te vayas!
                  </CustomTitle>
                  <small className={"text-center"}>Ajusta tu suscripción, no la canceles</small>
                </Container>
              </>
              }
              <RecommendedPlanCardList>
                {
                  planHasLowerOptions && Object.keys(PLANS_PERIODICITY_HIERARCHY).map(periodicity => {
                    const isALowerPlanThanCurrent = PLANS_PERIODICITY_HIERARCHY[periodicity as PlanFrequency] < PLANS_PERIODICITY_HIERARCHY[currPlan.typeSuscription as PlanFrequency]

                    return plans.map(plan => {
                      const isSamePlanWithLowerPeriodicity = plan.suscriptionInfo.name === currPlan.name && isALowerPlanThanCurrent
                      if (!isSamePlanWithLowerPeriodicity) return
                      return <RecommendedPlanCard mode="LIST" periodicity={periodicity as PlanFrequency} plan={plan} key={plan.suscriptionInfo.idSuscription} />
                    })
                  })
                }
              </RecommendedPlanCardList>
            </Card>
          </ContainerItem>
        }
        <ContainerItem>
          <Card
            backgroundColor={darkMode ? "#0d0d0d" : "white"}
            padding={"0 2rem 2.5rem 2rem"}
            overflow={"auto"}
            height={"100%"}
            position={"relative"}
          >
            <Container
              margin={"auto"}
              display={"flex"}
              width={"100%"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              {(isEmailCancellation || alreadyHasLitePlanOrIsTrial) &&
                <BackButtonStyled onClick={onAbortCancellation}>
                  <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
              }
              <CustomTitle color="black" margin="2rem auto 0 auto" fontWeight={"800"}>
                {auth.dataUser.nameUser}, ¡Lamentamos que te vayas!
              </CustomTitle>
              <small className="text-center">
                Ayúdanos a mejorar, completa este breve formulario para finalizar la cancelación de tu suscripción.
              </small>
              <FormContainer>
                <UnsubscribeForm setFormData={setFormData} formState={formState} isEmailCancellation={isEmailCancellation} />
                <Container alignItems={"center"} justifyContent={"center"} gap={"2rem"} margin={"2rem 0"}>
                  <CancelButtonStyled disabled={!isFormValid} onClick={() => isFormValid && setIsOpenCancellationModal(true)}>
                    {isEmailCancellation ? "Desactivar herramienta" : "Cancelar mi cuenta"}
                  </CancelButtonStyled>
                  <AcceptButtonStyled onClick={handleRedirectToProfile}>
                    ¡Ni pensarlo!
                  </AcceptButtonStyled>
                </Container>
              </FormContainer>
            </Container>
          </Card>
        </ContainerItem>
      </Container>
      <AccountCancellationModal onUnsubscribeUser={handleUnsubscribeUser} isOpen={isOpenCancellationModal} onClose={closeCancellationModal} isEmailCancellation={isEmailCancellation} />
      <ChangeToLitePlanModal isOpen={isOpenChangeToLiteModal} closeModal={closeChangeToLiteModal} />
    </>
  );
};
