import React from "react"
import { SVGProps } from "react"

/*
* Tool to convert a SVG element to React customizable component https://react-svgr.com/playground/?typescript=true
* NOTE: We enforce that you use SVG interface declared down below, to maintain the consistency of SVG components
* */

interface SVG extends SVGProps<SVGSVGElement> {
    size?: string | number;
}

export function CameraIcon(props: SVG) {
    return <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? props.size: 20} height={props.size ? props.size: 18} {...props}>
        <path
            fill="#a5a5a5"
            d="M6 13.85h8v-.475a1.781 1.781 0 0 0-1.062-1.625 7.791 7.791 0 0 0-5.875 0A1.781 1.781 0 0 0 6 13.375Zm4-4.35a1.832 1.832 0 1 0-1.287-.537A1.762 1.762 0 0 0 10 9.5ZM1.5 18A1.538 1.538 0 0 1 0 16.5V3.675a1.453 1.453 0 0 1 .45-1.037 1.421 1.421 0 0 1 1.05-.462h3.675L7 0h6l1.825 2.175H18.5a1.587 1.587 0 0 1 1.5 1.5V16.5a1.421 1.421 0 0 1-.462 1.05A1.453 1.453 0 0 1 18.5 18Zm17-1.5V3.675h-4.375L12.3 1.5H7.7L5.875 3.675H1.5V16.5ZM10 9Z"
        />
    </svg>;
}

export const CloseIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size: 20}
        height={props.size ? props.size: 20}
        viewBox={`0 0 23 24`}
        fill="none"
        {...props}
    >
        <path
            stroke="#A5A5A5"
            strokeWidth={2}
            d="M0-.5h27.546"
            transform="matrix(-.6955 .71853 -.6955 -.71853 19.587 .864)"
        />
        <path
            stroke="#A5A5A5"
            strokeWidth={2}
            d="M0-.5h27.546"
            transform="scale(-.98358 -1.01616) rotate(45 14.4 -35.338)"
        />
    </svg>
)

export const UserFormIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size: 16}
        height={props.size ? props.size: 16}
        viewBox={"0 0 20 20"}
        {...props}
    >
        <path
            fill="#cecece"
            d="M1.575 21.006A1.617 1.617 0 0 1 0 19.43V6.83a1.617 1.617 0 0 1 1.575-1.576h6.223V1.578A1.617 1.617 0 0 1 9.374.003h2.284a1.617 1.617 0 0 1 1.576 1.575v3.676h6.2A1.616 1.616 0 0 1 21.01 6.83v12.6a1.616 1.616 0 0 1-1.576 1.576Zm0-1.576H19.43V6.83h-6.2a1.446 1.446 0 0 1-.486 1.155 1.8 1.8 0 0 1-1.221.42H9.475a1.763 1.763 0 0 1-1.195-.42 1.446 1.446 0 0 1-.486-1.155H1.571Zm2.416-2.809h6.276v-.368a1.516 1.516 0 0 0-.237-.84 1.188 1.188 0 0 0-.6-.5 10.773 10.773 0 0 0-1.312-.381 4.844 4.844 0 0 0-.919-.092 5.192 5.192 0 0 0-1.064.119 11.518 11.518 0 0 0-1.273.354 1.181 1.181 0 0 0-.631.5 1.524 1.524 0 0 0-.236.84Zm8.823-1.759h4.463v-1.313h-4.463Zm-5.619-1.313a1.349 1.349 0 0 0 1.01-.42 1.383 1.383 0 0 0 .407-1 1.39 1.39 0 0 0-1.417-1.418 1.385 1.385 0 0 0-1 .407 1.35 1.35 0 0 0-.42 1.011 1.434 1.434 0 0 0 1.418 1.418Zm5.619-1.655h4.463v-1.312h-4.463Zm-3.44-5.067h2.284V1.575H9.374Z"
            data-name="Trazado 231"
        />
    </svg>
)

export const UserIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size: 20}
        height={props.size ? props.size: 20}
        {...props}
    >
        <path
            fill="#cecece"
            d="M10.395 9.745a4.674 4.674 0 1 1 3.508-1.364 4.746 4.746 0 0 1-3.508 1.364Zm-10.4 10.428v-3.054a3.575 3.575 0 0 1 .617-2.111 3.961 3.961 0 0 1 1.592-1.332 24.4 24.4 0 0 1 4.174-1.462 16.866 16.866 0 0 1 4.012-.487 16.189 16.189 0 0 1 3.995.5 27.717 27.717 0 0 1 4.158 1.446 3.914 3.914 0 0 1 1.633 1.335 3.575 3.575 0 0 1 .617 2.111v3.054Zm1.949-1.949h16.892v-1.105a1.78 1.78 0 0 0-.309-.991 1.96 1.96 0 0 0-.763-.7 17.266 17.266 0 0 0-3.8-1.381 16.812 16.812 0 0 0-3.573-.373 17.277 17.277 0 0 0-3.606.373 16.512 16.512 0 0 0-3.8 1.381 1.956 1.956 0 0 0-1.04 1.689ZM10.39 7.796a2.834 2.834 0 1 0-2.095-.828 2.839 2.839 0 0 0 2.1.828Z"
            data-name="Trazado 232"
        />
    </svg>
)

export const EmailIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size: 19}
        height={props.size ? props.size: 15}
        {...props}
    >
        <path
            fill="#cecece"
            d="M1.466 15.638A1.5 1.5 0 0 1 0 14.172V1.466A1.5 1.5 0 0 1 1.466 0h16.615a1.5 1.5 0 0 1 1.466 1.466v12.706a1.5 1.5 0 0 1-1.466 1.466Zm8.308-7.379L1.466 2.81v11.362h16.615V2.81Zm0-1.466 8.21-5.327h-16.4ZM1.466 2.81Z"
            data-name="Trazado 233"
        />
    </svg>
)

export const CheckIcon = (props: SVG) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         width={props.size ? props.size : 16}
         height={props.size ? props.size : 12}
         viewBox={props.viewBox ? props.viewBox : "0 0 16 14"}
         {...props}
    >
        <path
            fill="#fff"
            d="M6.881 14.438-.001 7.556 1.32 6.235l5.561 5.561 11.8-11.8 1.321 1.322Z"
            data-name="Trazado 184"
        />
    </svg>
)

export const PrecautionIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size : 89}
        height={props.size ? props.size : 89}
        fill="none"
        {...props}
    >
        <path
            fill="#F92828"
            d="M89 44.5C89 69.077 69.077 89 44.5 89S0 69.077 0 44.5 19.923 0 44.5 0 89 19.923 89 44.5Zm-81.71 0c0 20.551 16.659 37.21 37.21 37.21 20.551 0 37.21-16.659 37.21-37.21 0-20.551-16.659-37.21-37.21-37.21-20.551 0-37.21 16.659-37.21 37.21Z"
        />
        <path fill="#F92828" d="M42 24h6v26h-6z" />
        <circle cx={45} cy={64} r={4} fill="#F92828" />
    </svg>
)


export const SuccessIcon = (props: SVG) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 108.9 108.9"
         width={props.size ? props.size : 108 }
         height={props.size ? props.size : 108 }
         {...props}
    >
        <path
            d="M46.42 79.23 84.94 40.7l-6.26-6.13-32.26 32.26-16.33-16.34-6.13 6.13 22.46 22.6Zm8.03 29.67c-7.25.04-14.44-1.42-21.1-4.29A54.864 54.864 0 0 1 4.29 75.55a54.38 54.38 0 0 1 0-42.34A54.478 54.478 0 0 1 16 15.92c5-4.94 10.89-8.89 17.36-11.64a54.354 54.354 0 0 1 42.33 0 54.392 54.392 0 0 1 28.93 28.93 54.354 54.354 0 0 1 0 42.33A55.82 55.82 0 0 1 92.98 92.9a54.367 54.367 0 0 1-17.29 11.71 52.927 52.927 0 0 1-21.24 4.29Zm0-8.17c25.48.09 46.2-20.5 46.28-45.97v-.31c.15-25.41-20.33-46.13-45.74-46.28h-.54C28.88 8.18 8.16 28.92 8.17 54.49c0 12.26 4.88 24.03 13.54 32.7a44.635 44.635 0 0 0 32.74 13.55Z"
            style={{
                fill: "#00dfb0",
            }}
        />
    </svg>
)

export const LeftArrowIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size : 20 }
        height={props.size ? props.size : 20 }
        viewBox={"0 0 28 28"}
        fill="none"
        {...props}
    >
        <path
            fill={props.fill ? props.fill : "black"}
            d="M.586 13.586a2 2 0 0 0 0 2.828l12.728 12.728a2 2 0 1 0 2.828-2.828L4.828 15 16.142 3.686A2 2 0 1 0 13.314.858L.586 13.586ZM2 17h31v-4H2v4Z"
        />
    </svg>
)

export const AddIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size : 8.6 }
        height={props.size ? props.size : 8.6 }
        {...props}
    >
        <g data-name="Grupo 1273">
            <g fill="#fff" data-name="Grupo 1272">
                <path
                    d="M4.319 0A4.331 4.331 0 1 1 0 4.356 4.333 4.333 0 0 1 4.319 0Zm3.722 4.327a3.711 3.711 0 1 0-3.727 3.715 3.713 3.713 0 0 0 3.727-3.715Z"
                    data-name="Trazado 4222"
                />
                <path
                    d="M4.02 3.211v-.715a.31.31 0 1 1 .618 0v1.43c0 .073.015.1.094.1h1.44a.307.307 0 0 1 .3.2.3.3 0 0 1-.071.329.32.32 0 0 1-.246.091h-1.42c-.082 0-.1.023-.1.1v1.44a.3.3 0 0 1-.188.29.29.29 0 0 1-.331-.058.321.321 0 0 1-.1-.252v-1.42c0-.079-.017-.1-.1-.1H2.505a.311.311 0 1 1 0-.619h1.42c.079 0 .1-.023.094-.1-.001-.238.001-.477.001-.716Z"
                    data-name="Trazado 4223"
                />
            </g>
        </g>
    </svg>
)

export const TrashIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 9.43 9.82"
        width={props.size ? props.size : 8.6 }
        height={props.size ? props.size : 8.6 }
        {...props}
    >
        <path
            d="M4.73 9.82H2.18c-.11 0-.23-.01-.34-.02-.33-.02-.65-.36-.68-.71-.03-.44-.05-.87-.08-1.31L.99 6.07C.97 5.73.95 5.39.94 5.06.9 4.31.87 3.55.84 2.8c0-.1-.05-.14-.14-.13H.16c-.11 0-.15-.05-.15-.15v-.28c0-.3.16-.46.46-.47h1.34c.16 0 .16 0 .16-.16.01-.31-.03-.61.03-.92.03-.16.12-.3.19-.44.02-.04.06-.06.1-.08.18-.11.39-.17.61-.17h3.64c.21 0 .41.06.6.15.15.07.19.22.25.35.06.14.08.29.08.44v.67c0 .11.03.15.14.15h1.32c.36 0 .5.14.5.5v.25c0 .11-.05.16-.16.16h-.53c-.1 0-.14.04-.15.14-.03.74-.07 1.48-.1 2.21L8.37 7.5c-.03.52-.07 1.03-.09 1.55-.01.34-.17.57-.47.71-.13.06-.29.06-.43.06H4.73Zm0-7.16H1.94c-.17 0-.18 0-.17.17.01.37.03.73.05 1.1.03.67.07 1.35.1 2.02.04.95.09 1.89.13 2.84 0 .1.03.14.14.14h5.07c.1 0 .17 0 .17-.13.03-.73.07-1.46.1-2.19.02-.41.03-.82.05-1.24.05-.84.08-1.67.12-2.51 0-.2 0-.2-.2-.2H4.74Zm0-1.77H2.99c-.09 0-.13.02-.13.12v.62c0 .09.02.12.12.12h3.48c.09 0 .12-.04.12-.12v-.58q0-.17-.17-.17H4.73Z"
            style={{
                fill: "#fff",
            }}
        />
    </svg>
)

export const ToolIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ? props.size : 15.667}
        height={props.size ? props.size : 15.662}
        {...props}
    >
        <g data-name="Grupo 864">
            <path
                fill="#a8a8a8"
                d="M15.667 5.134a5.064 5.064 0 0 1-.392 1.823 5.218 5.218 0 0 1-1.639 2.108 4.7 4.7 0 0 1-2.753.994 5.955 5.955 0 0 1-1.341-.113.337.337 0 0 0-.332.1q-2.677 2.683-5.36 5.362l-.041.042c-.282.282-.326.279-.613-.009-1.029-1.03-2.056-2.062-3.092-3.086-.133-.131-.142-.209-.008-.344q2.529-2.541 5.048-5.09c.166-.167.327-.338.491-.506a.274.274 0 0 0 .084-.266 5.154 5.154 0 0 1 .154-2.743 4.874 4.874 0 0 1 1.622-2.3A5.151 5.151 0 0 1 10.203.03a4.739 4.739 0 0 1 2.745.519c.471.244.494.409.114.786q-1.509 1.5-3.024 2.987c-.121.118-.135.189-.008.313.354.348.7.708 1.041 1.064.06.063.1.129.2.026.9-.964 1.855-1.875 2.783-2.813.151-.153.3-.307.461-.452.2-.184.349-.167.493.066a3.019 3.019 0 0 1 .392.852 7.053 7.053 0 0 1 .267 1.756Zm-4.823-3.648a3.346 3.346 0 0 0-1.36.177 3.046 3.046 0 0 0-1.8 1.34 3.527 3.527 0 0 0-.306 3.494.23.23 0 0 1-.047.3c-.389.4-.764.8-1.157 1.2q-2.025 2.021-4.06 4.03c-.141.139-.141.229 0 .367q.583.579 1.146 1.181c.124.133.209.157.34.006.2-.226.41-.438.622-.651q2.015-2.018 4.031-4.032c.2-.2.414-.381.6-.594a.178.178 0 0 1 .258-.054 3.471 3.471 0 0 0 2.922.057 3.627 3.627 0 0 0 2.189-3.306c0-.045.011-.1-.041-.119s-.064.034-.09.06c-.786.784-1.562 1.578-2.373 2.337-.387.363-.594.37-.993.021-.861-.754-1.642-1.589-2.44-2.405a.53.53 0 0 1-.159-.386.78.78 0 0 1 .268-.586q1.02-1.017 2.037-2.037c.118-.131.245-.244.413-.4Z"
                data-name="Trazado 868"
            />
        </g>
    </svg>
)

interface ArrowIconProps extends SVG{
    direction?: 'left' | 'right' | 'bottom' | 'top'
}

export const ArrowIcon = (props: ArrowIconProps) => {
    const DIRECTIONS: any = {
        'left': 'rotateZ(180deg)',
        'right': '',
        'bottom': 'rotateZ(90deg)',
        'top': 'rotateZ(270deg)',
    }
    const transform = props.direction ? DIRECTIONS[props.direction] : ''
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={7}
            height={10}
            fill="none"
            style={{
                transform: transform
            }}
            {...props}
        >
            <path
                fill="#000"
                d="M1.707.293A.5.5 0 0 0 1 1l.707-.707ZM1 1l4.5 4.5.707-.707-4.5-4.5L1 1Z"
            />
            <path
                fill="#000"
                d="M.66 8.634a.5.5 0 1 0 .68.732l-.68-.732Zm.68.732 4.147-3.853-.68-.733L.66 8.634l.68.732Z"
            />
        </svg>
    );
}

export const StatsIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15.67}
        height={15.662}
        {...props}
    >
        <g fill="#a8a8a8" data-name="Grupo 70">
            <path
                d="M7.823 15.664H.229c-.229 0-.229 0-.229-.223V.226C0 .002 0 .001.227.001h.627c.105 0 .151.037.143.143v14.283c0 .242 0 .242.237.242H15.4c.295 0 .267-.022.269.275v.581c0 .107-.041.148-.145.141h-.153Z"
                data-name="Trazado 58"
            />
            <path
                d="M9.74 9.305a.4.4 0 0 1-.284-.133c-.432-.395-.843-.812-1.258-1.225L7.062 6.815c-.137-.136-.147-.137-.263.022L4.75 9.632c-.425.578-.839 1.165-1.285 1.728a1.535 1.535 0 0 1-.244.272.384.384 0 0 1-.44.052c-.35-.167-.427-.4-.218-.724.481-.751 1.026-1.456 1.548-2.178q1.026-1.416 2.057-2.828a3.4 3.4 0 0 1 .312-.376.469.469 0 0 1 .745-.029c.378.335.723.7 1.083 1.056q.669.658 1.328 1.329c.095.1.143.083.223-.015q1.952-2.39 3.91-4.772a.5.5 0 0 1 .919.165.414.414 0 0 1-.113.421c-.271.35-.543.7-.823 1.042q-1.485 1.824-2.976 3.642c-.184.224-.378.441-.571.658a.652.652 0 0 1-.465.23Z"
                data-name="Trazado 59"
            />
        </g>
    </svg>
)
export const SalesIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        viewBox="0 0 19.4 13.14"
        width={19.67}
        height={19.662}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#798188}"}</style>
        </defs>
        <g id="Capa_1-2">
            <path
                d="M7.76 13.12c-1.17 0-2.35-.02-3.52 0-1.14.02-2.28.01-3.43.01-.15 0-.3-.02-.45-.06-.24-.06-.36-.22-.36-.46 0-.23.13-.39.37-.46.13-.04.26-.03.39-.03h2.97c.07 0 .17.03.21-.04.05-.09-.03-.16-.07-.22-.37-.54-.72-1.1-.97-1.7-1.27-2.99-.34-6.33 2-8.35C6.43.47 8.22-.12 10.23.02c2.04.14 3.72 1.02 5.09 2.56 1.03 1.17 1.54 2.54 1.71 4.06.15 1.34-.04 2.62-.66 3.84-.25.49-.51.97-.85 1.4-.04.05-.11.11-.08.18.04.09.13.06.2.06h3.2c.39 0 .57.18.56.51 0 .25-.14.42-.38.47-.14.03-.29.04-.43.04H7.77v-.02Zm2.03-1h3.66c.33 0 .57-.07.78-.34.37-.46.83-.86 1.07-1.42.47-1.05.79-2.14.71-3.31-.06-.91-.29-1.77-.66-2.59-.24-.54-.64-.96-1.03-1.39-.31-.35-.63-.71-1.04-.96C12 1.33 10.63.92 9.11 1.06c-1.11.11-2.14.46-3.04 1.09-.85.59-1.53 1.37-2.02 2.29-.23.42-.32.88-.46 1.33-.36 1.18-.27 2.35.08 3.51.27.92.8 1.68 1.41 2.4.27.32.57.43.96.43 1.25-.01 2.5 0 3.75 0Z"
                className="cls-1"
            />
            <path
                d="M10.02 5.73h-.66c-.42.02-.56.16-.56.55 0 .37.14.5.55.54.33.03.67.02 1 .08.93.18 1.56 1.23 1.22 2.12-.17.46-.54.77-1.01.92-.33.11-.33.1-.34.45v.23c-.05.5-.24.56-.72.49-.15-.02-.22-.16-.25-.3-.03-.17-.05-.35-.05-.52 0-.2-.08-.27-.27-.26-.18.01-.37 0-.55 0-.29 0-.42-.15-.45-.46-.02-.26.13-.48.38-.52.16-.03.34-.05.5-.03.44.04.87 0 1.3-.02.37-.01.51-.16.51-.52 0-.37-.13-.53-.51-.56-.38-.03-.76-.02-1.13-.13-.83-.25-1.35-1.01-1.19-1.86.11-.58.52-.98 1.09-1.16.24-.08.34-.18.32-.43-.01-.1 0-.2 0-.3.04-.27.24-.44.53-.44.25 0 .45.17.48.43.02.15.02.3.01.46 0 .15.06.21.2.2h.53c.33.02.54.21.55.5 0 .28-.16.44-.49.49-.3.05-.61.01-.98.03Z"
                className="cls-1"
            />
        </g>
    </svg>
)

export const PendingOrdersIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        viewBox="0 0 11.76 13.88"
        width={19.67}
        height={19.662}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#798188}"}</style>
        </defs>
        <g id="Capa_1-2">
            <path
                d="M1 13.87c-.58 0-.98-.44-.99-1.1-.01-.57 0-1.13 0-1.7v-4.9C0 4.95.01 3.72 0 2.49c0-.38.12-.65.42-.88.16-.13.36-.19.65-.19h1.88c.34 0 .57-.14.71-.43.29-.59.9-.98 1.56-.98.2 0 .39.04.57.11.4.15.69.44.91.88.13.28.36.43.68.43h1.98c.35 0 .57.15.7.28.19.19.31.47.31.73v3.34c-.18-.06-.36-.11-.54-.14V2.67c0-.08 0-.31-.17-.49-.11-.12-.27-.17-.46-.17H8.01l-.27.17c-.11.11-.17.27-.17.45v1.48c-.69-.01-1.34-.02-2-.02H3.35c-.16 0-.55.02-.55.02V2.67c0-.17 0-.68-.64-.68l-1.21.02-.25.18c-.08.08-.16.23-.14.46v9.96c0 .53.19.73.72.73h2.75c.11.2.23.38.36.56H1ZM5.17.57c-.6 0-1.09.5-1.08 1.11 0 .61.49 1.11 1.09 1.11.3 0 .57-.12.79-.33.21-.21.33-.49.32-.79 0-.62-.49-1.11-1.1-1.11h-.01Z"
                className="cls-1"
            />
            <path
                d="M8.68 13.88c-.82 0-1.57-.31-2.13-.87-.61-.61-.94-1.44-.93-2.35 0-1.42 1.16-2.9 3.07-2.92.84 0 1.6.31 2.15.87.6.6.92 1.42.91 2.3-.02 1.58-1.41 2.97-2.97 2.97h-.11Zm-.62-5.42-.13.13-.03.32v1.81c0 .25.09.48.27.66l1.17 1.17c.12.12.25.27.49.27s.36-.14.5-.27l.13-.13c.14-.13.21-.28.22-.45 0-.16-.07-.32-.21-.46l-1-1.02c0-.32-.01-1.35-.01-1.35 0-.46-.06-.85-.78-.85h-.39l-.23.16Z"
                className="cls-1"
            />
        </g>
    </svg>
)
export const DeliveredOrdersIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        viewBox="0 0 17.48 13.93"
        width={19.67}
        height={19.662}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#798188}"}</style>
        </defs>
        <g id="Capa_1-2">
            <path
                d="M0 6.4c0-1.39.02-2.79 0-4.18C-.02.88 1.14.02 2.17.01 4.23-.01 6.28 0 8.34.01c1.1 0 2.16.91 2.19 2.01.01.49.01.98.02 1.48 0 .56 0 .56.57.57.99 0 1.98.05 2.96 0 .96-.05 1.65.34 2.2 1.07.31.42.64.82.88 1.29.21.4.31.84.31 1.29v3.08c0 .55-.22.99-.71 1.28-.21.12-.44.15-.67.16-.26.01-.52.01-.78 0-.14 0-.22.04-.25.18-.14.66-.57 1.11-1.17 1.37-.53.23-1.08.23-1.6-.05-.54-.29-.89-.72-1.05-1.31-.04-.15-.12-.19-.27-.19H6.49c-.14 0-.21.05-.24.18-.16.64-.56 1.1-1.15 1.36-.49.22-1.01.22-1.51-.01-.58-.27-.95-.72-1.12-1.34-.04-.15-.13-.2-.29-.2-.36 0-.71.03-1.07-.04-.53-.09-.96-.52-1.05-1.04-.03-.2-.05-.39-.05-.59V6.41Zm.92.08v4.33c0 .37.1.47.45.49.27.02.55.02.82.03.17.01.28-.05.35-.21.12-.29.33-.51.56-.73.63-.61 1.75-.56 2.3-.12.27.22.53.46.68.78.09.2.22.28.44.28.85-.01 1.7 0 2.55 0 .11 0 .22-.02.33-.02.15 0 .19-.08.19-.22 0-.42.03-.85.02-1.27-.01-.94 0-1.87 0-2.81 0-1.47-.01-2.94-.02-4.41 0-.15 0-.3-.01-.45-.03-.51-.28-.88-.73-1.11-.26-.13-.56-.15-.84-.15H2.5c-.29 0-.6.01-.86.16-.47.25-.72.65-.72 1.18v4.25Zm9.63 1.68v2.91c0 .08-.03.19.08.24.27.12.61 0 .76-.26.05-.09.1-.18.16-.26.66-.93 1.89-1.12 2.74-.44.27.22.48.48.64.79.05.1.11.17.24.16.31-.01.61.03.92 0 .34-.04.45-.13.45-.48V7.58c0-.26-.06-.5-.18-.73-.24-.46-.57-.87-.9-1.27-.32-.41-.72-.62-1.26-.61-1.13.02-2.26 0-3.39 0-.26 0-.27 0-.27.26v2.91ZM3.3 11.89c-.04.65.56 1.1 1.06 1.09.57-.01 1.03-.5 1.03-1.11 0-.53-.49-.97-1.07-.97-.58 0-1.07.49-1.02.98Zm10.86.02c0-.56-.44-.99-1.03-1-.56 0-1.05.43-1.06.93 0 .64.45 1.14 1.04 1.14.59 0 1.05-.46 1.05-1.07Z"
                className="cls-1"
            />
            <path
                d="M13.22 7.17c-.36-.02-.71.02-1.05-.04-.24-.04-.37-.21-.35-.49.01-.24.15-.39.38-.4h1.91c.24 0 .36.15.39.39.02.25-.08.44-.3.49-.33.08-.67 0-.98.04Z"
                className="cls-1"
            />
        </g>
    </svg>
)
export const OrdersIcon = (props: SVG) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.57 14.46" width={19.67} height={19.662} {...props}>
        <path
            d="M12.57 7.22v3.43c0 .16-.05.25-.19.33-1.98 1.14-3.96 2.28-5.94 3.43-.13.07-.22.05-.34-.02-.83-.49-1.67-.99-2.51-1.47-.99-.57-1.99-1.13-2.98-1.7-.16-.09-.31-.2-.48-.28-.11-.06-.15-.13-.15-.25v-6.9c0-.15.04-.24.18-.32C1.91 2.48 3.64 1.48 5.37.48c.24-.14.49-.27.72-.42.14-.09.26-.08.4 0 .52.31 1.04.61 1.56.91 1.44.83 2.89 1.66 4.33 2.49.13.07.19.16.19.32v3.44Zm-6.84 3.11c0-.9-.01-1.71 0-2.52 0-.19-.06-.29-.21-.38-.75-.46-1.52-.9-2.28-1.34-.68-.39-1.36-.8-2.04-1.19-.14-.08-.16-.07-.16.1v1.1c0 .54-.02 1.08.02 1.62 0 .09.03.15.11.2.65.36 1.29.74 1.94 1.09.17.09.22.2.21.37-.04.68-.03 1.35 0 2.03 0 .12.04.19.15.25.69.4 1.4.77 2.07 1.21.05.03.11.1.17.06.08-.04.04-.13.04-.19-.05-.83 0-1.66-.02-2.4Zm1.09-.09v2.52c0 .05-.03.13.03.16s.11-.03.15-.06c.69-.41 1.38-.82 2.07-1.21.19-.11.24-.24.22-.44-.04-.66-.01-1.32-.02-1.98 0-.08.02-.13.1-.17.63-.36 1.25-.73 1.89-1.08.18-.1.25-.21.25-.42-.01-.82 0-1.65 0-2.47 0-.06.05-.14-.02-.17-.06-.03-.11.03-.15.06-1.44.82-2.88 1.65-4.32 2.47-.15.08-.2.17-.2.34v2.45Zm4.14-6.31s-.04-.05-.07-.07l-2.04-1.2c-.11-.06-.19-.04-.29.01-.68.4-1.36.78-2.03 1.19-.18.11-.31.11-.49 0-.67-.41-1.36-.8-2.04-1.2-.07-.04-.13-.09-.23-.03-.68.41-1.37.81-2.05 1.21-.13.08-.11.11 0 .18 1.47.84 2.95 1.67 4.4 2.54.12.07.21.07.32 0 .19-.12.38-.23.57-.34 1.28-.74 2.56-1.49 3.85-2.2.04-.02.09-.03.1-.09Z"
            style={{
                fill: "#798188",
            }}
        />
    </svg>
)


export const TimeIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14.92}
        height={14.91}
        {...props}
    >
        <g fill="#a8a8a8" data-name="Grupo 71">
            <path
                d="M7.473 14.909A7.487 7.487 0 0 1 .008 7.084a7.462 7.462 0 1 1 7.465 7.825Zm.061-1.3A6.149 6.149 0 1 0 1.31 7.438a6.144 6.144 0 0 0 6.224 6.169Z"
                data-name="Trazado 60"
            />
            <path
                d="M6.919 5.973V3.829c0-.213.026-.24.237-.243s.41 0 .615 0 .23.024.231.23V7.62a.363.363 0 0 0 .175.33c.718.51 1.428 1.03 2.145 1.541.123.088.134.162.049.285a4.311 4.311 0 0 1-.447.6.158.158 0 0 1-.244.02c-.837-.647-1.712-1.241-2.563-1.868a.444.444 0 0 1-.2-.392c.007-.724.002-1.444.002-2.163Z"
                data-name="Trazado 61"
            />
        </g>
    </svg>
)

export const ListIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        viewBox="0 0 12.19 10.15"
        width={14.92}
        height={14.91}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#798188}"}</style>
        </defs>
        <g id="Capa_1-2">
            <path
                d="M3.21 5.61v-.96c0-.03.05-.08.09-.09.08-.02.17-.02.25-.02H12.06c.08 0 .12.03.12.12v.82c0 .09-.04.13-.13.13H3.2ZM7.68 1.36H3.46c-.22 0-.24-.03-.24-.24V.46c0-.13.06-.17.17-.17h8.53c.27 0 .28.01.28.28v.51c0 .26-.02.29-.29.29H7.69ZM7.7 9.86H3.45c-.21 0-.24-.03-.24-.24v-.66c0-.12.05-.16.16-.17H11.91c.26 0 .28.01.28.28v.55c0 .2-.04.24-.24.24H7.71ZM.84 1.65C.38 1.66.02 1.3 0 .84A.81.81 0 0 1 .79 0c.53 0 .84.34.86.81.01.37-.32.87-.81.84ZM.84 10.15c-.4 0-.84-.31-.84-.83 0-.5.42-.82.79-.82.54 0 .84.34.86.82 0 .37-.32.87-.81.84ZM0 5.07c0-.47.36-.83.82-.82.48 0 .82.36.82.84 0 .47-.35.8-.84.8-.47 0-.8-.34-.8-.82Z"
                className="cls-1"
            />
        </g>
    </svg>
)

export const CheckListIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        viewBox="0 0 12.68 9.51"
        width={14.92}
        height={14.91}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#798188}"}</style>
        </defs>
        <g id="Capa_1-2">
            <path
                d="M6.39 0h5.07c.61 0 1.07.38 1.2.97.02.07.02.15.02.22v6.99c0 .17-.02.35-.06.51-.08.35-.51.72-.88.78-.14.02-.28.03-.42.03H1.38c-.47 0-.87-.12-1.15-.51-.18-.23-.23-.5-.23-.78V1.26C0 .55.51.02 1.22 0c.52-.01 1.03 0 1.55 0h3.62Zm-.03 8.69h4.9c.51 0 .61-.1.61-.61V1.44c0-.11 0-.21-.02-.32-.02-.17-.12-.27-.29-.29-.11-.02-.21-.03-.32-.03H1.38c-.1 0-.2.01-.29.03-.15.03-.24.13-.26.28-.01.11-.03.22-.03.33v6.67c0 .1.01.19.03.28.03.16.13.26.29.28.12.02.25.03.37.03h4.87Z"
                className="cls-1"
            />
            <path
                d="M7.92 3.18h-2c-.23-.02-.37-.16-.38-.39 0-.23.11-.37.35-.4H9.9c.16 0 .3.04.37.19.07.16.06.32-.04.47-.08.12-.22.14-.35.14H7.92ZM7.96 5.54h1.93c.3 0 .43.14.41.44-.01.21-.13.33-.34.36H5.9c-.23-.02-.36-.16-.37-.39 0-.23.1-.36.33-.4.06-.01.13-.01.2-.01h1.89ZM2.08 3.17c.03-.18.12-.32.29-.41.14-.08.27-.03.39.07.02.02.05.03.07.05.06.07.11.05.18 0 .26-.27.53-.53.8-.79.11-.12.24-.2.41-.13.18.07.29.22.32.41.02.11-.05.2-.12.27-.27.27-.54.53-.8.8-.12.12-.23.24-.34.36-.06.07-.14.11-.22.16-.09.05-.17.05-.26-.01-.25-.18-.45-.4-.64-.63a.246.246 0 0 1-.05-.15ZM2.08 6.33c.03-.18.12-.31.28-.4.15-.08.28-.03.4.07.15.12.15.12.29-.01.25-.25.51-.5.76-.75.12-.12.25-.19.41-.11.18.08.28.23.3.42.01.11-.05.19-.13.26l-.81.81c-.12.12-.24.25-.36.37-.22.21-.37.21-.59.01-.15-.14-.29-.28-.43-.43-.06-.07-.13-.14-.12-.25Z"
                className="cls-1"
            />
        </g>
    </svg>
)

export const CalendarIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={9.796}
        height={9.793}
        {...props}
    >
        <g data-name="Grupo 37">
            <path
                fill="#aaa"
                d="M4.911 9.792h-3.64a1.266 1.266 0 0 1-1.006-.484 1.2 1.2 0 0 1-.261-.742V1.829A1.272 1.272 0 0 1 .983.638a3.739 3.739 0 0 1 .724-.03c.132 0 .132 0 .133-.13V.249c.015-.177.132-.26.349-.253a.264.264 0 0 1 .271.272 1.989 1.989 0 0 1 0 .229c-.008.092.031.122.116.11h4.633c.132 0 .132 0 .133-.129v-.22c.011-.178.128-.267.34-.262a.264.264 0 0 1 .281.273V.45c0 .157 0 .157.152.157h.516A1.261 1.261 0 0 1 9.78 1.652a2.4 2.4 0 0 1 .017.295v6.475a2.553 2.553 0 0 1-.03.409 1.251 1.251 0 0 1-1.148.953H4.912Zm-.012-7.334H.772c-.147 0-.147 0-.147.143V8.35a1.708 1.708 0 0 0 .037.341.56.56 0 0 0 .452.447 1.851 1.851 0 0 0 .313.03h6.945a1.713 1.713 0 0 0 .3-.027.555.555 0 0 0 .459-.441 1.259 1.259 0 0 0 .039-.331V2.591c0-.133 0-.133-.138-.133Z"
                data-name="Trazado 37"
            />
        </g>
    </svg>
)

export const SearchIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        className="icon"
        viewBox="0 0 24 24"
        {...props}
    >
        <circle cx={11} cy={11} r={8} />
        <path d="m21 21-4.35-4.35" />
    </svg>
)

export const DocIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={8.235}
        height={10.503}
        {...props}
    >
        <g fill="#f9f9f9" data-name="Grupo 881">
            <path
                d="M.001 5.233v-3.59A1.422 1.422 0 0 1 .135.88 1.518 1.518 0 0 1 1.353.029c.8-.048 1.594-.025 2.392-.015A1.5 1.5 0 0 1 4.801.5c1.01 1.011 2.026 2.017 3.029 3.036a1.391 1.391 0 0 1 .4.985q.008 2.251 0 4.5a1.557 1.557 0 0 1-.768 1.3 1.305 1.305 0 0 1-.753.17c-.529.006-1.059 0-1.588 0H1.747a1.75 1.75 0 0 1-.878-.145 1.5 1.5 0 0 1-.846-1.172A6.792 6.792 0 0 1 0 8.462l.001-3.229Zm.761.035v3.31c0 .168.009.335.022.5a.713.713 0 0 0 .515.61 3.228 3.228 0 0 0 .51.05h4.484c.167 0 .334-.012.5-.023a.69.69 0 0 0 .649-.581 2.44 2.44 0 0 0 .03-.372V4.668c0-.157 0-.157-.163-.157H5.067a1.381 1.381 0 0 1-.517-.076 1.082 1.082 0 0 1-.612-.453 1.608 1.608 0 0 1-.2-.783q-.008-1.14 0-2.281c0-.114-.025-.156-.148-.154-.623.006-1.245 0-1.868 0a2.873 2.873 0 0 0-.371.037.683.683 0 0 0-.557.567c-.009.136-.034.27-.034.407q.003 1.75.002 3.493ZM6.883 3.73a.388.388 0 0 0-.038-.051Q5.708 2.563 4.599 1.42c-.021-.022-.042-.059-.074-.048s-.025.06-.025.092v1.753a1.458 1.458 0 0 0 .044.3.224.224 0 0 0 .2.188 1.9 1.9 0 0 0 .369.045h1.628a.264.264 0 0 0 .142-.02Z"
                data-name="Trazado 880"
            />
            <path
                d="M4.125 6.384h-1.8a.429.429 0 0 1-.392-.188.357.357 0 0 1 .128-.514.559.559 0 0 1 .282-.062h3.573a.747.747 0 0 1 .116.007.375.375 0 0 1 .332.4.394.394 0 0 1-.373.354c-.155.007-.311 0-.467 0Z"
                data-name="Trazado 881"
            />
            <path
                d="M4.126 8.256h-1.8a.422.422 0 0 1-.41-.216.358.358 0 0 1 .175-.5.537.537 0 0 1 .227-.046h3.62a.386.386 0 0 1 .426.358.391.391 0 0 1-.418.4c-.605.005-1.212.004-1.82.004Z"
                data-name="Trazado 882"
            />
        </g>
    </svg>
)

export const DashboardIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={11.448}
        height={11.448}
        {...props}
    >
        <path
            fill="#fff"
            d="M6.201 4.293V0h5.247v4.293Zm-6.2 1.908v-6.2h5.247v6.2Zm6.2 5.247v-6.2h5.247v6.2Zm-6.2 0V7.155h5.247v4.293Zm.954-6.2h3.339V.955H.955Zm6.2 5.247h3.339V6.202H7.155Zm0-7.155h3.339V.955H7.155Zm-6.2 7.155h3.339V8.11H.955Z"
            data-name="Trazado 81"
        />
    </svg>
)

export const GeolocationIcon = (props: SVG) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Capa_2"
        viewBox="0 0 9.9 12.22"
        width={14.92}
        height={14.91}
        {...props}
    >
        <defs>
            <style>{".cls-1{fill:#798188}"}</style>
        </defs>
        <g id="Capa_1-2">
            <path
                d="M9.9 4.82c.02.93-.24 1.79-.65 2.61-.63 1.25-1.52 2.31-2.5 3.3-.53.54-1.12 1.01-1.72 1.47-.05.04-.08.04-.13 0-1.13-.82-2.13-1.78-2.99-2.88C1.21 8.43.59 7.5.26 6.4c-.51-1.65-.26-3.16.78-4.52C1.55 1.22 2.23.75 3 .41a4.82 4.82 0 0 1 1.62-.4c.88-.06 1.71.1 2.5.5 1.09.54 1.92 1.34 2.4 2.47.24.58.37 1.2.38 1.83Zm-1.12.19c0-.32-.02-.64-.1-.94-.29-1.28-1.06-2.16-2.26-2.66-.84-.35-1.71-.36-2.58-.13-.27.07-.52.19-.77.32-.63.33-1.1.82-1.46 1.43-.27.45-.4.95-.46 1.47-.04.35-.05.7.01 1.05.11.64.36 1.23.68 1.78.78 1.33 1.84 2.43 3.01 3.43.08.07.14.04.21-.02.51-.43 1-.87 1.45-1.37.63-.7 1.21-1.42 1.66-2.26.35-.66.6-1.35.61-2.11Z"
                className="cls-1"
            />
            <path
                d="M7.14 4.96c.02 1.02-.82 2.19-2.18 2.2-1.43 0-2.23-1.25-2.21-2.24.02-1.08.9-2.16 2.2-2.16 1.26 0 2.21 1.03 2.2 2.2Zm-2.2 1.08c.55.03 1.09-.43 1.09-1.1 0-.59-.48-1.07-1.07-1.07-.62 0-1.11.46-1.11 1.08 0 .65.52 1.12 1.09 1.1Z"
                className="cls-1"
            />
        </g>
    </svg>
)
