import {useEffect, useState} from "react";
import {Checkbox} from "../../../../../components/checkbox/checkbox.component";
import {Container, ContainerItem} from "../../../../../styles/globalcomponents";
import {Input} from "../../../../../components/inputs/Input.component";
import {changePlatformData, QuestionsData} from "./questions.data";
import {UnsubscribeFormState} from "../unsubscribe.component";
import {Simulate} from "react-dom/test-utils";
import input = Simulate.input;
import {Text} from "../unsubscribe.styles";

interface Props {
  setFormData: (newState: Partial<UnsubscribeFormState>) => void;
}

export const UnsubscribeFormChangePlatformQuestion = ({ setFormData }: Props) => {
  const [options, setOptions] = useState(changePlatformData);
  const [otherInput, setOtherInput] = useState("");
  const isAMigration = !!options.filter((option) => option.checked).find(it => it.name === "Si.")

  function handleInputChange(value: string) {
    setOtherInput(value)
  }

  function handleSelectItem(item: QuestionsData) {
    setOptions((prev) =>
      prev.map((input) => {
        if (input.name === item.name) {
          return {
            ...input,
            checked: true,
          }
        }
        return {
          ...input,
          checked: false,
        }
      })
    );
  }

  useEffect(() => {
    function updateFormState() {
      setFormData({isAMigration, migrationReason: otherInput});
    }
    updateFormState()
  }, [options, otherInput]);

  return (
    <Container display="block">
      <ContainerItem rowGap={".5rem"} display={"grid"} gridTemplateColumns={"1fr"} responsiveLg={"grid-template-columns: 1fr 1fr"}>
        {options.map((item, index) => (
          <Checkbox
            key={`${item}-${index}`}
            onClick={() => handleSelectItem(item)}
            label={item.name}
            checked={item.checked}
          />
        ))}
      </ContainerItem>
      {isAMigration && (
        <ContainerItem padding={"0 2rem"} margin={"1rem 0 0"}>
          <Text fontSize={"0.7rem"} textAlign={"left"}>
            ¿A cuál?
          </Text>
          <Input
            borderColor={"#A5A5A5"}
            backgroundColor={"white"}
            padding={"10px 15px"}
            placeholder="Especifique el motivo"
            value={otherInput}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </ContainerItem>
      )}
    </Container>
  );
};
