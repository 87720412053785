import {CloseableModal, CloseableModalProps} from "../../../../../../components/ui/modal/closeable-modal.component";
import {PrecautionIcon} from "../../../../../../components/icons";
import {ConfirmButton, Container, CustomText} from "../../../../../../styles/globalcomponents";

interface UpdateMethodAdviceModalProps extends Omit<CloseableModalProps, "children">{
}

export function UpdateMethodAdviceModal({closeModal, isOpen}: UpdateMethodAdviceModalProps) {
    function handleRefreshPage() {
        window.location.reload()
    }

    return (
        <CloseableModal isOpen={isOpen} closeModal={closeModal}>
            <Container flexDirection={"column"} alignItems={"center"} gap={"2rem"} justifyContent={"center"} maxWidth={"20rem"}>
                <PrecautionIcon/>
                <CustomText fontSize={"14px"} fontWeight={"400"}>
                    Ahora mismo vas a ver una ventana emergente en la cual podrás actualizar el método de pago. Cuando termines la actualización cierra la ventana y luego recarga está pestaña.
                </CustomText>
                <ConfirmButton onClick={handleRefreshPage}>
                    Recargar pestaña
                </ConfirmButton>
            </Container>
        </CloseableModal>
    )
}
