import {
  FloatButton,
  FloatMenu,
  FloatTools,
  ToolsLayer,
} from "./buttonTools.styles";
import { FaTools } from "react-icons/fa";
import { ToolsQuickAccess } from "src/pages/home/tools/tools.component";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { Title } from "src/styles/globalcomponents";

const ButtonTools = () => {
  const [enableMenu, setEnableMenu] = useState<boolean>(false);

  return (
    <FloatTools>
      <FloatMenu style={{ display: enableMenu ? "flex" : "none" }}>
        <ToolsLayer>
          <Title
            fontSize={"20px"}
            fontWeight={700}
            textAlign="left"
            margin={"2rem 0"}
          >
            Mis herramientas
          </Title>
          <ToolsQuickAccess/>
        </ToolsLayer>
      </FloatMenu>
      <FloatButton
        onClick={() => setEnableMenu(!enableMenu)}
        activeMenu={enableMenu}
      >
        {!enableMenu ? <FaTools /> : <MdClose size={35} />}
      </FloatButton>
    </FloatTools>
  );
};

export default ButtonTools;
