import {
    PlanDescriptionContainer,
    PlanImage,
    PlanDetailsWrapper,
    PlanLink,
    PlanSelection, PlanInfoWrapper
} from "../planDescription.styles";
import {PlanTools} from "./planTools.component";
import {useAppSelector} from "../../../../../../../hooks/useRedux";
import {SuccessIcon} from "../../../../../../../components/icons";
import {PLANS_BANNERS} from "../../plans.data";
import {PlanPriceNumber} from "../../planSelectionTab/plans.styles";
import {PlanDescriptionModal} from "../../planSelectionTab/modals/planDescription.modal";
import {useState} from "react";
import {numberFormat} from "../../../../../../../lib/numberFormat";
import {PlanFrequency} from "../../../../../../../interfaces/plans";

export function PlanSelected() {
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false)
    const planSelected  = useAppSelector((state) => state.checkout.selectedPlan)
    const plans = useAppSelector((state) => state.auth.plans)

    if(!planSelected) return <></>

    const planInfo = plans.find(plan => plan.suscriptionInfo.idSuscription === planSelected?.id)

    if(!planInfo) return <></>

    const planPrices: {[key in PlanFrequency]: string} = {
        'Mensual': planInfo.suscriptionInfo.monthPrice,
        'Trimestral': planInfo.suscriptionInfo.trimesterPrice,
        'Semestral': planInfo.suscriptionInfo.semiAnualPrice,
        'Anual': planInfo.suscriptionInfo.anualPrice,
    }
    const planPayFrequencies: {[key in PlanFrequency]: string} = {
        'Mensual': "USD / mes",
        'Trimestral': "USD / trimestre",
        'Semestral': "USD / semestre",
        'Anual': "USD / año",
    }
    const planPrice: string = numberFormat.format(Number(planPrices[planSelected.frequency] ?? 0))
    const planPayFrequency: string = planPayFrequencies[planSelected.frequency] ?? ""
    const subscriptionName = planInfo?.suscriptionInfo.name ?? ""
    const periodicity = planSelected.frequency ?? ""
    const backgroundImage = PLANS_BANNERS[subscriptionName.toLowerCase()] ?? PLANS_BANNERS[0]

    function closeDescriptionModal() {
        setIsDescriptionModalOpen(false)
    }

    return (
        <>
            <PlanDescriptionContainer>
                <PlanInfoWrapper>
                    <PlanImage backgroundImage={backgroundImage}>
                        {subscriptionName}
                    </PlanImage>
                    <PlanDetailsWrapper>
                        <PlanPriceNumber>
                            ${planPrice} {planPayFrequency}
                        </PlanPriceNumber>
                        <PlanLink onClick={() => setIsDescriptionModalOpen(true)}>Ver descripción del plan</PlanLink>
                    </PlanDetailsWrapper>
                </PlanInfoWrapper>
                <div>
                    <PlanTools plan={planInfo}/>
                </div>
                <PlanSelection>
                    <SuccessIcon width={"30px"}/> Plan Seleccionado
                </PlanSelection>
            </PlanDescriptionContainer>
            <PlanDescriptionModal periodicity={periodicity} planName={subscriptionName} isOpen={isDescriptionModalOpen} closeModal={closeDescriptionModal}/>
        </>
    )
}
