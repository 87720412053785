import styled from "styled-components";
import { config } from "../../../../../config";
import { globalstyles } from "../../../../../styles/animations/globalstyles";

interface ToolCardContainerProps {
  haveTopBanner: boolean
}

export const ToolCardContainer = styled.div<ToolCardContainerProps>`
  align-items: center;
  background-color: white;
  box-shadow: ${globalstyles.boxShadows.centered};
  border-radius: 1rem;
  display: grid;
  width: 100%;
  gap: 1rem;
  overflow: hidden;
  padding: ${(props) => props.haveTopBanner ? '3rem 1rem .5rem' : '1rem 1rem'};
  grid-template-columns: 1fr;
  position: relative;
  
  @media only screen and (min-width: ${globalstyles.sizes.lg}) {
    grid-template-columns: 20% 1fr;
  }
`;

interface ImageContainerProps {
  width?: string
  marginLeft?: string
  marginRight?: string
}

export const ImageContainer = styled.div<ImageContainerProps>`
  align-items: flex-start;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: ${(props) => props.width ?? 'auto'};
  margin-left: ${(props) => props.marginLeft ?? ''};
  margin-right: ${(props) => props.marginRight ?? ''};
`;

type ToolIconProps = {
  src: string;
  height?: string;
  margin?: string;
  width?: string;
};

export const IconContainer = styled.img<ToolIconProps>`
  align-items: center;
  aspect-ratio: 1;
  background-image: url(${(props) => props.src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  height: ${(props) => props.height ? props.height : '50%'};
  justify-content: center;
  margin: ${(props) => props.margin && props.margin};
  width: ${(props) => props.width ? props.width : "50%"};
`;

export const ToolTitle = styled.div`
  align-items: center;
  display: flex;
  font-weight: 500;
  height: 50px;
  gap: 0.5rem;
  margin: 1rem 0 0.5rem 0 !important;
`;

export const ToolDescription = styled.div`
  display: grid;
  grid-template-columns: 1fr  ;
  column-gap: .5rem;
  width: 100%;
  
  p {
    font-size: ${globalstyles.fontSize.paragraph};
    font-weight: 500;
    line-height: 20px;
    margin-top: 0 !important;
  }
  
  & h1 {
    font-size: 25px;
    font-weight: 600;
    margin: 0;
  }
  
  & h2 {
    font-size: 18px;
    margin: 0;
  }
  
  & h6 {
    margin: 0;
  }
  
  & small {
    font-size: 10px;
  }
  
  & li {
    font-size: 11px;
  }
  
  & ul {
    padding-left: 1rem;  
  }

  @media only screen and (min-width: ${globalstyles.sizes.lg}) {
    grid-template-columns: 1fr 65%;
  }
`;

export const ButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: start;
  margin: 1rem 0;
  width: 100%;
`;

export const InfoTooltipWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.3rem;
  height: 1.3rem;
  border-radius: 50%;
  border: 2px solid ${globalstyles.colors.secondary};
  color: ${globalstyles.colors.secondary};
  
  &::before {
    content: "?";
  }
`
export const InfoTooltipContent = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  color: initial;
  width: 20rem;
  font-size: .8rem;
  background-color: white;
  border-radius: ${globalstyles.borders.tooltip};
  padding: .5rem 1rem;
  box-shadow: ${globalstyles.boxShadows.centered};
`
