import {
    EmptyContainer,
    EmptyNotificationsContainer,
    MenuNotifications,
    NotificationButtonsContainer,
    NotificationCounter,
    NotificationsContainer,
    NotificationsCounterContainer,
    NotificationSelectButton,
    NotificationsHeader,
    NotificationsInnerWrapper,
    NotificationsTitle, SelectAllInput
} from "./notifications.styles";

import { Dispatch, SetStateAction, useState } from "react";
import { BsCircle, BsFillCheckCircleFill } from "react-icons/bs";
import { FaTrashAlt } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import { GoInbox } from "react-icons/go";
import { RiCheckDoubleLine } from "react-icons/ri";
import { useAppDispatch, useAppSelector } from "src/hooks/useRedux";
import {
    deleteNotificationsThunk,
    updateViewedNotificationsThunk
} from "src/redux/slices/notifications";
import { globalstyles } from "src/styles/animations/globalstyles";
import { SpiralLoadingIcon } from "../loadingIcon/loadingIcon.component";
import { Notification } from "./notification.component";

interface NotificationMenuProps {
  showNotificationMenu: boolean;
  notViewedNotifications: number;
  setNotViewedNotifications: React.Dispatch<React.SetStateAction<number>>;
}

export const NotificationMenu = ({
  showNotificationMenu,
  notViewedNotifications,
  setNotViewedNotifications,
}: NotificationMenuProps) => {
  const { notifications, isLoading } = useAppSelector((state) => state.notifications);

  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>(
    []
  );
  const [selectAll, setSelectAll] = useState(false);
  const [showNotificationButtons, setShowNotificationButtons] = useState(false);
  const [showDeleteButton, setShowDeleteButton] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  const handleDeleteNotifications = async () => {
    dispatch(
      deleteNotificationsThunk({
        idUser: Number(dataUser.id),
        notificationIds: selectedNotifications,
      })
    );
    setShowDeleteButton([]);
    setSelectedNotifications([]);
  };

  const handleViewedNotifications = async () => {
    dispatch(
      updateViewedNotificationsThunk({
        idUser: Number(dataUser.id),
        notificationIds: selectedNotifications,
      })
    );
    const notViewed = notifications?.filter(
      (notification) => notification.viewed == false
    ).length;
    setNotViewedNotifications(notViewed);
    setShowDeleteButton([]);
    setSelectedNotifications([]);
    setSelectAll(false);
  };

  const handleSelectAll = () => {
    setSelectAll((prev) => !prev);
    setSelectedNotifications((prev) => {
        prev = notifications?.map((el) => el.idUserNotification);
        return prev;
    });
  };

  return (
    <MenuNotifications
      style={{
        right: `${showNotificationMenu ? "0" : "-380px"}`,
      }}
    >
      <NotificationsInnerWrapper>
        <NotificationsHeader>
          <NotificationsCounterContainer>
            <NotificationsTitle>Notificaciones</NotificationsTitle>
            {notViewedNotifications !== 0 && (
              <NotificationCounter>
                {notViewedNotifications}
              </NotificationCounter>
            )}
          </NotificationsCounterContainer>
          {notifications?.length !== 0 && (
            <NotificationSelectButton
              backgroundColor={globalstyles.colors.yellow}
              onClick={() => setShowNotificationButtons((prev) => !prev)}
            >
              <FiCheckCircle />
              Seleccionar
            </NotificationSelectButton>
          )}
        </NotificationsHeader>

        {showNotificationButtons && (
          <SelectAllInput
            style={{
              display: `${
                notViewedNotifications !== 0 || notifications?.length > 0
                  ? "flex"
                  : "none"
              }`,
            }}
          >
            Todos
            {selectAll ? (
              <BsFillCheckCircleFill
                style={{
                  fill: `${globalstyles.colors.green}`,
                }}
                onClick={() => {
                  setSelectAll((prev) => !prev);
                  setSelectedNotifications([]);
                }}
              />
            ) : (
              <BsCircle onClick={() => handleSelectAll()} />
            )}
          </SelectAllInput>
        )}

        <NotificationsContainer>
          {notifications?.length === 0 ? (
            <EmptyContainer>
              <EmptyNotificationsContainer>
                No tienes notificaciones
                <GoInbox style={{ fontSize: "50px" }} />
              </EmptyNotificationsContainer>
            </EmptyContainer>
          ) : !isLoading ? (
            notifications
              ?.map((el, i) => (
                <Notification
                  key={i}
                  {...el}
                  setShowDeleteButton={setShowDeleteButton}
                  showNotificationButtons={showNotificationButtons}
                  selectedNotifications={selectedNotifications}
                  setSelectedNotifications={setSelectedNotifications}
                  selectAll={selectAll}
                  setNotViewedNotifications={setNotViewedNotifications}
                  notifUrl={el.url}
                  notifTargetUrl={el.targetUrl}
                />
              ))
              .reverse()
          ) : (
            <EmptyContainer>
              <SpiralLoadingIcon width="100px" />
            </EmptyContainer>
          )}
        </NotificationsContainer>
      </NotificationsInnerWrapper>

      <NotificationButtonsContainer
        style={{
          display: `${showNotificationButtons ? "flex" : "none"}`,
        }}
      >
        {(showDeleteButton.length > 0 || selectAll) && (
          <NotificationSelectButton
            backgroundColor={globalstyles.colors.yellow}
            style={{
              display: `${notViewedNotifications !== 0 ? "flex" : "none"}`,
            }}
            onClick={() => handleViewedNotifications()}
          >
            <RiCheckDoubleLine />
            Marcar como leído
          </NotificationSelectButton>
        )}

        {(showDeleteButton.length > 0 || selectAll) && (
          <NotificationSelectButton
            backgroundColor={globalstyles.colors.red}
            color="white"
            style={{
              display: `${notifications?.length > 0 ? "flex" : "none"}`,
            }}
            onClick={() => handleDeleteNotifications()}
          >
            <FaTrashAlt />
            Eliminar
          </NotificationSelectButton>
        )}
      </NotificationButtonsContainer>
    </MenuNotifications>
  );
};
