import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";


interface Props {
    backgroundUrl: string
    mode: "FULL_PAGE" | "BANNER"
}


export const BlackFridayCounterStyled = styled.section<Props>`
    display: flex;
    justify-content: center;
    gap: 2rem;
    align-items: center;
    padding: .5rem 1rem;
    background: url(${(props) => props.backgroundUrl}) no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 2rem;
    ${(props) => props.mode === "FULL_PAGE" ? `
        height: 11rem;
    ` : ''}

    & > img {
        object-fit: contain;
        ${(props) => props.mode === "FULL_PAGE" ? `
            position: relative;
            top: -.5rem;
        ` : ''}
    }

    & > span {
        color: white;
        font-weight: 300;
        font-size: 0.8rem;
    }
`
export const CountdownWrapperStyled = styled.section`
    display: flex;
    gap: 1rem;
`
export const CountdownTitleStyled = styled.h3`
    color: white;
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0;
`

export const CountdownButtonStyled = styled.button`
    border-radius: 20px;
    color: white;
    appearance: none;
    background-color: ${globalstyles.colors.red};
    border: none;
    font-weight: 500;
    padding: 0.5rem 1rem;
`

export const CountdownSectionStyled = styled.article`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 0.2rem;

    &:not(:last-child) > article::before {
        content: ":";
        position: absolute;
        color: white;
        left: 105%;
        font-size: 2rem;
        font-weight: 800;
    }

    & > article {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 5rem;
        height: 3rem;
        gap: .5rem;
        position: relative;

        & > div {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 5px;
            font-weight: 900;
            font-size: 1.5rem;
        }
    }

    & > span {
        color: white;
        font-size: 0.7rem;
    }
`
