import {
    AdditionalRangeLegendContainer,
    AdditionalToolCollapsibleContent, AdditionalToolContainer,
    AdditionalToolDescription, AdditionalToolFixedContent,
    AdditionalToolFooter,
    AdditionalToolHeader,
} from "../planDescription.styles";
import {IconContainer, ImageContainer} from "../../../../overview/toolCard/toolCard.styles";
import {config} from "../../../../../../../config";

import {ConfirmButton, Container, CustomTitle, DeleteButton} from "../../../../../../../styles/globalcomponents";
import {useState} from "react";
import {AddIcon, SuccessIcon, TrashIcon} from "../../../../../../../components/icons";
import {AdditionalToolRangeSelector} from "./additionalToolRangeSelector.component";
import {PlanPriceNumber} from "../../planSelectionTab/plans.styles";
import {useAppDispatch, useAppSelector} from "../../../../../../../hooks/useRedux";
import {getFormattedNumber, numberFormat} from "../../../../../../../lib/numberFormat";
import {removeAdditionalTool} from "../../../../../../../redux/slices/checkout";


export function AdditionalToolEmail() {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currentMasterToolsPlan = dataUser.suscription
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)
    const additionalToolsInfo = useAppSelector((state) => state.checkout.additionalTools)
    const additionalToolEmail = additionalToolsInfo.find((tool) => tool.name === 'EmailTool')
    const [showCollapsedContent, setShowCollapsedContent] = useState<boolean>(payOnlyAdditionalTools || additionalToolEmail?.price !== 0)
    const additionalToolQuantity = additionalToolsInfo.find((addon) => addon.name === 'EmailTool')?.quantity ?? 0
    const formattedQuantity = numberFormat.format(additionalToolQuantity);
    const dispatcher = useAppDispatch()
    const currentEmailAddonsQuantity = getFormattedNumber(currentMasterToolsPlan.limitsTool.emailMarketingSuscribers.value)

    function handleDeleteAddonQuantity() {
        setShowCollapsedContent(false)
        dispatcher(removeAdditionalTool({toolName: 'EmailTool'}))
    }

    return (
        <AdditionalToolContainer>
            <AdditionalToolFixedContent>
                <AdditionalToolHeader>
                    <ImageContainer width={"70px"}>
                        <IconContainer src={`${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`} width={"75px"} height={"75px"} />
                    </ImageContainer>
                    <CustomTitle color={"black"} fontSize={"16px"} fontWeight={"700"}>EmailTool</CustomTitle>
                </AdditionalToolHeader>
                <AdditionalToolDescription>
                    {
                        !payOnlyAdditionalTools ?
                            <ul>
                                <li>Elige entre 1.000 y 100.000 suscriptores.</li>
                                <li>Seguimiento de embudos y eventos</li>
                                <li>Envío ilimitado de correo.</li>
                            </ul>
                        :
                            <Container justifyContent={"center"} alignItems={"center"} gap={"1rem"}>
                                <SuccessIcon size={"30px"} />
                                <p>Tu <strong>Plan {currentMasterToolsPlan.name}</strong> incluye {currentEmailAddonsQuantity} contactos</p>
                            </Container>
                    }
                </AdditionalToolDescription>
                {
                    !payOnlyAdditionalTools &&
                    <AdditionalToolFooter>
                        {
                            showCollapsedContent ?
                                <DeleteButton  onClick={handleDeleteAddonQuantity} height={"1.5rem"} padding={"12px 15px"}>
                                    <TrashIcon width={"13px"} height={"12px"}/> Eliminar
                                </DeleteButton>
                            :
                                <ConfirmButton onClick={() => setShowCollapsedContent(true)} height={"1.5rem"} padding={"12px 15px"}>
                                    <AddIcon height={"12px"} width={"13px"} viewBox={"0 0 9 9"}/> Agregar
                                </ConfirmButton>
                        }
                    </AdditionalToolFooter>
                }
            </AdditionalToolFixedContent>
            <AdditionalToolCollapsibleContent active={showCollapsedContent}>
                <CustomTitle color={"black"} textAlign={"left"} fontWeight={"800"} fontSize={"14px"} margin={"1rem 0 0"}>
                    ¿Cuántos contactos quieres agregar a tu lista?
                </CustomTitle>
                <AdditionalRangeLegendContainer>
                    <CustomTitle fontSize={"12px"} color={"black"} textAlign={"start"} fontWeight={"400"} margin={"0"}>
                        Selecciona una cantidad:
                    </CustomTitle>
                    <div>
                        <PlanPriceNumber textAlign={"center"}>{formattedQuantity}</PlanPriceNumber>
                        <div>suscriptores</div>
                    </div>
                </AdditionalRangeLegendContainer>
                <AdditionalToolRangeSelector/>
            </AdditionalToolCollapsibleContent>
        </AdditionalToolContainer>
    )
}
