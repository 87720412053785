import {Container, CustomTitle} from "../../../../../../styles/globalcomponents";
import {PlanSelected} from "./planSelected/planSelected.component";
import {AdditionalToolEmail} from "./additionalTool/additionalToolEmail.component";
import {useAppSelector} from "../../../../../../hooks/useRedux";


export function PlansDescriptionTab() {
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)

    return (
        <Container flexDirection={"column"}>
            {!payOnlyAdditionalTools && <PlanSelected/>}
            {/*{!payOnlyAdditionalTools &&*/}
            {/*    <CustomTitle textAlign={"start"} margin={"1.5rem 1rem 1rem"} fontSize={"15px"} color={"black"} fontWeight={"300"}>*/}
            {/*        Equipa tu plan con herramientas complementarias.*/}
            {/*    </CustomTitle>*/}
            {/*}*/}
            {payOnlyAdditionalTools && <AdditionalToolEmail/>}
        </Container>
    )
}
