import {OrderDetailsListItemStyled, OrderDetailsListStyled} from "./orderDetailsList.styles";
import {Container} from "../../../../styles/globalcomponents";

type OrderDetailItem = {
    index?: number
    color?: string
    name: string
    value: number
}

interface OrderDetailsListProps {
    totalOrders: number
    items: OrderDetailItem[]
}

export function OrderDetailsList({totalOrders, items}: OrderDetailsListProps) {

    if(!items) {
        return (
            <Container justifyContent={"center"} alignItems={"center"} >
                <h6>No hay datos</h6>
            </Container>
        )
    }

    return (
        <OrderDetailsListStyled>
            {
                items?.map((item, index) => {
                    if(typeof item.color === "string") {
                        return (
                            <OrderDetailsListItemStyled key={`order-details-${index}`} legendColor={item.color}>
                                {item.name}
                                <span>{item.value}/{totalOrders}</span>
                            </OrderDetailsListItemStyled>
                        )
                    }

                    return (
                        <OrderDetailsListItemStyled key={`order-details-${index}`}>
                            {item.name}
                            <span>{item.value}/{totalOrders}</span>
                        </OrderDetailsListItemStyled>
                    )
                })
            }
        </OrderDetailsListStyled>
    )

}
