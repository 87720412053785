import { useState } from "react";
import { BsPaperclip } from "react-icons/bs"
import { BodyBackground, Button, Card, CardContent, Container, Input, InputContainer, InputFile, Label, Textarea, Title } from "../../../styles/globalcomponents"
import { MessageSupport, TextSupport } from "../support.styles";

export const Mail = () => {
  const [emailSent, setEmailSent] = useState(false);

  const sendEmail = () => {
    setEmailSent(true);
  }


  return (
    <BodyBackground img={"Fondo_login.png"}>
      {
        emailSent ?
          <Container width={"60%"} responsive={"width: 100%"}>
            <Card
              backgroundColor={"linear-gradient(240deg,rgb(43, 190, 155) 20%,rgb(1, 195, 56) 100%)"}
              margin={"32px 0 0 0"}
              responsive={"height: 120vh; margin-top: 0px; border-radius: 0px;"}
            >
              <CardContent>
                <MessageSupport>
                  <TextSupport>¡Pronto estaremos respondiendo tu solicitud! ;)</TextSupport>
                </MessageSupport>
              </CardContent>
            </Card>
          </Container> :
          <>
            <Title>Envíanos un email</Title>
            <Container width="50%">
              <Card>
                <CardContent>
                  <Label>Asunto:</Label> <br />
                  <Input placeholder="Escribe el asunto" width={"98%"} style={{ marginBottom: "16px" }} /> <br />
                  <Label>¿En qué podemos ayudarte?</Label> <br />
                  <Textarea placeholder="Por favor se detallado con tu solicitud" /> <br />

                  <InputContainer>
                    <InputFile />
                    <BsPaperclip size={"24px"} />
                    Agregar archivos
                  </InputContainer>

                  <Container width={"100px"}>
                    <Button onClick={sendEmail}> ENVIAR </Button>
                  </Container>
                </CardContent>
              </Card>
            </Container>
          </>
      }
    </BodyBackground>
  )
}