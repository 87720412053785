import { useCallback, useEffect, useRef, useState } from "react";
import { LeftArrowIcon } from "../../../../components/icons";
import { BackButtonStyled } from "../../../../components/ui/backButton/index.styles";
import { useAppSelector } from "../../../../hooks/useRedux";
import { Card, Container, ContainerItem } from "../../../../styles/globalcomponents";
import { CANCELLATION_VIDEO, CANCELLATION_VIDEO_THUMBNAIL, DOWNGRADE_TO_LITE_VIDEO } from "../../consts";
import { ChangeToLitePlanModal } from "./modal/ChangeToLitePlanModal.component";
import { PreUnSubscriptionCountdown } from "./preUnsubscriptionCountdown.component";
import {
    DecreaseButtonStyled,
    StartCountDownButtonStyled,
    TitleStyled,
    VideoStyled
} from "./unsubscribe.styles";

interface Props {
    onAbortCancellation: () => void
    onStartCancellation: (isEmailCancellation: boolean) => void
}


export function PreUnsubscribe({onAbortCancellation, onStartCancellation}: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const darkMode = useAppSelector((state) => state.session.darkMode);
    const [isCountingDown, setIsCountingDown] = useState<boolean>(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    const [isOpenChangeToLiteModal, setIsOpenChangeToLiteModal] = useState<boolean>(false)


    const startVideoWhenMouseClicked = useCallback(() => {
        if (videoRef.current) {
            videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
            videoRef.current.muted = false
        }
    }, [])

    function onCountDownStarts() {
        startVideoWhenMouseClicked()
    }

    function closeChangeToLiteModal() {
        setIsOpenChangeToLiteModal(false)
    }

    function onCountdownCompleted() {
        setIsCountingDown(false)
    }

    function openChangePlanModal() {
        setIsOpenChangeToLiteModal(true)
    }

    useEffect(() => {
        const videoElement = videoRef.current

        if (!videoElement) return

        videoElement.addEventListener('click', startVideoWhenMouseClicked)
        return () => {
            videoElement.removeEventListener('click', startVideoWhenMouseClicked)
        }
    }, [startVideoWhenMouseClicked])


    return (
        <Card
            backgroundColor={darkMode ? "#0d0d0d" : "white"}
            padding={"0 2.5rem 2rem"}
            position={"relative"}
        >
            <Container
                margin={"auto"}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                padding={"2rem 0 0 "}
            >
                <BackButtonStyled onClick={onAbortCancellation}>
                    <LeftArrowIcon fill={"white"} />
                </BackButtonStyled>
                <TitleStyled>
                    ¿Seguro que quieres cancelar? ¡Tal vez podamos encontrar una solución!
                </TitleStyled>
                <span> Te mostramos como puedes reducir tu plan a una opción mas económica para seguir disfrutando de los beneficios de MasterTools</span>
                <ContainerItem alignItems={"center"} margin="2rem 0 0">
                    <VideoStyled poster={CANCELLATION_VIDEO_THUMBNAIL} active={true} ref={videoRef} src={CANCELLATION_VIDEO} muted={true} loop={true} width={"400px"} height={"300px"} />
                </ContainerItem>
                <DecreaseButtonStyled onClick={openChangePlanModal}>
                    Disminuir plan
                </DecreaseButtonStyled>
                {
                    isCountingDown ?
                        <PreUnSubscriptionCountdown onCompleted={onCountdownCompleted} onStarts={onCountDownStarts} />
                    :
                        <StartCountDownButtonStyled onClick={() => onStartCancellation(false)}>
                            Continuar con la cancelación
                        </StartCountDownButtonStyled>
                }
            </Container>
            <ChangeToLitePlanModal isOpen={isOpenChangeToLiteModal} closeModal={closeChangeToLiteModal} />
        </Card>
    )
}
