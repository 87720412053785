import { ActiveCampaignSignIn } from "../interfaces/auth";
import { instance } from "../axios/axiosConfig";

export class ActiveCampaignService {
  async activeCampaignSignIn(user: ActiveCampaignSignIn) {
    const response = await instance.post("/app/activecampaign/signin", {
      host: user.host,
      pass: user.pass,
      user: user.user,
      ip: user.ip,
    });

    return response.data;
  }
}
