import { config } from "../../config"
import { Icon, IconContainer } from "./loadingIcon.styles"

type props = {
  width?: string;
}

export const SpiralLoadingIcon = ({ width }: props) => {
  return(
    <IconContainer width={width}>
      <Icon src={`${config.urls.res}/platform/icons/GIF/Gif_Carga_MasterTools.gif`} alt="loading icon" />
    </IconContainer>
  )
}

export const LoadingIcon = ({ width }: props) => {
    return(
        <IconContainer width={width}>
            <Icon src={`${config.urls.res}/platform/icons/GIF/mastertools-loader.gif`} alt="loading icon" />
        </IconContainer>
    )
}
