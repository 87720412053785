import styled from "styled-components";
import {globalstyles} from "../../../../../../styles/animations/globalstyles";


interface PaymentMethodCardStyledProps {
    isSelected: boolean
}

export const PaymentMethodCardStyled = styled.article<PaymentMethodCardStyledProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem 0 0;
  border-radius: 5px;
  border: 1px solid ${(props) => props.isSelected ? globalstyles.colors.secondary : globalstyles.colors.lightGray};
  transition: box-shadow 200ms ease-in-out;
  box-shadow: ${(props) => props.isSelected ? `0px 0px 4px 0px ${globalstyles.colors.secondary}` : "none"};

  --grid-count: 5;
  --grid-template-cols: repeat(var(--grid-count), 1fr);
  --grid-template-rows: repeat(var(--grid-count), 1fr);
  --padding: 0;

  & .header {
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    grid-template-rows: var(--grid-template-rows);
    text-align: left;
    padding: var(--padding);
    font-weight: 700;
    font-size: 14px;
    white-space: nowrap;

    & > div {
      padding: .5rem;
    }
  }

  & .body {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: var(--grid-template-rows);
    padding: var(--padding);
    text-align: center;
    border-radius: 5px;
    align-items: center;

    & > div {
      padding: .5rem;
    }

    &:has(.full-row) {
      grid-template-columns: repeat(1fr, var(--grid-count));
    }

  }

  @media (min-width: ${globalstyles.sizes.lg}) {
    & {
      flex-direction: column;
      border: none;
      box-shadow: none;
    }

    & .header {
      grid-template-columns: var(--grid-template-cols);
      grid-template-rows: auto;
      text-align: center;
      
      & > div:last-child {
        font-size: 0;
      }
    }

    & .body {
      grid-template-columns: var(--grid-template-cols);
      grid-template-rows: auto;
      border: 1px solid ${(props) => props.isSelected ? globalstyles.colors.secondary : globalstyles.colors.lightGray};
      transition: box-shadow 200ms ease-in-out;
      box-shadow: ${(props) => props.isSelected ? `0px 0px 4px 0px ${globalstyles.colors.secondary}` : "none"};
    }

    & > div:last-child {
      justify-self: flex-end;
    }
  }
`
export const PaymentMethodCardButtonStyled = styled.input`
  appearance: none;
  cursor: pointer;

  &::before {
    content: "";
    display: block;
    background-color: transparent;
    border: 1px solid ${globalstyles.colors.primary};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }

  &:checked::before {
    background-color: ${globalstyles.colors.secondary};
    border: 1px solid ${globalstyles.colors.secondary};
  }
`
