import styled from "styled-components";
import {globalstyles} from "../../../styles/animations/globalstyles";

interface BackButton {
    secondary?: boolean
}

export const BackButtonStyled = styled.button<BackButton>`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border-color: transparent;
  background-color: ${globalstyles.colors.secondary};
  position: absolute;
  left: 1.5rem;
  top: 1.5rem;
  padding: .5rem;
  cursor: pointer;
  filter: ${(props) => props.secondary && "grayscale(1)"};
    
  &:hover {
    box-shadow: ${globalstyles.boxShadows.normal};
  }
`
