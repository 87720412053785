import { useEffect, useState } from "react";
import { sendEvent } from "src/services/events";
import { SpiralLoadingIcon } from "../../../../../components/loadingIcon/loadingIcon.component";
import { CloseableModal, type CloseableModalProps } from "../../../../../components/ui/modal/closeable-modal.component";
import { config } from "../../../../../config";
import { useAppSelector } from "../../../../../hooks/useRedux";
import type { GetSubscriptionStateResponseNew } from "../../../../../interfaces/plans";
import { getFormattedNumber } from "../../../../../lib/numberFormat";
import { PlansService } from "../../../../../services/plansService";
import { ToastService } from "../../../../../services/toastService";
import { ConfirmButton, Container } from "../../../../../styles/globalcomponents";
import { TermsStyled } from "../unsubscribe.styles";
import { ResultChangePlanModal } from "./ResultChangePlanModal.component";

interface ChangeToLitePlanModalProps extends Omit<CloseableModalProps, "children"> { }

const ICON_URL = `${config.urls.res}/platform/icons/PNG/downgrade-icon.png`
const plansService = new PlansService()
const toastService = new ToastService()

export function ChangeToLitePlanModal({ isOpen, closeModal }: ChangeToLitePlanModalProps) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const plans = useAppSelector(state => state.auth.plans)
    const [isResultModalOpen, setIsResultModalOpen] = useState<boolean>(false)
    const [paymentApproved, setPaymentApproved] = useState<boolean>(false)
    const [validationData, setValidationData] = useState<GetSubscriptionStateResponseNew | undefined>(undefined)
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [submitting, setSubmitting] = useState<boolean>(false)
    const price = getFormattedNumber(validationData?.apportionmentPrice ?? validationData?.priceTotal ?? 0)
    const currentPlanName = dataUser.suscription.name
    const currentPlanPeriodicity = dataUser.suscription.typeSuscription

    function closeResultModal() {
        setIsResultModalOpen(false)
    }

    function findLitePlan() {
        const litePlanIndex = plans.findIndex(plan => plan.suscriptionInfo.name === 'Lite')
        return plans[litePlanIndex]
    }

    async function handleChangePlan() {
        try {
            if (!validationData || !validationData.action) throw new Error("Validation Data not found")
            setSubmitting(true)
            const response = await plansService.handlePayment(
                dataUser.email,
                validationData.codePlanPaytool,
                395,
                validationData.action,
                validationData.apportionmentPrice || validationData.priceTotal || 0
            );
            setPaymentApproved(response.data.suscriptionUpdated)
            toastService.success("¡Compra exitosa!");
            setIsResultModalOpen(true);
            closeModal()
            sendEvent({
                source: "MASTERTOOLS-SUBSCRIPTION-CANCELLATION",
                detailType: "SCHEDULED-DOWNGRADE",
                detail: {
                  dataUser,
                }
              }).then(() => console.log("Event sent!"))
        } catch (error) {
            toastService.error("¡Tu pago ha sido rechazado!");
        }
    }

    async function validateSubscription() {
        try {
            const litePlan = findLitePlan()

            const planResponse = await plansService.getUserSubscriptionStateNewest({
                newPlan: litePlan.suscriptionInfo,
                periodicity: 'Mensual'
            });
            setValidationData(planResponse)
        } catch (e) {
            toastService.error("No pudimos verificar tu plan, contacta a soporte")
        }
    }


    useEffect(() => {
        isOpen && validateSubscription()
    }, [isOpen])

    return (
        <>
            <CloseableModal isOpen={isOpen} closeModal={closeModal}>
                <Container flexDirection={"column"} alignItems={"center"} maxWidth={"45rem"}>
                    <img src={ICON_URL} alt={"Change icon"} width={"200px"} height={"200px"} />
                    <h2 className={"text-center m-0"}>
                        ¿Listo para utilizar MasterTools al menor costo?
                    </h2>
                    {
                        !validationData ?
                            <SpiralLoadingIcon width={"50px"} />
                            :
                            <p>
                                Nada mejor que acceder a esos descuentos que nadie más conoce! Pagando solo <strong>{price} USD</strong> en tu próxima fecha de cobro <strong>{validationData.startDate}</strong> te pasaremos del plan <strong>{currentPlanName} {currentPlanPeriodicity}</strong> a tu nuevo plan <strong>Lite {validationData.periodicity}</strong>.
                            </p>
                    }
                    <p>
                        <strong><i>*No cobraremos nada en este momento ;)</i></strong>
                    </p>
                    <p>
                        <strong>Toca el botón para empezar a usar MasterTools al menor costo!</strong>
                    </p>
                    <TermsStyled>
                        <input type="checkbox" onChange={() => setTermsAccepted(prev => !prev)} />
                        <small>
                            <i>Entiendo que al dar click en el botón “¡CAMBIAR A PLAN LITE!” estoy realizando un cambio dentro de mi suscripción de MasterTools y autorizo que se siga cobrando dicha suscripción con la tarjeta de crédito registrada en mi cuenta hasta que sea cancelada. También estoy aceptando los <a href="https://mastertools.com/politica-servicio" rel="noreferrer" target="_blank">términos de compra</a> y servicio de MasterTools.</i>
                        </small>
                    </TermsStyled>
                    <ConfirmButton margin={"2rem 0 0 0"} onClick={handleChangePlan} disabled={!termsAccepted || submitting}>
                        ¡CAMBIAR A PLAN LITE!
                    </ConfirmButton>
                </Container>
            </CloseableModal>
            <ResultChangePlanModal isOpen={isResultModalOpen} closeModal={closeResultModal} isApproved={paymentApproved} />
        </>
    )
}
