import styled from 'styled-components'
import {globalstyles} from "../../../../styles/animations/globalstyles";


interface SelectableCardStyledProps {
    active: boolean
}
export const SelectableCardStyled = styled.div<SelectableCardStyledProps>`
  position: relative;
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 15px;
  padding: 1rem;
  border: ${(props) => props.active ? `2px solid ${globalstyles.colors.secondary}`: "2px solid transparent"};
  transition: border-color 250ms ease-in-out;
  
  & * {
    user-select: none;
  }
`

interface CardActiveSelectorStyledProps {
    active: boolean
}
export const CardActiveSelectorStyled = styled.div<CardActiveSelectorStyledProps>`
  position: absolute;
  right: .5rem;
  top: .5rem;
  width: .8em;
  height: .8em;
  border-radius: 50%;
  outline: 1px solid ${globalstyles.colors.secondary};
  outline-offset: 2px;
  background-color: ${(props) => props.active ? globalstyles.colors.secondary : "transparent"};
  transition: background-color 250ms ease-in-out;
`
