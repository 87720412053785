import {config} from "../../../../../config";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useRedux";
import {ConfirmButton, Container, CustomTitle} from "../../../../../styles/globalcomponents";
import {ButtonsContainer, IconContainer, ImageContainer, ToolCardContainer, ToolDescription,} from "./toolCard.styles";
import { UnsubscribeButton } from "../../subscription.styles";
import {
    moveToFollowingStep,
    resetCheckoutState,
    setPayOnlyAdditionalTools,
    STEPS_INDEXES
} from "../../../../../redux/slices/checkout";
import {PlansService} from "../../../../../services/plansService";
import {AddIcon} from "../../../../../components/icons";
import DateSubscriptionInfo from "../../../../../components/dateSubscriptionInfo/dateSubscriptionInfo.component";
import {useEffect, useState} from "react";
import {ToastService} from "../../../../../services/toastService";
import {EmailToolAccountResponse} from "../../../../../interfaces/plans";
import {GetUserEmailToolContactsConsumed} from "../../../../../services/addonService";
import {ChatRedirectModal} from "../modals/chatRedirectModal";
import {getFormattedNumber} from "../../../../../lib/numberFormat";
import {ProgressBar} from "../../../../../components/progressBar/progressBar.component";
import {ExplainToolTip} from "../../../../../components/tooltip/tooltip.component";
import {SUBSCRIPTION_NAMES, SubscriptionsPeriodTypes} from "../../../../../redux/consts";

interface Props {
    showUnsubscribe: (isEmailCancellation?: boolean) => void
    showPlans: () => void
}

const plansService = new PlansService();
const toastService = new ToastService()

const ToolCard = ({showUnsubscribe, showPlans}: Props) => {
    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const haveEmailContacts = dataUser.addonsInfo?.emailContacts.length !== 0
    const userAddonsPlan = dataUser.addonsInfo.emailContacts?.[0]
    const addonsQuantity = getFormattedNumber(haveEmailContacts ? dataUser.addonsInfo?.emailContacts[0]?.quantity : 0)
    const dispatcher = useAppDispatch()
    const userBelongsToPayTool = dataUser.existPaytoolLogic === 1
    const belongsToHotmart = !userBelongsToPayTool
    const isAddonPlanDisabled = Boolean(plansService.checkPlanExpired(String(userAddonsPlan?.expired_time)))
    const isAddonPlanCancelled = userAddonsPlan?.isCancellationScheduled ?? userAddonsPlan?.isCanceled ?? false
    const subscriptionExpirationDate = String(userAddonsPlan?.expired_time ?? '').split("T", 1)[0]
    const [isHotmartUserRedirectionModalOpen, setIsHotmartUserRedirectionModalOpen] = useState<boolean>(false)
    const [userActiveCampaignData, setUserActiveCampaignData] = useState<EmailToolAccountResponse | undefined>()
    const paidEmailToolContacts = userActiveCampaignData?.accounts?.[0]?.products?.[0]?.entitlements?.find((element) => element.code === 'contacts')?.limit?.purchased_units
    const haveActiveCampaignAccount = userActiveCampaignData?.accounts?.length !== 0
    const isMastertoolsPlanDisabled = Boolean(plansService.checkPlanExpired(dataUser.suscription.expired_time));
    const isTrial = dataUser.suscription.name === SUBSCRIPTION_NAMES.TRIAL


    function handleChangeAddonPlan() {
        if (belongsToHotmart) return setIsHotmartUserRedirectionModalOpen(true);
        dispatcher(resetCheckoutState())
        dispatcher(setPayOnlyAdditionalTools({isOnlyAdditionalTools: true}))
        dispatcher(moveToFollowingStep({stepId: STEPS_INDEXES.planDescription}))
        showPlans()
    }

    function closeHotmartUserRedirectionModal() {
        setIsHotmartUserRedirectionModalOpen(false)
    }

    function handleAddonCancellation() {
        const isCancellationScheduled = userAddonsPlan?.isCancellationScheduled ?? false

        if (isCancellationScheduled) {
            return toastService.warning("¡Ya está programado la cancelación del plan!")
        }
        showUnsubscribe(true)
    }

    async function getEmailToolAccountState(userId: string) {
        try {
            setUserActiveCampaignData(await GetUserEmailToolContactsConsumed(userId))
        } catch (e) {
            console.log("⚠️⚠️No se encontró cuenta de EmailTool Activa")
        }
    }


    function renderActionButton() {

        if (isTrial || isMastertoolsPlanDisabled) return <></>

        if (isAddonPlanDisabled) {
            return (
                <Container>
                    <ButtonsContainer>
                        <ConfirmButton
                            onClick={handleChangeAddonPlan}
                        >
                            Renovar / Cambiar plan
                        </ConfirmButton>
                        <ExplainToolTip>
                            Cuando presiones el botón de <strong>"Renovar / Cambiar plan"</strong> podrás renovar
                            EmailTool con el mismo plan anterior o seleccionar uno nuevo
                        </ExplainToolTip>
                    </ButtonsContainer>
                </Container>
            )
        }

        if (haveEmailContacts) {
            return (
                <Container>
                    <ButtonsContainer>
                        <ConfirmButton
                            onClick={handleChangeAddonPlan}
                        >
                            Cambiar plan
                        </ConfirmButton>
                        <UnsubscribeButton
                            onClick={handleAddonCancellation}
                        >
                            Cancelar suscripción
                        </UnsubscribeButton>
                    </ButtonsContainer>
                </Container>
            )
        }

        if (!haveEmailContacts) {
            return (
                <Container>
                    <ButtonsContainer>
                        <ConfirmButton
                            padding={".5rem .8rem"}
                            onClick={handleChangeAddonPlan}
                        >
                            <AddIcon/> Agregar
                        </ConfirmButton>
                    </ButtonsContainer>
                </Container>
            )
        }
    }

    useEffect(() => {
        getEmailToolAccountState(dataUser.id)
    }, [dataUser.id])

    return (
        <>
            <Container>
                <ToolCardContainer haveTopBanner={haveEmailContacts}>
                    {
                        haveEmailContacts ?
                            <DateSubscriptionInfo
                                expired={isAddonPlanDisabled}
                                canceledPlan={isAddonPlanCancelled}
                                expirationDate={subscriptionExpirationDate}
                            />
                            : null
                    }
                    <ImageContainer marginLeft={"14px"} width={"100px"}>
                        <IconContainer src={`${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`} width={"100px"}
                                       height={"100px"}/>
                    </ImageContainer>
                    <ToolDescription>
                        {
                            haveEmailContacts ?
                                <section>
                                    <h2>EmailTool</h2>
                                    <small>Cantidad de contactos en tu ultimo plan:</small>
                                    <h1>{addonsQuantity}</h1>
                                    {
                                        !!userActiveCampaignData && haveActiveCampaignAccount && !isMastertoolsPlanDisabled &&
                                        <ProgressBar
                                            className={"mt-1/2 mr-1"}
                                            currentValue={userActiveCampaignData?.accounts?.[0]?.subscribers ?? 0}
                                            total={paidEmailToolContacts ?? 0}
                                            label={{ text: "Contactos suscritos", position: "top" }}
                                            showNumberIndicator={true}
                                        />
                                    }
                                </section> : null
                        }
                        <section>
                            {haveEmailContacts ?
                                <h6>Detalles de la herramienta:</h6>
                                :
                                <CustomTitle color={"black"} fontWeight={"700"} fontSize={"15px"} textAlign={"start"}
                                             margin={".5rem 0 0"}>
                                    EmailTool (Marketing)
                                </CustomTitle>
                            }
                            <ul>
                                <li>{haveEmailContacts ? 'Marketing Automatizado' : 'Elige entre 1.000 y 100.000 suscriptores'}</li>
                                <li>Seguimiento de embudos y eventos</li>
                                <li>Envío ilimitado de correos*</li>
                            </ul>
                            {renderActionButton()}
                        </section>
                    </ToolDescription>
                </ToolCardContainer>
            </Container>
            <ChatRedirectModal closeModal={closeHotmartUserRedirectionModal}
                               isOpen={isHotmartUserRedirectionModalOpen}/>
        </>
    );
};

export default ToolCard;
