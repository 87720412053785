import { BiSearch } from "react-icons/bi";
import { IoMdNotifications } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { config } from "../../config";
import { globalstyles } from "../../styles/animations/globalstyles";
import styled from "styled-components";


export const HomeLogo = styled.img`
  width: 32px;
  height: 32px;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    filter: invert(100%);
    width: 80px;
  }
`;

interface LinkProps {
  color?: string;
  fontSize?: string;
  hasHover?: boolean;
  margin?: string;
  width?: string;
}

export const LinkStyled = styled.div<LinkProps>`
  align-items: center;
  background: transparent;
  color: ${(props) => (props.color ? props.color : "white")};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "13px")};
  font-weight: 600;
  height: 100%;
  justify-content: center;
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  transition: all ease-in-out 0.3s;
  width: ${(props: LinkProps) => (props.width ? props.width : "100px")};

  &:hover {
    color: ${globalstyles.colors.text};
    cursor: ${(props: LinkProps) =>
    props.hasHover === false ? "default" : "pointer"};
    transition: all ease-in-out 0.2s;
  }
`;

interface ILinksContainer {
  mobileHidden?: boolean;
  right?: string;
}

export const LinksContainer = styled.div<ILinksContainer>`
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* right: ${(props) => (props.right ? props.right : "100px")}; */

  @media (max-width: ${globalstyles.sizes.mobile}) {
    display: ${(props) => props.mobileHidden && "none"};
  }
`;

interface BarProps {
  backgroundImage?: string
}

export const Bar = styled.div<BarProps>`
  align-items: center;
  background-image: url(${(props) => props.backgroundImage ? props.backgroundImage : globalstyles.colors.primary});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  height: 55px;
  left: 0;
  padding: 0 40px 0 5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const NavigationBar = styled.div`
  align-items: center;
  display: flex;
  gap: 1rem;
`;

export const MenuProfileContainer = styled.div`
  position: fixed;
  right: 0px;
  transition: top 0.5s;
  z-index: 90;
`;

export const MenuProfile = styled.div`
  width: 200px;
  background-color: white;
  padding: 8px;
  border-radius: 0 0 10px 10px;
  border: 1px solid ${globalstyles.colors.grayLight};
`;

export const MenuProfileLogo = styled.img.attrs(() => ({
  src: `${config.urls.res}/platform/Logo_mastertools_negro.png`,
}))`
  width: 48px;
`;

export const MenuProfileLogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 32px auto 16px auto;
`;

export const MenuProfileItem = styled.div`
  cursor: pointer;
  margin-bottom: 16px;
  margin-top: 16px;
  margin-left: 15px;
  display: flex;
  align-items: center;
`;

export const MenuProfileText = styled.div`
  color: black;
  line-height: 18px;
  font-size: 16px;
  border-bottom: 1px solid transparent;

  ${MenuProfileItem}:hover & {
    border-bottom: 1px solid black;
  }
`;


export const SearchIcon = styled(BiSearch)`
  color: white;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 100px;
  top: 35%;
`;

export const NotificationIcon = styled(IoMdNotifications)`
  color: white;
  width: 32px;
  height: 32px;
`;

export const ProfileIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 4;
  object-fit: cover;
`;

interface IconProps {
  width?: string;
}

export const Image = styled.img<IconProps>`
  cursor: pointer;
  width: ${(props) => (props.width ? props.width : "200px")};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 80px;
  }
`;

export const NotificationBellIcon = styled.div`
  display: grid;
  place-content: center;
  font-size: 27px;
  color: white;
  cursor: pointer;
  position: relative;
`;

export const BellISignal = styled.span`
  background-color: ${globalstyles.colors.green};
  border-radius: 50%;
  position: absolute;
  height: 8px;
  right: 4px;
  top: 5px;
  width: 8px;
`;
