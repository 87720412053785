import styled from "styled-components";
import {globalstyles} from "../../../../../styles/animations/globalstyles";

type DescriptionProps = {
  color?: string;
};

export const Description = styled.div<DescriptionProps>`
  color: ${(props) => (props.color ? "black" : "white")};
  font-size: 14px;
  text-align: center;
  margin: 16px auto;
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
`;

export const ImageContainer = styled.div`
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  height: 200px;
  justify-content: center;
  margin: 1rem 0;
  overflow: hidden;
  width: 200px;
`;

export const ImagePreviewContainer = styled.div`
  display: grid;
  place-items: center;
`;

export const PhotoPreviewStyled = styled.video`
  border-radius: 50%;
  border: 11px solid #00dfb0;
  width: 15em;
  height: 15em;
  margin: 0 auto;
  aspect-ratio: 1/1;
  animation: take-photo-animation 1s infinite;
  
  &.hidden {
    display: none;
  }
  
  @media(min-width: ${globalstyles.sizes.xl}) {
    width: 300px;
    height: 300px;
  }

  @keyframes take-photo-animation {
    0% {
      border-color: #00dfb0;
    }
    50% {
      border-color: #27c09f;
    }
    100% {
      border-color: #00dfb0;
    }
  }
`

export const CanvasPreviewStyled = styled.canvas`
  border: 11px solid white;
  border-radius: 50%;
  width: 15em;
  height: 15em;
  margin: 0 auto;
  aspect-ratio: 1 / 1;

  &.hidden {
    display: none;
  }

  @media(min-width: ${globalstyles.sizes.xl}) {
    width: 300px;
    height: 300px;
  }
`

