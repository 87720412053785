import styled from "styled-components";


export const AccountStatusBarStyled = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  place-content: center;
  column-gap: 1rem;
  width: 100%;

`
export const AccountStatusBarImgWrapperStyled = styled.div`
    width: 5rem;
    aspect-ratio: 1;
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
  
    & img {
      
    }
`
