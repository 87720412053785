import { instance } from "../axios/axiosConfig";
import {
	GetSubscriptionStateResponse,
	GetSubscriptionStateResponseNew,
	PlanFrequency,
	SubscriptionInfo,
	UserSubscriptionStateNewestResponse,
	UserSubscriptionStateResponse,
} from "../interfaces/plans";
import { addDays, DatesLib } from "src/lib/datesLib";
import { DataUser } from "src/interfaces/redux";
import { LocalStorageKeys } from "../redux/localStorageKeys";

interface GetUserSubscriptionState {
	productId: number;
	newPlanInfo: SubscriptionInfo;
	newPlanPayFrequency: PlanFrequency;
}

interface GetUserSubscriptionStateNewest {
	newPlan: SubscriptionInfo;
	periodicity: PlanFrequency;
}

export const PRODUCT_IDS = {
	MasterTools: 395,
	EmailTool: 402,
};

export interface CancellationFormAnswer {
	id: number;
	question: string;
	answer: string | string[];
	type: "MULTIPLE" | "SINGLE";
}

export interface UserWhiteListPayload {
	email: string;
	idCoupon: string;
}

export class PlansService {
	datesLib = new DatesLib();

	checkPlanExpired(expirationDate: string) {
		return this.datesLib.isDateExpired(expirationDate);
	}

	async sendCancellationForm(form: CancellationFormAnswer[], idUser: number) {
		const response = await instance.post(
			`/app/suscription/cancellationForm`,
			{
				idUser,
				answerCancellationForm: form,
			},
		);
		return response.data;
	}

	async createUserWhiteList(payload: UserWhiteListPayload) {
		const response = await instance.post("/app/plans/coupon/userWhiteList", {
			email: payload.email,
			name: "Automatic for MasterTools BW Button",
			state: 1,
			idCoupon: payload.idCoupon,
		});
		return response.data.data;
	}

	async unsubscribeByPaytool(userData: any) {
		const response = await instance.post("/app/paytool/cancel/client/", {
			emailUser: userData.email,
			product: userData.product,
		});
		return response.data.data;
	}

	async detailSubscription() {
		const response = await instance.get(
			"/app/suscription/detailSuscription/",
		);
		return response.data.data;
	}

	async getUserSubscriptionState({
		productId,
		newPlanInfo,
		newPlanPayFrequency,
	}: GetUserSubscriptionState) {
		const dataUser = this.getDataUser();
		const email = dataUser.email;
		const newPlan = newPlanInfo;
		const currentPlanName = dataUser.suscription.name;
		const codePlans: any = {
			Anual: newPlanInfo.codePlanAnualPaytool,
			Mensual: newPlanInfo.codePlanPaytool,
		};
		const planCode = codePlans[newPlanPayFrequency];
		if (!planCode) throw new Error("Plan frequency not found");

		const validationResponse = await instance.get(`/app/suscription/validation/${email}/${planCode}/${productId}`);
		if (validationResponse.data.codeResponse !== 200)
			throw new Error("Error validating subscription");

		const validationData = (
			validationResponse.data as UserSubscriptionStateResponse
		).data;
		const nextPayment = validationData.nextPayment?.split("T", 1);

		const planData: GetSubscriptionStateResponse = {
			isApportionmentPrice: false,
			apportionmentPrice: undefined,
			action: validationData.action,
			nextPayment: validationData.nextPayment
				? String(nextPayment)
				: undefined,
			priceTotal: undefined,
			codePlanPaytool: planCode,
			valueToCharge: undefined,
		};

		if (!validationData.action)
			throw new Error("action is undefined, validation failed");

		if (validationData.action === "Upgrade") {
			if (validationData.apportionmentPrice) {
				planData.isApportionmentPrice = true;
				planData.priceTotal = validationData.priceTotal;
				planData.apportionmentPrice = validationData.apportionmentPrice;
				return planData;
			}
			if (currentPlanName !== newPlan.name) {
				planData.priceTotal = validationData.priceTotal;
				return planData;
			}
			return planData;
		}

		if (validationData.action === "Renew") {
			const nextPaymentDate = String(addDays(new Date(), 30));
			planData.priceTotal = validationData.priceTotal;
			planData.nextPayment = nextPaymentDate;
			return planData;
		}

		if (!validationData.action || validationData.action === "Downgrade") {
			const nextPayment = validationData.nextPayment?.split("T", 1);
			planData.priceTotal = validationData.priceTotal;
			planData.nextPayment = String(nextPayment);
			planData.action = "Downgrade";
			return planData;
		}
	}

	async getUserSubscriptionStateNewest({
		periodicity,
		newPlan: plan,
	}: GetUserSubscriptionStateNewest) {
		const dataUser = this.getDataUser();
		const email = dataUser.email;
		const newPlan = plan;
		const codePlans: { [key in PlanFrequency]: string } = {
			Mensual: newPlan.codePlanPaytool,
			Trimestral: newPlan.codePlanTrimPayTool,
			Semestral: newPlan.codePlanSemiAnualPaytool,
			Anual: newPlan.codePlanAnualPaytool,
		};
		const codePlan = codePlans[periodicity];
		if (!codePlan) throw new Error("Plan frequency not found");

		const validationResponse = await instance.post(
			`/app/suscription/validateAction`,
			{
				email,
				planCode: codePlan,
			},
		);

		if (!validationResponse.data.success)
			throw new Error("Error validating subscription");

		const validationData = (
			validationResponse.data as UserSubscriptionStateNewestResponse
		).data;

		const planData: GetSubscriptionStateResponseNew = {
			action: validationData.action,
			startDate: validationData.startDate.split("T")[0] as string,
			finalDate: validationData.finalDate.split("T")[0] as string,
			codePlanPaytool: codePlan,
			valueToCharge: undefined,
			isApportionmentPrice: validationData.isApportionment,
			priceTotal: validationData.amount,
			apportionmentPrice: validationData.amount,
			periodicity,
			isSubProduct: validationData.isSubProduct,
		};

		return planData;
	}

	async handlePayment(
		email: string,
		codePlanToChange: string,
		codeProduct: number,
		typeChange: string,
		valueToCharge: number,
	): Promise<{
		data: {
			suscriptionUpdated: boolean;
		};
	}> {
		const response = await instance.post("app/suscription/changePlan", {
			email,
			planCode: codePlanToChange,
		});
		return {
			data: {
				suscriptionUpdated: response.data.data.codeResponse === 200,
			},
		};
	}

	async validateIfDowngradeIsAlreadySchedule(
		email: string,
		planCode: string,
		productId: number,
	): Promise<boolean> {
		const response = await instance.get(`/app/suscription/validate/scheduleDowngrade/${email}/${planCode}/${String(productId)}`);
		return response.data.data;
	}

	private getDataUser() {
		const dataUser = JSON.parse(
			localStorage.getItem(LocalStorageKeys.userLoggedInfo) ?? "",
		).dataUser as DataUser;
		if (!dataUser) throw new Error("User not found");
		return dataUser;
	}
}
