import {CustomTitle} from "../../../../../styles/globalcomponents";
import {numberFormat} from "../../../../../lib/numberFormat";
import {CheckoutSummaryHeaderStyled} from "./plansCheckoutSummary.styles";
import {useAppSelector} from "../../../../../hooks/useRedux";
import {PlanFrequency} from "../../../../../interfaces/plans";


export function SummaryHeader() {
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const plans = useAppSelector((state) => state.auth.plans)
    const planInfo = plans.find((plan) => plan.suscriptionInfo.idSuscription === selectedPlan?.id)

    if(!planInfo || !selectedPlan) return <></>

    const planPrices: {[key in PlanFrequency]: string} = {
        'Mensual': planInfo.suscriptionInfo.monthPrice,
        'Trimestral': planInfo.suscriptionInfo.trimesterPrice,
        'Semestral': planInfo.suscriptionInfo.semiAnualPrice,
        'Anual': planInfo.suscriptionInfo.anualPrice,
    }

    const price = Number(planPrices[selectedPlan.frequency] ?? 0)

    if(!planInfo || !selectedPlan) return <>Error calculando el plan 😭</>

    return (
        <CheckoutSummaryHeaderStyled>
            <div>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"} color={"black"}>
                    Plan {planInfo.suscriptionInfo.name}
                </CustomTitle>
                <CustomTitle fontWeight={"500"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"black"}>
                    {selectedPlan.frequency}
                </CustomTitle>
            </div>
            <div>
                ${numberFormat.format(price)} USD
            </div>
        </CheckoutSummaryHeaderStyled>
    )
}
