import styled from "styled-components";

export const TypeFormProfiler = styled.div`
  height: 100vh;
`;

interface IframeTypeFormProps {
  src: string;
}
export const IFrameTypeForm = styled.iframe.attrs<IframeTypeFormProps>(() => ({
  allowFullScreen: true,
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
}))`
  height: 100vh;
  width: 100%;
  `;

