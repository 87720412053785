import { useEffect, useState } from "react";
import {
  CloseButton,
  CloseButtonTootorials,
  PopupCard,
  PopupComponent,
  VideoContainer,
} from "./popup.styles";
import { MdClose } from "react-icons/md";
import { IframeVideo } from "./IframeVideo.styles";
import { useAppSelector } from "src/hooks/useRedux";

interface PopupProps {
  allowFullScreen?: boolean;
  canClose?: boolean;
  closeType?: number;
  delayButton?: boolean;
  fullScreen?: boolean;
  handleModalAction: () => void;
  tootorial?: boolean;
  urlTootorial: string | undefined;
  width?: string | undefined;
}

const Popup = ({
  allowFullScreen = false,
  canClose = false,
  closeType = 1,
  delayButton,
  fullScreen,
  urlTootorial,
  handleModalAction,
  width,
}: PopupProps) => {
  const [allowClose, setAllowClose] = useState<boolean>(false);
  const {
    id,
    email,
    suscription: subscription,
  } = useAppSelector((state) => state.auth.dataUser);

  useEffect(() => {    
    delayButton
      ? setTimeout(() => {
          setAllowClose(true);
        }, 10000)
      : setAllowClose(true);
  }, []);

  return (
    <PopupComponent>
      <PopupCard
        responsive={"top: 15%; bottom: 19%;"}
        fullScreen={fullScreen}
        width={width}
      >
        {allowClose && canClose ? (
          closeType === 1 ? (
            <CloseButton onClick={handleModalAction}>
              <MdClose size={45} />
            </CloseButton>
          ) : (
            <CloseButtonTootorials onClick={handleModalAction}>
              <MdClose size={30} />
            </CloseButtonTootorials>
          )
        ) : null}
        <VideoContainer>
          <IframeVideo
            allowFullScreen={allowFullScreen} src={`${urlTootorial}?userId=${id}&email=${email}&plan=${subscription.name}&planId=${subscription.idSuscription}`}
          ></IframeVideo>
        </VideoContainer>
      </PopupCard>
    </PopupComponent>
  );
};

export default Popup;
