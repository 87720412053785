import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from "react-icons/md";
import styled from "styled-components";
import {css} from "styled-components";
import {config} from "../../../../../../config";
import {globalstyles} from "../../../../../../styles/animations/globalstyles";

export const ArrowRight = styled(MdKeyboardArrowRight)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  transition: 0.2s;
  width: 80px;
  height: 80px;
  color: white;

  &:hover {
    transition: 0.2s;
    transform: scale(1.2);
    color: ${globalstyles.colors.yellow};
  }
`;

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  transition: 0.2s;
  width: 80px;
  height: 80px;
  color: white;

  &:hover {
    transition: 0.2s;
    transform: scale(1.2);
    color: ${globalstyles.colors.yellow};
  }
`;

export const ActualPlan = styled.div`
  padding: 8px 0;
  background: black;
  width: 90%;
  margin: 16px auto;
  border-radius: 8px;
  text-align: center;
  font-weight: bolder;
  font-size: 24px;
  color: white;
`;

export const PlanFeatureTextValue = styled.em`
  color: ${globalstyles.colors.lightOrange};
  font-size: 16px;
  font-weight: bolder;
  align-self: end;
  font-style: normal;
`;

export const PlanFeatureText = styled.div`
  color: ${globalstyles.colors.grayDarkness};
  font-size: 16px;
`;

export const PlanFeature = styled.div`
  display: flex;
  margin: 0.5rem 0;

  svg {
    margin-right: 0.8rem;
    min-width: 20px;
  }
`;

export const PlanFeatures = styled.div`
  height: 100%;
  width: 100%;
`;

export const RecommendedCardText = styled.div`
  position: absolute;
  top: 4px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
`;

export const RecommendedCard = styled.div`
  width: 95%;
  height: 64px;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 16px;
  background: ${globalstyles.colors.yellow};
  z-index: 0;
`;

interface buttonProps {
    buttonType?: string;
    disable?: boolean;
    padding?: string;
    width?: string;
}

export const Button = styled.div<buttonProps>`
  height: 24px;
  border: none;
  border-radius: 16px;
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 12px;
  justify-content: center;
  background: ${(props) =>
    props.disable
        ? globalstyles.colors.grayDarkness
        : props.buttonType === "purple"
            ? globalstyles.colors.primary
            : globalstyles.colors.yellow};
  cursor: ${(props) => (props.disable ? "not-allowed" : "pointer")};
  color: ${(props) =>
    props.disable
        ? "white"
        : props.buttonType === "purple"
            ? "white"
            : "unset"};
  padding: ${(props) => props.padding ? props.padding : "0.2rem 0"};
  pointer-events: ${(props) => (props.disable ? "none" : "unset")};
  width: ${(props) => (props.width ? props.width : "130px")};
`;

interface MembershipButton {
    active: boolean
    promotion?: string
}

export const MembershipButton = styled.div<MembershipButton>`
  align-items: center;
  position: relative;
  border: none;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  font-size: .8rem;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 1px;
  text-align: center;
  user-select: none;
  padding: .6rem 1rem;
  background-color: ${(props) => props.active ? globalstyles.colors.secondary : "transparent"};
  color: ${(props) => props.active ? "white" : globalstyles.colors.black};
  ${(props) => {
    if (props.promotion) return css`
      &::before {
        content: "${props.promotion}";
        display: block;
        top: -1rem;
        right: -2rem;
        font-size: .7rem;
        border-radius: 1rem;
        padding: .3rem .6rem;
        position: absolute;
        background-color: ${globalstyles.colors.yellow};
        color: ${globalstyles.colors.black};
        animation: ani-bounce 3s infinite ease;
        z-index: 1;
      }
    `
}
}
`;

export const Addons = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin: 0 0 4rem 0;
  position: relative;
  width: 100%;
`;

interface IPlansContainer {
    backgroundColor?: string;
    margin?: string;
    responsive?: string;
}

export const PlansContainerStyled = styled.div<IPlansContainer>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  position: relative;
  scroll-behavior: smooth;
  gap: 1.5rem;

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    flex-direction: column;
    ${(props: IPlansContainer) => props.responsive && props.responsive}
  }
`;

export const AddonBlock = styled.div`
  background-color: ${globalstyles.colors.grayDarkness};
  border-radius: 13px;
  display: flex;
  height: 170px;
  width: 80%;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 100%;
  }
`;

export const EmailBlock = styled.div`
  background-image: url("${config.urls.res}/platform/banners/banner_email.png");
  background-position: center;
  background-size: cover;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 0.4rem;
  height: 100%;
  justify-content: center;
  width: 20%;

  img {
    height: 60px !important;
    width: 60px !important;
  }
`;

export const SelectAddon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 55%;
`;

interface AddonBarProps {
	backgroundSize: string;
}

export const ClickableProgressBar = styled.div<AddonBarProps>`
  background-color: ${globalstyles.colors.gray} !important;
  background: linear-gradient(
    90deg,
    rgba(128, 54, 202, 1) 0%,
    rgba(179, 48, 190, 1) 35%,
    rgba(205, 64, 182, 1) 100%
  );
  background-repeat: no-repeat;
  background-size: ${(addonBarProps) => addonBarProps.backgroundSize}% 100%;
  border-radius: 30px;
  cursor: pointer;
  height: 12px;
  outline: none;
  position: relative;
  width: 90%;
`;

interface AddonPointProps {
    position?: number;
    currentAddon: boolean;
    marginRight?: number;
}

export const AddonPoint = styled.div<AddonPointProps>`
  background-color: ${(props) =>
    props.currentAddon
        ? globalstyles.colors.yellow
        : globalstyles.colors.grayLight};
  border-radius: 50%;
  cursor: pointer;
  height: 13px;
  margin-left: ${(props) => props.position?.toString()}%;
  margin-right: ${(props) => props.marginRight?.toString()}%;
  position: absolute;
  top: -1px;
  transform: ${(props) => (props.currentAddon ? "scale(1.5)" : "")};
  width: 13px;
`;

export const AddonResume = styled.div`
  align-items: center;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 25%;
  z-index: 3;
`;

export const AddonUpdate = styled.div`
  align-items: center;
  background-color: ${globalstyles.colors.grayDarkness};
  border-radius: 13px;
  box-shadow: 0 0 8px 5px black;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
  width: 80%;
`;

interface contactQuantityProps {
    position: number;
}

export const ContactQuantity = styled.div<contactQuantityProps>`
  align-items: center;
  bottom: 2rem;
  color: white;
  display: flex;
  height: 35px;
  justify-content: center;
  margin-left: ${(contactQuantityProps) =>
    contactQuantityProps.position}px !important;
  position: absolute;
  z-index: 1;
`;

export const AddonPriceArea = styled.div`
  display: flex;
  margin: 0.4rem;

  div {
    font-size: 1.2rem;
  }
`;

interface PlanToolProps {
    width?: string;
    height?: string;
}

export const PlanTool = styled.img<PlanToolProps>`
  width: ${(props) => props.width ? props.width : "1.2rem"} ;
  height: ${(props) => props.height ? props.height : "1.2rem"} ;
  margin: 0 5px 0 0;
`;

export const PlanToolsContainer = styled.div`
  display: flex;
  gap: .3rem
`;

interface PlanDescriptionProps {
    alignItems?: string;
    width?: string;
}

export const PlanDescription = styled.div<PlanDescriptionProps>`
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "100%")};
  line-height: 6px;
  padding: 1rem;
  & .link {
    appearance: none;
    background-color: transparent;
    border-color: transparent;
    color: ${globalstyles.colors.gray};
    font-size: 11px;
    margin: 1rem 0 0;
    text-align: center;
    text-decoration: none;
  }
`;

export const PlanTitle = styled.div`
  color: white;
  font-size: 19px;
  font-weight: bolder;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    font-size: 16px;
  }
`;

interface PlanPriceNumberProps {
    textAlign?: 'center' | 'start' | 'end'
}

export const PlanPriceNumber = styled.div<PlanPriceNumberProps>`
  color: ${globalstyles.colors.grayDarkness};
  letter-spacing: 0;
  text-align: ${(props) => props.textAlign ? props.textAlign : "left"};
  font-size: 18px;
  font-weight: bold;
`;

interface planPriceProps {
    fontSize?: string;
    fontWeight?: string;
    margin?: string;
}

export const PlanPriceText = styled.div<planPriceProps>`
  color: ${globalstyles.colors.grayDarkness};
  display: flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "unset")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
`;

export const PlanPrice = styled.div`
  align-items: center;
  display: flex;
  margin: 1.4rem 0;
`;

export const PlanHeaderActualPlan = styled.div`
  position: absolute;
  left: 0;
  top: 16px;
  width: 80%;
  height: 24px;
  background: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 14px;
`;

interface PlanHeader {
	backgroundImage: string;
	mode?: "LIST" | "CARD";
}

export const PlanHeader = styled.div<PlanHeader>`
  display: flex;
  height: 6rem;
  flex-direction: column;
  ${(props) => (props.mode === "LIST" ? `border-radius: ${globalstyles.borders.cards};` : "")}
  justify-content: center;
  align-items: center;
  margin: auto auto;
  overflow: hidden;
  border-radius: ${globalstyles.borders.cards} ${globalstyles.borders.cards} 0 0;
  width: 100%;
  ${(props) => (props.mode === "LIST" ? "margin: 0 0 0 1rem;" : "")}
  background-image: url(${(props) => props.backgroundImage});
  background-position: center;
  background-size: cover;
`;

interface PlanCard {
	active?: boolean;
	isRecommended?: boolean;
	width?: string;
	mode?: "LIST" | "CARD";
}

export const PlanCardStyled = styled.article<PlanCard>`
  display: flex;
  flex-direction: ${(props) => (props.mode === "LIST" ? "row" : "column")};
  ${(props) => (props.mode === "LIST" ? "align-items: center;" : "")};
  border-radius: ${globalstyles.borders.cards};
  box-shadow: 0 2px 20px 3px #DEDEDE;
  width: ${(props) => props.width ?? "15rem"};
  max-width:  ${(props) => (props.mode === "LIST" ? "100%" : "90%")};
  position: relative;

  ${(props) =>
		props.isRecommended &&
		css`
      outline: .5rem solid ${globalstyles.colors.flatPurple};

      &::before {
        content: "Recomendado";
        color: white;
        position: absolute;
        font-weight: 500;
        font-size: .9rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: ${globalstyles.colors.flatPurple};
        padding: .5rem;
        top: -1.5rem;
        border-radius: 0.5rem;
        z-index: 2;
      }
  `}
  ${(props) =>
		props.active
			? "box-shadow: 0 0 8px 0px #ADADAD;"
			: "box-shadow: 0 0 8px 0px rgba(173,173,173,0.33);"}
`;
