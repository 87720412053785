import { openFromLeft, openFromRight } from "src/styles/animations/globalAnimations";
import { globalstyles } from "src/styles/animations/globalstyles";
import styled, { css } from "styled-components";

export const MainTitle = styled.span`
  font-size: 28px;
  margin: 0.5rem 0;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    font-size: 18px !important;
    text-align: center;
  }
`;

interface IColumnBottom {
  animation?: string;
  alignItems?: string;
  backgroundColor?: string;
  flexDirection?: string;
  flexWrap?: boolean;
  gap?: string;
  height?: string;
  justifyContent?: string;
  margin?: string;
  responsive?: string;
  overflow?: string;
  padding?: string;
  width?: string;
}

export const ColumnBottom = styled.div<IColumnBottom>`
  ${(props) =>
    props.animation === "left"
      ? css`
          -webkit-animation: ${openFromLeft} 0.6s cubic-bezier(0.23, 1, 0.32, 1)
            both;
          animation: ${openFromLeft} 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
        `
      : props.animation === "bottom"
      ? css`
          -webkit-animation: ${openFromRight} 1.1s both;
	        animation: ${openFromRight} 1.1s both;
        `
      : null};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: 1.5rem;
  display: flex;
  flex-wrap: ${(props) => (props.flexWrap ? "wrap" : "unset")};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "unset"};
  gap: ${(props) => (props.gap ? props.gap : "unset")};
  height: ${(props) => (props.height ? props.height : "unset")};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "unset")};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  overflow: ${(props) => (props.overflow ? props.overflow : "unset")};
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  position: relative;
  width: ${(props) => (props.width ? props.width : "48%")};

  
  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    ${(props: IColumnBottom) => props.responsive && props.responsive};
    width: 100%;
  }
`;

export const IntegrationIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  height: 25px;
  margin: 10px auto 0 auto;
`;

export const CategoryLink = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 12px !important;
  text-decoration: underline;
  margin: 0.2rem auto;
  width: 85%;
`;

interface IntDescriptionProps {
  flexDirection?: string;
}

export const IntDescription = styled.div<IntDescriptionProps>`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "unset"};
  margin: 1rem auto;
  width: 90%;
`;

export const IntTitle = styled.span`
  font-weight: 500;
  margin: 5px 0;
`;

export const ComingSoonLayer = styled.div`
  align-items: center;
  background-color: #16161650;
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: fit-content;
`;

export const ComingSoon = styled.div`
  background-color: yellow;
  border-radius: 1rem;

  h6 { 
    margin: 0 !important;
    background-color: yellow;
    border-radius: 1rem;
    cursor: default;
    padding: 0.3rem 1rem;
  }
`;
