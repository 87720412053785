import Popup from "../popup/popup";
import { VideoContainer } from "./skippablePromotion.styles";

interface ISkippablePromotion {
  closeAction: () => void;
  urlPromotion?: string;
}

export const SkippablePromotion = ({
  closeAction,
  urlPromotion,
}: ISkippablePromotion) => {
  
  const handlePromModal = () => {
    closeAction();
  };

  return (
    <VideoContainer>
      <Popup
        canClose={true}
        fullScreen
        handleModalAction={handlePromModal}
        urlTootorial={urlPromotion}
        width={"70%"}
      ></Popup>
    </VideoContainer>
  );
};
