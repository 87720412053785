import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { IFrame } from "../../styles/globalcomponents";
import { CheckoutContainer } from "./checkout.styles";

export const Checkout = () => {
  const history = useHistory<any>();
  const accessUrl = history.location.state.accessUrl;

  const iframeRef = useRef<HTMLIFrameElement>(null);

  return (
    <CheckoutContainer>
      {accessUrl && <IFrame hasNavbar={true} ref={iframeRef} src={accessUrl} />}
    </CheckoutContainer>
  );
};
