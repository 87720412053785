import { useHistory } from "react-router-dom";
import { LinksContainerMobile, LinkMobile, NavbarBottom } from "./navbarMobile.styles";

const NavbarMobile = () => {
  let history = useHistory();

  const setRoute = (route: string) => {
    history.push(route);
  };
  return (
    <NavbarBottom>
      <LinksContainerMobile>
        <LinkMobile onClick={() => setRoute("/tootorials")} color={"#393939"} fontSize="11px">Tootoriales</LinkMobile>
        {/* <LinkMobile onClick={() => setRoute("/integrations")} color={"#393939"} fontSize="11px">Integraciones</LinkMobile> */}
        <LinkMobile onClick={() => setRoute("/community")} color={"#393939"} fontSize="11px">Comunidad</LinkMobile>
      </LinksContainerMobile>
    </NavbarBottom>
  );
};

export default NavbarMobile;
