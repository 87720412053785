import { config } from "src/config";

export const IntegrationsData = [
    {
        id: 0,
        name: "Envioclick",
        urlIcon: `${config.urls.res}/platform/integrations/Int_EnvioClick.png`,
        title: "Gestión Logística",
        description: "La integración entre MasterTools y Envioclick permite que los usuarios puedan simplificar el proceso de envío de los pedidos recibidos mediante MasterTools. La integración facilita la eliminación de cuellos de botella, la automatización de los procesos de logística y simplifica las entregas a los clientes."
    },
    {
        id: 1,
        name: "Dropi",
        urlIcon: `${config.urls.res}/platform/integrations/Int_Dropi.png`,
        title: "Gestión Logística",
        description: "La integración entre MasterTools y Dropi permite que los usuarios puedan simplificar el proceso de envío de los pedidos recibidos mediante MasterTools. La integración facilita la eliminación de cuellos de botella, la automatización de los procesos de logística y simplifica las entregas a los clientes."
    },
    {
        id: 2,
        name: "Skydropx",
        urlIcon: `${config.urls.res}/platform/integrations/Int_Skydropx.png`,
        title: "Gestión Logística",
        description: "La integración entre MasterTools y Skydropx permite que los usuarios puedan simplificar el proceso de envío de los pedidos recibidos mediante MasterTools. La integración facilita la eliminación de cuellos de botella, la automatización de los procesos de logística y simplifica las entregas a los clientes."
    },
    {
        id: 3,
        name: "Tiendanube",
        urlIcon: `${config.urls.res}/platform/integrations/Int_Tiendanube.png`,
        title: "Gestión Logística",
        description: "La integración entre MasterTools y Tiendanube permite que los usuarios puedan simplificar el proceso de envío de los pedidos recibidos mediante MasterTools. La integración facilita la eliminación de cuellos de botella, la automatización de los procesos de logística y simplifica las entregas a los clientes."
    },
    {
        id: 4,
        name: "Zoom",
        urlIcon: `${config.urls.res}/platform/integrations/Int_Zoom.png`,
        title: "Videoconferencias ",
        description: "Organiza reuniones más eficaces y productivas con la integración de Zoom y MasterTools para que puedas atender tus agendamientos sin preocuparte por mensajes de recordación a los participantes."
    },
    {
        id: 5,
        name: "Videoconferencias",
        urlIcon: `${config.urls.res}/platform/integrations/Int_Meet.png`,
        title: "Gestión Logística",
        description: "Organiza reuniones más eficaces y productivas con la integración de Meet y MasterTools para que puedas atender tus agendamientos sin preocuparte por mensajes de recordación a los participantes."
    },
]