import styled from 'styled-components'
import {globalstyles} from "../../styles/animations/globalstyles";

export const SwitchButtonWrapper = styled.div<{optionsLength: number}>`
    display: grid;
    grid-template-columns: repeat(${props => props.optionsLength}, 10rem);
    border-radius: 15px;
    width: fit-content;
    position: relative;
    background-color: white;
`

interface SwitchOptionStyledProps {
    active: boolean
}
export const SwitchOptionStyled = styled.button<SwitchOptionStyledProps>`
  display: flex;
  flex-grow: 1;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 2rem;
  font-size: .8em;
  font-weight: 600;
  color: ${(props) => props.active ? 'white': 'black'};
  width: 100%;
  padding: .5rem 1rem;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
  position: relative;
  z-index: 3;
  transition: color 200ms cubic-bezier(0, 0.75, 0.13, 0.93);
  cursor: pointer;
`

interface SwitchActiveBackgroundStyledProps {
    width: number,
    xOffset: number,
}

export const SwitchActiveBackgroundStyled = styled.div<SwitchActiveBackgroundStyledProps>`
  position: absolute;
  left: ${(props) =>  props.xOffset ? `${props.xOffset}px` : "0"};;
  width: ${(props) =>  props.width ? `${props.width}px` : "0"};
  height: 100%;
  border-radius: 15px;
  background-color: ${globalstyles.colors.secondary};
  transition: left 200ms cubic-bezier(0, 0.75, 0.13, 0.93);
  z-index: 2;
`
