import { useState } from "react";
import { AiOutlinePhone } from "react-icons/ai";
import { BiLockAlt } from "react-icons/bi";
import { globalstyles } from "../../styles/animations/globalstyles";
import {
  ClosedEyeIcon,
  EyeIcon,
  InputContainer,
  InputField,
} from "./Input.styles";
import {EmailIcon, UserFormIcon, UserIcon} from "../icons";

type props = {
  autoComplete?: string
  backgroundColor?: string;
  borderColor?: string;
  color?: string;
  CustomIcon?: React.ReactNode;
  defaultValue?: string;
  readOnly?: boolean;
  name?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
  type?: string;
  icon?: "email" | "password" | "profile" | "phone" |'userProfile';
  iconColor?: string;
  theme?: "dark" | "light" | "darkMode";
  value?: string;
  width?: string;
  padding?: string
  responsiveLg?: string
  responsiveXl?: string
};

export const Input = ({
  autoComplete,
  responsiveLg,
  responsiveXl,
  backgroundColor,
  borderColor,
  color,
  defaultValue,
  width,
  value,
  name,
  readOnly,
  placeholder,
  CustomIcon,
  style,
  icon,
  iconColor,
  type = "text",
  theme = "dark",
  onChange,
  onKeyPress,
  padding
}: props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <InputContainer
      id={"input-container"}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      style={style}
      theme={theme}
      focus={focus}
      width={width}
      readOnly={readOnly}
      padding={padding}
      responsiveLg={responsiveLg}
      responsiveXl={responsiveXl}
    >
      {icon === "email" ? (
          <EmailIcon viewBox={"0 0 21 15"} width={globalstyles.icons.formIcon} height={globalstyles.icons.formIcon}/>
      ) : icon === "password" ? (
        <BiLockAlt
          size={globalstyles.icons.formIcon}
          color={iconColor}
        />
      ) : icon === "profile" ? (
          <UserIcon viewBox={"0 0 21 20"} width={globalstyles.icons.formIcon} height={globalstyles.icons.formIcon}/>
      ) : icon === "userProfile" ? (
          <UserFormIcon  viewBox={"0 0 21 20"} width={globalstyles.icons.formIcon} height={globalstyles.icons.formIcon}/>
      ) : (
        icon === "phone" && (
          <AiOutlinePhone
            size={globalstyles.icons.formIcon}
            color={iconColor}
          />
        )
      )}
      {CustomIcon && CustomIcon}
      <InputField
        id={"input-field"}
        autoComplete={"off"}
        backgroundColor={backgroundColor}
        color={color}
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        onKeyPress={onKeyPress}
        type={showPassword ? "text" : type}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        defaultValue={defaultValue}
        readOnly={readOnly}
        darkMode={theme === "darkMode"}
      />

      {type === "password" && (
        <>
          {showPassword ? (
            <ClosedEyeIcon
              onClick={() => setShowPassword(!showPassword)}
              fill={"white"}
            />
          ) : (
            <EyeIcon
              onClick={() => setShowPassword(!showPassword)}
              fill={"white"}
            />
          )}
        </>
      )}
    </InputContainer>
  );
};
