import { useEffect, useState } from "react";
import { Checkbox } from "../../../../../components/checkbox/checkbox.component";
import {Container, ContainerItem} from "../../../../../styles/globalcomponents";
import { Input } from "../../../../../components/inputs/Input.component";
import {QuestionsData, whyCancelData} from "./questions.data";
import {UnsubscribeFormState} from "../unsubscribe.component";

interface Props {
  setFormData: (newState: Partial<UnsubscribeFormState>) => void;
}

export const UnsubscribeFormWhyCancelQuestion = ({ setFormData }: Props) => {
  const [options, setOptions] = useState(whyCancelData);
  const [otherInput, setOtherInput] = useState("");

  function handleInputChange(value: string) {
    setOtherInput(value);
  }

  function handleSelectItem(item: QuestionsData) {
    setOptions((prevState) =>
      prevState.map((input) => {
          if (input.name === item.name) {
            return {
              ...input,
              checked: !item.checked,
            };
          }
          return input;
        }
      )
    );
  }

  useEffect(() => {

    function updateFormState() {
      const optionsSelected = options
        .filter((option) => option.checked)
        .map((option) => option.name)

      if(otherInput) {
        optionsSelected.push(otherInput);
      }

      setFormData({cancelReasons: optionsSelected.join(",")});
    }
    updateFormState()
  }, [options, otherInput]);

  return (
    <Container display="block">
      <ContainerItem rowGap={".5rem"} display={"grid"} gridTemplateColumns={"1fr"} responsiveLg={"grid-template-columns: 1fr 1fr"}>
        {options.map((item, index) => (
          <Checkbox
            key={`${item}-${index}`}
            onClick={() => handleSelectItem(item)}
            label={item.name}
            checked={item.checked}
          />
        ))}
      </ContainerItem>

      {options[options.length - 1].checked && (
        <ContainerItem padding={"0 2rem"} margin={"1rem 0 0"}>
          <Input
            borderColor={"#A5A5A5"}
            backgroundColor={"white"}
            padding={"10px 15px"}
            placeholder="Especifique el motivo"
            value={otherInput}
            onChange={(e) => handleInputChange(e.target.value)}
          />
        </ContainerItem>
      )}
    </Container>
  );
};
