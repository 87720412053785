import { useState } from "react";
import { config } from "../../config";
import {
  FreeTrialForm,
  freeTrialFormInitialState,
} from "../../interfaces/auth";
import { AuthService } from "../../services/authService";
import { ToastService } from "../../services/toastService";
import {
  BodyBackground,
  Button,
  Card,
  Container,
  ContainerItem,
  ErrorInput,
  Label,
  CustomTitle,
  Logo,
} from "../../styles/globalcomponents";

import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { HalfWayText } from "./freeTrial.styles";
import { useMobileScreen } from "../../hooks/useMobileScreen";
import { validateEmail, validatePassword } from "../../lib/formsValidation";
import { setShowPromotion } from "../../redux/slices/session";
import { useAppDispatch } from "../../hooks/useRedux";
import { submitLoginAsync } from "../../redux/slices/auth";
import { Input } from "../../components/inputs/Input.component";

const formErrorInitialState = () => ({
  email: "",
  password: "",
  confirmPassword: "",
  name: "",
  lastName: "",
  phoneNumber: "",
});

export const FreeTrial = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  /** Dates afilliate */
  const REF = new URLSearchParams(location.search).get("ref");
  const EXTRA = new URLSearchParams(location.search).get("extra");

  // form
  const [form, setForm] = useState<FreeTrialForm>(
    freeTrialFormInitialState(REF, EXTRA)
  );
  const [blockedForm, setBlockedForm] = useState(false);

  // form errors
  const [formError, setFormError] = useState(formErrorInitialState());

  const mobileScreen = useMobileScreen();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const keySubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submit();
    }
  };

  const submit = async () => {
    if (blockedForm) {
      return;
    }

    const validatedEmail = validateEmail(form.email.trim());
    if (validatedEmail !== "") {
      return setFormError({
        ...formErrorInitialState(),
        email: validatedEmail,
      });
    }

    const validatedPassword = validatePassword(form.password.trim());
    if (validatedPassword !== "") {
      return setFormError({
        ...formErrorInitialState(),
        password: validatedPassword,
      });
    }

    if (form.confirmPassword.trim() === "") {
      return setFormError({
        ...formErrorInitialState(),
        confirmPassword: "Debe confirmar su contraseña",
      });
    }

    if (form.password.trim() !== form.confirmPassword.trim()) {
      return setFormError({
        ...formErrorInitialState(),
        confirmPassword: "Las contraseñas no coinciden",
      });
    }

    if (form.name.trim() === "") {
      return setFormError({
        ...formErrorInitialState(),
        name: "Debe ingresar su nombre",
      });
    }

    if (form.lastName.trim() === "") {
      return setFormError({
        ...formErrorInitialState(),
        name: "Debe ingresar su apellido",
      });
    }

    if (form.phoneNumber.trim() === "") {
      return setFormError({
        ...formErrorInitialState(),
        phoneNumber: "Debe ingresar su número celular",
      });
    }

    setFormError(formErrorInitialState());

    setBlockedForm(true);
    const authService = new AuthService();
    const toastService = new ToastService();

    try {
      const response = await authService.createFreeTrialAccount(form);
      if (response.response) {
        await dispatch(
          submitLoginAsync({
            email: form.email,
            password: form.password,
            ip: form.ip,
          })
        );

        toastService.success(
          "¡Su cuenta de MasterTools ha sido creada correctamente!"
        );
      }
    } catch (error: any) {
      toastService.error(
        "Ha ocurrido un error al crear su cuenta de MasterTools"
      );
    }

    setBlockedForm(false);
  };

  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`
          dataLayer = [{
            'PageCategory': 'Pag.Captacion-FreeTrial',
            'Product': 'MasterTools'
          }];
          `}
        </script>
      </Helmet>

      <BodyBackground img="Fondo_login.png">
        <Container
          width="70%"
          margin="64px auto"
          responsive="width: 90%; margin: 8px auto;"
        >
          <Card>
            <Container width="90%" margin="16px auto">
              <ContainerItem width="60%">
                <Container width="80%" display="block" margin="0 auto">
                  <CustomTitle fontSize={mobileScreen ? "20px" : "28px"}>
                    ¡Regístrate ahora y comienza tu
                    <HalfWayText> PRUEBA GRATUITA </HalfWayText> de 14 días!
                  </CustomTitle>
                  <Logo
                    width="100%"
                    src={`${config.urls.res}/platform/freeTrial.png`}
                    alt=""
                    style={{ display: mobileScreen ? "none" : "block" }}
                  />
                </Container>
              </ContainerItem>

              <ContainerItem width="40%">
                <Label>TU CORREO ELECTRÓNICO</Label>
                <Input
                  placeholder="Ingresa tu email"
                  name="email"
                  icon="email"
                  onChange={onChange}
                />
                <ErrorInput>{formError.email}</ErrorInput>

                <Label>CREAR CONTRASEÑA</Label>
                <Input
                  name="password"
                  onChange={onChange}
                  type="password"
                  icon="password"
                  placeholder="Ingresa tu contraseña"
                />
                <ErrorInput>{formError.password}</ErrorInput>

                <Label>CONFIRMAR CONTRASEÑA</Label>
                <Input
                  name="confirmPassword"
                  onChange={onChange}
                  type="password"
                  icon="password"
                  placeholder="Confirma tu contraseña"
                />
                <ErrorInput>{formError.confirmPassword}</ErrorInput>

                <Label>TU NOMBRE</Label>
                <Input
                  placeholder="Ingresa tu nombre"
                  name="name"
                  icon="profile"
                  onChange={onChange}
                />
                <ErrorInput>{formError.name}</ErrorInput>

                <Label>TUS APELLIDOS</Label>
                <Input
                  placeholder="Ingresa tu apellido"
                  name="lastName"
                  icon="profile"
                  onChange={onChange}
                />
                <ErrorInput>{formError.lastName}</ErrorInput>

                <Label>TU TELÉFONO MÓVIL</Label>
                <Input
                  placeholder="Ingresa tu número de teléfono móvil"
                  name="phoneNumber"
                  onChange={onChange}
                  icon="phone"
                  onKeyPress={(e) => keySubmit(e)}
                />
                <ErrorInput>{formError.phoneNumber}</ErrorInput>

                <Button typeButton={1} onClick={submit}>
                  ¡COMENZAR MI PRUEBA GRATUITA!
                </Button>

                <Logo
                  width="100%"
                  src={`${config.urls.res}/platform/freeTrial.png`}
                  alt=""
                  style={{ display: mobileScreen ? "block" : "none" }}
                />
              </ContainerItem>
            </Container>
          </Card>
        </Container>
      </BodyBackground>
    </>
  );
};
