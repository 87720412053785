import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { config } from "../../config";
import { loginReducerInitialState } from "../../interfaces/redux";
import {
  Bar, BellISignal, HomeLogo, Image, LinkStyled, LinksContainer, MenuProfile, MenuProfileContainer, MenuProfileItem, MenuProfileLogo, MenuProfileLogoContainer, MenuProfileText,
  NavigationBar,
  NotificationBellIcon, ProfileIcon
} from "./navbar.styles";
import { IoMdNotificationsOutline } from "react-icons/io"
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { closeSession } from "../../redux/slices/auth";
import { ToastService } from "../../services/toastService";
import { Button, ClickAway } from "../../styles/globalcomponents";
import { NotificationMenu } from "../notificationMenu/notificationMenu.component";
import { Alert } from "../alert/alert.component";
import { RiShareForwardLine } from "react-icons/ri";
import { DatesLib } from "src/lib/datesLib";
import { QuitLayout } from "../notificationMenu/notifications.styles";

export const Navbar = () => {
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const { notifications } = useAppSelector(state => state.notifications);
  const authenticated = dataUser.stateUser === 1 || dataUser.stateUser === 2;
  const expirationDate = dataUser.suscription.expired_time;
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [showNotificationMenu, setShowNotificationMenu] = useState(false);
  const [showNavbarItems, setShowNavbarItems] = useState(false);
  const [notViewedNotifications, setNotViewedNotifications] = useState(0);
  const [betaPopUp] = useState(false);

  const expiredPlan = expirationDate
    ? new DatesLib().isDateExpired(expirationDate)
    : false;

  const setRoute = (route: string) => {
    history.push(route);
    setShowMenu(false);
  };

  const closeUserSession = () => {
    localStorage.removeItem("userLogged");
    const closeSessionUser = loginReducerInitialState();
    dispatch(closeSession(closeSessionUser));

    const toastService = new ToastService();
    toastService.success("Sesión finalizada correctamente");

    setShowMenu(false);

    history.push("/");
  };


  const switchProfileMenu = () => {
    showNavbarItems && setShowNavbarItems(false);
    setShowMenu(!showMenu);
    setShowNotificationMenu(false);
  };

  const switchNotificationMenu = () => {
    setShowMenu(false);
    setShowNotificationMenu(prev => !prev)
  };

  const switchClickAway = () => {
    setShowMenu(false);
    setShowNavbarItems(false);
    setShowNotificationMenu(false);
  };

  const openSupport = () => {
    history.push("/support");
  };

  useEffect(() => {
    const notViewed = notifications?.filter(notification => notification.viewed == false).length;
    setNotViewedNotifications(notViewed);
  }, [notifications, notViewedNotifications])

  return (
    <>
      <Bar backgroundImage={`${config.urls.res}/platform/fondos/nav-background.png`}>
        <Image
          onClick={() => {
            authenticated ? history.push("/home") : history.push("/");
          }}
          src={`${config.urls.res}/platform/Logo-mastertools-new.svg`}
          alt="Mastertools logo"
          width={"80px"}
        />

        <NavigationBar>
          {!expiredPlan ? (
            <>
              {authenticated ? (
                <LinksContainer mobileHidden>
                  <LinkStyled onClick={() => setRoute("/tootorials")}>
                    Tootoriales
                  </LinkStyled>
                  <LinkStyled onClick={() => setRoute("/community")}>Comunidad</LinkStyled>
                </LinksContainer>
              ) : null}
            </>
          ) : null}

          {
            authenticated &&
            (
              <NotificationBellIcon
                onClick={() => switchNotificationMenu()}
              >
                <IoMdNotificationsOutline />
                <BellISignal style={{
                  display: `${notViewedNotifications > 0 ? "block" : "none"}`
                }} />
              </NotificationBellIcon>
            )
          }


          <NotificationMenu
            showNotificationMenu={showNotificationMenu}
            notViewedNotifications={notViewedNotifications}
            setNotViewedNotifications={setNotViewedNotifications}
          />

          <QuitLayout style={{ display: `${showNotificationMenu ? 'flex' : 'none'}`}} onClick={() => setShowNotificationMenu(false)} />


          {authenticated && (
            <ProfileIcon
              src={
                dataUser.url_image
                  ? dataUser.url_image
                  : `${config.urls.res}/platform/Foto_perfil.png`
              }
              onClick={switchProfileMenu}
            ></ProfileIcon>
          )}
        </NavigationBar>
      </Bar>
      <>
        <ClickAway
          style={{
            opacity: showMenu || showNavbarItems ? "1" : "0",
            transition:
              showMenu || showNavbarItems ? "opacity .5s" : "opacity .3s",
            pointerEvents: showMenu || showNavbarItems ? "unset" : "none",
          }}
          onClick={switchClickAway}
        ></ClickAway>

        {authenticated ? (
          <MenuProfileContainer
            style={{
              pointerEvents: showNavbarItems ? "unset" : "none",
              overflow: "auto",
              top: showNavbarItems ? "100px" : "-400px",
            }}
          >
            <MenuProfile>
              <MenuProfileItem onClick={() => setRoute("/tootorials")}>
                Tootoriales
              </MenuProfileItem>
              <MenuProfileItem onClick={() => setRoute("/profile#my-subscriptions")}>
                Planes
              </MenuProfileItem>
              <MenuProfileItem onClick={() => setRoute("/profile#my-subscriptions")}>
                Addons
              </MenuProfileItem>
              <MenuProfileItem onClick={() => setRoute("/support")}>
                Soporte
              </MenuProfileItem>
              <MenuProfileItem onClick={() => setRoute("/community")}>
                Comunidad
              </MenuProfileItem>

              <MenuProfileItem onClick={() => setRoute("/home")}>
                <HomeLogo
                  src={`${config.urls.res}/platform/icons/SVG/Icono-home.svg`}
                  alt="home logo"
                />
                Herramientas
              </MenuProfileItem>
            </MenuProfile>
          </MenuProfileContainer>
        ) : (
          <MenuProfileContainer
            style={{
              pointerEvents: showNavbarItems ? "unset" : "none",
              overflow: "auto",
              top: showNavbarItems ? "100px" : "-400px",
            }}
          >
            <MenuProfile>
              <MenuProfileItem onClick={() => setRoute("/")}>
                Iniciar sesión
              </MenuProfileItem>
              <MenuProfileItem onClick={() => setRoute("/resetPassword")}>
                Recuperar contraseña
              </MenuProfileItem>
              <MenuProfileItem onClick={() => setRoute("/begin")}>
                Activar cuenta
              </MenuProfileItem>
            </MenuProfile>
          </MenuProfileContainer>
        )}

        <MenuProfileContainer
          style={{
            pointerEvents: showMenu ? "unset" : "none",
            overflow: "auto",
            top: showMenu ? "55px" : "-400px",
          }}
        >
          <MenuProfile>
            <MenuProfileItem onClick={() => setRoute("/profile")}>
              <MenuProfileText>Mi perfil</MenuProfileText>
            </MenuProfileItem>
            <MenuProfileItem onClick={openSupport}>
              <MenuProfileText>Soporte</MenuProfileText>
            </MenuProfileItem>
            <MenuProfileItem onClick={closeUserSession}>
              <MenuProfileText>Cerrar Sesión</MenuProfileText>
              <RiShareForwardLine size={20} style={{ marginLeft: 5 }} />
            </MenuProfileItem>
            <MenuProfileLogoContainer>
              <MenuProfileLogo />
            </MenuProfileLogoContainer>
          </MenuProfile>
        </MenuProfileContainer>
      </>

      <Alert
        title={"¡Ups!"}
        isOpen={betaPopUp}
        description={
          <>
            Parece que aún no haces parte del grupo habilitado para ingresar a
            la versión beta de Mastertools 2.0. Pero no te desanimes, regístrate
            a continuación para ingresar a nuestra lista de espera:
            <Button
              typeButton={1}
              data-tf-popup="Mw2cY3aM"
              data-tf-iframe-props="title=Lista de Espera (Ambiente Beta)"
              data-tf-medium="snippet"
              margin="2rem 0 1rem 0"
            >
              INSCRIBIRME A LA LISTA DE ESPERA
            </Button>
          </>
        }
      />
    </>
  );
};
