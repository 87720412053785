import {Container, ContainerItem, CustomText, CustomTitle} from "../../../../../styles/globalcomponents";
import {SummaryToolLimits} from "./summaryToolLimits.component";
import {SpiralLoadingIcon} from "../../../../../components/loadingIcon/loadingIcon.component";
import {numberFormat} from "../../../../../lib/numberFormat";
import {CheckoutSummaryBodyStyled} from "./plansCheckoutSummary.styles";
import {useEffect, useRef, useState} from "react";
import {useAppSelector} from "../../../../../hooks/useRedux";
import {PRODUCT_IDS} from "../../../../../services/plansService";
import {ToastService} from "../../../../../services/toastService";
import {GetUserSubscriptionAddonState} from "../../../../../services/addonService";

interface PlanCheckoutSummaryBodyProps {
}

const toastService = new ToastService()
const DEBOUNCE_TIME_MS = 500

export function SummaryBodyAddons({}: PlanCheckoutSummaryBodyProps) {
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const plans = useAppSelector((state) => state.auth.plans)
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currentMasterToolsPlan = dataUser.suscription
    const currentPlanPrice = plans.find((element) => element.suscriptionInfo.name === currentMasterToolsPlan.name)
    const planInfo = plans.find((plan) => plan.suscriptionInfo.idSuscription === selectedPlan?.id)
    const additionalTools = useAppSelector((state) => state.checkout.additionalTools)
    const additionalToolEmail = additionalTools.find((tool) => tool.name === 'EmailTool')
    const [isLoading, setIsLoading] = useState(true)
    const debounceRef = useRef<NodeJS.Timeout>()
    const [addonsPriceToPayNow, setAddonsPriceToPayNow] = useState(0)
    const totalPrice = Number(addonsPriceToPayNow)
    const [isUpgrade, setIsUpgrade] = useState(false)
    const [newPlanDates, setNewPlanDates] = useState<{startDate?: string, finalDate?: string}>()

    async function retrieveAddonValidationState() {
        try {
            setIsLoading(true)
            if(!additionalToolEmail || !additionalToolEmail.code) {
                setAddonsPriceToPayNow(0)
                return
            }

            const response = await GetUserSubscriptionAddonState({
                email: dataUser.email,
                planCode:additionalToolEmail.code,
            })

            setIsUpgrade(response.action === "Upgrade")
            setNewPlanDates({
                startDate: response.startDate,
                finalDate: response.finalDate,
            })

            if(response.isApportionmentPrice) {
                setAddonsPriceToPayNow(response.apportionmentPrice ?? 0)
            }else {
                setAddonsPriceToPayNow(response.priceTotal ?? 0)
            }
        } catch (err){
            toastService.warning("Error validando el plan de addons")
        }finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if(debounceRef.current) {
            clearTimeout(debounceRef.current)
        }

        debounceRef.current = setTimeout(() => {
            retrieveAddonValidationState()
        }, DEBOUNCE_TIME_MS)
    }, [planInfo?.suscriptionInfo.name, additionalToolEmail?.quantity])


    return (
        <>
            <CheckoutSummaryBodyStyled>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"} color={"black"}>
                    Plan principal de MasterTools
                </CustomTitle>
                <Container display={"grid"} margin={".5rem 0 0 "} gridTemplateColumns={"1fr 30%"}>
                    <ContainerItem>
                        <CustomText fontWeight={"400"} textAlign={"start"} fontSize={"13px"}>
                            {currentMasterToolsPlan.name} - {currentMasterToolsPlan.typeSuscription}
                        </CustomText>
                    </ContainerItem>
                    <ContainerItem alignItems={"flex-end"}>
                        ${numberFormat.format(Number(currentPlanPrice?.suscriptionInfo?.monthPrice) ?? 0)} USD
                    </ContainerItem>
                </Container>
                <hr/>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"} color={"black"}>
                    Incluye
                </CustomTitle>
                <SummaryToolLimits toolLimits={additionalToolEmail?.specifications} />
                <hr/>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"0"} fontSize={"14px"} color={"black"}>
                    Plan de addons seleccionado
                </CustomTitle>
                <Container display={"grid"} margin={".5rem 0 0"} gridTemplateColumns={"1fr 30%"}>
                    <ContainerItem>
                        <CustomText fontWeight={"400"} textAlign={"start"} fontSize={"13px"}>
                            {numberFormat.format(additionalToolEmail?.quantity ?? 0)} suscriptores
                        </CustomText>
                    </ContainerItem>
                    <ContainerItem alignItems={"flex-end"}>
                        ${numberFormat.format(additionalToolEmail?.price ?? 0)} USD
                    </ContainerItem>
                </Container>
                <CustomTitle fontWeight={"700"} textAlign={"left"} margin={"1rem 0 1rem"} fontSize={"12px"} color={"black"}>
                    Nuevo valor a pagar (Suscripción: {numberFormat.format(additionalToolEmail?.quantity ?? 0)} Contactos)
                </CustomTitle>
                {
                    isLoading ?
                        <Container justifyContent={"center"}>
                            <SpiralLoadingIcon width={"3rem"} />
                        </Container>
                        :
                        <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                            <ContainerItem>
                                <CustomText fontSize={"11px"} color={"black"} textAlign={"left"} fontWeight={"400"} >

                                    {isUpgrade ? 'Este es un valor prorrateado por los días de uso de la herramienta complementaria.' : "Este será el valor a pagar en tu próxima fecha de corte (2024-12-23)"}
                                </CustomText>
                            </ContainerItem>
                            <ContainerItem alignItems={"flex-end"}>${numberFormat.format(addonsPriceToPayNow)} USD</ContainerItem>
                        </Container>
                }
                {newPlanDates?.startDate && <>
                    <hr/>
                        <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                        <ContainerItem>
                            <CustomText fontWeight={"400"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"gray"}>
                                Inicio de suscripción
                            </CustomText>
                        </ContainerItem>
                        <ContainerItem alignItems={"flex-end"}>
                            <CustomText fontSize={".9em"}>
                                {newPlanDates?.startDate}
                            </CustomText>
                        </ContainerItem>
                    </Container>
                </>
                }
            {newPlanDates?.finalDate && <>
                 <hr/>
                    <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                        <ContainerItem>
                            <CustomText fontWeight={"400"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"gray"}>
                                Fin de suscripción
                            </CustomText>
                        </ContainerItem>
                        <ContainerItem alignItems={"flex-end"}>
                            <CustomText fontSize={".9em"}>
                                {newPlanDates?.finalDate}
                            </CustomText>
                        </ContainerItem>
                    </Container>
                </>
            }
                <hr/>
                <Container display={"grid"} gridTemplateColumns={"1fr 30%"}>
                    <ContainerItem>
                        <CustomText fontWeight={"400"} textAlign={"left"} margin={"0"} fontSize={"13px"} color={"gray"}>
                            Valor total de suscripciones
                        </CustomText>
                    </ContainerItem>
                    <ContainerItem alignItems={"flex-end"}>${numberFormat.format(totalPrice)} USD</ContainerItem>
                </Container>
            </CheckoutSummaryBodyStyled>
        </>
    )
}
