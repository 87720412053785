import { instance } from "../axios/axiosConfig";
import { ResetPassword, SendEmailInterface } from "../interfaces/resetPassword";

const configHeader = (infoUser: any) => ({
  headers: { Authorization: `Bearer ${infoUser.tokenUser}` },
});

export class ResetPasswordService {
  async sendEmail(infoUser: SendEmailInterface) {
    const response = await instance.post(`/app/auth/recoveryPassword`, {
      emailUser: infoUser.emailUser,
    });
    return response.data;
  }
  async resetPassword(infoUser: ResetPassword) {
    const response = await instance.post(
      `/app/users/resetPassword`,
      {
        password: infoUser.password,
      },
      configHeader(infoUser)
    );

    return response.data;
  }

  async changePassword(
    email: string,
    actualPassword: string,
    password: string
  ) {
    const response = await instance.post(`/app/users/changePassword/${email}`, {
      actualPassword,
      password,
    });

    return response.data;
  }
}
