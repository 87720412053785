import {ConfirmButton, Container, ContainerItem, CustomTitle} from "../../../../../../styles/globalcomponents";
import {AddIcon} from "../../../../../../components/icons";
import {CardIcon} from "../../../../billing/billing.styles";
import {config} from "../../../../../../config";
import {PaymentMethodCardButtonStyled, PaymentMethodCardStyled} from "./paymentMethodTab.styles";
import {useAppDispatch, useAppSelector} from "../../../../../../hooks/useRedux";
import {ChangeEvent, useEffect, useState} from "react";
import {BillingDataCard} from "../../../../../../interfaces/billing";
import {getPaymentDetails} from "../../../../../../services/billingService";
import {ToastService} from "../../../../../../services/toastService";
import {toPascalCase} from "../../../../../../lib/stringHelper";
import CryptoJS from "crypto-js";
import {UpdateMethodAdviceModal} from "./updateMethodAdvice.modal";
import {completeStep, STEPS_INDEXES} from "../../../../../../redux/slices/checkout";

const toastService = new ToastService()

export function PaymentMethodTab() {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const isReadyToPay = useAppSelector((state) => state.checkout.isReadyToPay)
    const dispatcher = useAppDispatch()
    const [cardData, setCardData] = useState<any>()
    const [isAdviceModalOpen, setIsAdviceModalOpen] = useState(false)

    function closeAdviceModal() {
        setIsAdviceModalOpen(false)
    }

    async function getCardDetails() {
        try {
            const cardDataResponse: BillingDataCard = await getPaymentDetails(
                dataUser.email
            );
            setCardData(cardDataResponse?.data?.billingDataCards[0]);
        }catch (err) {
            toastService.error("Error consultando datos de la tarjeta")
        }
    }

    function handleSelectMethod(event: ChangeEvent<HTMLInputElement>) {
        if(event.target.checked) {
            dispatcher(completeStep({stepId: STEPS_INDEXES.paymentMethod}))
        }
    }

    const handleUpdatePaymentMethod = () => {
        setIsAdviceModalOpen(true)
        toastService.custom("Abriendo ventana emergente...");
        const windowFeatures = "left=400,top=100,width=1250,height=1024";
        const secret_key = String(config.secrets.updatePaymentMethod);
        const eParams = CryptoJS.AES.encrypt(
            `${dataUser.email}&${cardData?.idPaymet}`,
            secret_key
        ).toString();

        setTimeout(() => {
            window.open(
                `${config.urls.mastershopUrl}/update/method/${encodeURIComponent(eParams)}`,
                "_blank",
                windowFeatures
            );
        }, 3000)

    };

    useEffect(() => {
        getCardDetails()
    }, []);

    return (
        <>
            <Container flexDirection={"column"} margin={"1rem 0 0"}>
                <Container flexDirection={"column"}>
                    <ContainerItem flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <CustomTitle textAlign={"left"} fontWeight={"400"} fontSize={"15px"} color={"black"} margin={"0"}>
                            Método de pago actual
                        </CustomTitle>
                        <ConfirmButton onClick={handleUpdatePaymentMethod} height={"1.5rem"} padding={"12px 15px"}>
                            <AddIcon height={"12px"} width={"13px"} viewBox={"0 0 9 9"} /> Actualizar método
                        </ConfirmButton>
                    </ContainerItem>
                    <ContainerItem>
                        <PaymentMethodCardStyled isSelected={isReadyToPay}>
                            <div className="header">
                                <div>Tipo</div>
                                <div>Franquicia</div>
                                <div>Últimos 4 dígitos</div>
                                <div>&nbsp;</div>
                                <div>Seleccionar</div>
                            </div>
                            <div className="body">
                                {
                                    cardData ?
                                        <>
                                            <div>Tarjeta</div>
                                            <div>{cardData?.brandCard === "default" ? "Desconocida": toPascalCase(cardData?.brandCard)}</div>
                                            <div>{cardData?.last_four}</div>
                                            <div>
                                                <CardIcon src={`${config.urls.res}/platform/icons/cards/${cardData?.brandCard}.svg`}/>
                                            </div>
                                            <div>
                                                <PaymentMethodCardButtonStyled type={"radio"} checked={isReadyToPay} onChange={handleSelectMethod}/>
                                            </div>
                                        </>
                                        :
                                        <div className="full-row">Error consultando método de pago</div>
                                }
                            </div>
                        </PaymentMethodCardStyled>
                    </ContainerItem>
                </Container>
            </Container>
            <UpdateMethodAdviceModal isOpen={isAdviceModalOpen} closeModal={closeAdviceModal}/>
        </>
    )
}
