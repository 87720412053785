export const globalstyles = {
	colors: {
		tools: {
			shop: "#F97C00",
		},
		background: "#F9F9F9",
		black: "#161616",
		gray: "#798188",
		lightGray: "#E0E0E0",
		grayLinks: "rgb(0 0 0 / 48%)",
		purpleLinks: "#4d01ab",
		flatPurple: "#3c2b5d",
		grayLight: "#fafafa",
		grayLighter: "#f7f7f7",
		graySoftDarker: "#dcdcdc",
		grayDarkness: "#212121",
		lightDanger: "rgb(240,70,70)",
		softDanger: "rgba(220,50,50,0.39)",
		yellow: "#ffe100",
		lightYellow: "#ffe849",
		orangeYellow: "#FAC51C",
		orange: "#ff9615",
		lightOrange: "#fdab47",
		secundaryOrange: "#f92710",
		loaderLayout: "#ffffff7a",
		lightPurple: "#8427ff",
		red: "red",
		green: "#28e511",
		highlightGreen: "#a8fe00",
		lightGreen: "#7bffde9b",
		darkenGreen: "#04b68f",
		softGreen: "#00dfb0",
		inactiveGreen: "#55a94b",
		primary: "#6e00ff",
		secondary: "#00DFB0",
		text: "rgb(180,180,180)",
		danger: "rgb(220,50,50)",
		softBlue: "rgba(56,157,249,0.3)",
	},
	fontSize: {
		tableHeader: "10px",
		tableField: "10px",
		paragraph: "13px",
		paragrapMobile: "13px",
		popUpTitle: "20px",
		inputFontSize: "12px",
		mobile: {
			title: "25px",
			buttonLabel: "10px",
		},
	},
	sizes: {
		mobile: "600px",
		desktop: "750px",
		lg: "1024px",
		xl: "1280px",
		large: "1400px",
	},
	sizesInt: {
		mobile: 600,
		desktop: 750,
		large: 1200,
	},
	animations: {
		low: "0.32s",
		medium: "0.5s",
		fast: "200ms",
	},
	borders: {
		tooltip: "10px",
		cards: "19px",
		popUpMainCard: "40px",
		cardImages: "1rem",
		inputBorders: "13px",
		mobile: {
			mainCard: "15px",
		},
	},
	boxShadows: {
		normal: "0px 7px 29px rgba(100, 100, 111, 0.2)",
		lighten: "0px 7px 29px rgba(100, 100, 111, 0.2)",
		centered: "0px 2px 5px 1px rgba(0,0,0,0.10)",
	},
	icons: {
		formIcon: "17px",
	},
	themes: {
		light: {
			input: {
				color: "white",
				icon: "black",
			},
		},
		dark: {
			input: {
				color: "white",
				icon: "white",
			},
		},
	},
};
