import React, { useState } from "react";
import { BiKey } from "react-icons/bi";
import { globalstyles } from "src/styles/animations/globalstyles";
import { config } from "../../config";
import { useAppDispatch } from "../../hooks/useRedux";
import { activationAccountFormInitialState } from "../../interfaces/auth";
import { validatePassword } from "../../lib/formsValidation";
import { activateAccountAsync } from "../../redux/slices/auth";
import { Input } from "../../components/inputs/Input.component";
import {
  BodyBackground,
  Button,
  Card,
  CardContent,
  Container,
  ContainerItem,
  ErrorInput,
  Label,
  Logo,
  Title,
} from "../../styles/globalcomponents";

export const Begin = () => {
  const [activationForm, setActivationForm] = useState(
    activationAccountFormInitialState()
  );
  const [emailError, setEmailError] = useState("");
  const [activationCodeError, setActivationCodeError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [formBlock, setFormBlock] = useState(false);

  const dispatch = useAppDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setActivationForm({
      ...activationForm,
      [e.target.name]: e.target.value,
    });
  };

  const submit = () => {
    if (formBlock) {
      return;
    }

    const expression = config.regex.email;

    setActivationCodeError("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");

    if (activationForm.email.trim() === "") {
      return setEmailError("Debe ingresar un correo");
    }

    if (!expression.test(String(activationForm.email).toLowerCase())) {
      return setEmailError("Email inválido");
    }

    if (activationForm.codeActivation.trim() === "") {
      return setActivationCodeError(
        "Debe ingresar el codigo de activación que le enviamos a la hora de crear su cuenta."
      );
    }

    const passwordValidated = validatePassword(activationForm.password.trim());
    if (passwordValidated !== "") {
      return setPasswordError(passwordValidated);
    }

    if (activationForm.passwordConfirm.trim() === "") {
      return setConfirmPasswordError("Debe confirmar su contraseña");
    }

    if (
      activationForm.password.trim() !== activationForm.passwordConfirm.trim()
    ) {
      return setConfirmPasswordError("Las contraseñas no coinciden");
    }

    setFormBlock(true);

    dispatch(activateAccountAsync(activationForm));

    setFormBlock(false);
  };

  return (
    <BodyBackground backgroundColor="white">
      <Container width={"65%"} responsive="width: 90%;" margin={"0.5rem auto"}>
        <Card backgroundColor={globalstyles.colors.primary}>
          <Title
            margin={"1.5rem 0 0 50px"}
            color={"white"}
            fontSize={"28px"}
            fontWeight={600}
            textAlign={"left"}
          >
            Activa tu cuenta
          </Title>
          <CardContent padding={"15px 30px 40px 50px"} paddingRes="16px">
            <Container overflow="auto">
              <ContainerItem width={"50%"}>
                <Label color={"white"} fontSize={"10px"}>
                  Correo electrónico
                </Label>
                <Input
                  borderColor="white"
                  color="white"
                  name="email"
                  onChange={onChange}
                  icon="email"
                  iconColor="white"
                  placeholder="Ingresa el correo electrónico"
                />{" "}
                <ErrorInput>{emailError}</ErrorInput>
                <Label color={"white"} fontSize={"10px"}>
                  Código de activación
                </Label>
                <Input
                  borderColor="white"
                  color="white"
                  name="codeActivation"
                  onChange={onChange}
                  type="text"
                  iconColor="white"
                  CustomIcon={<BiKey color={"white"} size={24} />}
                  placeholder="Ingresa el codigo de activación"
                />{" "}
                <ErrorInput>{activationCodeError}</ErrorInput>
                <Label color={"white"} fontSize={"10px"}>
                  Contraseña
                </Label>
                <Input
                  borderColor="white"
                  color="white"
                  name="password"
                  onChange={onChange}
                  type="password"
                  icon="password"
                  iconColor="white"
                  placeholder="Ingresa tu contraseña"
                />{" "}
                <ErrorInput>{passwordError}</ErrorInput>
                <Label color={"white"} fontSize={"10px"}>
                  Confirmar contraseña
                </Label>
                <Input
                  borderColor="white"
                  color="white"
                  name="passwordConfirm"
                  onChange={onChange}
                  type="password"
                  icon="password"
                  iconColor="white"
                  placeholder="Confirma tu contraseña"
                />{" "}
                <ErrorInput>{confirmPasswordError}</ErrorInput>
                <Button onClick={submit} typeButton={3}>
                  Activar cuenta
                </Button>
              </ContainerItem>

              <ContainerItem
                alignItems={"center"}
                justifyContent={"center"}
                mobileHidden
                width={"40%"}
              >
                <Logo
                  src={`${config.urls.res}/platform/Logo_mastertools_blanco.png`}
                  width={"40%"}
                />
              </ContainerItem>
            </Container>
          </CardContent>
        </Card>
      </Container>
    </BodyBackground>
  );
};
