import styled from "styled-components";
import { config } from "../../config";
import { globalstyles } from "../../styles/animations/globalstyles";

export const BigEmoji = styled.img.attrs(() => ({ src: `${config.urls.res}/platform/Emoji error 404.png` }))`
  width: 100%;
`

type DescriptionProps = {
  bolder?: boolean;
}

export const Description = styled.div<DescriptionProps>`
  font-size: 40px;
  color: white;
  font-weight: ${props => props.bolder ? "900" : "200"};

  @media(max-width: ${globalstyles.sizes.mobile}) {
    text-align: center;
    font-size: 16px;
  }
  `

export const NotFoundBigTitle = styled.div`
  font-size: 200px;
  color: red;
  font-weight: 900;
  @media(max-width: ${globalstyles.sizes.mobile}) {
    text-align: center;
    font-size: 100px;
  }
`

export const NotFoundContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url('${config.urls.res}/platform/Background - Error 404 MasterTools.png');
  background-size: cover;
  padding-top: 128px;
`