import {TabContainer} from "../planTabContainer/plansTab.styles";
import {TabContent} from "../planTabContainer/PlanTabContent.component";
import {ReactNode, useEffect, useRef} from "react";

interface PurchaseTabContainerProps {
    titles: string[];
    contents: ReactNode[];
    currentIndex: number
}

export function PurchaseTabContainer({ titles, contents, currentIndex }: PurchaseTabContainerProps) {
    const listRef = useRef<HTMLUListElement>(null);

    function getActiveChild() {
        const children = listRef.current!.children;
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            const haveActiveClass = child.className.includes('active');
            if (haveActiveClass) return child;
        }
    }

    useEffect(() => {
        const activeChild = getActiveChild() as HTMLElement;
        activeChild.scrollIntoView();
    }, [currentIndex]);

    if (titles.length === 0) return <></>;
    if (contents.length === 0) return <></>;

    return (
        <>
            <TabContainer>
                <ul ref={listRef}>
                    {titles.map((title, index) => {
                        const isActiveTab = currentIndex === index;
                        const isPassedTab = currentIndex > index;
                        const classes = `${isActiveTab ? 'active' : isPassedTab ? 'passed' : ''}`;
                        return (
                            <li
                                key={`tab-title-${index}`}
                                className={classes}
                            >
                                {title}
                            </li>
                        );
                    })}
                </ul>
            </TabContainer>
            <TabContent key={currentIndex}>{contents[currentIndex]}</TabContent>
        </>
    )
}
