import { Dispatch, SetStateAction, useState } from "react";
import { LoaderLayout } from "src/pages/profile/subscription/unsubscribe/unsubscribe.styles";
import { Button, Container, Text, CustomTitle } from "../../styles/globalcomponents";
import { SpiralLoadingIcon } from "../loadingIcon/loadingIcon.component";
import { AlertContainer, CancelLink, Card } from "./alert.styles";

interface AlertButton {
  readonly text: string;
  handler?: (value?: any) => boolean | void | { [key: string]: any };
}

type AlertProps = {
  title?: string;
  description: any;
  isOpen?: boolean;
  buttons?: (AlertButton | string)[];
  cancelAction?: Dispatch<SetStateAction<boolean>>;
  cancelText?: string;
  loading?: boolean;
};

export const Alert = ({
  title,
  description,
  isOpen = true,
  buttons,
  cancelAction,
  cancelText,
  loading = false,
}: AlertProps) => {
  const [disabledButton, setEnabledButton] = useState<boolean>(false);

  const buttonAction = (button: AlertButton) => {
    if (button.handler && !disabledButton) {
      setEnabledButton(true);
      button.handler();
    }
  };

  return (
    <AlertContainer
      style={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? "auto" : "none",
      }}
    >
      <Card position="relative" padding="1rem">
        {title && (
          <CustomTitle color="black" fontSize="1.2rem" margin="1rem">
            {title}
          </CustomTitle>
        )}

        {loading && (
          <LoaderLayout>
            <Container justifyContent="center" position="absolute">
              <SpiralLoadingIcon width="100px" />
            </Container>
          </LoaderLayout>
        )}

        <Container width="80%" margin="16px auto" flexDirection="column">
          <Text color="black" fontSize="16px" lineHeight="20px" textAlign="justify">
            {description}
          </Text>
        </Container>
        <Container width="80%" flexDirection="column" margin="1rem auto">
          {buttons &&
            buttons.map((button, i) =>
              typeof button === "string" ? (
                <Button key={i} typeButton={1}>
                  {button}
                </Button>
              ) : (
                <Button
                  fontWeight="700"
                  key={i}
                  typeButton={1}
                  onClick={() => buttonAction(button)}
                  blocked={disabledButton}
                >
                  {button.text}
                </Button>
              )
            )}
          {cancelAction ? (
            <CancelLink>
              <p onClick={() => cancelAction(false)}>{cancelText}</p>
            </CancelLink>
          ) : null}
        </Container>
      </Card>
    </AlertContainer>
  );
};
