import {getStatsByProjectId} from "../services/statsService";
import {useEffect} from "react";
import {OrderInitialState} from "../pages/stats/consts";
import {useQuery} from "@tanstack/react-query";
import {OrdersData} from "../interfaces/stats";
import {useAppSelector} from "./useRedux";

export function useUserStats(startDate: string, finalDate: string) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const userFunnelProjectId = dataUser.toolsInfo?.find(tool => tool.name === 'Funnels')?.dataConfig?.projectId ?? 0

    const {isLoading, isError, data = OrderInitialState} = useQuery<OrdersData>(
        ['stats', startDate, finalDate],
        async () => await getStatsByProjectId(
            userFunnelProjectId,
            startDate,
            finalDate,
            false
        ),
        {
            staleTime: 50000,
            refetchOnWindowFocus: false
        }
    )

    return {
        isLoading,
        isError,
        data
    }

}
