import { useEffect } from "react";
import { ToggleButton } from "../../../components/toggleButton/toggleButton.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/useRedux";
import { setDarkMode } from "../../../redux/slices/session";
import { Card, Container, Title } from "../../../styles/globalcomponents";
import {
  PreferenceContainer,
  PreferenceLink,
  PreferenceText,
  Separator,
} from "./preferences.styles";

export const Preferences = () => {
  const darkMode = useAppSelector((state) => state.session.darkMode);
  const dispatch = useAppDispatch();

  const switchDarkMode = () => {
    dispatch(setDarkMode(!darkMode));
  };

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Card
      width="90%"
      backgroundColor={darkMode ? "#0d0d0d" : "white"}
      responsive="width: 95%; margin: auto;"
    >
      <Container
        flexDirection="column"
        margin={"unset"}
        padding={"0 0 2.5rem 2rem"}
        responsive="width: 95%;"
        width="70%"
      >
        <h1>Políticas</h1>
        <Container
          style={{ maxWidth: "600px", width: "unset" }}
          margin="0"
          flexDirection="column"
        >
          <PreferenceContainer darkMode={darkMode}>
            <ToggleButton isActive permanent />
            <PreferenceText darkMode={darkMode}>Acepto las</PreferenceText>
            <PreferenceLink
              onClick={() =>
                openLink("https://mastertools.com/politica-privacidad")
              }
              darkMode={darkMode}
            >
              Políticas de Privacidad
            </PreferenceLink>
          </PreferenceContainer>
          <PreferenceContainer darkMode={darkMode}>
            <ToggleButton isActive permanent />
            <PreferenceText darkMode={darkMode}>Acepto los</PreferenceText>
            <PreferenceLink
              onClick={() => openLink("https://mastertools.com/politica-uso")}
              darkMode={darkMode}
            >
              Términos de uso
            </PreferenceLink>
          </PreferenceContainer>
          {/* <PreferenceContainer darkMode={darkMode}>
            <ToggleButton isActive={darkMode} action={switchDarkMode} />
            <PreferenceText darkMode={darkMode}>Dark Mode</PreferenceText>
          </PreferenceContainer> */}
        </Container>
        <Container margin="8px 0" responsive="flex-direction: row;">
          <PreferenceLink
            onClick={() =>
              openLink("https://mastertools.com/politica-servicio")
            }
            darkMode={darkMode}
          >
            Términos de Servicio
          </PreferenceLink>
          <Separator darkMode={darkMode}>-</Separator>
          <PreferenceLink
            onClick={() =>
              openLink("https://mastertools.com/politica-notificaciones")
            }
            darkMode={darkMode}
          >
            Políticas de Notificaciones
          </PreferenceLink>
          <Separator darkMode={darkMode}>-</Separator>
          <PreferenceLink
            onClick={() => openLink("https://mastertools.com/politica-api")}
            darkMode={darkMode}
          >
            Políticas de API
          </PreferenceLink>
        </Container>
      </Container>
    </Card>
  );
};
