import { render } from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRouter } from "./routers/AppRouter";
import { Provider } from "react-redux";
import store from "./redux/store";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";

const queryClient = new QueryClient()

render(
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <AppRouter />
        </Router>
      </Provider>
        <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>,
    document.getElementById("root")
);
