import {StatsPanel, StatsPanelInfoWrapper} from "./ordersDetailedInfo.styles";
import {OrderData} from "../../../../interfaces/stats";
import {CustomTitle} from "../../../../styles/globalcomponents";
import {CategoryBar} from "../../../../components/categoryBar/categoryBar.component";
import {OrderDetailsList} from "../orderDetailsList/orderDetailsList.component";
import {format} from "date-fns";
import eoLocale from 'date-fns/locale/es'
import {CheckListIcon, GeolocationIcon, ListIcon} from "../../../../components/icons";


interface OrdersDetailedInfoProps {
    ordersData: OrderData
}

function getOrderStatusCategories(ordersData: OrderData) {
    return ordersData?.states?.map((state) => {
        const stateName = state.name
        const stateCount = state.value

        if (stateName === 'Pendiente') return {
            index: 1,
            name: stateName,
            color: '#798188',
            value: stateCount
        }

        if (stateName === 'Enviado') return {
            index: 2,
            name: stateName,
            color: '#CA9F36',
            value: stateCount
        }

        if (stateName === 'Entregado') return {
            index: 3,
            name: stateName,
            color: '#76BD65',
            value: stateCount
        }

        if (stateName === 'Cancelado') return {
            index: 4,
            name: stateName,
            color: '#DD1742',
            value: stateCount
        }

        return {
            index: 0,
            name: stateName,
            color: 'transparent',
            value: 0
        }
    })?.sort((a, b) => a.index - b.index);
}

function getFormattedDate() {
    const now = new Date()
    return format(new Date(now.getFullYear(), now.getMonth(), now.getDate()),
        "dd 'de' MMMM",
        {
            locale: eoLocale
        }
    )
}

export function OrdersDetailedInfo({ordersData}: OrdersDetailedInfoProps) {
    const categories = getOrderStatusCategories(ordersData)
    const nowDateFormatted = getFormattedDate()

    return (
        <StatsPanelInfoWrapper>
            <StatsPanel>
                <div id="header" className={"mb-1/2"}>
                    <div id="header-title">
                        <CheckListIcon/>
                        <CustomTitle
                            margin={"0"}
                            fontSize={"14px"}
                            textAlign={"left"}
                        >
                            Estado del pedido
                        </CustomTitle>
                    </div>
                    <div>
                        <small>{nowDateFormatted}</small>
                    </div>
                </div>
                <CategoryBar categories={categories} total={ordersData?.length} />
                <OrderDetailsList totalOrders={ordersData?.length} items={categories} />
            </StatsPanel>
            <StatsPanel>
                <div id="header" className={"mb-1/2"}>
                    <div id="header-title">
                        <ListIcon/>
                        <CustomTitle
                            margin={"0"}
                            fontSize={"14px"}
                            textAlign={"left"}
                        >
                            Pedidos por Método de Pago
                        </CustomTitle>
                    </div>
                    <div>
                        <small>{nowDateFormatted}</small>
                    </div>
                </div>
                <OrderDetailsList totalOrders={ordersData?.length} items={ordersData?.paymentMethods} />
            </StatsPanel>
            <StatsPanel>
                <div id="header" className={"mb-1/2"}>
                    <div id="header-title">
                        <GeolocationIcon/>
                        <CustomTitle
                            margin={"0"}
                            fontSize={"14px"}
                            textAlign={"left"}
                        >
                            Top Ciudades
                        </CustomTitle>
                    </div>
                    <div>
                        <small>{nowDateFormatted}</small>
                    </div>
                </div>
                <OrderDetailsList totalOrders={ordersData?.length} items={ordersData?.topCities} />
            </StatsPanel>
        </StatsPanelInfoWrapper>
    )
}
