import {getNDaysAgoDate, getTodayDate} from "../../../lib/datesLib";


export const INITIAL_DATE_FILTER = {
    startDate: getNDaysAgoDate(7),
    finalDate: getTodayDate()
}
export const SWITCH_PAID_OPTIONS = [
    {index: 1, name: "Recaudos", active: true},
    {index: 2, name: "Sin Recaudo", active: false},
]

export const OrderInitialState = {
    paid: {
        "paymentMethods": [],
        "states": [],
        "cities": [],
        "totalSales": 0,
        "averageSales": 0,
        "orders": [],
        "length": 0,
        "chartData": undefined,
        "topCities": []
    },
    noPaid: {
        "paymentMethods": [],
        "states": [],
        "cities": [],
        "totalSales": 0,
        "averageSales": 0,
        "orders": [],
        "length": 0,
        "chartData": undefined,
        "topCities": []
    },
    length: 0
}

export const orderStates = {
    PENDING: 'Pendiente',
    DELIVERED: 'Entregado',
    SHIPPED: 'Enviado',
    CANCELED: 'Cancelado'
}

export const FilterTitles = [
    "Últimos 7 días",
    "Últimos 14 días",
    "Últimos 30 días",
    "Personalizado",
]


// The indexes of reference are above this code (FilterTitles)
export function getFiltersDatesByIndex(index: number, customStartDate: string, customFinalDate: string) {
    const filtersEnum: any = {
        0: {
            startDate: getNDaysAgoDate(7),
            finalDate: getTodayDate()
        },
        1: {
            startDate: getNDaysAgoDate(14),
            finalDate: getTodayDate()
        },
        2: {
            startDate: getNDaysAgoDate(30),
            finalDate: getTodayDate()
        },
        3: {
            startDate: customStartDate,
            finalDate: customStartDate
        }
    }


    return filtersEnum[index]
}
