import { useEffect } from "react";
import {
  CloseableModal,
  CloseableModalProps,
} from "src/components/ui/modal/closeable-modal.component";
import { config } from "src/config";
import { ToastService } from "src/services/toastService";
import {
  ConfirmButton,
  Container,
  CustomText,
} from "src/styles/globalcomponents";

interface Props extends Omit<CloseableModalProps, "children"> {}

const toastService = new ToastService();
const confirmPlanRenewalImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-renewal-icon.png`;
const MESSAGE = "Hola, quiero activar un plan de más de 10.000 suscriptores..."

export const PlanSelectedEmailSupportModal = ({ isOpen, closeModal }: Props) => {
  function handleRedirectToChat() {
    window.open(`https://api.whatsapp.com/send?phone=573207623044&text=${MESSAGE}`, "_blank");
  }

  useEffect(() => {
    if (isOpen) {
      toastService.warning("Redirigiendo a soporte...");
      setTimeout(() => {
        window.open(`https://api.whatsapp.com/send?phone=573207623044&text=${MESSAGE}`, "_blank");
      }, 5000);
    }
  }, [isOpen]);

  return (
    <CloseableModal isOpen={isOpen} closeModal={closeModal}>
      <Container
        flexDirection={"column"}
        alignItems={"center"}
        maxWidth={"45rem"}
        padding={"0 .5rem"}
      >
        <img
          src={confirmPlanRenewalImg}
          alt={"MasterTools icon plan renewal"}
          width={"170px"}
          height={"170px"}
        />
        <h2 className={"text-center"}>¡Hola, Tooler!</h2>
        <Container flexDirection={"column"} margin={"0 0 1rem"}>
          <p>
            ¡Eres un caso excepcional! Por lo tanto, te llevaremos con nuestro equipo de soporte para conocer más acerca de tu solicitud y ayudarte a resolverla ;)
          </p>
          <p>
            Ya que el cambio que deseas realizar requiere ayuda de nuestro equipo de soporte para que puedas culminarlo de manera exitosa!
          </p>
          <p>
            En breves segundos se abrirá una pestaña de tu navegador y serás redirigido al Whatsapp de soporte, en caso contrario toca el botón <strong>"¡CONTACTAR CON SOPORTE!"</strong>
          </p>
        </Container>
        <Container flexDirection={"column"} width={"auto"} alignItems={"center"}>
          <ConfirmButton onClick={handleRedirectToChat}>
            ¡CONTACTAR CON SOPORTE!
          </ConfirmButton>
          <CustomText
            textAlign={"center"}
            onClick={() => closeModal()}
            cursor={"pointer"}
            fontSize={"13px"}
            textDecoration={"underline"}
            margin={"1rem 0"}
          >
            Cancelar
          </CustomText>
        </Container>
      </Container>
    </CloseableModal>
  );
};
