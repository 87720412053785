export interface QuestionsData {
  name: string,
  checked: boolean
}
export const whyCancelData: QuestionsData[] = [
  {
    name: "Tiene falencias o carece de funciones.",
    checked: false,
  },
  {
    name: "Es demasiado costosa.",
    checked: false,
  },
  {
    name: "No la necesito/No la estoy usando.",
    checked: false,
  },
  {
    name: "Fallos técnicos en la herramienta.",
    checked: false,
  },
  {
    name: "No tengo suficientes ventas para poder costearla.",
    checked: false,
  },
  {
    name: "Tengo problemas con el pago de mi suscripción.",
    checked: false,
  },
  {
    name: "Estoy insatisfecho con el soporte.",
    checked: false,
  },
  {
    name: "No entiendo cómo utilizar una o varias funciones.",
    checked: false,
  },
  {
    name: "Otro (¿Cuál?)",
    checked: false,
  },
];

export const changePlatformData: QuestionsData[] = [
  {
    name: "Si.",
    checked: false,
  },
  {
    name: "No.",
    checked: true,
  }
];

