import { config } from "src/config";
import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

type BillingMainContainerProps = {
  darkMode?: boolean;
};

export const BillingMainContainer = styled.div<BillingMainContainerProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  h1 {
    color: ${(props) => (props.darkMode ? "white" : "unset")};
  }
  span {
    color: ${(props) => (props.darkMode ? "#cfcfcf" : "unset")};
  }
`;

type BillingTopContainerProps = {
  color?: string;
};

export const BillingTopContainer = styled.div<BillingTopContainerProps>`
  &h1 {
    color: ${(props) => (props.color ? props.color : "unset")};
  }
  &span {
    color: ${(props) => (props.color ? props.color : "unset")};
  }
`;

type BillingBottomContainerProps = {};

export const BillingBottomContainer = styled.div<BillingBottomContainerProps>`
  display: flex;
  gap: 1rem;

  @media (max-width: ${globalstyles.sizes.lg}) {
    flex-direction: column;
  }
`;

export const BillingTable = styled.div`
  background-color: ${globalstyles.colors.grayLight};
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;
  width: 100%;
  
  & h4 {
    margin: 0 0 1rem 0;
  }
  
  @media (min-width: ${globalstyles.sizes.lg}) {
    width: 62%;
  }
`;

type TableHeadersProps = {};

export const TableHeaders = styled.div<TableHeadersProps>`
  border-bottom: 1px solid ${globalstyles.colors.gray};
  display: flex;
  padding: 0.5rem 0;
`;

type HeaderProps = {
  justifyContent?: string;
  hiddeOnMobile?: boolean
};

export const Header = styled.span<HeaderProps>`
  color: gray;
  display: flex;
  font-size: ${globalstyles.fontSize.tableHeader};
  font-weight: bold;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  width: calc(100% / 6);

  @media (max-width: ${globalstyles.sizes.lg}) {
    display: ${props => props.hiddeOnMobile ? "none" : "block"};
    font-size: ${globalstyles.fontSize.paragrapMobile};
  }
`;

type PayMethodProps = {
  justifyContent?: string;
};

export const PayMethod = styled.div<PayMethodProps>`
  background-color: ${globalstyles.colors.grayLighter};
  border-radius: 1rem;
  font-size: ${globalstyles.fontSize.paragrapMobile};
  display: flex;
  font-weight: bold;
  flex-direction: column;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "start"};
  padding: 1rem;
  width: 100%;
  height: fit-content;

  @media (min-width: ${globalstyles.sizes.lg}) {
    width: 35%;
  }

  h4 {
    color: black;
    margin: 0 !important;
  }
`;

type CardDescriptionProps = {
  justifyContent?: string;
};

export const CardDescription = styled.div<CardDescriptionProps>`
  align-items: center;
  color: gray;
  display: flex;
  justify-content: space-between;
  margin: 0.4rem 0 0 0;

  span {
    font-size: 0.7rem;
    font-weight: 500;
  }
`;

export const CardIcon = styled.img.attrs((props) => ({
  src: props.src,
}))`
  margin: 0 1rem;
  width: 35px;
`;

type TableRowProps = {};

export const TableRow = styled.div<TableRowProps>`
  border-bottom: 1px solid ${globalstyles.colors.grayDarkness};
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
`;

type NoBillingHistoryProps = {};

export const NoBillingHistory = styled.div<NoBillingHistoryProps>`
  margin: auto;

  p {
    color: ${globalstyles.colors.gray};
  }

  @media (max-width: ${globalstyles.sizes.mobile}) {
    margin: 0 0 0 5px;
    width: 80%;
  }
`;

type TableFieldProps = {
  color?: string;
  justifyContent?: string;
};

export const TableField = styled.span<TableFieldProps>`
  align-items: center;
  display: flex;
  font-size: ${globalstyles.fontSize.tableField};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  width: calc(100% / 6);

  svg {
    color: ${(props) => (props.color ? props.color : "unset")};
    margin: 0 0.5rem;
  }

  @media (max-width: ${globalstyles.sizes.mobile}) {
    font-size: ${globalstyles.fontSize.paragrapMobile};
  }
`;
