import {SwitchOptionStyled, SwitchButtonWrapper, SwitchActiveBackgroundStyled} from "./switchButton.styles";
import {MouseEvent, useEffect, useRef, useState} from "react";

type Option = {
    name: string,
    active: boolean
}
interface SwitchButtonProps {
    initialOptions: Option[]
    updateSwitchOption: (index: number) => void
}

export function SwitchButton({initialOptions, updateSwitchOption}: SwitchButtonProps) {
    const defaultOptionIndex = initialOptions.findIndex(option => option.active)
    const [options,] = useState(initialOptions)
    const switchWrapperRef = useRef<HTMLDivElement>(null)
    const [optionSelected, setOptionSelected] = useState({
        index: defaultOptionIndex,
        width: 0,
        xOffset: 0
    })

    function handleSelectOption(event: MouseEvent<HTMLButtonElement>, index: number) {
        const width = event.currentTarget.clientWidth
        setOptionSelected({
            index: index,
            width: width,
            xOffset: width * (index)
        })
        updateSwitchOption(index + 1)
    }

    function calculateActiveSelectorWidth() {
        const switchChildren = switchWrapperRef.current !== null && switchWrapperRef.current.children
        if(switchChildren === false) return
        for (let index = 0; index < switchChildren.length; index++) {
            if(index - 1 === defaultOptionIndex) {
                setOptionSelected({
                    index: index - 1,
                    width: switchChildren[index].clientWidth,
                    xOffset: switchChildren[index].clientWidth * (index - 1)
                })
            }
        }
    }

    useEffect(() => {
        calculateActiveSelectorWidth()
    },[])

    return (
        <SwitchButtonWrapper ref={switchWrapperRef} optionsLength={options.length}>
            <SwitchActiveBackgroundStyled width={optionSelected.width} xOffset={optionSelected.xOffset}/>
            {options.map((option, index) =>
                <SwitchOptionStyled active={optionSelected.index === index} onClick={(event) => handleSelectOption(event, index)}>
                    {option.name}
                </SwitchOptionStyled>
            )}
        </SwitchButtonWrapper>
    )
}


