import {useState} from "react";
import { config } from "../../../../config";
import { useAppSelector } from "../../../../hooks/useRedux";
import { Container } from "../../../../styles/globalcomponents";
import { SpiralLoadingIcon } from "../../../../components/loadingIcon/loadingIcon.component";
import { CameraIcon } from "../../../../components/icons";
import { UpdateImageModalComponent } from "./imagePreview/updateImageModal.component";

import {
    ImageLegend,
    ImageProfile,
    ImageSettingButton, ImageSmallLegend,
} from "./profileImage.styles";

export const DEFAULT_PHOTO = `${config.urls.res}/platform/Foto_perfil.png`

export const ProfileImage = () => {
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const srcPhoto = dataUser.url_image ? dataUser.url_image : DEFAULT_PHOTO;
  const haveProfilePhoto = !!dataUser.url_image
  const [imageLoading, setImageLoading] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  function handleOpenUpdateModal() {
    setIsUpdateModalOpen(true);
  }

  function handleCloseUpdateModal() {
    setIsUpdateModalOpen(false)
  }

  function handleImageLoader(loading: boolean = false) {
      setImageLoading(loading)
  }

  function getProfileImage() {
      if(imageLoading) return <SpiralLoadingIcon />

      return <ImageProfile src={srcPhoto} />
  }

  return (
    <>
      <Container
        width="70%"
        style={{ position: "relative" }}
        flexDirection="column"
      >
        <ImageLegend>{haveProfilePhoto ? "¡Que bien te ves!" : "¡Hey, Subamos una foto!"}</ImageLegend>
        {getProfileImage()}
        <ImageSmallLegend>
            Toca en el botón de abajo y actualiza la imagen de tu perfil
        </ImageSmallLegend>
      </Container>
      <Container
        width="90%"
        responsive="flex-direction: row;"
        justifyContent={"center"}
        style={{ minHeight: "32px" }}
        gap=".5rem"
        margin={"0 auto 25px"}
      >
        <ImageSettingButton onClick={handleOpenUpdateModal}>
          <CameraIcon color="#CECECE" />
          Actualizar
        </ImageSettingButton>
      </Container>
      <UpdateImageModalComponent isOpen={isUpdateModalOpen} closeModal={handleCloseUpdateModal} setImageLoading={handleImageLoader} />
    </>
  );
};
