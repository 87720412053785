import { config } from "src/config";
import { useAppDispatch } from "src/hooks/useRedux";
import type { Plan, PlanFrequency } from "src/interfaces/plans";
import { PlanSubtitle, PlanTitle, PlanTool } from "src/pages/profile/account/account.styles";
import { setPlan } from "src/redux/slices/checkout";
import { ConfirmButton, Container, CustomText } from "src/styles/globalcomponents";
import { PlanCardStyled, PlanDescription, PlanHeader, PlanPrice, PlanPriceNumber, PlanToolsContainer } from "../../plan/tabs/planSelectionTab/plans.styles";
import { PLANS_BANNERS, TOOLS_ICONS } from "../../plan/tabs/plans.data";
import { PlanListItem } from "../../subscription.styles";
import { useState } from "react";
import { CloseableModal } from "src/components/ui/modal/closeable-modal.component";
import { ConfirmPlanTab } from "../../plan/purchase/tabs/confirmPlanTab/confirmPlan.tab";
import { FinishPurchaseModal } from "../../plan/purchase/tabs/finishPurchase.modal";

interface RecommendedPlanCardProps {
    plan: Plan
    periodicity: PlanFrequency
    mode?: 'LIST' | 'CARD'
}

function RecommendedPlanCard({ plan, periodicity, mode = "CARD" }: RecommendedPlanCardProps) {
    const subscriptionName = plan.suscriptionInfo.name
    const dispatcher = useAppDispatch();
    const haveLitePlan = subscriptionName === "Lite"
    const haveBasicOrLitePlan = haveLitePlan || subscriptionName === "Basic";
    const backgroundImage = PLANS_BANNERS[subscriptionName.toLowerCase()] ?? PLANS_BANNERS[0]
    const price = periodicity === 'Anual' ? plan.suscriptionInfo.anualPrice :
        periodicity === 'Semestral' ? plan.suscriptionInfo.semiAnualPrice :
            periodicity === 'Trimestral' ? plan.suscriptionInfo.trimesterPrice :
                periodicity === 'Mensual' ? plan.suscriptionInfo.monthPrice :
                    undefined
    const pricingPeriod = periodicity === 'Anual' ? "/ año" :
        periodicity === 'Semestral' ? "/ 6 meses" :
            periodicity === 'Trimestral' ? "/ 3 meses" :
                periodicity === 'Mensual' ? "/ mes" :
                    ""
    const pricingPerDay = periodicity === 'Anual' ? Number(price) / 12 / 30 :
        periodicity === 'Semestral' ? (Number(price) / 6 / 30).toFixed(2) :
            periodicity === 'Trimestral' ? (Number(price) / 3 / 30).toFixed(2) :
                periodicity === 'Mensual' ? (Number(price) / 30).toFixed(2) :
                    Number(price)
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)
    const [isOpenChangePlanModal, setIsOpenChangePlanModal] = useState<boolean>(false)

    function closeSuccessModal() {
        setIsSuccessModalOpen(false)
    }
    function openSuccessModal() {
        setIsSuccessModalOpen(true)
    }
    function closeModal() {
        setIsOpenChangePlanModal(false)
    }
    function handleSelectPlan() {
        dispatcher(
            setPlan({
                id: plan.suscriptionInfo.idSuscription,
                frequency: periodicity,
            })
        );
        setIsOpenChangePlanModal(true)
    }

    return (<>
        <PlanCardStyled width={mode === "LIST" ? '100%' : "20rem"} mode={mode}>
            <PlanHeader backgroundImage={backgroundImage} mode={mode}>
                <PlanTitle>
                    Plan {subscriptionName}
                </PlanTitle>
                <PlanSubtitle>{periodicity}</PlanSubtitle>
            </PlanHeader>
            <PlanDescription>
                {mode !== "LIST" &&
                <PlanToolsContainer>
                    {TOOLS_ICONS.map((toolIcon, index) => {
                        const isEmailToolImg = toolIcon === `${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`
                        const showEmailToolDependOnPlan = haveBasicOrLitePlan && isEmailToolImg
                        if (!showEmailToolDependOnPlan) {
                            return <PlanTool src={toolIcon} key={`tools-${toolIcon}`} />;
                        }
                    })}
                    </PlanToolsContainer>}
                <Container flexDirection="flex" margin="1rem 0 0.5rem">
                    <PlanPriceNumber>
                        ${price} USD
                    </PlanPriceNumber>
                    <small>{pricingPeriod}</small>
                </Container>
                <Container margin="0 0 .5rem">
                    <CustomText fontSize="0.6rem" fontWeight="300" lineHeight=".8rem" fontStyle="italic">Equivalente a <strong>${pricingPerDay} USD</strong> por día</CustomText>
                </Container>
                {mode !== "LIST" && 
                <PlanDescription alignItems={"flex-start"} width={"100%"}>
                    {
                            plan.limitsTool.slice(0, 3).map(features => <PlanListItem key={features.name}><strong>{features.value}</strong> / {features.name}</PlanListItem>)
                    }
                    </PlanDescription>}
                <ConfirmButton
                    onClick={handleSelectPlan}
                >
                    {mode === "LIST" ? "Quiero este plan" : "Disminuir plan"}
                </ConfirmButton>
            </PlanDescription>
        </PlanCardStyled>
        {isOpenChangePlanModal && <CloseableModal isOpen={isOpenChangePlanModal} closeModal={closeModal}>
            <ConfirmPlanTab closePurchaseModal={closeModal} hasAdditionalTools={false} openSuccessModal={openSuccessModal} />
        </CloseableModal>}
        {isOpenChangePlanModal && <FinishPurchaseModal isOpen={isSuccessModalOpen} closeModal={closeSuccessModal} />}
    </>);
}

export default RecommendedPlanCard