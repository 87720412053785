import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

export const ComButton = styled.div`
  background-color: #1876f2 !important;
  border-radius: 60px !important;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 4%) !important;
  cursor: pointer !important;
  color: white;
  display: grid !important;
  font-size: 2.3rem !important;
  width: 60px !important;
  height: 60px !important;
  bottom: 9rem !important;
  position: absolute !important;
  place-items: center !important;
  right: 1.4rem !important;
  transition: transform ease-in-out 0.3s !important;
  
  &:hover {
    transform: scale(1.1);
  }
  
  @media (max-width: ${globalstyles.sizes.mobile}) {
    bottom: 12rem !important;
    right: 1rem !important;
  }
`;
