import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

export const HelpCenter = styled.div`
  overflow: hidden;
  padding-top: 2.8rem;
  z-index: 1;
`;

export const MessageSupport = styled.div`
  display: grid;
  place-items: center;
`;

export const TextSupport = styled.div`
  font-size: 24px;
  color: white;
  margin: 30% auto;
  font-weight: bolder;
  text-align: center;

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    & {
      margin: 80% auto;
    }
  }
`;
