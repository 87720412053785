import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMobileScreen } from "src/hooks/useMobileScreen";
import CrispChatBox from "../../components/crispChatBox/crispChatBox";
import { IFrame } from "../../styles/globalcomponents";
import CommunityButton from "./community-button";
import { HelpCenter } from "./support.styles";

export const Support = () => {
  const location = useLocation();
  const mobileScreen = useMobileScreen();

  useEffect(() => {
    // document.body.removeChild(script)
    const scriptAppended = document.getElementById("crispScript");
    scriptAppended?.remove();
  }, [location]);

  return (
    <>
      <HelpCenter>
        <IFrame hasNavbar={true} src={"https://ayuda.mastertools.com/es/"} />
        <CommunityButton />
        {!mobileScreen ? <CrispChatBox /> : null}
      </HelpCenter>
    </>
  );
};
