import {Fragment, ReactNode, useEffect, useRef, useState} from "react";
import {TabContent} from "./PlanTabContent.component";
import {TabContainer} from "./plansTab.styles";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useRedux";
import {moveToFollowingStep} from "../../../../../redux/slices/checkout";

interface ProgressTabContainerProps {
    titles: string[];
    contents: ReactNode[];
}

export function PlansTabContainer({ titles, contents }: ProgressTabContainerProps) {
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)
    const currentStep = useAppSelector((state) => state.checkout.currentStep)
    const dispatcher = useAppDispatch()
    const currentIndex = currentStep - 1
    const listRef = useRef<HTMLUListElement>(null);

    function getActiveChild() {
        const children = listRef.current!.children;
        for (let index = 0; index < children.length; index++) {
            const child = children[index];
            const haveActiveClass = child.className.includes('active');
            if (haveActiveClass) return child;
        }
    }

    function handleSelectTab(index: number) {
        dispatcher(moveToFollowingStep({stepId: ++index}))
    }

    useEffect(() => {
        const activeChild = getActiveChild() as HTMLElement;
        activeChild.scrollIntoView();
    }, [currentIndex]);

    if (titles.length === 0) return <></>;
    if (contents.length === 0) return <></>;

    return (
        <>
            <TabContainer>
                <ul ref={listRef}>
                    {titles.map((title, index) => {
                        const isActiveTab = currentIndex === index;
                        const isPassedTab = currentIndex > index;
                        const classes = `${isActiveTab ? 'active' : isPassedTab ? 'passed' : ''}`;
                        if(payOnlyAdditionalTools && index === 0) return <Fragment key={`tab-title-${index}`}></Fragment>
                        return (
                            <li
                                key={`tab-title-${index}`}
                                className={classes}
                                onClick={() => handleSelectTab(index)}
                            >
                                {title}
                            </li>
                        );
                    })}
                </ul>
            </TabContainer>
            <TabContent key={currentIndex}>{contents[currentIndex]}</TabContent>
        </>
    )
}
