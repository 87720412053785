import {QuickAccessButtonStyled} from "./quickAccessButton.styles";
import {DashboardIcon} from "../../../../components/icons";

interface QuickAccessButtonProps {
    onClick: () => void
}

export function QuickAccessButton({onClick}: QuickAccessButtonProps) {
    return (
        <QuickAccessButtonStyled onClick={onClick}>
            <DashboardIcon /> Ir al panel de la tienda
        </QuickAccessButtonStyled>
    )
}
