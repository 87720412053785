export interface FreeTrialForm {
  email: string;
  name: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  confirmPassword: string;
  ref: string | null | undefined;
  extra: string | null | undefined;
  ip: string;
}

export interface ActivationAccountForm {
  email: string;
  codeActivation: string;
  password: string;
  passwordConfirm: string;
  ip: string;
}
export interface ActiveCampaignSignIn {
  host: string;
  pass: string;
  user: string;
  ip: string;
}
export interface LoginForm {
  email: string;
  password: string;
  ip: string;
}

export interface User {
  id: string;
  rol: "admin" | "student";
  stateUser: number;
  email: string;
  password: string;
  suscription: any;
  toolsInfo: any;
}

export const freeTrialFormInitialState = (
  ref: any,
  extra: any
): FreeTrialForm => ({
  email: "",
  lastName: "",
  name: "",
  phoneNumber: "",
  confirmPassword: "",
  password: "",
  ref: ref,
  extra: extra,
  ip: "",
});

export const activationAccountFormInitialState = (): ActivationAccountForm => ({
  passwordConfirm: "",
  codeActivation: "",
  email: "",
  password: "",
  ip: "",
});

export const loginFormInitialState = (): LoginForm => ({
  email: "",
  password: "",
  ip: "",
});

export const userInitialState = (): User => ({
  id: "",
  rol: "student",
  email: "",
  password: "",
  stateUser: 0,
  suscription: null,
  toolsInfo: null,
});
