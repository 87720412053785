import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { deleteNotifications, getUserNotifications, viewedNotifications } from "src/services/notifications";


export interface INotification {
    idUserNotification: number;
    idUser: number;
    title: string;
    text: string;
    toolNotification: string;
    url: string;
    date: Date;
    viewed: boolean;
    viewedDate: Date | null;
    deleted: boolean;
    deletedDate: Date | null;
    targetUrl: string;
    type: 'notification' | 'recent-activity';
};

interface Notifications {
    notifications: INotification[];
    recentActivity: INotification[]
    isLoading: boolean;
};


const initialState: Notifications = {
    notifications: [],
    recentActivity: [],
    isLoading: false
};

export const getNotificationsThunk = createAsyncThunk("notifications/getNotifications",
    async (idUser: number, thunkAPI) => {

        try {
            return await getUserNotifications(idUser);
        } catch (error) {
            thunkAPI.rejectWithValue(error);
        }
    }
);

type UpdateNotifications = {
    idUser: number;
    notificationIds: number[];
}

export const updateViewedNotificationsThunk = createAsyncThunk("notifications/viewedNotifications",
    async ({ idUser, notificationIds }: UpdateNotifications, thunkAPI) => {
        try {
            return await viewedNotifications(idUser, notificationIds);
        } catch (error) {
            thunkAPI.rejectWithValue(error);
        }
    }
);

export const deleteNotificationsThunk = createAsyncThunk("notifications/deleteNotifications",
    async ({ idUser, notificationIds }: UpdateNotifications, thunkAPI) => {

        try {
            return await deleteNotifications(idUser, notificationIds);
        } catch (error) {
            thunkAPI.rejectWithValue(error);
        }
    }
);

const notificationSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNotificationsThunk.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(getNotificationsThunk.fulfilled, (state, { payload }: PayloadAction<INotification[]>) => {
            const notifications = payload.filter(notification => notification.type === 'notification')
            const recentActivity = payload.filter(notification => notification.type === 'recent-activity')

            state.isLoading = false;
            state.notifications = notifications;
            state.recentActivity = recentActivity
        })
        builder.addCase(deleteNotificationsThunk.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(deleteNotificationsThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.notifications = payload;
        })
        builder.addCase(updateViewedNotificationsThunk.pending, (state) => {
            state.isLoading = true;
        })
        builder.addCase(updateViewedNotificationsThunk.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.notifications = payload;
        })
    }
});

export default notificationSlice.reducer;
