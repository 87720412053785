import {ReactNode, useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import { Toaster } from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { instance } from "../../axios/axiosConfig";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { DatesLib } from "../../lib/datesLib";
import { setPlans, setUserAsync } from "../../redux/slices/auth";
import { PlansService } from "../../services/plansService";
import ButtonTools from "../buttonTools/buttonTools.component";
import { Navbar } from "../navbar/navbar.component";
import NavbarMobile from "../navbarMobille/navbarMobile.component";
import { Sidebar } from "../sidebar/sidebar.component";
import TypeForm from "../typeform/typeform.component";
import { CheckoutLayout } from "./checkoutLayout/checkoutLayout.component";
import { LayoutContainer } from "./mainLayout.styles";
import { ToolLayout } from "./toolLayout/toolLayout.component";
import {AUTH_ROUTES, AUTO_AUTH_ROUTES, PUBLIC_ROUTES, TOOL_ROUTES} from "../../routers/consts";

export const MainLayout = ({ children }: {children: ReactNode}) => {
  const [showTypeform, setShowTypeform] = useState<boolean>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const datesLib = new DatesLib();
  const expiredPlan = datesLib.isDateExpired(dataUser.suscription.expired_time);
  const isAuthenticated = dataUser.stateUser === 1 || dataUser.stateUser === 2;
  const isNotPublicRoute = PUBLIC_ROUTES.every(publicRoute => publicRoute !== location.pathname)
  const isAToolLayout = TOOL_ROUTES.some(toolRoute => toolRoute === location.pathname)
  const isLoginRoute = AUTH_ROUTES.some(route => route === location.pathname)

  function onFormSubmit() {
    setShowTypeform(false);
  }

  async function getPlanFeatures(plansService: PlansService) {
    const data = await plansService.detailSubscription();
    data.forEach((_item: any, i: number) => {
      const features = data[i].limitsTool;
      const list = [];
      list.push(features.funnels);
      list.push(features.linkedDomains);
      list.push(features.bandwidth);
      list.push(features.emailMarketingSuscribers);
      list.push(features.storeProducts);
      list.push(features.operatorsReservations);
      list.push(features.appointmentsReservations);
      list.push(features.linkAnalyticsLinks);
      list.push(features.linkAnalyticsClicks);
      list.push(features.linkAnalyticsDomains);
      list.push(features.linkAnalyticsPixels);
      data[i].limitsTool = list;
    });
    dispatch(setPlans(data));
  }

  async function finishOnBoarding() {
    if (dataUser.onBoarding === 0 && isAuthenticated) {
      try {
        await instance.post("/app/users/onBoarding", {
          email: dataUser.email,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  async function authenticateUserAutomatically() {
    if (isAuthenticated && AUTO_AUTH_ROUTES.includes(location.pathname)) {
      dispatch(setUserAsync());
    }
  }

  useEffect(() => {
    finishOnBoarding();
  }, [isAuthenticated, dataUser, dispatch]);

  useEffect(() => {
    authenticateUserAutomatically();
  }, [isAuthenticated, location]);

  useEffect(() => {
    const haveToShowUserProfiler = dataUser.showUserProfiler === undefined ? true : dataUser.showUserProfiler === 0;
    const showTypeForm = isAuthenticated && haveToShowUserProfiler
    setShowTypeform(showTypeForm);
  }, [dataUser.showUserProfiler]);

  useEffect(() => {
    if (isAuthenticated) {
      const plansService = new PlansService();
      getPlanFeatures(plansService);
    }
  }, [isAuthenticated]);

  if (location.pathname === "/checkout") {
    return <CheckoutLayout children={children} />;
  }

  if (isAToolLayout) {
    return <ToolLayout children={children} />;
  }

  return (
    <>
      {isNotPublicRoute && <ButtonTools />}
      <Helmet>
        <script type="text/javascript">
          {`
            ${isAuthenticated &&
            `
                dataLayer = [{
                  'email': '${dataUser.email}',
                  'actualPlan': '${dataUser.suscription.name}',
                  'planType': '${dataUser.suscription.typeSuscription}',
                  'expiredPlan': '${expiredPlan}',
                  'id': '${dataUser.id}',
                  'userName': '${dataUser.nameUser}',
                  'lastNameUser': '${dataUser.lastUser}',
                  'cellphone': '${dataUser.cellphone}',
                  'PageCategory': '${location.pathname}',
                  'Product': 'MasterTools'
                }]
              `
            }
          `}
        </script>
      </Helmet>
      {showTypeform ?
        <TypeForm idUser={dataUser.id} email={dataUser.email} onFormSubmit={onFormSubmit} />
       :
        <LayoutContainer isLoginRoute={isLoginRoute}>
          <Toaster />
          {!isLoginRoute && <Navbar />}
          {isNotPublicRoute && <NavbarMobile />}
          {!expiredPlan && <Sidebar />}
          {children}
        </LayoutContainer>
      }
    </>
  );
};
