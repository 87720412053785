import {TOOLS_ICONS} from "../../plans.data";
import {config} from "../../../../../../../config";
import {Fragment} from "react";
import {PlanTool, PlanToolsContainer} from "../../planSelectionTab/plans.styles";
import {Plan} from "../../../../../../../interfaces/plans";

interface PlanToolsProps {
    plan?: Plan
}

export function PlanTools({plan}: PlanToolsProps) {
    const subscriptionName = plan?.suscriptionInfo.name
    const haveLitePlan =  subscriptionName === "Lite"
    const haveBasicOrLitePlan = haveLitePlan || subscriptionName === "Basic";


    return (
        <PlanToolsContainer>
            {TOOLS_ICONS.map((toolIcon, index) => {
                const isEmailToolImg = toolIcon === `${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`
                const showEmailToolDependOnPlan = haveBasicOrLitePlan && isEmailToolImg
                if (showEmailToolDependOnPlan) {
                    return <Fragment key={`tools-${index}`}></Fragment>;
                } else {
                    return <PlanTool src={toolIcon} key={`tools-${index}`} width={"1.5rem"} height={"1.5rem"}></PlanTool>;
                }
            })}
        </PlanToolsContainer>
    )
}
