import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SpiralLoadingIcon } from "src/components/loadingIcon/loadingIcon.component";
import { config } from "src/config";
import { useAppSelector } from "src/hooks/useRedux";
import { ConnecToolService } from "src/services/connecToolService";
import { ToastService } from "src/services/toastService";
import { Container, ContainerItem, CustomTitle } from "src/styles/globalcomponents";

interface TokenData {
    shop:         string;
    access_token: string;
    iat:          number;
}


const connecToolLogo = `${config.urls.res}/platform/home/icons/Icono_connect.svg`
const toastService = new ToastService()

export function ShopifyIntegrationPage() {
    const dataUser = useAppSelector(state => state.auth.dataUser)
    const history = useHistory()
    const { token } = useParams<{ token: string }>()
    
    async function sendDataToConnecTool() {
        const tokenData = jwtDecode<TokenData>(token)
        const connecToolService = new ConnecToolService(dataUser.id)
        await connecToolService.setCredentials()
        await connecToolService.createShopifyConfigurationToolRegistry({
            urlApi: `https://${tokenData.shop}`,
            headerAccessValue: tokenData.access_token
        })
    }


    useEffect(() => {
        sendDataToConnecTool()
            .then(() => {
                setTimeout(() => {
                    toastService.success("Redirigiendo a ConnecTool 😉 ...")
                }, 2000);
                setTimeout(() => {
                    history.push("/connect?redirectUrl=/myApps/Shopify")
                }, 4000);
            })
            .catch(err => {
                toastService.error("Error al integrar con ConnecTool 😬")
            }) 
    }, [])

  return (
    <Container justifyContent="center" alignItems="center" height="100vh">
        <ContainerItem alignItems="center">
              <img src={connecToolLogo} alt="ConnecTool" width="100px" height="100px" />
              <CustomTitle
                  color="black"
                  fontSize="2rem"
              >
                  Conectando herramienta...
              </CustomTitle>
              <SpiralLoadingIcon width={"6rem"} />
        </ContainerItem>
    </Container>
  )
}
