import {
  BodyBackground,
  Card,
  Container,
  ContainerItem,
  Text,
  CustomTitle,
} from "../../../styles/globalcomponents";
import {
  GoBackContainer,
  GoBackIcon,
  TootorialCard,
  VideosContainer,
} from "./tootorial.styles";
import ReactStars from "react-stars";
import { TootorialVideo } from "./tootorialVideo/tootorialVideo.component";
import { config } from "../../../config";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { ToastService } from "../../../services/toastService";
import { ContentCategory, Video } from "../../../interfaces/tootorials";
import { Searchbar } from "../../../components/searchbar/searchbar.component";
import { ContentService } from "../../../services/contentService";

export const Tootorial = (props: any) => {
  let history = useHistory();
  const id = props.match.params.id;

  const [data, setData] = useState<ContentCategory>();
  const [filteredData, setFilteredData] = useState<Video[]>([]);
  const [searchbarInput, setSearchbarInput] = useState("");

  useEffect(() => {
    (async () => {
      const toastService = new ToastService();
      const contentService = new ContentService();
      try {
        const response = await contentService.getTootorial(id);
        setData(response[0]);
      } catch (error) {
        toastService.error("Ha ocurrido un error al obtener los tootoriales");
      }
    })();
  }, [id]);

  useEffect(() => {
    if (data) {
      if (searchbarInput) {
        setFilteredData(
          data.content.filter(
            (video) =>
              video.title
                .toLowerCase()
                .includes(searchbarInput.toLowerCase()) ||
              video.description
                .toLowerCase()
                .includes(searchbarInput.toLowerCase())
          )
        );
      } else {
        setFilteredData([]);
      }
    }
  }, [searchbarInput, data, id]);

  return (
    <BodyBackground backgroundColor="#F2F2F2">
      <Container width="95%" margin="32px auto" height="75vh">
        <ContainerItem width="30%" responsive="width: 100%;" margin="8px auto">
          <Card width="90%" margin=" 0 auto" height="100%">
            <Container width="80%" display="block" margin="32px auto">
              <Container width="90%">
                {data && (
                  <TootorialCard
                    src={`${config.urls.res}/platform/tootorials/category/${data.banner}`}
                    style={{ borderRadius: "16px" }}
                  ></TootorialCard>
                )}
              </Container>
              <CustomTitle color="black">{data && data.section}</CustomTitle>
              <Container
                width="80%"
                margin="8px auto"
                style={{ justifyContent: "center" }}
              >
                <ReactStars size={24} value={4} />
              </Container>
              <Container width="90%">
                <Text>{data && data.description}</Text>
              </Container>
            </Container>
          </Card>
        </ContainerItem>
        <ContainerItem width="70%" responsive="width: 100%;">
          <Card width="90%" margin="auto" height="100%" position={"relative"}>
            <Container
              width="95%"
              height="90%"
              responsive="height: unset;"
              margin="24px auto"
              flexDirection="column"
            >
              <Container margin="8px auto">
                <GoBackContainer>
                  <GoBackIcon onClick={() => history.goBack()} />
                </GoBackContainer>
                <Searchbar
                  width="88%"
                  value={searchbarInput}
                  setValue={setSearchbarInput}
                />
              </Container>
              <VideosContainer>
                {searchbarInput ? (
                  filteredData.length === 0 ? (
                    <CustomTitle color="black">No se encontraron resultados</CustomTitle>
                  ) : (
                    filteredData.map((video, i) => (
                      <TootorialVideo video={video} key={i}></TootorialVideo>
                    ))
                  )
                ) : (
                  data &&
                  data.content.map((video, i) => (
                    <TootorialVideo video={video} key={i}></TootorialVideo>
                  ))
                )}
              </VideosContainer>
            </Container>
          </Card>
        </ContainerItem>
      </Container>
    </BodyBackground>
  );
};
