import styled from "styled-components";
import {globalstyles} from "../../../../styles/animations/globalstyles";


export const QuickAccessButtonStyled = styled.button`
    background-color: ${globalstyles.colors.tools.shop};
    border: none;
    border-radius: 20px;
    color: white;
    padding: .6rem 1rem;
    font-weight: 300;
    cursor: pointer;
  
    &:hover {
      opacity: .9;
    }
`
