import { format } from "date-fns/esm";
import { useEffect, useState } from "react";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";
import { config } from "src/config";
import { useAppSelector } from "src/hooks/useRedux";
import { BillingDataCard } from "src/interfaces/billing";
import { getPaymentDetails } from "src/services/billingService";
import { billingResume } from "src/services/profileService";
import {Button, Card, ConfirmButton} from "src/styles/globalcomponents";
import {
  BillingBottomContainer,
  BillingMainContainer,
  BillingTable,
  BillingTopContainer,
  CardDescription,
  CardIcon,
  Header,
  NoBillingHistory,
  PayMethod,
  TableField,
  TableHeaders,
  TableRow,
} from "./billing.styles";
import {toPascalCase} from "../../../lib/stringHelper";
import CryptoJS from 'crypto-js'
import {SpiralLoadingIcon} from "../../../components/loadingIcon/loadingIcon.component";
import {Loader} from "../../../components/planPopUp/planPopUp.styles";

const Billing = () => {
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const darkMode = useAppSelector((state) => state.session.darkMode);
  const [cardData, setCardData] = useState<any>();
  const [billingData, setBillingData] = useState<any>();

  useEffect(() => {
    (async () => {
      const response = await billingResume(dataUser.email);
      const cardDataResponse: BillingDataCard = await getPaymentDetails(
        dataUser.email
      );

      setCardData(cardDataResponse?.data?.billingDataCards[0]);
      setBillingData(response);
    })();
  }, []);

  const handlePaymentMethod = () => {
    const windowFeatures = "left=400,top=100,width=1250,height=1024";
    const secret_key = String(config.secrets.updatePaymentMethod);
    const eParams = CryptoJS.AES.encrypt(
      `${dataUser.email}&${cardData?.idPaymet}`,
      secret_key
    ).toString();
    window.open(
      `${config.urls.mastershopUrl}/update/method/${encodeURIComponent(
        eParams
      )}`,
      "_blank",
      windowFeatures
    );
  };

  return (
    <Card
      backgroundColor={darkMode ? "#0d0d0d" : "white"}
      padding={"0 1rem 2rem"}
    >
      <BillingMainContainer darkMode={darkMode}>
        <BillingTopContainer color={darkMode ? "white" : "#0d0d0d"}>
          <h1>Facturación</h1>
        </BillingTopContainer>
        <BillingBottomContainer>
          {cardData ? (
            <PayMethod>
              <h4>Método de pago</h4>
              <CardDescription>
                <span>
                  {cardData?.brandCard === "default"
                    ? "Tarjeta"
                    : toPascalCase(cardData?.brandCard)}{" "}
                  que termina en {cardData?.last_four}
                </span>
                <CardIcon
                  src={`${config.urls.res}/platform/icons/cards/${cardData?.brandCard}.svg`}
                />
              </CardDescription>
              <ConfirmButton
                id="btnUpdatePayMethod"
                margin={"1rem 0 0 0"}
                onClick={handlePaymentMethod}
                width={"fit-content"}
              >
                Actualizar método de pago
              </ConfirmButton>
            </PayMethod>
          ) : null}
          {billingData && billingData.length > 0 ? (
            <BillingTable>
              <TableHeaders>
                <Header justifyContent="left">Fecha</Header>
                <Header hiddeOnMobile={true}>Código de transacción</Header>
                <Header justifyContent="right">Descripción</Header>
                <Header>Valor</Header>
                <Header hiddeOnMobile={true}>Tarjeta de pago</Header>
                <Header justifyContent="right">Estado</Header>
              </TableHeaders>
              {billingData?.map((resume: any, index: number) => {
                return (
                  <TableRow key={`billing-table-${index}`}>
                    <TableField justifyContent="left">
                      {" "}
                      {format(
                        new Date(resume.date.split("T", 1)),
                        "dd-MM-yyyy"
                      )}{" "}
                    </TableField>
                    <TableField> {resume.idTransaction} </TableField>
                    <TableField justifyContent="right">
                      {resume.description}
                    </TableField>
                    <TableField> {resume.currencyCode} {resume.value} </TableField>
                    <TableField>
                      {resume.last_four_numbers_payment_method}
                    </TableField>
                    <TableField
                      justifyContent="right"
                      color={true ? "green" : "red"}
                    >
                      <BsCheckCircleFill /> Aprobado
                    </TableField>
                  </TableRow>
                );
              })}
            </BillingTable>
          ) : (
            <NoBillingHistory>
              {billingData ? (
                <p>Aún no tienes historial de facturación disponible.</p>
              ) : null}
            </NoBillingHistory>
          )}
        </BillingBottomContainer>
      </BillingMainContainer>
    </Card>
  );
};

export default Billing;
