import { Container, ContainerItem } from "../../styles/globalcomponents";
import {
  BigEmoji,
  NotFoundBigTitle,
  NotFoundContainer,
  Description
} from "./notFound.styles";

export const NotFound = () => {
  return (
    <NotFoundContainer>
      <Container>
        <ContainerItem width="60%" alignItems="flex-end">
          <Container width="500px" margin="0 32px" responsive="margin: auto; width: 80%;" flexDirection="column">
            <NotFoundBigTitle>404</NotFoundBigTitle>
            <Description bolder>Algo ha salido mal.</Description>
            <Description>La página web que está buscando no existe</Description>
          </Container>
        </ContainerItem>
        <ContainerItem width="40%">
          <Container width="300px" margin="0" responsive="margin: auto; width: 80%;"  alignItems="flex-start">
            <BigEmoji />
          </Container>
        </ContainerItem>
      </Container>
    </NotFoundContainer>
  );
};
