import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

type ButtonProps = {
  active: boolean;
}

export const Button = styled.div<ButtonProps>`
  border-radius: 50%;
  background: white;
  width: 40%;
  height: 80%;
  position: absolute;
  left: ${props => props.active ? "50%" : "6px"};
  top: 50%;
  transform: translateY(-50%);
  transition: all ease-in-out .2s;
`

type ToggleButtonContainerProps = {
  active: boolean;
  permanent: boolean;
}

export const ToggleButtonContainer = styled.div<ToggleButtonContainerProps>`
  width: 48px;
  height: 24px;
  border-radius: 32px;
  background: ${ props => props.permanent ? (globalstyles.colors.inactiveGreen) : (props.active ? globalstyles.colors.green : "#262626")};
  position: relative;
  cursor: ${props => props.permanent ? "unset" : "pointer"};
`