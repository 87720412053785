import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { SpiralLoadingIcon } from "src/components/loadingIcon/loadingIcon.component";
import { config } from "../../config";
import { useAppSelector } from "../../hooks/useRedux";
import { ActiveCampaignService } from "../../services/activeCampaignService";
import { ToastService } from "../../services/toastService";
import { ToolsService } from "../../services/toolsService";
import { Container, IFrame } from "../../styles/globalcomponents";
import { LoaderLayout } from "../profile/subscription/unsubscribe/unsubscribe.styles";
import { useQuery } from "src/hooks/useQuery";

const toastService = new ToastService();
const toolsService = new ToolsService();
const activeCampaignService = new ActiveCampaignService();

export const Tools = () => {
  const history = useHistory();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const [loading, setLoading] = useState(true);
  const [accessUrl, setAccessUrl] = useState("");
  const queryParams = useQuery()

  const message =
    history.location.pathname === "/email/initial/version"
      ? "email"
      : history.location.pathname.split("/")[1];

  const activeCampaignSetup = async (ip: string) => {
    const toolsInfo = dataUser.toolsInfo.find((tool) => tool.name === "Active");

    if (toolsInfo) {
      let user;

      toolsInfo.dataConfig.payload
        ? (user = {
          host: `https://${toolsInfo.dataConfig.payload.account}/`,
          user: toolsInfo.dataConfig.payload.username,
          pass: toolsInfo.dataConfig.payload.password,
          ip,
        })
        : (user = {
          host: `https://${toolsInfo.dataConfig.account}/`,
          user: toolsInfo.dataConfig.username,
          pass: toolsInfo.dataConfig.password,
          ip,
        });

      try {
        const { url_login } = await activeCampaignService.activeCampaignSignIn(
          user
        );
        window.open(url_login, "_self");
      } catch (error) {
        toastService.error("Ha ocurrido un error con la herramienta Email");
      }
    } else {
      history.push("/home");
    }
  };

  const loadIframeData = () => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);

    if (history.location.pathname === "/copy") {
      return iframeRef.current?.contentWindow?.postMessage(
        {
          idUser: dataUser.id,
        },
        "*"
      );
    }

    if (history.location.pathname === "/connect") {
      const userData = {
        idUser: dataUser?.id,
        userName: dataUser?.nameUser,
        email: dataUser?.email,
        userPhoto: dataUser?.url_image,
        projectId: dataUser?.toolsInfo[0]?.dataConfig?.projectId,
        platformOrigin: 'mastertools'
      }
      return iframeRef.current?.contentWindow?.postMessage(
        userData,
        "*"
      );
    }

    if (history.location.pathname === "/funnels" || history.location.pathname === "/crm" || history.location.pathname === "/shop") {
      setTimeout(() => {
        iframeRef.current?.contentWindow?.postMessage(
          {
            message,
            pioneer: dataUser.websiteFunnelActive === 1,
            isSeminario: dataUser.isSeminario === 1 ? "enable" : "disable",
            isAffiliate: dataUser.isAffiliate === 1 ? "enable" : "disable",
            isRafa: dataUser.isRafa === 1 ? "enable" : "disable",
            isCrecel: dataUser.isCrecel === 1 ? "enable" : "disable",
            isCopyNation: dataUser.isCopyNation === 1 ? "enable" : "disable",
            isGIS: dataUser.isGIS === 1 ? "enable" : "disable",
            isCrecelMex: dataUser.isCrecelMex === 1 ? "enable" : "disable",
            isEmailMarketing:
              dataUser.isEmailMarketing === 1 ? "enable" : "disable",
          },
          "*"
        );
      }, 1200);
    }
  };

  async function manageAccessUrl() {
    try {
      switch (history.location.pathname) {
        case "/email":
          const geoData = await axios.get("https://d1qh9l1lloiwpp.cloudfront.net/ipInfo");
          await activeCampaignSetup(geoData.data.ip);
          break;
        case "/copy":
          setAccessUrl(config.urls.copyToolUrl);
          break;
        case "/connect":
          if (queryParams.has("redirectUrl")) {

            const accessUrl = `${config.urls.connecToolUrl}${queryParams.get("redirectUrl")}${history.location.hash}`
            setAccessUrl(accessUrl)
            return
          }

          setAccessUrl(config.urls.connecToolUrl);
          break;
        case "/linktool":
          const responseLinktool = await toolsService.getSOOUrlLinkTool(dataUser);
          setAccessUrl(responseLinktool.ssoUrl);
          break;
        default:
          const responseSimvoly = await toolsService.loginSimvolyService(dataUser);
          setAccessUrl(responseSimvoly.accessUrl);
          break;
      }
    } catch (error) {
      toastService.error("Ha ocurrido un error con la herramienta Funnel");
    }
  }

  useEffect(() => {
    manageAccessUrl();
  }, [dataUser, message]);

  useEffect(() => {
    function sendMessageToIFrame() {
      iframeRef.current?.contentWindow?.postMessage(
        "Connecting with LinkTool iframe from mastertools",
        "*"
      );
    }

    function listenForRefreshPage(event: MessageEvent<any>) {
      const childMessage = event.data;
      if (childMessage === "LINKTOOL:REFRESH") {
        window.location.reload();
      }
    }

    sendMessageToIFrame();
    window.addEventListener("message", listenForRefreshPage);
  }, [accessUrl]);

  return (
    <>
      {loading && history.location.pathname === "/linktool" && (
        <LoaderLayout>
          <Container justifyContent="center" position="absolute">
            <SpiralLoadingIcon width="100px" />
          </Container>
        </LoaderLayout>
      )}
      {accessUrl && (
        <IFrame
          hasNavbar={false}
          src={accessUrl}
          ref={iframeRef}
          onLoad={loadIframeData}
          allow="clipboard-read; clipboard-write"
          allowFullScreen
        />
      )}
    </>
  );
};
