import styled from "styled-components";

interface IframeVideoProps {
  allowFullScreen?: boolean;
  fullScreens?: boolean;
  src: string;
}

export const IframeVideo = styled.iframe.attrs<IframeVideoProps>((props) => ({
  src: props.src,
  allowFullScreen: true,
  frameBorder: 0,
  height: props.allowFullScreen ? "400px" : "100%",
  width: "100%",
  allow:
    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;",
}))`
  border-radius: 25px;
`;
