import { LimitsTool } from "../../../../interfaces/plans";
import { PlanListItem } from "../subscription.styles";

interface PlanDescriptionProps {
  currentPlan: LimitsTool;
}

const PlanListItems = ({ currentPlan }: PlanDescriptionProps) => {
  return (
    <>
      {
          currentPlan.funnels ?
            <PlanListItem>
                <strong>{currentPlan.funnels.value}</strong> {currentPlan.funnels.name}
            </PlanListItem>
          : null
      }
      {
          currentPlan.linkAnalyticsDomains ?
            <PlanListItem>
              <strong>{currentPlan.linkAnalyticsDomains.value}</strong> {currentPlan.linkAnalyticsDomains.name}
            </PlanListItem>
          : null
      }
      {
          currentPlan.bandwidth ?
            <PlanListItem>
              <strong>{currentPlan.bandwidth.value}</strong> {currentPlan.bandwidth.name}
            </PlanListItem>
          : null
      }
      {
          currentPlan.storeProducts ?
            <PlanListItem>
              <strong>{currentPlan.storeProducts.value}</strong> {currentPlan.storeProducts.name}
            </PlanListItem>
          : null
      }
      {
          currentPlan.linkAnalyticsLinks ?
            <PlanListItem>
              <strong>{currentPlan.linkAnalyticsLinks.value}</strong>  {currentPlan.linkAnalyticsLinks.name}
            </PlanListItem>
          : null
      }
      {
          currentPlan.copyProjects ?
            <PlanListItem>
              <strong>{currentPlan?.copyProjects?.value}</strong> {currentPlan?.copyProjects?.name}
            </PlanListItem>
          : null
      }
    </>
  );
};

export default PlanListItems;
