import {
    Bar,
    CartesianGrid,
    Cell,
    ComposedChart,
    Legend,
    Line, Pie, PieChart,
    ResponsiveContainer, Sector, Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {ChartProps} from "../../../../interfaces/stats";
import {Card, Container} from "../../../../styles/globalcomponents";
import {useState} from "react";

interface BarChartProps {
    data: ChartProps[] | undefined,
    showMonetaryInfo: boolean
    isLoading: boolean
}

const COLORS_BY_STATE: any = {
    'Pendiente':'#798188',
    'Enviado':'#CA9F36',
    'Entregado':'#76BD65',
    'Cancelado':'#DD1742',
}

export function ComposedStatsCharts({data, showMonetaryInfo, isLoading}: BarChartProps) {
    const [activeIndex, setActiveIndex] = useState(0)

    function onPieEnter(_: any, index: number){
        setActiveIndex(index)
    }

    return (
        <Card padding={"1rem 0"} margin={"1rem 0"} borderRadius={"10px"}>
            {
                data === undefined ?
                    <Container justifyContent={"center"} alignItems={"center"} height={"100%"}>
                        <h2>No hay datos</h2>
                    </Container>
                :
                <ResponsiveContainer width={"100%"} aspect={2}>
                    {
                        !showMonetaryInfo ?
                            <PieChart width={400} height={400}>
                                <Pie
                                    activeIndex={activeIndex}
                                    activeShape={renderActivePieShape}
                                    data={data}
                                    cx="50%"
                                    cy="50%"
                                    innerRadius={80}
                                    outerRadius={130}
                                    fill="red"
                                    dataKey="value"
                                    onMouseEnter={onPieEnter}
                                >
                                    {data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS_BY_STATE[entry.xLegend] ?? COLORS_BY_STATE[0]}/>
                                    ))}
                                </Pie>
                            </PieChart>
                            :
                            <ComposedChart
                                width={800}
                                height={300}
                                data={data}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="xLegend"/>
                                <YAxis/>
                                <Bar
                                    name="Ventas totales"
                                    dataKey="totalSalesValue"
                                    fill="#389DF9"
                                    shape={<RoundedBar />}
                                    label={{ position: "top" }}
                                >
                                    {data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={"#389DF9"} />
                                    ))}
                                </Bar>
                                <Line name={"Promedio de ventas"} type="monotone" dataKey="avgValue" stroke="#82ca9d" strokeWidth={3} />
                                <Legend />
                                <Tooltip />
                            </ComposedChart>
                    }
                </ResponsiveContainer>
            }
        </Card>
    )
}

function RoundedBar(props: any) {
    const {fill, x, y, width, height} = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
}

function getPath(x: number, y: number, width: number, height: number) {
    const heightFromRounded = height - (height - 7);
    const widthFromRounded = width - (width - 8);
    const barWidth = -15;

    return `
  M ${x - barWidth} ${y + height}
  V ${y + heightFromRounded}
  Q 
    ${x - barWidth} ${y}
    ${x - barWidth + widthFromRounded} ${y} 
  H ${x + (width + barWidth - widthFromRounded)} 
  Q 
    ${x + width + barWidth} ${y} 
    ${x + width + barWidth} ${y + heightFromRounded}
  V ${y + height} 
  Z`;
}


const renderActivePieShape = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g>
            <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.xLegend}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                {`${(percent * 100).toFixed(2)}%`}
            </text>
        </g>
    );
};
