
export const PUBLIC_ROUTES = [
    "/begin",
    "/resetPassword",
    "/login",
    "/login/:token"
]

export const TOOL_ROUTES = [
    "/funnels",
    "/email",
    "/email/initial/version",
    "/shop",
    "/connect",
    "/crm",
    "/linktool",
    "/copy"
]

export const AUTH_ROUTES = [
    '/login',
    '/'
]

export const AUTO_AUTH_ROUTES = [
    "/home",
    "/plans",
    "/profile",
    "/addons",
    "/funnels",
    "/email",
    "/emailold",
    "/shop",
    "/crm",
    "/copy",
    "/connect",
    "/linktool",
]
