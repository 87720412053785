import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styled from "styled-components";
import { config } from "../../config";
import { globalstyles } from "../../styles/animations/globalstyles";

type MonthTooler = {
    imgUrl: string;
}

export const MonthTooler = styled.div<MonthTooler>`
    width: 100%;
    height: 90%;
    background-image: url('${props => props.imgUrl}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    margin: auto;
    transition: .2s;

    &:hover {
        transform: scale(1.02);
    }
`

export const FacebookGroupContainer = styled.div`
    background-image: url('${config.urls.res}/platform/Card-Facebook-group.png');
    background-size: cover;
    border-radius: 8px;
    cursor: pointer;
    height: 90%;
    margin: auto;
    transition: .2s;
    width: 100%;

    &:hover {
        transform: scale(1.02);
    }
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  transition: 0.2s;
  
  &:hover {
      transition: 0.2s;
      transform: scale(1.2);
    }
    `;

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  cursor: pointer;
  transition: 0.2s;
  
  &:hover {
      transition: 0.2s;
      transform: scale(1.2);
    }
`;

export const EventDate = styled.div`
    background-image: url('https://picsum.photos/180/140');
    background-size: cover;
    width: 100%;
    height: 32px;
    border-radius: 8px;
    align-self: flex-end;
`

export const EventDescription = styled.div`
    font-size: 12px;
    color: white;
    white-space: initial;
    margin-top: 4px;
`

export const EventTitle = styled.div`
    font-size: 24px;
    color: white;
    font-weight: bolder;
    white-space: initial;
`

export const EventCardContent = styled.div`
    height: 60%;
    width: 100%;
    display: flex;
`

export const CardHeader = styled.div`
    height: 40%;
    width: 100%;
    background-size: cover;
`

export const EventCard = styled.div`
    cursor: pointer;
    width: 95%;
    height: 93%;
    margin: auto;
    border-radius: 16px;
    overflow: hidden;
    transition: .2s;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
        box-shadow: 0 0 10px 1px black;
    }
`

export const EventCardContainer = styled.div`
    width: 33%;
    height: 100%;
    display: inline-flex;

    @media (max-width: ${globalstyles.sizes.mobile}) {
        width: 100%;
    }
`

export const EventsList = styled.div`
    width: 80%;
    height: 100%;
    margin: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    white-space: nowrap;
`

export const EventsContainer = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    position: relative;
`