import { useAppSelector } from "../../../hooks/useRedux";
import {
  Card,
  Container,
  ContainerItem,
} from "../../../styles/globalcomponents";
import { AccountForm } from "./accountForm/accountForm.component";
import { ProfileImage } from "./profileImage/profileImage.component";

export const Account = () => {

  const darkMode = useAppSelector(state => state.session.darkMode);

  return (
    <>
      <Card backgroundColor={darkMode ? "#0d0d0d" : "transparent"} responsive="width: 100%;">
        <Container gap="10px" overflow="auto" responsive={"flex-wrap: wrap"}>
          <ContainerItem width="100%" backgroundColor="white" borderRadius="49px" responsiveLg={"width: 40%"}>
            <ProfileImage />
          </ContainerItem>
          <ContainerItem width="100%" borderRadius="49px" responsiveLg={"width: 60%"}>
            <AccountForm />
          </ContainerItem>
        </Container>
      </Card>
    </>
  );
};

