import { instance } from "src/axios/axiosConfig";

export const getUserNotifications = async (idUser: number) => {
    try {
        const response = await instance.get(`app/notifications/user/${idUser}`);
        return response.data.data.data;
    } catch (error) {
        console.log("Ha fallado el fetch de notificaciones.", error);
    }
};


export const viewedNotifications = async (idUser: number, ids: number[]) => {
    try {
        const response = await instance.put(`app/notifications/updateViewed/${idUser}`, { ids });
        return response.data.data.data;
    } catch (error) {
        console.log("Ha fallado la actualización de notificaiones vistas.", error);
    }
};

export const deleteNotifications = async (idUser: number, ids: number[]) => {
    try {
        const response = await instance.put(`app/notifications/updateDeleted/${idUser}`, { ids });
        return response.data.data.data;
    } catch (error) {
        console.log("Ha fallado la eliminación de notificaiones.", error);
    }
};