import {CloseableModal, CloseableModalProps} from "../../../../../components/ui/modal/closeable-modal.component";
import {PurchaseTabContainer} from "./purchaseTabContainer.component";
import {ConfirmPlanTab} from "./tabs/confirmPlanTab/confirmPlan.tab";
import {ConfirmAddonTab} from "./tabs/confirmAddonTab/confirmAddon.tab";
import {useState} from "react";
import {useAppSelector} from "../../../../../hooks/useRedux";
import {FinishPurchaseModal} from "./tabs/finishPurchase.modal";

interface PurchaseModalProps extends Omit<CloseableModalProps, "children">{
}

export function PurchaseModal({isOpen, closeModal}: PurchaseModalProps) {
    const [currentIndex, setCurrentIndex] = useState(0)
    const additionalTools = useAppSelector((state) => state.checkout.additionalTools)
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const hasAdditionalTools = additionalTools.some(addon => addon.quantity !== 0)
    const hasPlanSelected = selectedPlan !== undefined
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false)

    const titles = [
        'Confirmar plan',
        'Confirmar addon',
    ]
    function setIndex(index: number) {
        setCurrentIndex(index)
    }

    function closeSuccessModal() {
        setIsSuccessModalOpen(false)
    }
    function openSuccessModal() {
        setIsSuccessModalOpen(true)
    }

    const contents = [
        <ConfirmPlanTab closePurchaseModal={closeModal} setTabIndex={setIndex} hasAdditionalTools={hasAdditionalTools} openSuccessModal={openSuccessModal} />,
        <ConfirmAddonTab closePurchaseModal={closeModal} openSuccessModal={openSuccessModal} />
    ]

    if(!hasAdditionalTools && hasPlanSelected) {
        return (
            <>
                <CloseableModal isOpen={isOpen} closeModal={closeModal}>
                    <ConfirmPlanTab closePurchaseModal={closeModal} setTabIndex={setIndex} hasAdditionalTools={hasAdditionalTools} openSuccessModal={openSuccessModal} />
                </CloseableModal>
                <FinishPurchaseModal isOpen={isSuccessModalOpen} closeModal={closeSuccessModal} />
            </>
        )
    }

    if(payOnlyAdditionalTools) {
        return (
            <>
                <CloseableModal isOpen={isOpen} closeModal={closeModal}>
                    <ConfirmAddonTab closePurchaseModal={closeModal} openSuccessModal={openSuccessModal} />
                </CloseableModal>
                <FinishPurchaseModal isOpen={isSuccessModalOpen} closeModal={closeSuccessModal} />
            </>
        )
    }

    return (
        <>
            <CloseableModal isOpen={isOpen} closeModal={closeModal}>
                <PurchaseTabContainer titles={titles} contents={contents} currentIndex={currentIndex}/>
            </CloseableModal>
            <FinishPurchaseModal isOpen={isSuccessModalOpen} closeModal={closeSuccessModal} />
        </>
    )
}
