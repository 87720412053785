import { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Container,
  ErrorInput,
  Input,
  Label,
} from "../../../styles/globalcomponents";
import { ResetPasswordService } from "../../../services/resetPasswordService";
import { ToastService } from "../../../services/toastService";
import { useHistory } from "react-router-dom";
import { globalstyles } from "src/styles/animations/globalstyles";

const newPasswordFormInitialState = {
  password: "",
  passwordConfirm: "",
};

export const NewPassword = (props: any) => {
  const [newPasswordForm, setNewPasswordForm] = useState(
    newPasswordFormInitialState
  );
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [formBlock, setFormBlock] = useState(false);

  const history = useHistory();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPasswordForm({
      ...newPasswordForm,
      [e.target.name]: e.target.value,
    });
  };

  const submitNewPassword = async () => {
    if (formBlock) {
      return;
    }

    setPasswordError("");
    setConfirmPasswordError("");

    if (newPasswordForm.password.trim() === "") {
      return setPasswordError("Debe ingresar una contraseña");
    }

    if (newPasswordForm.passwordConfirm.trim() === "") {
      return setConfirmPasswordError("Debe confirmar su contraseña");
    }

    if (
      newPasswordForm.password.trim() !== newPasswordForm.passwordConfirm.trim()
    ) {
      return setConfirmPasswordError("Las contraseñas no coinciden");
    }
    const service = new ResetPasswordService();
    const toastService = new ToastService();
    try {
      const responseService = await service.resetPassword({
        password: newPasswordForm.password,
        tokenUser: props.tokenUser,
      });
      setFormBlock(true);
      if (responseService.message) {
        toastService.success("Su contraseña ha sido reestablecida");
        history.push("/");
      }
      setFormBlock(false);
    } catch (error: any) {
      toastService.error(
        "Hubo un error al restablecer tu contraseña, contactate con soporte!"
      );
    }
  };

  return (
    <Container width={"50%"} responsive="width: 90%; margin-top: 1rem;">
      <Card backgroundColor={globalstyles.colors.primary}>
        <CardContent>
          <Label color={"white"}>Ingresa tu nueva contraseña</Label>
          <Input
            onChange={onChange}
            name="password"
            type="password"
            placeholder="Nueva contraseña"
          />
          <ErrorInput>{passwordError}</ErrorInput>

          <Label color={"white"}>Confirma tu contraseña</Label>
          <Input
            onChange={onChange}
            name="passwordConfirm"
            type="password"
            placeholder="Confirmar contraseña"
          />
          <ErrorInput>{confirmPasswordError}</ErrorInput>

          <Button onClick={submitNewPassword} typeButton={3}>Reestablecer contraseña</Button>
        </CardContent>
      </Card>
    </Container>
  );
};
