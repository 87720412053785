import styled from "styled-components";


export const TextLayerStyles = styled.div`
  position: absolute;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  text-shadow:  -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
  z-index: 2;
  letter-spacing: 1px;
  opacity: 0;
  transition: opacity 250ms ease-in;
  user-select: none;
  text-align: center;
  
  &:hover {
    opacity: 1;
  }
`
