import { config } from "src/config";

export const BLACK_FRIDAY = {
	startDate: new Date(Date.UTC(2024, 10, 18, 5, 0, 0)),
	finalDate: new Date(Date.UTC(2024, 10, 29, 23, 59, 59)),
};

export const CIBER_MONDAY = {
	startDate: new Date(Date.UTC(2024, 11, 2, 0, 0, 0)),
	finalDate: new Date(Date.UTC(2024, 11, 3, 5, 30, 0)),
};
export const DOWNGRADE_TO_LITE_VIDEO = `${config.urls.res}/media/videos/cancellation-video-1.mp4`;
export const DOWNGRADE_TO_LITE_VIDEO_THUMBNAIL = `${config.urls.res}/media/videos/cancellation-video-1.png`;
export const CANCELLATION_VIDEO = `${config.urls.res}/media/videos/cancellation-video-2.mp4`;
export const CANCELLATION_VIDEO_THUMBNAIL = `${config.urls.res}/media/videos/cancellation-video-2.png`;

export const CIBER_MONDAY_COUPONS = [
	{
		idCoupon: "BW-BA-24",
		name: "Cyber Monday Basic Anual",
		price: 340.0,
	},
	{
		idCoupon: "BW-BT-24",
		name: "Cyber Monday Basic Trimestral",
		price: 110.0,
	},
	{
		idCoupon: "BW-BS-24",
		name: "Cyber Monday Basic Semestral",
		price: 190.0,
	},
	{
		idCoupon: "BW-SA-24",
		name: "Cyber Monday Starter Anual",
		price: 340.0,
	},
	{
		idCoupon: "BW-ST-24",
		name: "Cyber Monday Starter Trimestral",
		price: 110.0,
	},
	{
		idCoupon: "BW-BS-24",
		name: "Cyber Monday Starter Semestral",
		price: 190.0,
	},
	{
		idCoupon: "BW-PT-24",
		name: "Cyber Monday Plus Trimestral",
		price: 190.0,
	},
	{
		idCoupon: "BW-PS-24",
		name: "Cyber Monday Plus Semestral",
		price: 350.0,
	},
	{
		idCoupon: "BW-PA-24",
		name: "Cyber Monday Plus Anual",
		price: 570.0,
	},
	{
		idCoupon: "BW-MT-24",
		name: "Cyber Monday Master Trimestral",
		price: 520.0,
	},
	{
		idCoupon: "BW-MS-24",
		name: "Cyber Monday Master Semestral",
		price: 940.0,
	},
	{
		idCoupon: "BW-MA-24",
		name: "Cyber Monday Master Anual",
		price: 1450.0,
	},
];

export const BLACK_FRIDAY_COUPONS = [
	{
		idCoupon: "BW-BA-24",
		name: "Black Week Basic Anual",
		price: 333.0,
	},
	{
		idCoupon: "BW-BT-24",
		name: "Black Week Basic Trimestral",
		price: 99.0,
	},
	{
		idCoupon: "BW-BS-24",
		name: "Black Week Basic Semestral",
		price: 167.0,
	},
	{
		idCoupon: "BW-SA-24",
		name: "Black Week Starter Anual",
		price: 333.0,
	},
	{
		idCoupon: "BW-ST-24",
		name: "Black Week Starter Trimestral",
		price: 99.0,
	},
	{
		idCoupon: "BW-BS-24",
		name: "Black Week Starter Semestral",
		price: 167.0,
	},
	{
		idCoupon: "BW-PT-24",
		name: "Black Week Plus Trimestral",
		price: 179.0,
	},
	{
		idCoupon: "BW-PS-24",
		name: "Black Week Plus Semestral",
		price: 319.0,
	},
	{
		idCoupon: "BW-PA-24",
		name: "Black Week Plus Anual",
		price: 557.0,
	},
	{
		idCoupon: "BW-MT-24",
		name: "Black Week Master Trimestral",
		price: 499.0,
	},
	{
		idCoupon: "BW-MS-24",
		name: "Black Week Master Semestral",
		price: 907.0,
	},
	{
		idCoupon: "BW-MA-24",
		name: "Black Week Master Anual",
		price: 1447.0,
	},
];
