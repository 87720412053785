import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

type DateTagProps = {
  expired?: Boolean;
};

export const DateTag = styled.div<DateTagProps>`
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  width: auto;

  & > p {
    background-color: ${(props) =>
        props.expired ? globalstyles.colors.red : globalstyles.colors.yellow};
    font-size: 10px;
    font-weight: 500;
    padding: .5rem 1rem;
    border-radius: 0 0 16px 16px;
    box-shadow: ${globalstyles.boxShadows.normal};
    margin: 0;
    color: ${(props) => (props.expired ? "white" : "black")};
  }
`;
