import React from "react";
import { BsFacebook } from "react-icons/bs";
import { ComButton } from "./community-button.styles";

const CommunityButton = () => {
  const CommunityRedirection = () => {
    window.open("https://www.facebook.com/groups/mastertools.lat", "_blank");
  };

  return (
    <ComButton onClick={() => CommunityRedirection()}>
      <BsFacebook />
    </ComButton>
  );
};

export default CommunityButton;
