import { useState } from "react";
import { config } from "../../../config";
import {
  Button,
  Card,
  CardContent,
  Container,
  Label,
  Title,
} from "../../../styles/globalcomponents";
import { ErrorInput } from "../../../styles/globalcomponents";
import { MessageEmailSent, SubLabel, TextEmailSent } from "./sendEmail.styles";
import { ResetPasswordService } from "../../../services/resetPasswordService";
import { useMobileScreen } from "../../../hooks/useMobileScreen";
import { Input } from "../../../components/inputs/Input.component";
import { globalstyles } from "src/styles/animations/globalstyles";

export const SendEmail = () => {
  const mobileScreen = useMobileScreen();

  const [resetPasswordForm, setResetPasswordForm] = useState("");
  const [emailError, setEmailError] = useState("");
  const [submited, setSubmited] = useState(false);

  const resetPasswordSubmit = async () => {
    const expression = config.regex.email;

    if (resetPasswordForm.trim() === "") {
      return setEmailError("Debe ingresar un correo");
    }

    if (!expression.test(String(resetPasswordForm).toLowerCase())) {
      return setEmailError("Email inválido");
    }
    const service = new ResetPasswordService();
    try {
      await service.sendEmail({ emailUser: resetPasswordForm });
      setSubmited(true);
    } catch (error: any) {
      console.error(error.response);
    }
  };

  if (submited) {
    return (
      <Container width={"50%"} responsive="width: 90%;">
        <Card
          backgroundColor={
            "linear-gradient(240deg,rgb(43, 190, 155) 20%,rgb(1, 195, 56) 100%)"
          }
          responsive={`height: ${
            mobileScreen ? "60" : "120"
          }vh; margin-top: 0px; border-radius: 0px;`}
        >
          <CardContent>
            <MessageEmailSent>
              <TextEmailSent>
                Si tienes una cuenta existente, te enviáremos un correo con el
                link para recuperar tu contraseña
              </TextEmailSent>
            </MessageEmailSent>
          </CardContent>
        </Card>
      </Container>
    );
  }

  return (
    <Container margin={"3rem auto"} responsive="width: 95%;" responsiveMargin={"2rem auto"} width={"55%"}>
      <Card backgroundColor={globalstyles.colors.primary} borderRadius={globalstyles.borders.popUpMainCard} padding={"2rem 4rem"} responsivePadding={"1rem"}>
        <CardContent paddingRes="5px">
          <Container style={{ flexDirection: "column" }}>
            <Title color={"white"} fontSize={"18px"} fontWeight={500} margin={"0 0 0.5rem 0"} textAlign={"left"}>Restablece tu contraseña</Title>
            <Label color={"white"} fontWeight={"400"} fontSize={"10px"}>Ingresa la dirección de correo electrónico que hayas usado para registrarte.</Label>
            <Input
              backgroundColor="transparent"
              borderColor="white"
              color={"white"}
              type="text"
              placeholder="Escribe tu correo electrónico"
              icon="email"
              iconColor="white"
              onChange={(e) => setResetPasswordForm(e.target.value)}
            />
            <SubLabel fontSize={"10px"} textAlign={"left"}>
              Enviaremos a su correo un link para reestablecer su contraseña.
            </SubLabel>
            <ErrorInput>{emailError}</ErrorInput>

            <Button backgroundColor={"white"} typeButton={3} onClick={resetPasswordSubmit}>
              Enviar correo
            </Button>
          </Container>
        </CardContent>
      </Card>
    </Container>
  );
};
