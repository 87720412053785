import {PlansOverview} from "./plans.component";

interface PlansSelectionTabProps {
}

export function PlansSelectionTab({}: PlansSelectionTabProps) {
    return (
        <>
            <PlansOverview />
        </>
    )
}
