import styled from "styled-components";
import { globalstyles } from "src/styles/animations/globalstyles";
import { BsCircle } from "react-icons/bs";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { center } from "src/styles/mixins/minxins";

export const MenuNotifications = styled.div`
  background-color: white;
  border-radius: ${globalstyles.borders.cardImages} 0 0
    ${globalstyles.borders.cardImages};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px;
  position: absolute;
  top: 100px;
  width: 350px;
  min-height: 440px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  transition: 0.2s all ease-in-out;
  z-index: 9;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    min-width: 300px;
    width: unset;
  }
`;

export const QuitLayout = styled.div`
  background-color: transparent;
  bottom: 0;
  height: 100%;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: -1;
`;

export const NotificationsInnerWrapper = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;

export const NotificationsCounterContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  max-width: 180px;
`;

export const HeaderText = styled.p`
  color: white;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
`;

export const NotificationCounter = styled.div`
  background-color: ${globalstyles.colors.yellow};
  border-radius: 50%;
  ${center.centerDesktop};
  font-size: 12px;
  font-weight: bold;
  width: 18px;
  height: 18px;
`;

export const NotificationsHeader = styled.div`
  border-radius: 12px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const NotificationsTitle = styled.h4`
  font-size: 18px;
  font-weight: bold;
  margin: 0;
`;

type NotificationSelectButton = {
  backgroundColor?: string;
  color?: string;
  active?: boolean;
};

export const NotificationSelectButton = styled.button<NotificationSelectButton>`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 12px;
  border: none;
  color: ${({ color }) => (color ? color : globalstyles.colors.grayDarkness)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  font-size: 10.5px;
  font-weight: bold;
  padding: 5px 10px;
  outline: none;

  :hover {
    background-color: ${globalstyles.colors.graySoftDarker};
    color: ${globalstyles.colors.grayDarkness};
  }
`;

export const SelectAllInput = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  justify-content: flex-end;
  gap: 5px;
  margin-right: 15px;
  margin-bottom: 5px;
`;

export const NotificationsContainer = styled.div`
  align-items: center;
  border-radius: ${globalstyles.borders.cardImages};
  display: flex;
  gap: 10px;
  flex-flow: column;
  padding: 8px;
  height: 300px;
  margin-bottom: 20px;
  position: relative;
  width: 100%;
  overflow: auto;
`;

export const NotificationItem = styled.div`
  align-items: center;
  background-color: ${globalstyles.colors.grayLighter};
  border-radius: ${globalstyles.borders.cardImages};
  cursor: pointer;
  display: flex;
  padding: 15px;
  position: relative;
  transition: 0.1s all ease-in-out;
  width: 100%;
  :hover {
    background-color: ${globalstyles.colors.graySoftDarker};
  }
`;

export const NotificationSignal = styled.div`
  background-color: ${globalstyles.colors.green};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  left: -5px;
`;

export const NotificationDate = styled.p`
  color: ${globalstyles.colors.gray};
  font-size: 8.5px;
  position: absolute;
  top: 5px;
  right: 10px;
  margin: 0;
`;

export const NotificationToolImage = styled.div`
  border-radius: ${globalstyles.borders.inputBorders};
  width: 20px;
  height: 20px;
  margin-right: 8px;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`;

export const NotificationContent = styled.div`
  display: flex;
  flex-flow: column;
  gap: 5px;
`;

export const NotificationTitle = styled.h3`
  font-size: 12px;
  font-weight: 600;
  margin: 0;
`;

export const NotificationText = styled.p`
  font-weight: 500;
  font-size: 10px;
  word-wrap: break-word;
  width: 80%;
  margin: 0;
`;

export const NotificationButtonsContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 5px;
  width: 100%;

  transition: 0.5s all ease-in-out;
`;

export const NotificationLayer = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  /* z-index: 100; */
`;

export const EmptyNotificationsContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${globalstyles.colors.text};
  flex-flow: column;
  gap: 10px;
`;

export const EmptyContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const LinkTag = styled.div`
  text-decoration: none;
  color: inherit;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  z-index: 100;
`;

export const SelectedIcon = styled(BsFillCheckCircleFill)`
  fill: ${globalstyles.colors.green};
  position: absolute;
  right: 20px;
`;

export const NonSelectedIcon = styled(BsCircle)`
  position: absolute;
  right: 20px;
`;
