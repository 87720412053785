import { instance } from "src/axios/axiosConfig";
import { config } from "src/config";
import { DataUser } from "../interfaces/redux";
import { CopyToolService } from "./copyToolService";
import { SimvolyService } from "./simvolyService";

export class ToolsService {

  loginCopyTool = async (id: string) => {
    const copyToolService = new CopyToolService();
    const response = await copyToolService.login(id);
    return response;
  }

  loginSimvolyService = async (dataUser: DataUser) => {
    const userData = {
      idUser: dataUser.id,
      idProject: dataUser.toolsInfo.find(tool => tool.name === "Funnels")?.dataConfig.projectId,
    };
    const simvolyService = new SimvolyService();
    const response = await simvolyService.login(userData);
    return response;
  }

  loginLinkAnalyticsService = async (dataUser: DataUser) => {
    const userData = {
      email: dataUser.toolsInfo.find((tool) => tool.name === "Shorter")
        ?.dataConfig.email,
      token: dataUser.toolsInfo.find((tool) => tool.name === "Shorter")
        ?.dataConfig.token,
    };

    !sessionStorage.getItem('joinToShorter') && sessionStorage.setItem('joinToShorter', '0');

    if (sessionStorage.getItem("joinToShorter") === "0") {
      sessionStorage.setItem("joinToShorter", "1");
      window.location.href = `https://mstr.ly/user/login/?email='${userData.email}'&password='${userData.token}'`;
    } else if (sessionStorage.getItem("joinToShorter") === "1") {
      window.location.href = "https://mstr.ly/user";
    }
  }

  getSOOUrlLinkTool = async (dataUser: DataUser) => {
    const userId = dataUser.toolsInfo.find(tool => tool.name === 'Shorter')?.dataConfig.id
    
    const response = await instance.get(
      `/app/sso/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${config.authorization.linktoolAuthToken}`,
        },
      }
    );

    return response.data;
  }
}
