import { useEffect, useState } from "react"
import { globalstyles } from "../styles/animations/globalstyles";
import { useWindowSize } from "./useWindowSize";

export const useMobileScreen = () => {
  const [mobileScreen, setMobileScreen] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setMobileScreen(windowSize.width < globalstyles.sizesInt.mobile);
  }, [windowSize]);

  return mobileScreen;
}