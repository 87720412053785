import type { PlanFrequency } from "src/interfaces/plans";
import { CIBER_MONDAY } from "src/pages/profile/consts";
import { LeftArrowIcon } from "../../../../../components/icons";
import { BackButtonStyled } from "../../../../../components/ui/backButton/index.styles";
import { useAppSelector } from "../../../../../hooks/useRedux";
import { Card, Container, ContainerItem, CustomTitle } from "../../../../../styles/globalcomponents";
import { CiberMondayCounter } from "../../overview/ciberMondayCounter.component";
import { PLANS_PERIODICITY_HIERARCHY } from "../tabs/plans.data";
import { CiberMondayPlanCardPromotion } from "./ciberMondayPlanPromotion.component";

interface Props {
    onAbortCancellation: () => void
}

export function CiberMondayPromotionSelector({ onAbortCancellation }: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currPlan = dataUser.suscription
    const plans = useAppSelector((state) => state.auth.plans);

    return (
        <Container
            display={"grid"}
            gap={"1rem"}
            gridTemplateColumns={"1fr"}
        >
            <ContainerItem>
                <Card
                    backgroundColor={"white"}
                    padding={"0 2.5rem 2rem"}
                    responsive={"0 2.5rem 2rem"}
                    position={"relative"}
                >
                    <Container
                        margin={"auto"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        padding={"3rem 0 0 "}
                        responsiveLg={"padding: 1.5rem;"}
                    >
                        <BackButtonStyled secondary={true} onClick={onAbortCancellation}>
                            <LeftArrowIcon fill={"white"} />
                        </BackButtonStyled>
                        <CustomTitle margin={"0.5rem 0"} color={"black"} fontWeight={"600"} fontSize={"18px"}>
                            <CiberMondayCounter startDate={CIBER_MONDAY.startDate} finalDate={CIBER_MONDAY.finalDate} mode="FULL_PAGE" />
                            <ContainerItem alignItems={"center"} display="flex" flexDirection="row" gap="1.5rem" justifyContent="center" margin="3rem 0">
                                {
                                    Object.keys(PLANS_PERIODICITY_HIERARCHY).map(periodicity => {
                                        return plans.map(plan => {
                                            const isMonthlyPlan = periodicity === 'Mensual'
                                            const isAnualPlan = periodicity === 'Anual'
                                            const isCurrPlanLiteOrTrial = currPlan.name === "Lite" || currPlan.name === "Trial"
                                            const isBasic = plan.suscriptionInfo.name === "Basic"
                                            const isSamePlanThanCurrent = currPlan.name === plan.suscriptionInfo.name
                                            const isGreaterOrEqualsToCurrent = PLANS_PERIODICITY_HIERARCHY[periodicity as PlanFrequency] >= PLANS_PERIODICITY_HIERARCHY[currPlan.typeSuscription as PlanFrequency]

                                            if (isMonthlyPlan) return

                                            if (isCurrPlanLiteOrTrial) {
                                                if (isBasic) {
                                                    return <CiberMondayPlanCardPromotion isRecommended={isAnualPlan} periodicity={periodicity as PlanFrequency} plan={plan} key={plan.suscriptionInfo.idSuscription} />
                                                }
                                                return
                                            }

                                            if (!isSamePlanThanCurrent || !isGreaterOrEqualsToCurrent) return
                                            return <CiberMondayPlanCardPromotion isRecommended={isAnualPlan} periodicity={periodicity as PlanFrequency} plan={plan} key={plan.suscriptionInfo.idSuscription} />
                                        })
                                    })
                                }
                            </ContainerItem>
                        </CustomTitle>
                    </Container>
                </Card>
            </ContainerItem>
        </Container>
    )
}
