import { useMutation } from "@tanstack/react-query";
import { updatePromotionState } from "src/services/promotionService";

export function useUpdatePromotionState() {
	return useMutation({
		mutationFn: updatePromotionState,
		onError: () => console.log("Skip-able promotion not disabled"),
		onSuccess: () => console.log("Skip-able promotion disabled"),
	});
}
