import { useHistory } from "react-router-dom";
import {
  ToolBlockedText,
  ToolQuickAccess,
  ToolContainer,
  ToolName,
  ToolsList,
  ToolsListContent,
} from "./tools.styles";

import axios from "axios";
import { useState } from "react";
import { tourData } from "../../../components/tour/tour.data";
import { useAppSelector } from "../../../hooks/useRedux";
import { ActiveCampaignService } from "../../../services/activeCampaignService";
import { ToastService } from "../../../services/toastService";
import { toolsDataMock } from "./tools.data";
import { GlobalPopup } from "src/components/globalPopup/globalPopup.component";
import CrispChatBox from "src/components/crispChatBox/crispChatBox";
import { useMobileScreen } from "src/hooks/useMobileScreen";


export const ToolsQuickAccess = () => {
  const tourIndex = useAppSelector((state) => state.tour.i);
  const tourCard = tourData[tourIndex];
  const history = useHistory();
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const activeCampaignService = new ActiveCampaignService();
  const toastService = new ToastService();
  const mobileScreen = useMobileScreen();
  const [popUpValidationEmail, setPopUpValidationEmail] = useState(false);
  const [activateCrisp, setActivateCrisp] = useState<boolean>(false);

  const handleQuickAccess = async (toolSlug: string) => {
    switch (toolSlug) {
      case "GENERADOR-DE-COPY":
        history.push("/copy");
        break;

      case "FUNNELS":
        history.push("/funnels");
        break;

      case "TIENDA":
        history.push("/shop");
        break;

      case "CRM":
        history.push("/crm");
        break;

      case "CONNECTOOL":
        history.push("/connect");
        break;

      case "EMAIL-MARKETING":
        const toolsInfoActive = dataUser.toolsInfo.find(
          (tool) => tool.name === "Active"
        );

        const geoData = await axios.get("https://d1qh9l1lloiwpp.cloudfront.net/ipInfo");

        if (toolsInfoActive) {
          let user;
          toolsInfoActive.dataConfig.payload ? (user = {
            host: `https://${toolsInfoActive.dataConfig.payload.account}/`,
            user: toolsInfoActive.dataConfig.payload.username,
            pass: toolsInfoActive.dataConfig.payload.password,
            ip: geoData.data.ip,
          }) : (user = {
            host: `https://${toolsInfoActive.dataConfig.account}/`,
            user: toolsInfoActive.dataConfig.username,
            pass: toolsInfoActive.dataConfig.password,
            ip: geoData.data.ip,
          });

          try {
            const data = await activeCampaignService.activeCampaignSignIn(user);
            const url = data.url_login as string;
            sessionStorage.setItem("joinToActiveCampaign", "1");
            window.location.href = url;
          } catch (error) {
            toastService.error("Ha ocurrido un error con la herramienta Email");
          }
        } else if (dataUser.existPaytoolLogic) {
          history.push("/profile#my-subscriptions");
        } else {
          setPopUpValidationEmail(true);
        }

        break;

      case "LINK-ANALYTICS":
        history.push("/linktool");
        break;
    }
  };

  const handleCrispBubble = () => {
    setActivateCrisp(true);
    setPopUpValidationEmail(false);
  };

  return (
    <>
      {activateCrisp && !mobileScreen ? (
        <CrispChatBox
          autoOpen
          message="Hola, estoy interesado en migrar mi herramienta de Email antigua a la nueva versión."
        />
      ) : null}

      {popUpValidationEmail && (
        <GlobalPopup
          title="HERRAMIENTA VERSIÓN ANTIGUA"
          description={`Estás utilizando la versión antigua de la herramienta de Email, esta herramienta va a desaparecer próximamente, te recomendamos hablar con nuestro equipo de soporte para que te ayuden a pasarte a nuestra nueva herramienta.`}
          isOpen={popUpValidationEmail}
          showPopup={setPopUpValidationEmail}
          buttons={[
            {
              text: "Quiero conocer la nueva herramienta",
              handler: handleCrispBubble,
            },
            {
              text: "Ingresar a la versión antigua",
              handler: () => {
                history.push("/email/initial/version");
              },
            },
          ]}
        />
      )}

      <ToolsList
        style={{
          zIndex: tourCard.title === "CONOCE LAS HERRAMIENTAS" ? 204 : "unset",
        }}
        mobileTools
      >
        <ToolsListContent>
          {toolsDataMock.map((tool) => {
            return (
              <ToolContainer
                key={tool.name}
                onClick={() => tool.available && handleQuickAccess(tool.slug)}
              >
                <ToolBlockedText
                  id="toolBlocked"
                  style={{ display: tool.available ? "none" : "block" }}
                >
                  Próximamente
                </ToolBlockedText>
                <ToolQuickAccess
                  id="toolButton"
                  src={tool.urlImage}
                  available={tool.available}
                ></ToolQuickAccess>
                <ToolName fontSize={"14px"} id="toolName">
                  {tool.name}
                </ToolName>
              </ToolContainer>
            );
          })}
        </ToolsListContent>
      </ToolsList>
    </>
  );
};
