import { useState } from "react";
import { ToggleButtonContainer, Button } from "./toggleButton.styles";

type props = {
  isActive?: boolean;
  permanent?: boolean;
  action?: Function;
};

export const ToggleButton = ({
  isActive = false,
  permanent = false,
  action
}: props) => {
  const [active, setActive] = useState(isActive);

  const toggleButton = () => {
    !permanent && setActive(!active);
    action && action();
  };

  return (
    <ToggleButtonContainer
      permanent={permanent}
      active={active}
      onClick={toggleButton}
    >
      <Button active={active} />
    </ToggleButtonContainer>
  );
};
