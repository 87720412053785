import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

type InputFieldProps = {
  backgroundColor?: string;
  color?: string;
  darkMode?: boolean;
};

export const InputField = styled.input<InputFieldProps>`
  border: none;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  color: ${(props) => props.color ? props.color : "black"};
  outline: none;
  width: 100%;
  margin-left: 8px;
  font-size: ${globalstyles.fontSize.inputFontSize};
  font-family: "Montserrat" !important;

  &::placeholder {
    color: ${(props) => props.color !== "black" ? "white" : "gray"};
    font-weight: 200;
  }
  &[readonly] {
    color: #A5A5A5;
  }
`;

interface InputContainerProps {
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  padding?: string;
  theme?: "dark" | "light" | "darkMode";
  focus?: boolean;
  readOnly?: boolean;
  responsiveLg?: string;
  responsiveXl?: string;
}

export const InputContainer = styled.div<InputContainerProps>`
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};
  border-radius: ${globalstyles.borders.inputBorders};
  border: ${(props) =>
    props.focus && !props.readOnly
      ? "1px solid gray"
      : `1px solid ${props.borderColor ? props.borderColor : "black"}`};
  padding: ${(props) => props.padding || "7px 8px"};
  font-size: 17px;
  width: ${(props) => (props.width ? props.width : "-webkit-fill-available")};
  ${(props) => props.height && `height: ${props.height};`}
  min-height: 25px;
  margin: 4px 0px 4px 0px;
  transition: ${globalstyles.animations.low};
  display: flex;

  @media only screen and (min-width: ${globalstyles.sizes.lg}) {
    ${(props: InputContainerProps) => props.responsiveLg && props.responsiveLg}
  }
  @media only screen and (min-width: ${globalstyles.sizes.large}) {
    ${(props: InputContainerProps) => props.responsiveXl && props.responsiveXl}
  }
`;

export const ClosedEyeIcon = styled(AiOutlineEyeInvisible).attrs((_props) => ({
  size: 24,
}))`
  justify-self: flex-end;
  cursor: pointer;
`;

export const EyeIcon = styled(AiOutlineEye).attrs((_props) => ({
  size: 24,
}))`
  justify-self: flex-end;
  cursor: pointer;
`;
