import {CloseableModal, CloseableModalProps} from "../../../../../components/ui/modal/closeable-modal.component";
import {ImageSettingButton, ImageUploadButton} from "../profileImage.styles";
import {RiCameraLine, RiDeleteBinLine, RiUploadLine} from "react-icons/ri";
import {ChangeEvent, useEffect, useReducer, useRef, useState} from "react";
import {AuthService} from "../../../../../services/authService";
import {ToastService} from "../../../../../services/toastService";
import {useAppSelector} from "../../../../../hooks/useRedux";
import {ConfirmButton, Container} from "../../../../../styles/globalcomponents";
import {Webcam, WebcamState, webcamStateReducer} from "./webcam.component";
import {DEFAULT_PHOTO} from "../profileImage.component";
import {CheckIcon} from "../../../../../components/icons";
import {DeleteImageModalComponent} from "./deleteImageModal.component";


const INITIAL_WEBCAM_STATE: WebcamState = {
    idle: true,
    startCamera: false,
    preview: false,
    takePhoto: false,
    status: 'idle',
};

interface Props extends Omit<CloseableModalProps, 'children'> {
    setImageLoading: () => void
}

const toastService = new ToastService();
export function UpdateImageModalComponent({isOpen, closeModal}: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const [srcPhoto, setSrcPhoto] = useState(dataUser.url_image ? dataUser.url_image : DEFAULT_PHOTO)
    const [image, setImage] = useState<File | null>(null);
    const inputFileRef = useRef<HTMLInputElement>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(true)
    const [webcamState, webcamDispatch] = useReducer(webcamStateReducer, INITIAL_WEBCAM_STATE);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)

    function setTookImage(imageFile: File) {
        setImage(imageFile)
        setIsDisabled(false)
    }

    function handleOpenDeleteModal() {
        closeModal()
        setIsOpenDeleteModal(true);
    }

    function handleCloseDeleteModal() {
        setIsOpenDeleteModal(false)
    }

    async function deleteProfilePhoto() {
        const authService = new AuthService();

        try {
            await authService.deleteProfilePhoto(dataUser.id);
            toastService.success("Su foto se eliminó correctamente");
            setTimeout(() => {
                window.location.reload()
            }, 2000)
        } catch (error) {
            closeModal()
            toastService.error("No se pudo eliminar su foto, intente más tarde");
        }
    }

    async function uploadProfilePhoto() {
        const authService = new AuthService();
        const formData = new FormData();

        if (image) {
            formData.append("img", image);
            formData.append("idUser", dataUser.id);

            try {
                await authService.uploadProfilePhoto(formData);
                closeModal()
                toastService.success("Su foto se cambió correctamente");
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            } catch (error) {
                closeModal()
                toastService.error("No se pudo subir su foto, intente más tarde");
            }
        }
    }

    function onFileChange(event: ChangeEvent<HTMLInputElement>) {
        const files = event.target.files
        if (files !== null && files.length > 0) {
            const file = files[0];
            setImage(file);
            setSrcPhoto(URL.createObjectURL(file))
            setIsDisabled(false)
            toastService.success("Foto subida con éxito, ¡Lista para guardar!");
        }
    }

    function handleCloseWebcamCleanup() {
        const files = inputFileRef.current!.files
        const hasFiles = files !== null && files.length > 0
        const dataTransfer = new DataTransfer()
        if(hasFiles) {
            // Keep empty the file object when modal is closed
            inputFileRef.current!.files = dataTransfer.files
        }
        webcamDispatch({type: 'idle'})
        setImage(null)
        setIsDisabled(true)
        setSrcPhoto("")
    }

    useEffect(()=> {
        setSrcPhoto(dataUser.url_image ? dataUser.url_image : DEFAULT_PHOTO)
    }, [isOpen])

    function webcamActionToRender() {
        switch (webcamState.status) {
            case 'idle':
                return (
                    <ImageSettingButton onClick={() => webcamDispatch({ type: 'start_camera' })}>
                        <RiCameraLine size={16} color="#CECECE" />
                        Iniciar cámara
                    </ImageSettingButton>
                );
            case 'start_camera':
                return (
                    <ImageSettingButton onClick={() => webcamDispatch({ type: 'shoot_camera' })}>
                        <RiCameraLine size={16} color="#CECECE" />
                        Tomar
                    </ImageSettingButton>
                );
            case 'shooted':
                return (
                    <ImageSettingButton onClick={() => webcamDispatch({ type: 'start_camera' })}>
                        <RiCameraLine size={16} color="#CECECE" />
                        De nuevo
                    </ImageSettingButton>
                );
        }
    }

    return (
        <>
            <CloseableModal isOpen={isOpen} closeModal={closeModal} onCloseCleanup={handleCloseWebcamCleanup}>
                <Container
                    flexDirection="column"
                >
                    <Webcam
                        state={webcamState}
                        photoSrc={srcPhoto}
                        setTookImage={setTookImage}
                    />
                    <Container
                        justifyContent="space-between"
                        margin="2.5rem 0 1.5rem"
                        responsive={"gap: 1rem"}
                        gap={"1rem"}
                    >
                        <ImageUploadButton>
                            <input
                                accept="image/png, image/jpg, image/jpeg, image/gif"
                                onChange={onFileChange}
                                ref={inputFileRef}
                                style={{ display: "none" }}
                                type="file"
                            />
                            <RiUploadLine size={16} color="#CECECE" />
                            Subir
                        </ImageUploadButton>
                        {
                            webcamActionToRender()
                        }
                        <ImageSettingButton onClick={handleOpenDeleteModal}>
                            <RiDeleteBinLine size={16} color="#CECECE" />
                            Eliminar
                        </ImageSettingButton>
                    </Container>
                    <ConfirmButton onClick={uploadProfilePhoto} disabled={isDisabled}>
                        <CheckIcon/>
                        Guardar
                    </ConfirmButton>
                </Container>
            </CloseableModal>
            <DeleteImageModalComponent isOpen={isOpenDeleteModal} closeModal={handleCloseDeleteModal} handleDeleteImage={deleteProfilePhoto} />
        </>
    )
}
