import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

interface LoginOptionProps {
  color?: string;
  fontWeight?: number;
  margin?: string;
}

export const LoginLink = styled.span<LoginOptionProps>`
  margin: ${(props) => (props.margin ? props.margin : "0 8px")};
  font-size: 12px;
  font-weight: 700;
  color: ${globalstyles.colors.primary};
  display: inline;
  cursor: pointer;
  transition: 0.2s;

  & > a {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }

  &:hover {
    opacity: .9;
  }
`;

interface TextButtonProps {
  justifyContent?: string;
  margin?: string;
}

export const TextButton = styled.div<TextButtonProps>`
  align-items: center;
  border-radius: 10px;
  padding: 1rem;
  color: white;
  font-size: 12px;
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "center"};
  margin: ${(props) => (props.margin ? props.margin : "unset")};
  text-align: center;
  background-color: white;


  & > a {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
`;

interface LoginBackgroundProps {
  backgroundUrl: string
}

export const LoginBackground = styled.main`
  height: 100vh;
`

export const LoginContainer = styled.div`
  height: 100%;
  
  @media screen and (min-width: ${globalstyles.sizes.lg}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
  }

`

export const LoginForm = styled.div<LoginBackgroundProps>`
  background-image: url(${(props) => props.backgroundUrl});
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0 0 0 auto;
  background-repeat: no-repeat;
  
  & > section {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    max-width: 30rem;
    margin: 0 auto;
  }
  

  & #input-container {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid ${globalstyles.colors.gray};
    color: white;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      display: block;
      bottom: -2px;
      right: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: white;
      transition: width 250ms ease-in-out;
    }

    &:has(#input-field:focus)::before {
      width: 100%;
    }
  }

  & #input-field {
    color: white;
    font-size: 14px;
    font-weight: 800;
    font-family: inherit;
    
    &::placeholder {
      color: white;
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media screen and (min-width: ${globalstyles.sizes.lg}) {
    padding: 1rem 3rem .5rem 8rem;
    background-position: initial;
    margin: 0 0 0 auto;
    width: 100%;
  }

`
export const LoginFormTitle = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  color: white;
  margin: 0 auto 2rem;
  width: fit-content;
  height: 11rem;
  
  & h6 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
  }
  
  & h2 {
    font-size: 3rem;
    margin: 0;
    font-weight: 800;
  }
  
  & img {
    position: absolute;
    top: 0;
    left: 4rem;
  }

  @media screen and (min-width: ${globalstyles.sizes.lg}) {
    margin-left: 0;
  }

`

export const LoginSubmitButton = styled.button`
  color: ${globalstyles.colors.primary};
  background-color: white;
  appearance: none;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 700;
  padding: .7rem 1.3rem;
  width: auto;
  cursor: pointer;
  
  &:hover {
    opacity: .9;
  }
  
  &[disabled] {
    cursor: not-allowed;
  }

`
