import {useEffect, useRef} from 'react';
import {ImageProfile} from "../profileImage.styles";
import {CanvasPreviewStyled, PhotoPreviewStyled} from "./imagePreview.styles";
import {ToastService} from "../../../../../services/toastService";

export type WebcamState = {
    idle: boolean;
    startCamera: boolean;
    preview: boolean;
    takePhoto: boolean;
    status: 'start_camera' | 'idle' | 'preview' | 'shooted';
};

export type WebcamActionType =
    | { type: 'start_camera' }
    | { type: 'shoot_camera' }
    | { type: 'idle' }
    | { type: 'preview' };

export function webcamStateReducer(
    state: WebcamState,
    { type }: WebcamActionType
): WebcamState {
    switch (type) {
        case 'start_camera':
            return {
                ...state,
                startCamera: true,
                takePhoto: false,
                preview: true,
                status: 'start_camera',
            };
        case 'shoot_camera':
            return {
                startCamera: false,
                takePhoto: true,
                preview: false,
                idle: false,
                status: 'shooted',
            };
        case 'preview':
            return {
                startCamera: false,
                takePhoto: false,
                preview: true,
                idle: false,
                status: 'preview',
            };
        case 'idle':
            return {
                startCamera: false,
                takePhoto: false,
                preview: false,
                idle: true,
                status: 'idle',
            };
        default:
            return state;
    }
}

interface Props {
    state: WebcamState;
    photoSrc: string;
    setTookImage: (imageFile: File) => void
}

const toastService = new ToastService()

export function Webcam({ state, photoSrc, setTookImage }: Props) {
    const videoPreviewRef = useRef<HTMLVideoElement>(null);
    const photoCanvasRef = useRef<HTMLCanvasElement>(null);
    let videoStream = useRef<MediaStream>();

    async function handleStartCamera() {
        const constrains: MediaStreamConstraints = {
            audio: false,
            video: {
                width: 300,
                height: 300,
            },
        };
        videoStream.current = await navigator.mediaDevices.getUserMedia(constrains)
        videoPreviewRef.current!.srcObject = videoStream.current;
    }

    async function handleStopCamera() {
        videoStream.current?.getTracks().forEach(track => track.stop())
    }

    function handleTakePhoto() {
        try {
            if (videoPreviewRef.current === null || photoCanvasRef.current === null) {
                throw Error('Image not taken, video or canvas undefined');
            }

            const { width, height } = photoCanvasRef.current;
            photoCanvasRef
                .current!.getContext('2d')
                ?.drawImage(videoPreviewRef.current, 0, 0, width, height);

            const imageEncodedBase64 = photoCanvasRef.current!.toDataURL('image/jpeg')
            const imageBase64Data: any = imageEncodedBase64.split(",")
            const mimeType = imageBase64Data[0].match(/:(.*?);/)[1]
            const imageDecoded = atob(imageBase64Data[1])
            const dataArr = new Uint8Array(imageDecoded.length)
            let index = imageDecoded.length

            while (index--) {
                dataArr[index] = imageDecoded.charCodeAt(index)
            }
            const imageFile = new File([dataArr], "photo", { type: mimeType})
            setTookImage(imageFile)
        }catch (error) {
            toastService.error('Image not taken, please refresh the page')
        }
    }

    useEffect(() => {
        if (state.startCamera) {
            handleStartCamera();
        } else if (state.takePhoto) {
            handleTakePhoto();
        } else {
            handleStopCamera()
        }
    }, [state]);

    if (state.preview || state.takePhoto) {
        return (
            <>
                <PhotoPreviewStyled
                    ref={videoPreviewRef}
                    autoPlay
                    width="300px"
                    height="300px"
                    className={`${state.preview ? '' : 'hidden'}`}
                ></PhotoPreviewStyled>
                <CanvasPreviewStyled
                    ref={photoCanvasRef}
                    className={`photo-taken ${!state.preview ? '' : 'hidden'}`}
                    width="300px"
                    height="300px"
                ></CanvasPreviewStyled>
            </>
        );
    }

    return (
        <>
            <ImageProfile className={"webcam"} src={photoSrc} width="300px" height="300px" />
        </>
    );
}
