import {TextLayerStyles} from "./textLayer.styles";
import {ReactNode} from "react";

interface TextLayerProps {
    children: ReactNode
    showLayer: boolean
}

export function TextLayer({children, showLayer}: TextLayerProps) {

    if(!showLayer) {
        return <></>
    }

    return (
        <TextLayerStyles>
            {children}
        </TextLayerStyles>
    )
}
