import {CloseableModal, CloseableModalProps} from "../../../../../../components/ui/modal/closeable-modal.component";
import {SuccessIcon} from "../../../../../../components/icons";
import {ConfirmButton, Container} from "../../../../../../styles/globalcomponents";
import {useHistory} from "react-router-dom";
import {ToastService} from "../../../../../../services/toastService";
import {useAppDispatch} from "../../../../../../hooks/useRedux";
import {resetCheckoutState} from "../../../../../../redux/slices/checkout";

interface FinishPurchaseModalProps extends Omit<CloseableModalProps, "children">{
}

const toastService = new ToastService()
export function FinishPurchaseModal({ closeModal, isOpen }: FinishPurchaseModalProps) {
    const history = useHistory()
    const dispatcher = useAppDispatch()
    function handleCloseModal() {
        toastService.warning("Redirigiendo al perfil...")
        setTimeout(() => {
            closeModal()
            history.push("/profile#account")
            dispatcher(resetCheckoutState())
        }, 3000)
    }

    return (
        <CloseableModal isOpen={isOpen} closeModal={closeModal}>
            <Container flexDirection={"column"} alignItems={"center"} maxWidth={"25rem"}>
                <SuccessIcon width={"100px"} height={"100px"} viewBox={"0 0 110 110"}/>
                <h2 className={"text-center"}>
                   ¡Todo en orden!
                </h2>
                <p className="text-center">
                   Puedes estar tranquilo/a tu transacción ha llegado con éxito a su destino!
                </p>
                <ConfirmButton onClick={handleCloseModal} className={"mt-2"}>
                    ¡Qué bien!
                </ConfirmButton>
            </Container>
        </CloseableModal>
    )
}
