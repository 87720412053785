import {Container, ContainerItem, PageTitleStyled} from "../../styles/globalcomponents";
import {ArrowIcon} from "../../components/icons";
import {useHistory} from "react-router-dom";
import {SwitchButton} from "../../components/switchButton/switchButton.component";
import {CardSwitcher} from "./subcomponents/cardSwitcher/cardSwitcher.component";
import {ComposedStatsCharts} from "./subcomponents/graphic/statsComposedCharts.component";
import {OrdersDetailedInfo} from "./subcomponents/ordersDetailedInfo/ordersDetailedInfo.component";
import {useState} from "react";
import {StatsFilter} from "./subcomponents/statsFilter/statsFilter.component";
import {DateFilter} from "../../interfaces/stats";
import {INITIAL_DATE_FILTER, SWITCH_PAID_OPTIONS} from "./consts";
import {QuickAccessButton} from "./subcomponents/quickAccessButton.component.tsx/quickAccessButton.component";
import {useUserStats} from "../../hooks/useStats";
import {ToastService} from "../../services/toastService";

const toastService = new ToastService()

export function StatsPage() {
    const history = useHistory()
    const [showMonetaryInfo, setShowMonetaryInfo] = useState(true)
    const [switchPaidOptions, setSwitchPaidOptions] = useState(SWITCH_PAID_OPTIONS)
    const isPaidOrders = switchPaidOptions.find((option) => option.index === 1)?.active
    const [dateToFilter, setDateToFiler] = useState<DateFilter>(INITIAL_DATE_FILTER)
    const {isLoading, isError, data: ordersData} = useUserStats(dateToFilter.startDate, dateToFilter.finalDate)
    const chartData = getChartData()

    function getChartData() {
        if(isPaidOrders) {
            return showMonetaryInfo ? ordersData?.paid?.chartData?.monetaryData : ordersData?.paid?.chartData?.statesData
        }
        if(!isPaidOrders) {
            return showMonetaryInfo ? ordersData?.noPaid?.chartData?.monetaryData : ordersData?.noPaid?.chartData?.statesData
        }
    }

    function updateSwitchPaidOrNoPaidOption(index: number) {
        setSwitchPaidOptions(prev => {
            const indexToUpdate = prev.findIndex((option) => option.index === index)
            prev = prev.map(option => ({...option, active: false}))
            prev[indexToUpdate] = {...prev[indexToUpdate], active: true}
            return  prev
        })
    }

    function updateValueToFiler(value: any) {
        setDateToFiler(value)
    }

    function switchCardOption() {
        setShowMonetaryInfo(prev => !prev)
    }

    function handleBackToHome() {
        history.push("/home")
    }

    function handleRedirectToToolDashboard() {
        history.push("/shop")
    }

    if(isError) {
        toastService.warning("No se encontraron datos 🧐")
    }

    return (
        <Container width={"85%"} responsiveLg={"max-width: 1400px; margin: 0 auto;"} flexDirection={"column"} padding={"4.5rem 0 0"}>
            <Container flexDirection={"column"} margin="0 1.5rem" width={"auto"}>
                <ContainerItem flexDirection={"row"} alignItems={"center"}>
                    <ArrowIcon direction={'left'} className={"mr-1/2 pointer"} onClick={handleBackToHome}/><PageTitleStyled>Panel de Control de Herramientas</PageTitleStyled>
                </ContainerItem>
                <small className={"mt-1/2"}>En este panel encontrarás el resumen de tus herramientas de MasterTools.</small>
            </Container>
            <Container flexDirection={"column"} margin="1.5rem auto 0" justifyContent="center" padding={"0 0 1rem"} gap={"1rem"} flexWrap={"wrap"}>
                <ContainerItem width="100%" gap={"1rem"} justifyContent={"space-between"} flexDirection={"column"} alignItems={"center"} responsiveLg={"flex-direction: row"}>
                    {/*Filters*/}
                    <SwitchButton initialOptions={switchPaidOptions} updateSwitchOption={updateSwitchPaidOrNoPaidOption}/>
                    <Container width={"auto"} justifyContent={"flex-end"} margin={"0"} gap={"1rem"} alignItems={"center"}>
                        <StatsFilter setValueToFilter={updateValueToFiler}/>
                        <QuickAccessButton onClick={handleRedirectToToolDashboard}/>
                    </Container>
                </ContainerItem>
                <Container width="100%" gap={"1rem"}>
                    {/*Stats panels*/}
                    <ContainerItem width={"70%"}>
                        <CardSwitcher ordersData={isPaidOrders ? ordersData?.paid : ordersData?.noPaid} switchOption={switchCardOption} initialState={showMonetaryInfo} />
                        <ComposedStatsCharts
                            isLoading={isLoading}
                            data={chartData}
                            showMonetaryInfo={showMonetaryInfo}
                        />
                    </ContainerItem>
                    <ContainerItem width={"30%"}>
                        <OrdersDetailedInfo ordersData={isPaidOrders ? ordersData?.paid : ordersData?.noPaid} />
                    </ContainerItem>
                </Container>
            </Container>
        </Container>
    )
}

