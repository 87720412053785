import styled from "styled-components";

type IconContainerProps = {
  width?: string;
}

export const IconContainer = styled.div<IconContainerProps>`
  width: ${props => props.width || "100%"};
  display: flex;
  aspect-ratio: 1;
`

export const Icon = styled.img`
  width: 100%;
  z-index: 100;
`
