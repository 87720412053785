import isAfter from "date-fns/isAfter";
import { Route } from "react-router";
import { Redirect, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/useRedux";
import { useQuery } from "src/hooks/useQuery";

type props = {
  path: string | string[];
  comp: React.FC<any>;
  exact?: boolean;
};

export const AppAuth = ({ path, comp, exact }: props) => {
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const isLogged = dataUser.stateUser === 1 || dataUser.stateUser === 2;
  const location = useLocation();
  const pathname = location.pathname;
  const expirationDate = useAppSelector((state) => state.auth.dataUser.suscription.expired_time);
  const expiredPlan = expirationDate && isAfter(new Date(), new Date(expirationDate));
  const queryParams = useQuery()

  const checkRoutes = (routes: string[]) => {
    return routes.some(route => pathname.includes(route));
  };

  const checkIsUnloggedRoute = () => {
    const routes = ["/begin", "/freeTrial", `/login`];
    return checkRoutes(routes);
  };

  const checkIsPublicRoute = () => {
    const routes = ["/resetPassword"];
    return checkRoutes(routes);
  };

  const expiredPlanRoute = () => {
    const routes = ["/plans", "/profile", "/checkout", "/support"];
    return checkRoutes(routes);
  };

  if (expiredPlan && !expiredPlanRoute()) {
    return <Redirect to="/profile#my-subscriptions" />;
  }

  if (checkIsUnloggedRoute() && isLogged) {
    if (queryParams.has("redirectUrl")) {
      const redirectionUrl = `${queryParams.get("redirectUrl")}${location.hash}`
      return <Redirect to={ redirectionUrl } />;
    }
    return <Redirect to="/home" />;
  }

  if (
    checkIsUnloggedRoute() ||
    (!checkIsUnloggedRoute() && isLogged) ||
    checkIsPublicRoute() ||
    (expiredPlan && expiredPlanRoute())
  ) {
    return <Route exact={exact} path={path} component={comp} />;
  } else {
    const redirectUrl = `${location.pathname}${location.hash}`
    return <Redirect to={`/login?redirectUrl=${redirectUrl}`} />;
  }
};
