import { useEffect, useState } from "react";
import { config } from "src/config";
import { useAppDispatch, useAppSelector } from "src/hooks/useRedux";
import { setShowPromotion } from "src/redux/slices/session";
import { SkippablePromotion } from "src/components/skippablePromotion/skippablePromotion.component";
import { Card, Container, ContainerItem, CustomText, CustomTitle } from "../../styles/globalcomponents";
import {
    BottomSection,
    ChartImage,
    LeftContainer,
    MainHomeContainer, QuickAccessSubscriptionButton,
    RightContainer, StatItem, StatItemLegendColor, StatsList,
} from "./home.styles";
import { ToolsQuickAccess } from "./tools/tools.component";
import Reminder from "src/components/reminder/reminder.component";
import { getNotificationsThunk } from "src/redux/slices/notifications";
import { ArrowIcon, DocIcon, StatsIcon, TimeIcon, ToolIcon } from "../../components/icons";
import { getFormattedNumber } from "../../lib/numberFormat";
import { ActivityList } from "./recentActivity/activityList.component";
import { AccountStatusBar } from "./accountStatusBar/accountStatusBar.component";
import { Link, useHistory } from "react-router-dom";
import { useUserStats } from "../../hooks/useStats";
import { INITIAL_DATE_FILTER } from "../stats/consts";
import { TextLayer } from "../../components/ui/textLayer/textLayer.component";
import { useUpdatePromotionState } from "src/hooks/useUpdatePromotionState";


export function HomePage() {
    const history = useHistory()
    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const authenticated = dataUser.stateUser === 1 || dataUser.stateUser === 2;
    const { recentActivity } = useAppSelector((state) => state.notifications);
    const [showProm, setShowProm] = useState(false);
    const canShowPromotion = useAppSelector((state) => state.session.canShowPromotion);
    const dispatch = useAppDispatch();
    const userPromotion = useAppSelector((state) => state.auth.dataUser.promotionInfo);
    const { data: ordersData } = useUserStats(INITIAL_DATE_FILTER.startDate, INITIAL_DATE_FILTER.finalDate)
    const { mutateAsync: updatePromotionState } = useUpdatePromotionState()
    const paidOrdersTotalSales = ordersData?.paid?.totalSales ?? 0
    const paidOrdersCount = ordersData?.paid?.length ?? 0
    const haveOrdersToShow = paidOrdersCount > 0
    const haveRecentActivityList = (recentActivity?.length) > 0

    function handleOpenModal() {
        return dispatch(setShowPromotion(false))
    }

    async function handleCloseModal() {
        setShowProm(false);
        if (!userPromotion.idPromotion) return console.error("No se encontró la promoción")
        await updatePromotionState(userPromotion.idPromotion)
    }

    useEffect(() => {
        if (userPromotion?.promotionState) {
            if (!canShowPromotion) {
                dispatch(setShowPromotion(true));
                setShowProm(true);
            }
        }
    }, [canShowPromotion]);

    useEffect(() => {
        dispatch(getNotificationsThunk(Number(dataUser.id)));
    }, []);

    return (
        <>
            {userPromotion?.promotionState ? <Reminder handleClick={handleOpenModal} /> : null}
            {authenticated && showProm && (
                <SkippablePromotion
                    closeAction={handleCloseModal}
                    urlPromotion={userPromotion.promotionUrl}
                />
            )}
            <MainHomeContainer>
                <Container
                    margin={"1rem 0"}
                    flexDirection={"column"}
                    justifyContent={"space-between"}
                    width={"100%"}
                    gap={"1rem"}
                    responsiveLg={"flex-direction: row"}
                >
                    <ContainerItem width={"100%"} responsiveLg={"width: 55%"}>
                        <AccountStatusBar />
                    </ContainerItem>
                    <ContainerItem width={"100%"} responsiveLg={"width: auto"} alignItems={"center"} justifyContent={"center"}>
                        <QuickAccessSubscriptionButton onClick={() => history.push("/profile#my-subscriptions")}>
                            <DocIcon className={"mr-1/2"} /> Mi suscripción
                        </QuickAccessSubscriptionButton>
                    </ContainerItem>
                </Container>
                <BottomSection>
                    <LeftContainer>
                        <Card padding={"1rem 2rem 2rem"} height={"100%"}>
                            <Container alignItems={"center"}>
                                <ToolIcon className={"mr-1/2"} />
                                <CustomTitle
                                    fontSize={"17px"}
                                    fontWeight={"600"}
                                    textAlign="left"
                                >
                                    Mis herramientas
                                </CustomTitle>
                            </Container>
                            <ToolsQuickAccess />
                        </Card>
                    </LeftContainer>
                    <RightContainer>
                        <Card padding={"1rem 2rem"} position={"relative"}>
                            <TextLayer showLayer={!haveOrdersToShow}>
                                No hay datos para mostrar
                            </TextLayer>
                            <Container width={"100%"}>
                                <ContainerItem flexDirection={"row"} alignItems={"center"}>
                                    <StatsIcon className={"mr-1/2"} />
                                    <CustomTitle
                                        fontSize={"17px"}
                                        fontWeight={"600"}
                                        textAlign="left"
                                    >
                                        Estadísticas
                                    </CustomTitle>
                                </ContainerItem>
                                <ContainerItem flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"}>
                                    <CustomText
                                        fontSize={"14px"}
                                        fontWeight={"400"}
                                    >
                                        <Link to={"/stats"}>Ver más</Link>
                                    </CustomText>
                                    <ArrowIcon className={"ml-1/4"} />
                                </ContainerItem>
                            </Container>
                            <Container gap={"1rem"}>
                                <ContainerItem width={"35%"}>
                                    <StatsList>
                                        <StatItem>
                                            <CustomText
                                                fontSize={"12px"}
                                                fontWeight={"400"}
                                                textAlign={"left"}
                                            >
                                                <StatItemLegendColor color={"#389DF9"} />Ventas totales
                                            </CustomText>
                                            <CustomText
                                                fontSize={"16px"}
                                                fontWeight={"800"}
                                                textAlign={"left"}
                                            >
                                                ${getFormattedNumber(paidOrdersTotalSales)}
                                            </CustomText>
                                        </StatItem>
                                        <StatItem>
                                            <CustomText
                                                fontSize={"12px"}
                                                fontWeight={"400"}
                                                textAlign={"left"}
                                            >
                                                <StatItemLegendColor color={"#00DFB0"} />N° Pedidos
                                            </CustomText>
                                            <CustomText
                                                fontSize={"16px"}
                                                fontWeight={"800"}
                                                textAlign={"left"}
                                            >
                                                {getFormattedNumber(paidOrdersCount)}
                                            </CustomText>
                                        </StatItem>
                                    </StatsList>
                                </ContainerItem>
                                <ContainerItem width={"65%"}>
                                    <ChartImage src={`${config.urls.res}/platform/chart/chart-2.png`} />
                                </ContainerItem>
                            </Container>
                        </Card>
                        <Card padding={"1rem 2rem"} position={"relative"}>
                            <TextLayer showLayer={!haveRecentActivityList}>
                                No hay datos para mostrar
                            </TextLayer>
                            <Container alignItems={"center"} responsive={"flex-direction: row"}>
                                <TimeIcon className={"mr-1/2"} />
                                <CustomTitle
                                    fontSize={"17px"}
                                    fontWeight={"600"}
                                    textAlign="left"
                                >
                                    Actividad Reciente
                                </CustomTitle>
                            </Container>
                            <ActivityList />
                        </Card>
                    </RightContainer>
                </BottomSection>
            </MainHomeContainer>
        </>
    );
}
