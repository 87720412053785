import {ReactNode} from "react";
import {CardActiveSelectorStyled, SelectableCardStyled} from "./cardSwitcher.styles";

interface SelectableCardProps {
    children: ReactNode
    active: boolean
}

export function SelectableCard({children, active}: SelectableCardProps) {
    return (
        <SelectableCardStyled active={active}>
            <CardActiveSelectorStyled active={active}/>
            {children}
        </SelectableCardStyled>
    )
}
