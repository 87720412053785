import {Container, ContainerItem, CustomText, SeparatorStyled} from "../../../../styles/globalcomponents";
import {getFormattedNumber} from "../../../../lib/numberFormat";
import {DeliveredOrdersIcon, OrdersIcon, PendingOrdersIcon, SalesIcon, StatsIcon} from "../../../../components/icons";
import {SelectableCard} from "./selectableCard.component";
import {useEffect, useState} from "react";
import {orderStates} from "../../consts";
import {OrderData} from "../../../../interfaces/stats";

interface CardSwitcherProps {
    ordersData: OrderData,
    switchOption: () => void
    initialState: boolean
}

export function CardSwitcher({ordersData, switchOption, initialState}: CardSwitcherProps) {
    const [isPriceStatsSelected, setIsPriceStatsSelected] = useState<boolean>(initialState ?? true)
    const pendingOrders = ordersData?.states?.find(item => item.name === orderStates.PENDING)
    const shippedOrders = ordersData?.states?.find(item => item.name === orderStates.SHIPPED)

    function handleCardClick(hasPriceStatsSelected: boolean) {
        setIsPriceStatsSelected(hasPriceStatsSelected)
        switchOption()
    }

    return (
        <Container gap={"1rem"}>
            <ContainerItem width={"40%"} onClick={() => handleCardClick(true)}  cursor={"pointer"}>
                <SelectableCard active={isPriceStatsSelected}>
                    <Container flexDirection={"row"}>
                        <ContainerItem flexDirection={"column"} padding={".5rem 1rem"} gap={".2rem"}>
                            <SalesIcon/>
                            <CustomText
                                fontSize={"12px"}
                                fontWeight={"400"}
                                height={"2.5rem"}
                            >
                                Ventas totales
                            </CustomText>
                            <CustomText
                                fontSize={"16px"}
                                fontWeight={"800"}
                                textAlign={"left"}
                            >
                                ${getFormattedNumber(ordersData?.totalSales ?? 0)}
                            </CustomText>
                        </ContainerItem>
                        <SeparatorStyled/>
                        <ContainerItem flexDirection={"column"} padding={".5rem 1rem"} gap={".2rem"}>
                            <StatsIcon/>
                            <CustomText
                                fontSize={"12px"}
                                fontWeight={"400"}
                                height={"2.5rem"}
                            >
                                Promedio de ventas
                            </CustomText>
                            <CustomText
                                fontSize={"16px"}
                                fontWeight={"800"}
                                textAlign={"left"}
                            >
                                ${getFormattedNumber(ordersData?.averageSales ?? 0)}
                            </CustomText>

                        </ContainerItem>
                    </Container>
                </SelectableCard>
            </ContainerItem>
            <ContainerItem width={"60%"} onClick={() => handleCardClick(false)} cursor={"pointer"}>
                <SelectableCard active={!isPriceStatsSelected}>
                    <Container flexDirection={"row"}>
                        <ContainerItem flexDirection={"column"} padding={".5rem 1rem"} gap={".2rem"}>
                            <OrdersIcon/>
                            <CustomText
                                fontSize={"12px"}
                                fontWeight={"400"}
                                height={"2.5rem"}
                            >
                                Número de Pedidos
                            </CustomText>
                            <CustomText
                                fontSize={"16px"}
                                fontWeight={"800"}
                                textAlign={"left"}
                            >
                                {getFormattedNumber(ordersData?.length ?? 0)}
                            </CustomText>
                        </ContainerItem>
                        <SeparatorStyled/>
                        <ContainerItem flexDirection={"column"} padding={".5rem 1rem"} gap={".2rem"}>
                            <PendingOrdersIcon/>
                            <CustomText
                                fontSize={"12px"}
                                fontWeight={"400"}
                                height={"2.5rem"}
                            >
                                Pedidos pendientes
                            </CustomText>
                            <CustomText
                                fontSize={"16px"}
                                fontWeight={"800"}
                                textAlign={"left"}
                            >
                                {getFormattedNumber(pendingOrders?.value ?? 0)}
                            </CustomText>
                        </ContainerItem>
                        <SeparatorStyled/>
                        <ContainerItem flexDirection={"column"} padding={".5rem 1rem"} gap={".2rem"}>
                            <DeliveredOrdersIcon/>
                            <CustomText
                                fontSize={"12px"}
                                fontWeight={"400"}
                                height={"2.5rem"}
                            >
                                Pedidos enviados
                            </CustomText>
                            <CustomText
                                fontSize={"16px"}
                                fontWeight={"800"}
                                textAlign={"left"}
                            >
                                {getFormattedNumber(shippedOrders?.value ?? 0)}
                            </CustomText>
                        </ContainerItem>
                    </Container>
                </SelectableCard>
            </ContainerItem>
        </Container>
    )
}
