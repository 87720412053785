import {
    InfoTooltipContent,
    InfoTooltipWrapper
} from "../../pages/profile/subscription/overview/toolCard/toolCard.styles";
import {ReactNode, useEffect, useRef} from "react";

interface props {
  children: ReactNode;
}

export const ExplainToolTip = ({ children }: props) => {

    const triggerRef = useRef<HTMLDivElement>(null)
    const tooltipRef = useRef<HTMLDivElement>(null)

    function getHorizontalSafeArea(x: number, width: number) {
        if((x + width) > window.innerWidth) return window.innerWidth - width - 10
        return x - width / 2
    }

    useEffect(() => {

        function onMouseEnter() {
            tooltipRef.current!.style.display = 'block'
            tooltipRef.current!.style.position = "fixed"
        }

        function onMouseMove(event: MouseEvent) {
            tooltipRef.current!.style.top = `${event.clientY - tooltipRef.current!.clientHeight - 13}px`
            tooltipRef.current!.style.left = `${getHorizontalSafeArea(event.clientX, tooltipRef.current!.clientWidth)}px`
        }

        function onMouseLeave() {
            tooltipRef.current!.style.position = 'none'
            tooltipRef.current!.style.display = 'none'
        }

        if(triggerRef.current) {
            triggerRef.current.addEventListener('mouseenter', onMouseEnter)
            triggerRef.current.addEventListener('mousemove', onMouseMove)
            triggerRef.current.addEventListener('mouseleave', onMouseLeave)
        }

        return () => {
            if(triggerRef.current) {
                triggerRef.current.removeEventListener('mouseenter', onMouseEnter)
                triggerRef.current.removeEventListener('mousemove', onMouseEnter)
                triggerRef.current.removeEventListener('mouseleave', onMouseEnter)
            }
        }
    }, [])


  return (
      <InfoTooltipWrapper ref={triggerRef}>
          <InfoTooltipContent ref={tooltipRef}>
              {children}
          </InfoTooltipContent>
      </InfoTooltipWrapper>
  );
};
