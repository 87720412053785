import { useEffect, useRef, useState } from "react";
import { tourData } from "../../components/tour/tour.data";
import { config } from "../../config";
import { useAppSelector } from "../../hooks/useRedux";
import { Event } from "../../interfaces/community";
import { ContentService } from "../../services/contentService";
import { ToastService } from "../../services/toastService";
import {
  BodyBackground,
  Card,
  Container,
  ContainerItem,
  Title,
} from "../../styles/globalcomponents";
import {
  ArrowLeft,
  ArrowRight,
  EventCard,
  EventCardContainer,
  EventsContainer,
  EventsList,
  FacebookGroupContainer,
  MonthTooler,
} from "./comunity.styles";
import { instance } from "../../axios/axiosConfig";

export const Community = () => {
  const tourIndex = useAppSelector((state) => state.tour.i);
  const tourCard = tourData[tourIndex];

  const scroll = useRef<HTMLDivElement>(null);

  const [data, setData] = useState<Event[]>();

  const [cardImg, setCardImg] = useState<{
    img: string;
    link: string;
    visible: number;
  }>();

  const setScroll = (sum: number) => {
    if (scroll.current) {
      scroll.current.scrollLeft += sum;
    }
  };

  useEffect(() => {
    (async () => {
      const contentService = new ContentService();
      const toastService = new ToastService();
      try {
        const response = await contentService.getEvents();
        setData(response);
      } catch (error) {
        toastService.error("Ha sucedido un error al traer los eventos");
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await instance.get(
        "/app/contentPlataform/content/classified"
      );

      setCardImg(response.data[0]);
    })();
  }, []);

  return (
    <BodyBackground backgroundColor="white">
      <Container
        width="80%"
        height="70vh"
        responsive="height: unset;"
        margin="16px auto"
        style={{
          position: "relative",
          zIndex: tourCard.title === "COMUNIDAD" ? 204 : "unset",
        }}
      >
        <ContainerItem width="70%" responsive="height:50vh">
          <Card height="100%">
            <Container height="20%">
              <Title>EVENTOS</Title>
            </Container>
            <Container width="100%" height="90%">
              <EventsContainer>
                <ArrowLeft
                  size={64}
                  color={"white"}
                  onClick={() => setScroll(-200)}
                />
                <EventsList ref={scroll}>
                  {data &&
                    data.map((event, i) => (
                      <EventCardContainer
                        key={i}
                        onClick={() => {
                          window.open(event.link, "_blank");
                        }}
                      >
                        <EventCard
                          style={{
                            backgroundImage: `url('${config.urls.res}/platform/events/${event.img}')`,
                          }}
                        />
                      </EventCardContainer>
                    ))}
                </EventsList>
                <ArrowRight
                  size={64}
                  color={"white"}
                  onClick={() => setScroll(200)}
                />
              </EventsContainer>
            </Container>
          </Card>
        </ContainerItem>
        <ContainerItem width="30%" responsive="height:70vh">
          <Container width="90%" height="100%" flexDirection="column">
            <ContainerItem height="40%" cursor="pointer">
              <FacebookGroupContainer
                onClick={() => {
                  window.open(config.urls.fbCommunity, "_blank");
                }}
              />
            </ContainerItem>
            {cardImg && cardImg.visible === 1 && (
              <ContainerItem height="60%" cursor="pointer">
                <MonthTooler
                  imgUrl={`${config.urls.res}/platform/events/${cardImg.img}`}
                  onClick={() => window.open(cardImg.link)}
                />
              </ContainerItem>
            )}
          </Container>
        </ContainerItem>
      </Container>
    </BodyBackground>
  );
};
