import { config } from "src/config";
import { useAppSelector } from "src/hooks/useRedux";
import type { Plan, PlanFrequency } from "src/interfaces/plans";
import { PlanSubtitleHighlighted, PlanTitle } from "src/pages/profile/account/account.styles";
import { BLACK_FRIDAY_COUPONS, CIBER_MONDAY_COUPONS } from "src/pages/profile/consts";
import { PlansService } from "src/services/plansService";
import { ConfirmButton, Container, CustomText } from "src/styles/globalcomponents";
import { PlanListItem } from "../../subscription.styles";
import { PlanCardStyled, PlanDescription, PlanHeader, PlanPriceNumber, PlanTool, PlanToolsContainer } from "../tabs/planSelectionTab/plans.styles";
import { PLANS_BANNERS, TOOLS_ICONS } from "../tabs/plans.data";

interface Props {
    plan: Plan
    periodicity: PlanFrequency
    isRecommended?: boolean
}

const plansService = new PlansService();

export function CiberMondayPlanCardPromotion({ plan, periodicity, isRecommended = false }: Props) {
    const subscriptionName = plan.suscriptionInfo.name
    const haveLitePlan = subscriptionName === "Lite"
    const haveBasicOrLitePlan = haveLitePlan || subscriptionName === "Basic";
    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const backgroundImage = PLANS_BANNERS[subscriptionName.toLowerCase()] ?? PLANS_BANNERS[0]
    const coupon = CIBER_MONDAY_COUPONS.find(it => it.name.includes(plan.suscriptionInfo.name) && it.name.includes(periodicity))
    const price = coupon ? coupon.price : 0

    const pricingPeriod = periodicity === 'Anual' ? "/ año" :
        periodicity === 'Semestral' ? "/ 6 meses" :
            periodicity === 'Trimestral' ? "/ 3 meses" :
                periodicity === 'Mensual' ? "/ mes" :
                    ""
    const pricingPerDay = periodicity === 'Anual' ? (Number(price) / 12 / 30).toFixed(2) :
        periodicity === 'Semestral' ? (Number(price) / 6 / 30).toFixed(2) :
            periodicity === 'Trimestral' ? (Number(price) / 3 / 30).toFixed(2) :
                periodicity === 'Mensual' ? (Number(price) / 30).toFixed(2) :
                    Number(price)
    const planCode = periodicity === 'Anual' ? plan.suscriptionInfo.codePlanAnualPaytool :
        periodicity === 'Semestral' ? plan.suscriptionInfo.codePlanSemiAnualPaytool :
            periodicity === 'Trimestral' ? plan.suscriptionInfo.codePlanTrimPayTool :
                periodicity === 'Mensual' ? plan.suscriptionInfo.codePlanPaytool :
                    ""

    async function handleSelectPlan() {
        const isStarterTrimestral = plan.suscriptionInfo.name === 'Starter' && periodicity === 'Trimestral'

        if (isStarterTrimestral) {
            window.open(`https://pay.mastershop.com/M4DC45?cpln=${planCode}&email=${dataUser.email}&coupon=${coupon?.idCoupon}&msp=cm24p&dcht=T7RIA7`, '_blank')
        } else {
            window.open(`https://pay.mastershop.com/M4DC45?cpln=${planCode}&email=${dataUser.email}&coupon=${coupon?.idCoupon}&msp=cm24p`, '_blank')
        }
    }

    return (<>
        <PlanCardStyled isRecommended={isRecommended} width={"20rem"}>
            <PlanHeader backgroundImage={backgroundImage}>
                <PlanTitle>
                    Plan {subscriptionName}
                </PlanTitle>
                <PlanSubtitleHighlighted>{periodicity}</PlanSubtitleHighlighted>
            </PlanHeader>
            <PlanDescription>
                <PlanToolsContainer>
                    {TOOLS_ICONS.map((toolIcon) => {
                        const isEmailToolImg = toolIcon === `${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`
                        const showEmailToolDependOnPlan = haveBasicOrLitePlan && isEmailToolImg
                        if (!showEmailToolDependOnPlan) {
                            return <PlanTool width="2rem" height="2rem" src={toolIcon} key={`tools-${toolIcon}`} />;
                        }
                    })}
                </PlanToolsContainer>
                <PlanDescription alignItems={"flex-start"} width={"100%"}>
                    <Container flexDirection="flex" margin="0rem 0 0.5rem">
                        <PlanPriceNumber style={{ fontSize: "1.5rem" }}>
                            ${price} USD
                        </PlanPriceNumber>
                        <small>{pricingPeriod}</small>
                    </Container>
                    <Container margin="0 0 .5rem">
                        <CustomText fontSize="0.6rem" fontWeight="300" lineHeight=".8rem" fontStyle="italic">Equivalente a <strong>${pricingPerDay} USD</strong> por día</CustomText>
                    </Container>
                    {
                        plan.limitsTool.slice(0, 3).map(features => <PlanListItem key={features.name}><strong>{features.value}</strong> / {features.name}</PlanListItem>)
                    }
                </PlanDescription>
                <ConfirmButton
                    onClick={handleSelectPlan}
                >
                    QUIERO ESTA OFERTA
                </ConfirmButton>
            </PlanDescription>
        </PlanCardStyled>
    </>);
}