import { connecToolInstance } from "src/axios/axiosConfig";
import {config} from "../config";

export class ConnecToolService {
    token: string = "";
    userId: string | number;

    constructor(userId: string | number) {
        this.userId = userId;
    }

    async setCredentials() {
        const response = await connecToolInstance.post("/auth/login", {
            idUser: Number(this.userId)
        });
        this.token = response.data.data.token
    }

    async createShopifyConfigurationToolRegistry({ urlApi, headerAccessValue}: {urlApi: string, headerAccessValue: string}) {
        const AUTH_BY_API_KEY_HEADER = 1
        const SHOPIFY_TOOL_ID = 20

        const body = {
            idTool: SHOPIFY_TOOL_ID,
            credentials: {
                urlApi: urlApi,
                headers: [
                    {
                        headerName: "X-Shopify-Access-Token",
                        headerValue: headerAccessValue
                    }
                ]
            },
            idAuthMethod: AUTH_BY_API_KEY_HEADER,
            name: "Shopify ConnecTool Integration"
        }

        if(this.token.length === 0) throw new Error("You need to setCredentials first to allow the request")

        const response = await connecToolInstance.post("/toolConfig/createToolConfig", body, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        });
        return response.data
    }

    async createGoogleConfigurationToolRegistry({ refreshToken }: {refreshToken: string}) {
        const AUTH_BY_BEARER_TOKEN = 3
        const GOOGLE_TOOL_ID = 5

        const body = {
            idTool: GOOGLE_TOOL_ID,
            credentials: {
                urlApi: "https://oauth2.googleapis.com",
                headers: [
                    {
                        headerName: "Content-Type",
                        headerValue: "application/x-www-form-urlencoded"
                    },
                    {
                        headerName: "client_id",
                        headerValue: config.connecttoolIntegrations.google.clientId
                    },
                    {
                        headerName: "client_secret",
                        headerValue: config.connecttoolIntegrations.google.clientSecret
                    },
                    {
                        headerName: "grant_type",
                        headerValue: "refresh_token"
                    },
                    {
                        headerName: "refresh_token",
                        headerValue: refreshToken
                    },
                    {
                        headerName: "redirect_uri",
                        headerValue: "https://app.mastertools.com/connect"
                    }
                ],
                clientAuthentication: "sendQueryParams"
            },
            idAuthMethod: AUTH_BY_BEARER_TOKEN,
            name: "Google ConnectTool Integration"
        }

        if(this.token.length === 0) throw new Error("You need to setCredentials first to allow the request")

        const response = await connecToolInstance.post("/toolConfig/createToolConfig", body, {
            headers: {
                Authorization: `Bearer ${this.token}`
            }
        });
        return response.data
    }
}
