import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { tourReducerInitialState } from "../../interfaces/redux";

const tourSlice = createSlice({
  name: "tour",
  initialState: tourReducerInitialState(),
  reducers: {
    goTour: (state, action: PayloadAction<number>) => {
      state.i += action.payload;
    },
    startTour: (state, action: PayloadAction<boolean>) => {
      state.onTour = action.payload;
    },
  },
});

export const { goTour, startTour } = tourSlice.actions;

export default tourSlice.reducer;
