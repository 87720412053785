import styled from 'styled-components'
import {globalstyles} from "../../styles/animations/globalstyles";

export const DropdownWrapperStyled = styled.div`
    position: relative;
    font-size: 13px;
`


export const DropdownTriggerStyled = styled.div`
    background-color: white;
    border-radius: 15px;
    padding: .5rem 1rem;
    user-select: none;
    cursor: pointer;
`

interface DropdownContentStyledProps {
    active: boolean
}
export const DropdownContentStyled = styled.div<DropdownContentStyledProps>`
    display: ${(props) => props.active ? 'flex' : 'none'};
    position: absolute;
    top: 110%;
    right: 0;
    background-color: white;
    box-shadow: ${globalstyles.boxShadows.centered};
    flex-direction: column;
    border-radius: 15px;
    gap: .5rem;
    padding: .5rem 0;
    z-index: 2;
`
export const DropdownContentItemStyled = styled.div`
    padding: .5rem 1.5rem;
    
    &:hover {
      background-color: ${globalstyles.colors.grayLight};
      cursor: pointer;
    }
    
`
