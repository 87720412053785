import {CategoryBarSegment, CategoryBarWrapper} from "./categoryBar.styles";

export type CategoryBarItem = {
    color: string
    value: number
}
interface CategoryBarProps {
    total: number
    categories: CategoryBarItem[]
}

export function CategoryBar({total, categories}: CategoryBarProps) {
    function getPercentage(value: number) {
        return value * 100 / total
    }

    if(!categories) {
        return (
            <CategoryBarWrapper>
                <h2>No hay datos</h2>
            </CategoryBarWrapper>
        )
    }

    return (
        <CategoryBarWrapper>
            {
                categories?.map((category, index) => {
                    return <CategoryBarSegment
                        key={`category-segment-${index}`}
                        percentage={getPercentage(category.value)}
                        color={category.color}
                    />
                })
            }
        </CategoryBarWrapper>
    )
}
