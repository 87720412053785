import {Countdown} from "../../../../components/countdown/countDown.component";
import {StartCountDownButtonStyled} from "./unsubscribe.styles";
import {useState} from "react";

interface PreUnSubscriptionCountdownProps {
    onCompleted: () => void
    onStarts: () => void
}

export function PreUnSubscriptionCountdown({ onCompleted, onStarts }: PreUnSubscriptionCountdownProps) {
    const [startCountDown, setStartCountDown] = useState<boolean>(false)
    const endTimeInSeconds = 10

    function onStart() {
        setStartCountDown(true)
        onStarts()
    }

    return (
        <>
            {
                !startCountDown ?
                    <StartCountDownButtonStyled onClick={onStart}>
                        Cancelar plan
                    </StartCountDownButtonStyled>
                :
                    <Countdown endTimeInSeconds={endTimeInSeconds} onCompleted={onCompleted} label={"Cancelar plan en..."}/>
            }
        </>
    )
}
