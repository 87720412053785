import { AiOutlineArrowLeft } from "react-icons/ai";
import styled from "styled-components";
import { globalstyles } from "../../../styles/animations/globalstyles";

export const VideosContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: block !important;
    width: 10px !important;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px !important;
    background-color: ${globalstyles.colors.yellow};
    border-radius: 8px;
  }
`;

export const TootorialCard = styled.img`
  width: 100%;
  box-shadow: 0 4px 12px 3px rgb(0 0 0 / 30%);

  @media not all and (min-resolution: 0.001dpcm) {
    height: 100%;
  }
`;

export const GoBackIcon = styled(AiOutlineArrowLeft)`
  color: white;
  width: 32px;
  height: 32px;
`;

export const GoBackContainer = styled.div`
  height: 32px;
  cursor: pointer;
  margin-top: 2px;
  width: 32px;

  svg {
    fill: ${globalstyles.colors.gray}; 
  }
`;
