import {
  BlackweekTag,
  CallToAction,
  MainBannerText,
  PopUpCallToAction,
  ReminderContainer,
} from "./reminder.styles";

interface IReminder {
    handleClick: () => void;
}

const Reminder = ({handleClick}: IReminder) => {
  return (
    <ReminderContainer>
      <MainBannerText>
        <h4>NADA DE DESCUENTOS!</h4> <PopUpCallToAction onClick={handleClick}>Toca aquí</PopUpCallToAction> para reclamar tu
        regalo de <BlackweekTag>BLACKWEEK!</BlackweekTag>
      </MainBannerText>
      <CallToAction onClick={handleClick}>¡LO QUIERO!</CallToAction>
    </ReminderContainer>
  );
};

export default Reminder;
