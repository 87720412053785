import { Box, BoxLabel, CheckboxContainer } from "./checkbox.styles";

type props = {
  checked?: boolean;
  label: string;
  flexDirection?: "column" | "row";
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export const Checkbox = ({
  checked = true,
  label,
  flexDirection = "row",
  onClick,
}: props) => {
  return (
    <CheckboxContainer flexDirection={flexDirection} onClick={onClick}>
      <Box checked={checked} />
      <BoxLabel>{label}</BoxLabel>
    </CheckboxContainer>
  );
};
