import { globalstyles } from "src/styles/animations/globalstyles";
import { center } from "src/styles/mixins/minxins";
import styled from "styled-components";

export const NavbarBottom = styled.div`
  ${center.centerDesktop};
  background-color: white;
  box-shadow: 0 2px 8px 0px #000000ab;
  bottom: 0;
  display: none;
  height: 45px;
  position: fixed;
  width: 100%;
  z-index: 200;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    display: flex;
  }
`;

export const LinksContainerMobile = styled.div`
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 95%;
`;

interface LinkMobileProps {
  color?: string;
  fontSize?: string;
  hasHover?: boolean;
  width?: string;
}

export const LinkMobile = styled.div<LinkMobileProps>`
  align-items: center;
  background: transparent;
  color: ${(props) => (props.color ? props.color : "black")};
  display: flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "13px")};
  font-weight: 600;
  height: 100%;
  justify-content: center;
  text-align: center;
  transition: all ease-in-out 0.2s;
  width: 25%;
`;
