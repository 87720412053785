import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toolsDataMock } from "src/pages/home/tools/tools.data";
import { config } from "../../config";
import { useAppSelector } from "../../hooks/useRedux";
import { Container } from "../../styles/globalcomponents";
import { tourData } from "../tour/tour.data";
import axios from "axios";
import {
  OpenSidebarButton,
  OpenSidebarIcon,
  SidebarContainer,
  SidebarItem,
  SidebarTool,
  ToolTip,
} from "./sidebar.styles";
import { ActiveCampaignService } from "../../services/activeCampaignService";
import { ToastService } from "src/services/toastService";

export const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();

  const tourIndex = useAppSelector((state) => state.tour.i);
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const authenticated = dataUser.stateUser === 1 || dataUser.stateUser === 2;
  const activeCampaignService = new ActiveCampaignService();
  const toastService = new ToastService();

  const tourCard = tourData[tourIndex];

  const [isHidden, setIsHidden] = useState(true);

  const handleClickQuickAccessButton = async (toolSlug: string) => {
    switch (toolSlug) {
      case "GENERADOR-DE-COPY":
        history.push("/copy");
        break;

      case "FUNNELS":
        history.push("/funnels");
        break;

      case "EMAIL-MARKETING":
        const toolsInfoActive = dataUser.toolsInfo.find(
          (tool) => tool.name === "Active"
        );

        const geoData = await axios.get(
          "https://d1qh9l1lloiwpp.cloudfront.net/ipInfo"
        );

        if (toolsInfoActive) {
          let user;
          toolsInfoActive.dataConfig.payload
            ? (user = {
                host: `https://${toolsInfoActive.dataConfig.payload.account}/`,
                user: toolsInfoActive.dataConfig.payload.username,
                pass: toolsInfoActive.dataConfig.payload.password,
                ip: geoData.data.ip,
              })
            : (user = {
                host: `https://${toolsInfoActive.dataConfig.account}/`,
                user: toolsInfoActive.dataConfig.username,
                pass: toolsInfoActive.dataConfig.password,
                ip: geoData.data.ip,
              });

          try {
            const data = await activeCampaignService.activeCampaignSignIn(user);
            const url = data.url_login as string;
            sessionStorage.setItem("joinToActiveCampaign", "1");
            window.location.href = url;
          } catch (error) {
            toastService.error("Ha ocurrido un error con la herramienta Email");
          }
        } else if (dataUser.existPaytoolLogic) {
          history.push("/profile#my-subscriptions");
        } else {
          toastService.error("Estás utilizando la versión antigua de la herramienta de Email.");
        }

        break;

      case "TIENDA":
        history.push("/shop");
        break;

      case "CRM":
        history.push("/crm");
        break;

      case "CONNECTOOL":
        history.push("/connect");
        break;

      case "LINK-ANALYTICS":
        history.push("/linktool");
        break;
    }
  };

  const isToolRoute = () => {
    return (
      location.pathname === "/funnels" ||
      location.pathname === "/shop" ||
      location.pathname === "/email/initial/version" ||
      location.pathname === "/crm" ||
      location.pathname === "/copy" ||
      location.pathname === "/connect" ||
      location.pathname === "/linktool"
    );
  };

  if (authenticated && location.pathname !== "/home") {
    return (
      <>
        {isToolRoute() && (
          <OpenSidebarButton
            isHidden={isHidden}
            onMouseEnter={() => setIsHidden(false)}
          >
            <OpenSidebarIcon />
          </OpenSidebarButton>
        )}
        <SidebarContainer
          style={{
            zIndex: tourCard.title === "MENU LATERAL" ? 204 : 100,
          }}
          isHidden={isHidden}
          right={isToolRoute()}
          onMouseLeave={() => setIsHidden(true)}
        >
          <SidebarItem>
            <Container width="70%">
              <SidebarTool
                onClick={() => history.push("/home")}
                src={`${config.urls.res}/platform/icons/SVG/Icono-home.svg`}
              ></SidebarTool>
            </Container>
            <ToolTip right={isToolRoute()}>HOME</ToolTip>
          </SidebarItem>
          {toolsDataMock.map(
            (tool: any, i: any) =>
              tool.slug !== "VIDEOTOOL" && (
                <SidebarItem
                  key={i}
                  onClick={() => handleClickQuickAccessButton(tool.slug)}
                >
                  <Container width="60%">
                    <SidebarTool
                      src={tool.urlImage}
                      key={tool.name}
                    ></SidebarTool>
                  </Container>
                  <ToolTip right={isToolRoute()}>{tool.name}</ToolTip>
                </SidebarItem>
              )
          )}
        </SidebarContainer>
      </>
    );
  }

  return <></>;
};
