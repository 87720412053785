import styled from "styled-components";
import { globalstyles } from "../../../styles/animations/globalstyles";

export const MessageEmailSent = styled.div`
  display: grid;
  place-items: center;
`;

export const TextEmailSent = styled.div`
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bolder;
  margin: 30% auto;

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    & {
      margin: 50% auto;
    }
  }
`;

interface SubLabelProps {
  fontSize?: string;
  textAlign?: string;
}

export const SubLabel = styled.div<SubLabelProps>`
  color: white;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;
