import styled from 'styled-components'

export const StatsPanelInfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: .7rem;
`
export const StatsPanel = styled.div`
    background-color: white;
    border-radius: 6px;
    padding: .7rem;
  
  
    & #header {
      display: flex;
      justify-content: space-between;
    }
  
    & #header-title {
      display: flex;
      align-items: center;
      gap: .5rem;
    }

`
