import { config } from "src/config";
import {
  BounceInBottom,
  rotate,
  SlideInLeft,
  SlideInRight,
} from "src/styles/animations/globalAnimations";
import { globalstyles } from "src/styles/animations/globalstyles";
import { borders, center } from "src/styles/mixins/minxins";
import styled from "styled-components";

export const MainHomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5em 0;
  width: 90%;
  max-width: 1200px;
  margin-inline: auto;

  @media (min-width: ${globalstyles.sizes.lg}) {
    padding: 5em 0 0;
    height: 100vh;
  }
`;

export const BottomSection = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr;
  place-items: start;
  justify-items: center;
  column-gap: 2rem;
  row-gap: 1rem;
  width: 100%;

  @media (min-width: ${globalstyles.sizes.lg}) {
    grid-template-columns: 55% 1fr;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  gap: 1rem;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    display: none;
  }
`;

export const ToolsContainer = styled.div`
  background-color: white;
  ${borders.borderDesktop};
  height: 100%;
  position: relative;
  padding: 1rem 2rem;
`;

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  min-height: 260px; // TODO: delete this
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  background-color: white;
  padding: 1rem 2rem;
`;

export const ChartImage = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: cover;
`;

export const DarkLayout = styled.div`
  align-items: center;
  background-color: #fffafa70;
  display: flex;
  justify-content: center;
  height: 100%;
  position: absolute;
  width: 100%;

  h6 {
    background-color: yellow;
    border-radius: 1rem;
    cursor: pointer;
    padding: 0.3rem 1rem;
  }
`;

export const RightContainer = styled.div`
  ${center.centerDesktop};
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 100%;
  }
`;

export const NewsContainer = styled.div`
  ${borders.borderDesktop};
  animation: ${rotate} 20s ease-in-out infinite;
  height: 130px;
  margin: auto;
  position: relative;
  transform-style: preserve-3d;
  transform-origin: center center;
  width: 90%;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: ${globalstyles.sizes.mobile}) {
    margin: 0 5%;
    width: 95%;
  }
`;

export const NewsContainerScene = styled.div`
  ${borders.borderDesktop};
  align-items: flex-start;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
  justify-content: center;
  perspective: 800px;
  margin: auto;
  height: 100%;
  width: 90%;
`;

interface NewsWrapperProps {
  urlImage?: string;
  position: string;
}

export const NewsWrapper = styled.div<NewsWrapperProps>`
  align-items: flex-end;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #f8f8f8;
  background-image: url(${(props) => props.urlImage});
  border-radius: 1rem;
  display: flex;
  height: 130px;
  margin: auto;
  opacity: 0.95;
  position: absolute;
  transform: ${(props) => props.position};
  width: 90%;

  &:hover {
    opacity: 1;
  }
`;


export const StatsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: .3rem;
`
export const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: .2rem;
  padding: .8rem .5rem;
  
  &:not(:last-child) {
    border-bottom: 1px solid ${globalstyles.colors.lightGray};
  }
`

interface StatItemLegendColorProps {
  color: string
}

export const StatItemLegendColor = styled.div<StatItemLegendColorProps>`
  display: inline-block;
  background-color: ${(props) => props.color || '#389DF9'};
  width: .5rem;
  height: .5rem;
  border-radius: 2px;
  margin-right: .2rem;
`

export const ActivityListStyled = styled.ul`
  list-style: none;
  padding: 0 .6rem 0 0;
  margin: 0;
  max-height: 12rem;
  overflow-y: auto;
  scrollbar-gutter: stable;
`
export const ActivityListItemStyled = styled.li`
  display: grid;
  grid-template-columns: 10% 1fr 20%;
  place-items: center;
  padding: .8rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${globalstyles.colors.graySoftDarker};
  }
  
  & p {
    justify-self: left;
    font-size: 12px;
    text-align: left;
  }
  
  
  & small {
    font-size: 11px;
    color: ${globalstyles.colors.graySoftDarker};
  }
`

export const QuickAccessSubscriptionButton = styled.button`
  background-color: ${globalstyles.colors.primary};
  border: none;
  border-radius: 20px;
  padding: .8rem 1rem;
  color: white;
  cursor: pointer;
  
  &:hover {
    opacity: .9;
  }
`
