//Imports
import { css } from "styled-components";

//Code
export const backgroundColors = {
  homeCardDesktop: () => css`
    background-color: green;
  `,
  
  homeCardMobile: () => css`
    background-color: red;
  `,
};

export const borders = {
  borderDesktop: () => css`
    border-radius: 1.5rem;
  `,

  borderMobile: () => css`
    border-radius: 15px;
  `,
};

export const center = {
    centerDesktop: () => css`
        align-items: center;
        display: flex;
        justify-content: center;
    `
}

// export const responsive = {
//   mobile: {
//     standard: (...args: any) => css`
//       @media (max-width: 600px) {
//           ${css(...args)};
//     }
//     `,
//   },
// };
