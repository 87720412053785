import { instance } from "../axios/axiosConfig";

export class ContentService {
  async getTootorials() {
    const response = await instance.get(
      "/app/contentPlataform/content/tootorials"
    );
    return response.data;
  }

  async getTootorial(id: string) {
    const response = await instance.get(
      `/app/contentPlataform/content/tootorials/${id}`
    );
    return response.data;
  }

  async getEvents() {
    const response = await instance.get(
      "/app/contentPlataform/content/community/"
    );
    return response.data;
  }
}
