import {CloseableModal, CloseableModalProps} from "../../../../../components/ui/modal/closeable-modal.component";
import {PrecautionIcon} from "../../../../../components/icons";
import {ConfirmButton, Container} from "../../../../../styles/globalcomponents";

interface DeleteImageModalComponentProps extends Omit<CloseableModalProps, 'children'> {
    handleDeleteImage: () => Promise<void>
}

export function DeleteImageModalComponent({isOpen, closeModal, handleDeleteImage}: DeleteImageModalComponentProps) {

    async function onDeletePhoto() {
        closeModal()
        await handleDeleteImage()
    }

    return (
        <>
            <CloseableModal isOpen={isOpen} closeModal={closeModal} >
                <Container display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                    <PrecautionIcon width={"100px"} height={"100px"} viewBox={"0 0 100 100"}/>
                    <h2 className={"text-center"}>¡Estas a punto de eliminar tu foto de perfil!</h2>
                    <p>¿Deseas continuar?</p>
                    <ConfirmButton onClick={onDeletePhoto} margin={"2rem 0 0 0"}>
                        ¡Aceptar!
                    </ConfirmButton>
                </Container>
            </CloseableModal>
        </>
    )
}
