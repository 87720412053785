import { useCallback, useEffect, useState } from "react";
import { LeftArrowIcon } from "../../../../../../../components/icons";
import { SpiralLoadingIcon } from "../../../../../../../components/loadingIcon/loadingIcon.component";
import { config } from "../../../../../../../config";
import { useAppSelector } from "../../../../../../../hooks/useRedux";
import type { GetSubscriptionStateResponseNew } from "../../../../../../../interfaces/plans";
import { sendEvent } from "../../../../../../../services/events";
import { PlansService } from "../../../../../../../services/plansService";
import { ToastService } from "../../../../../../../services/toastService";
import { ConfirmButton, Container, CustomText } from "../../../../../../../styles/globalcomponents";
import { TermsStyled } from "../../../../unsubscribe/unsubscribe.styles";
import { ConfirmTabParagraphs } from "./confirmTabParagraphs.component";

interface Props {
    closePurchaseModal: (force?: boolean) => void
    openSuccessModal: () => void
    setTabIndex?: (index: number) => void
    hasAdditionalTools: boolean
}

const confirmPlanImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-icon.png`
const confirmPlanRenewalImg = `${config.urls.res}/platform/icons/PNG/confirm-plan-renewal-icon.png`
const planService = new PlansService()
const toastService = new ToastService()

export function ConfirmPlanTab({closePurchaseModal, setTabIndex, hasAdditionalTools, openSuccessModal}: Props) {
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const plans = useAppSelector((state) => state.auth.plans)
    const selectedPlan = useAppSelector((state) => state.checkout.selectedPlan)
    const selectedPlanInfo = plans.find(plan => plan.suscriptionInfo.idSuscription === selectedPlan?.id)
    const [validationData, setValidationData] = useState<GetSubscriptionStateResponseNew | undefined>(undefined)
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [paymentApproved, setPaymentApproved] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const newPlanPrice = (validationData?.isApportionmentPrice ? validationData.apportionmentPrice : validationData?.priceTotal) ?? 0

    const validateSubscription = useCallback(async () => {
        try {
            setIsLoading(true)
            if(!selectedPlan || !selectedPlanInfo) throw new Error()

            const response = await planService.getUserSubscriptionStateNewest({
                newPlan: selectedPlanInfo.suscriptionInfo,
                periodicity: selectedPlan.frequency
            })

            if (!response) throw new Error()
            setValidationData(response)
        }catch (e) {
            toastService.error("No pudimos verificar tu plan, contacta a soporte")
        }finally {
            setIsLoading(false)
        }
    }, [selectedPlan, selectedPlanInfo])

    async function handleChangePlan() {
        try {
            setIsSubmitting(true)
            if(!validationData) throw new Error("Subscription validation info not found")

            const response = await planService.handlePayment(
                dataUser.email,
                validationData.codePlanPaytool,
                395,
                validationData.action,
                validationData.apportionmentPrice || validationData.priceTotal || 0
            );

            setPaymentApproved(response.data.suscriptionUpdated)
            setIsSubmitting(false)

            sendEvent({
                source: "MASTERTOOLS-SUBSCRIPTION-CHANGE",
                detailType: "CHANGE-PLAN-SUCCESS",
                detail: {
                    dataUser,
                    selectedPlan: selectedPlanInfo,
                    currentPlan: `Principal - ${dataUser.suscription.name}`,
                }
            }).then(() => console.log("Event sent!"))

            toastService.success("¡Compra exitosa!");
        } catch (error) {
            toastService.error("¡Tu pago ha sido rechazado!");
        }
    }

    function handleChangeToNextTab() {
        setTabIndex?.(1)
    }

    function handleShowSuccessfulMessage() {
        openSuccessModal()
        toastService.success("¡Se terminó tu compra! Revisa tu correo electrónico en unos momentos")
    }

    useEffect(() => {
        validateSubscription()
    }, [validateSubscription])

    return (
        <Container flexDirection={"column"} alignItems={"center"} maxWidth={"45rem"} padding={"0 .5rem"}>
            <img src={getImageSource(validationData?.action ?? '')} alt={"MasterTools icon plan confirmation"} width={"170px"} height={"170px"} />
            <h2 className={"text-center m-0"}>
                {getTitleCopy(validationData?.action ?? '')}
            </h2>
            {
                !isLoading && validationData !== undefined && selectedPlanInfo ?
                    <ConfirmTabParagraphs
                        changeType={validationData.action ?? ''}
                        currentPlanPeriodicity={dataUser.suscription.typeSuscription}
                        currentPlanName={dataUser.suscription.name}
                        newPlan={selectedPlanInfo.suscriptionInfo}
                        startDate={validationData.startDate ?? ''}
                        finalDate={validationData.finalDate ?? ''}
                        price={newPlanPrice}
                        periodicity={validationData.periodicity}
                    />
                :
                    <SpiralLoadingIcon width={"3rem"} />
            }
            <TermsStyled>
                <input type="checkbox" onChange={() => setTermsAccepted(prev => !prev)}/>
                <small>
                    <i>{getFooterCopy(validationData?.action ?? "")}</i>
                </small>
            </TermsStyled>
            {
                isSubmitting ?
                    <SpiralLoadingIcon width={"3rem"} />
                :
                    !paymentApproved ?
                        <div>
                            <ConfirmButton onClick={handleChangePlan} disabled={!termsAccepted || isSubmitting}>
                                {getSubmitCopy(validationData?.action ?? '')}
                            </ConfirmButton>
                            <CustomText textAlign={"center"} onClick={() => closePurchaseModal()} cursor={"pointer"} fontSize={"13px"} textDecoration={"underline"} margin={"1rem 0"}>
                                Cancelar
                            </CustomText>
                        </div>
                    :
                        <div>
                            {
                                hasAdditionalTools ?
                                    <ConfirmButton onClick={handleChangeToNextTab}>
                                        ¡CONTINUAR! <LeftArrowIcon style={{transform: "rotateZ(180deg)", marginLeft: ".5rem"}} fill={"white"} size={"10px"} />
                                    </ConfirmButton>
                                :
                                    <ConfirmButton onClick={handleShowSuccessfulMessage}>
                                        FINALIZAR
                                    </ConfirmButton>
                            }
                        </div>

            }
        </Container>
    )
}

function getSubmitCopy(typeChange: string) {
    if(typeChange === 'Upgrade') return "¡QUIERO MI NUEVO PLAN!"
    if(typeChange === 'Downgrade') return "¡DISMINUIR MIS FUNCIONALIDADES!"
    if (typeChange === 'Renew') return "¡Ya comenzábamos a extrañarte!"
    return "¡CONFIRMAR!"
}
function getTitleCopy(typeChange: string) {
    if(typeChange === 'Upgrade') return "¡Uff! ¡Qué buena elección!"
    if(typeChange === 'Downgrade') return "¿Listo para utilizar MasterTools a un menor costo?"
    if (typeChange === 'Renew') return "¡Ya comenzábamos a extrañarte!"
    return "¡Hola Tooler!"
}
function getFooterCopy(typeChange: string) {
    if (typeChange === 'Upgrade') return <>Entiendo que al dar click en el botón ¡QUIERO MI NUEVO PLAN! estoy realizando un pago proporcional a los días de uso de la suite de herramientas en MasterTools y autorizo que sea cobrado de la tarjeta de crédito registrada en mi cuenta. También estoy aceptando los <a href="https://mastertools.com/politica-servicio" rel="noreferrer" target="_blank">términos de compra</a> y servicio de MasterTools.</>
    if (typeChange === 'Downgrade') return <>Entiendo que al dar click en el botón ¡DISMINUIR MIS FUNCIONALIDADES! estoy realizando un cambio dentro de mi suscripción de MasterTools y autorizo que se siga cobrando dicha suscripción con la tarjeta de crédito registrada en mi cuenta hasta que sea cancelada. También estoy aceptando los <a href="https://mastertools.com/politica-servicio" rel="noreferrer" target="_blank">términos de compra</a> y servicio de MasterTools.</>
    if (typeChange === 'Renew') return <>Entiendo que al dar click en el botón ¡REACTIVAR MI PLAN AHORA!” estoy realizando un cambio dentro de mi suscripción de MasterTools y autorizo que se siga cobrando dicha suscripción con la tarjeta de crédito registrada en mi cuenta hasta que sea cancelada. También estoy aceptando los <a href="https://mastertools.com/politica-servicio" rel="noreferrer" target="_blank">términos de compra</a> y servicio de MasterTools.</>
    return "¡Hola Tooler!"
}
function getImageSource(typeChange: string) {
    if (typeChange === 'Renew') return confirmPlanRenewalImg
    return confirmPlanImg
}
