import {ReactNode} from 'react';
import {Modal} from "./modal.component";
import {CloseButtonStyled} from "./modal.styles";
import {CloseIcon} from "../../icons";

export interface CloseableModalProps {
    isOpen: boolean;
    closeModal: () => void;
    children: ReactNode;
    onCloseCleanup?: () => void
}

export function CloseableModal({ isOpen = false, closeModal, children, onCloseCleanup }: CloseableModalProps) {

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} onCloseCleanup={onCloseCleanup}>
            <CloseButtonStyled onClick={closeModal}>
                <CloseIcon />
            </CloseButtonStyled>
            {children}
        </Modal>
    );
}
