import {
  ClearSearchInputIcon,
  Searchbar as Bar,
  SearchIcon,
  SearchInput,
} from "./searchbar.styles";

type Props = {
  value?: string;
  margin?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  width?: string;
};

export const Searchbar = ({ width, margin, value, setValue }: Props) => (
  <Bar margin={margin} width={width}>
    <SearchIcon />
    <SearchInput value={value} onChange={(e) => setValue(e.target.value)} placeholder="Search" width={width} />
    {value && <ClearSearchInputIcon onClick={() => setValue("")} />}
  </Bar>
);
