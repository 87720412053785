import { IoMdClose } from "react-icons/io";
import { Button, Container, Text, CustomTitle } from "../../styles/globalcomponents";
import { ButtonsContainer, GlobalPContainer, PopUpCard, PopUpText } from "./globalPopup.styles";

interface buttonConfiguration {
  readonly text: string;
  primary?: boolean;
  handler?: (value?: any) => boolean | void | { [key: string]: any };
}

type GlobalPopupProps = {
  title?: string;
  description: any;
  isOpen?: boolean;
  showPopup: (isOpen: boolean) => void;
  buttons?: buttonConfiguration[];
};

export const GlobalPopup = ({
  title,
  description,
  isOpen = true,
  buttons,
  showPopup,
}: GlobalPopupProps) => {
  const buttonAction = (button: buttonConfiguration) => {
    if (button.handler) {
      button.handler();
    }
  };

  return (
    <GlobalPContainer
      style={{
        opacity: isOpen ? 1 : 0,
        pointerEvents: isOpen ? "auto" : "none",
      }}
    >
      <PopUpCard>
        <IoMdClose onClick={() => showPopup(false)} />
        <PopUpText>{title}</PopUpText>
        <Container flexDirection="column" textAlign="center">{description}</Container>
        <ButtonsContainer>
          {buttons &&
            buttons.map((button, i) =>
              <Button onClick={() => buttonAction(button)} typeButton={2}>
                {button.text}
              </Button>
            )}
        </ButtonsContainer>
      </PopUpCard>
    </GlobalPContainer>
  );
};
