import styled from "styled-components";


export const CheckoutSummaryHeaderStyled = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`

export const CheckoutSummaryBodyStyled = styled.div`
  & ul {
    padding-left: 1rem;
    font-size: 11px;
    line-height: 1.2rem;
  }
`

interface SummaryToolLimitsListProps {
    collapsed: boolean
}

export const SummaryToolLimitsList = styled.div<SummaryToolLimitsListProps>`
    & ul {
      height: ${(props) => props.collapsed ? "5rem" : "auto"};
      overflow-y: hidden;
      position: relative;
      
      &::before {
        content: "";
        position: absolute;
        display: ${(props) => props.collapsed ? 'block' : "none"};
        width: 100%;
        height: 60%;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(to top,white 10%,transparent 50%);
      }
    }
`
/*linear-gradient(to top,#ffffff 10%,transparent 40%)*/
