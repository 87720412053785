import { configureStore } from "@reduxjs/toolkit";

import authSlice from "./slices/auth";
import tourSlice from "./slices/tour";
import sessionSlice from "./slices/session";
import notificationSlice from "./slices/notifications";
import checkout from "./slices/checkout";

const store = configureStore({
  reducer: {
    auth: authSlice,
    checkout: checkout,
    tour: tourSlice,
    session: sessionSlice,
    notifications: notificationSlice
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
