import {useEffect, useState} from "react";
import { useAppSelector } from "../../hooks/useRedux";
import {Container, ContainerItem, PageTitleStyled} from "../../styles/globalcomponents";
import {Background, ProfileTab, ProfileTabContainer} from "./profile.styles";
import { profileTabs } from "src/services/profileService";
import {useHistory, useLocation} from "react-router-dom";

export const ProfilePage = () => {
  const darkMode = useAppSelector((state) => state.session.darkMode);
  const [activeTab, setActiveTab] = useState(0);
  const { hash} = useLocation()
  const history = useHistory()

  function handleSelectTab(index: number) {
    const hashToSet = profileTabs[index].hash
    history.push(hashToSet)
    setActiveTab(index)
  }

  useEffect(() => {
    if(hash.length > 0) {
      const tabByHashIndex = profileTabs.findIndex(tab => tab.hash === hash)

      if(tabByHashIndex !== -1) {
        setActiveTab(tabByHashIndex)
      }
    }
  }, [hash])

  return (
    <Background
        backgroundColor={darkMode ? "#212121" : "#F9F9F9"}
        padding={"4.5rem 0 0"}
        responsiveLg={"padding: 128px 0 3rem"}
    >
      <Container responsiveLg={"max-width: 1400px; margin: 0 auto;"} flexDirection={"column"}>
        <Container flexDirection={"column"} margin="0 1.5rem" width={"auto"} responsiveLg={"margin: 0 6rem;"}>
          <PageTitleStyled>Gestión de Cuenta</PageTitleStyled>
          <small>Administra tu información personal y gestiona todo lo relacionado a tu suscripción de MasterTools y las aplicaciones conectadas.</small>
        </Container>
        <Container margin="15px auto 0" justifyContent="center" padding={"0 0 1rem"}>
          <ContainerItem width="100%" responsiveLg="width: 100%; padding: 0 1.5rem" margin="0 0 0 3rem" padding={"0 10px"}>
            <ProfileTabContainer>
              {profileTabs.map((tab: any, index: number) => (
                  <ProfileTab
                      key={`profile-tab-${index}`}
                      onClick={() => handleSelectTab(index)}
                      active={activeTab === index}
                  >
                    {tab.name}
                  </ProfileTab>
              ))}
            </ProfileTabContainer>
            {profileTabs[activeTab].comp}
          </ContainerItem>
        </Container>
      </Container>
    </Background>
  );
};
