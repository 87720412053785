import { useReducer } from "react";
import { useAppSelector } from "src/hooks/useRedux";
import type { Plan, PlanFrequency } from "../../../interfaces/plans";
import { PlansService } from "../../../services/plansService";
import { Container } from "../../../styles/globalcomponents";
import { type State, SubscriptionTabReducer } from "./SubscriptionTabReducer";
import { SubscriptionPanelOverview } from "./overview/subscriptionPanelOverview.component";
import { PlansCheckout } from "./plan/plansCheckout.component";
import { PLANS_HIERARCHY, PLANS_PERIODICITY_HIERARCHY } from "./plan/tabs/plans.data";
import { PreUnsubscribe } from "./unsubscribe/PreUnsubscribe.component";
import { RecommendedPlansDowngrade } from "./unsubscribe/downgrade/recommendedPlansDowngrade.component";
import { Unsubscribe } from "./unsubscribe/unsubscribe.component";
import { BlackFridayPromotionSelector } from "./plan/blackFriday/blackFridayPromotion.component";
import { CiberMondayPromotionSelector } from "./plan/ciberMonday/ciberMondayPromotion.component";

export const TAB_INITIAL_STATE: State = {
    showRecommendedPlansDowngrade: false,
    showOverview: true,
    showDowngradeVideo: false,
    showUnSubscribe: false,
    showPlans: false,
    showBlackFridayPromotion: false,
    showCiberMondayPromotion: false,
    isEmailCancellation: false,
    onlyAdditionalTools: false
}

const plansService = new PlansService();
export function SubscriptionTab() {
    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const haveOptionsToDowngradeTo = PLANS_PERIODICITY_HIERARCHY[dataUser.suscription.typeSuscription as PlanFrequency] > 1
    const [state, dispatch] = useReducer(SubscriptionTabReducer, TAB_INITIAL_STATE)

    function handleShowUnsubscribe(isEmailCancellation = false) {
        dispatch({ type: "showUnsubscribeVideo", payload: { isEmailCancellation, onlyAdditionalTools: false } })
    }

    function handleCheckDowngrade() {
        if (!haveOptionsToDowngradeTo) {
            dispatch({ type: "showDowngrade" })
            return
        }
        dispatch({ type: "showRecommendedPlansDowngrade" })
    }

    function handleDowngrade() {
        dispatch({ type: "showDowngrade" })
    }

    function handleShowPlans() {
        dispatch({ type: "showPlans" })
    }

    function handleShowCiberMondayPromotion() {
        dispatch({ type: "showCiberMondayPromotion" })
    }

    function handleShowBlackFridayPromotion() {
        dispatch({ type: "showBlackFridayPromotion" })
    }

    function handleShowSubscriptionsPanel() {
        dispatch({ type: "showOverview" })
    }

    return (
        <>
            <Container>
                {state.showOverview && <SubscriptionPanelOverview showPromotion={handleShowCiberMondayPromotion} showPlans={handleShowPlans} showDowngrade={handleCheckDowngrade} showUnsubscribe={handleShowUnsubscribe} />}
                {state.showRecommendedPlansDowngrade && <RecommendedPlansDowngrade onAbortCancellation={handleShowSubscriptionsPanel} onStartCancellation={handleDowngrade} />}
                {state.showDowngradeVideo && <PreUnsubscribe onAbortCancellation={handleShowSubscriptionsPanel} onStartCancellation={handleShowUnsubscribe} />}
                {state.showUnSubscribe && <Unsubscribe isEmailCancellation={state.isEmailCancellation} onAbortCancellation={handleShowSubscriptionsPanel} />}
                {state.showPlans && <PlansCheckout onAbortCancellation={handleShowSubscriptionsPanel} />}
                {state.showBlackFridayPromotion && <BlackFridayPromotionSelector onAbortCancellation={handleShowSubscriptionsPanel} />}
                {state.showCiberMondayPromotion && <CiberMondayPromotionSelector onAbortCancellation={handleShowSubscriptionsPanel} />}
            </Container>
        </>
    )
}

export async function validateIfDowngradeIsAlreadySchedule(plans: Plan[], userEmail: string) {
    const currentPlan = plans.find(plan => plan.suscriptionInfo.name === "Lite")
    if (!currentPlan) throw new Error()
    const litePlanCode = currentPlan.suscriptionInfo.codePlanPaytool
    return plansService.validateIfDowngradeIsAlreadySchedule(userEmail, litePlanCode, 395);
}
