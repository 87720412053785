import toast, { ToastPosition } from "react-hot-toast";

export class ToastService {
    success = (message: string, duration?: number) => {
        toast.success(message, {
            position: "bottom-left",
            duration: duration || 4000
        });
    }

    custom = (message: string, position?: ToastPosition, duration?: number) => {
        toast.success(message, {
            position: position || "bottom-left",
            duration: duration || 9000
        });
    }

    error = (message: string) => {
        toast.error(message, {
            position: "bottom-left",
            duration: 6000
        });
    }

    warning = (message: string) => {
        toast(message, {
            position: "bottom-left",
            duration: 6000,
            icon: "⚠️"
        })
    }
}
