import { BiArrowFromRight } from "react-icons/bi";
import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

interface ToolTipProps {
  right?: boolean;
}

export const ToolTip = styled.div<ToolTipProps>`
  position: absolute;
  left: ${(props) => (props.right ? "unset" : "60px")};
  right: ${(props) => props.right && "80px"};
  text-align: ${(props) => (props.right ? "right" : "left")};
  color: white;
  font-weight: bolder;
  font-size: 12px !important;
  transition: ${globalstyles.animations.low};
  opacity: 0;
  pointer-events: none;
  background: ${globalstyles.colors.black};
  padding: 8px;
  border-radius: 4px;
`;

interface SidebarContainerProps {
  isHidden?: boolean;
  right?: boolean;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  background: ${globalstyles.colors.black};
  border-radius: ${(props) =>
    props.isHidden ? "0 1.5rem 1.5rem 0" : "8px 0px 0px 8px"};
  right: ${(props) =>
    props.right ? (props.isHidden ? "-100px" : 0) : "unset"};
  left: ${(props) => (props.right ? "unset" : 0)};
  padding: 40px 5px;
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 48px;
  height: min-content;
  z-index: 100;
  transition: ${globalstyles.animations.low};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    display: none;
  }
`;

export const SidebarItem = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  &:hover {
    ${ToolTip} {
      opacity: 1;
    }
  }
`;

export const SidebarTool = styled.img`
  cursor: pointer;
  margin: 5px auto;
  transition: all ease-in-out 0.32s;
  width: 100%;

  &:hover {
    transform: scale(1.2);
  }
`;

export const OpenSidebarIcon = styled(BiArrowFromRight)`
  width: 48px;
  height: 48px;
  color: ${globalstyles.colors.black};
  margin: 8px;
`;

interface OpenSidebarButtonProps {
  isHidden: boolean;
}

export const OpenSidebarButton = styled.div<OpenSidebarButtonProps>`
  width: 64px;
  height: min-content;
  position: fixed;
  right: ${(props) => (props.isHidden ? 0 : "-100px")};
  top: 0;
  bottom: 0;
  margin: auto 0;
  background: white;
  border-radius: 32px 0px 0px 32px;
  transition: ${globalstyles.animations.low};
`;
