import styled from 'styled-components'
import {globalstyles} from "../../../../styles/animations/globalstyles";


export const DateFilterButton = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
`

export const DateFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  
  & > div {
    display: flex;
    white-space: nowrap;
    justify-content: space-between;
    gap: .5rem;
  }


  & input[type="date"] {
    border-radius: 5px;
    border: 1px solid ${globalstyles.colors.gray};
  }
`


