import {
    Card,
    ConfirmButton,
    Container,
    ContainerItem,
} from "../../../../../styles/globalcomponents";
import {useAppDispatch, useAppSelector} from "../../../../../hooks/useRedux";
import {useState} from "react";
import {STEPS_INDEXES, completeStep, moveToFollowingStep} from "../../../../../redux/slices/checkout";
import {SummaryHeader} from "./summaryHeader.component";
import {PurchaseModal} from "../purchase/purchase.modal";
import {SummaryBody} from "./planCheckoutSummaryBody.component";
import {ToastService} from "../../../../../services/toastService";
import {SummaryHeaderAddons} from "./summaryHeaderAddons.component";
import {SummaryBodyAddons} from "./summaryBodyAddons.component";
import {PlansService} from "../../../../../services/plansService";

const toastService = new ToastService()
const plansService = new PlansService()
const ACTIVE_STATE = 1

export function PlansCheckoutSummary() {
    const dispatcher = useAppDispatch()
    const dataUser = useAppSelector((state) => state.auth.dataUser)
    const currentAddonPlan = dataUser.addonsInfo.emailContacts.find((element) => element.state === ACTIVE_STATE)
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)
    const additionalTools = useAppSelector((state) => state.checkout.additionalTools)
    const additionalToolEmail = additionalTools.find((tool) => tool.name === 'EmailTool')
    const checkoutCurrentStep = useAppSelector((state) => state.checkout.currentStep)
    const isReadyToPay = useAppSelector((state) => state.checkout.isReadyToPay)
    const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false)
    const isSubscriptionExpired = plansService.checkPlanExpired(currentAddonPlan?.expired_time.toString() ?? '')

    function handleCheckoutStep() {
        const isPayingForSameAddonsQuantity = additionalToolEmail?.quantity === currentAddonPlan?.quantity

        if(currentAddonPlan && isPayingForSameAddonsQuantity && !isSubscriptionExpired) {
            toastService.warning(`Ya tienes la cantidad de "${currentAddonPlan?.suscriptionAddon}" comprada para esta herramienta`)
            return
        }

        if(payOnlyAdditionalTools && additionalToolEmail?.quantity === 0) {
            return toastService.warning("Selecciona una cantidad para continuar")
        }
        if(!isReadyToPay && checkoutCurrentStep === STEPS_INDEXES.paymentMethod) {
            return toastService.warning("Selecciona un método de pago para continuar")
        }

        if(!isReadyToPay && checkoutCurrentStep === STEPS_INDEXES.planDescription) {
            dispatcher(completeStep({stepId: STEPS_INDEXES.planDescription}))
            dispatcher(moveToFollowingStep({stepId: STEPS_INDEXES.paymentMethod}))
            return
        }
        if(isReadyToPay) {
            setIsPurchaseModalOpen(true)
            return
        }
    }

    function closePayModal(force = false) {
        if (force) {
            setIsPurchaseModalOpen(false)
            return
        }
        const userAccept = window.confirm("¿Estás segur@ de cerrar? No podrás continuar con tu compra 😢")
        userAccept && setIsPurchaseModalOpen(false)
    }

    return (
        <>
            <ContainerItem>
                <Card
                    backgroundColor={"white"}
                    padding={"1rem 2.5rem 2rem"}
                    position={"relative"}
                >
                    {
                        payOnlyAdditionalTools ?
                            <SummaryHeaderAddons />
                        :
                            <SummaryHeader />
                    }
                    <hr/>
                    {
                        payOnlyAdditionalTools ?
                            <SummaryBodyAddons />
                        :
                            <SummaryBody />
                    }
                    <hr/>
                    <Container>
                        <ConfirmButton onClick={handleCheckoutStep} width={"100%"} margin={"1rem 0 0"} fontSize={"15px"}>
                            { isReadyToPay ? "Actualizar plan" : "Continuar"}
                        </ConfirmButton>
                    </Container>
                </Card>
            </ContainerItem>
            <PurchaseModal isOpen={isPurchaseModalOpen} closeModal={closePayModal} />
        </>
    )
}
