import { Navbar } from "../../navbar/navbar.component"

type props = {
  children: any
}

export const CheckoutLayout = ({children}: props) => (
  <> 
    <Navbar />
    {children}
  </>
)