import {config} from "../../../config";
import {ActivityListItemStyled, ActivityListStyled} from "../home.styles";
import {useAppSelector} from "../../../hooks/useRedux";
import {SpiralLoadingIcon} from "../../../components/loadingIcon/loadingIcon.component";
import { formatDistance } from 'date-fns'
import esLocale from 'date-fns/locale/es'


const toolsIconsPath = `${config.urls.res}/platform/home/icons`;
const TOOL_IMAGE: any = {
    copytool: `${toolsIconsPath}/Icono_Copy.svg`,
    Mastertools: `${toolsIconsPath}/Icono_Mastertools.png`,
    email: `${toolsIconsPath}/Icono_Email.svg`,
    tienda: `${toolsIconsPath}/Icono_Shop.svg`,
    link: `${toolsIconsPath}/Icono_Link.svg`,
    funnels: `${toolsIconsPath}/Icono_Funnel.svg`,
    crm: `${toolsIconsPath}/Icono_Crm.svg`,
}

export function ActivityList() {
    const { recentActivity, isLoading } = useAppSelector((state) => state.notifications);

    function getImageSource(tool: string) {
        return TOOL_IMAGE[tool] ?? TOOL_IMAGE.Mastertools
    }

    function getDateForHumans(date: Date) {
        const dateToFormat = new Date(date)
        const now = new Date()
        return formatDistance(
            dateToFormat,
            now,
            {
                includeSeconds: true,
                locale: esLocale
            }
        )
    }

    if(recentActivity.length === 0) {
        return (
            <ActivityListStyled>
                <h5 className={"text-center"}>No hay datos</h5>
            </ActivityListStyled>
        )

    }

    return (
        <ActivityListStyled>
            {
                isLoading ?
                    <SpiralLoadingIcon />
                :
                    recentActivity.map((activity, index) => {
                        return (
                            <ActivityListItemStyled key={`activity-${index}`}>
                                <img src={getImageSource(activity.toolNotification)} alt={"Tool activity image"} width={"30px"} height={"30px"}/>
                                <p>{activity.text}</p>
                                <small>{getDateForHumans(activity.date)}</small>
                            </ActivityListItemStyled>
                        )
                    })
            }
        </ActivityListStyled>
    )
}
