import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMobileScreen } from "src/hooks/useMobileScreen";
import { useAppSelector } from "../../hooks/useRedux";

declare const window: any;

interface ICrispChatBox {
  autoOpen?: boolean;
  message?: string;
}

const CrispChatBox = ({ autoOpen = false, message }: ICrispChatBox) => {
  const dataUser = useAppSelector((state) => state.auth.dataUser);
  const mobileScreen = useMobileScreen();

  const userId = dataUser.id;
  const username = dataUser.nameUser;
  const email = dataUser.email;
  const phone = dataUser.cellphone;
  const userState = dataUser.stateUser;

  const subscriptionId = dataUser.suscription.idSuscription;
  const expirationDate = dataUser.suscription.expired_time;
  const createDate = dataUser.suscription.create_time;
  const subscriptionType = dataUser.suscription.typeSuscription;
  const plan = dataUser.suscription.name;

  const websiteFunnelActive = dataUser.websiteFunnelActive;
  const addonId = dataUser?.addonsInfo?.emailContacts?.[0]?.idAddons;
  const addonsQuantity = dataUser?.addonsInfo?.emailContacts?.[0]?.quantity;
  const addonsSubscription =
    dataUser?.addonsInfo?.emailContacts?.[0]?.suscriptionAddon;

  const toolName = dataUser.toolsInfo[0]?.name;
  const projectId = dataUser.toolsInfo[0]?.dataConfig.projectId;
  const subdomain = dataUser.toolsInfo[0]?.dataConfig.subdomain;
  const websiteId = dataUser.toolsInfo[0]?.dataConfig.websiteId;

  const location = useLocation();

  useEffect(() => {
    window.$crisp = [];

    autoOpen && window.$crisp.push(["do", "chat:open"]);
    message && window.$crisp.push(["set", "message:text", [message]]);

    // USER DATA
    window.$crisp.push(["set", "user:nickname", [`${username}`]]);
    window.$crisp.push(["set", "user:email", [`${email}`]]);
    window.$crisp.push(["set", "user:phone", [`${phone}`]]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["ID_Usuario", `${userId}`]]],
    ]);
    window.$crisp.push(["set", "session:data", [[["Estado", `${userState}`]]]]);

    //SUBSCRIPTION DATA
    window.$crisp.push(["set", "session:data", [[["Plan", `${plan}`]]]]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["ID_Suscripción", `${subscriptionId}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Fecha_Expiracion", `${expirationDate}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Fecha_Creacion", `${createDate}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Tipo_Suscripcion", `${subscriptionType}`]]],
    ]);

    //ADDONS INFO
    window.$crisp.push(["set", "session:data", [[["ID Addon", `${addonId}`]]]]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Website_Funnel_Active", `${websiteFunnelActive}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Cantidad_Addons", `${addonsQuantity}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Suscripcion_Addon", `${addonsSubscription}`]]],
    ]);

    //TOOLS INFO
    window.$crisp.push([
      "set",
      "session:data",
      [[["ID_Proyecto", `${projectId}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Nombre_Herramienta", `${toolName}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["Subdominio", `${subdomain}`]]],
    ]);
    window.$crisp.push([
      "set",
      "session:data",
      [[["ID_Website", `${websiteId}`]]],
    ]);

    window.CRISP_WEBSITE_ID = "a0a01ccc-813c-4bad-a9b1-9f060edf87ea";

    (async () => {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.id = "crispScript";
      s.async = true;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

    return () => {
      if(!mobileScreen) window.$crisp.push(["do", "chat:hide"]);
    };
  }, [location]);

  return <></>;
};

export default CrispChatBox;
