export const SubscriptionsPeriodTypes = {
    SEMESTRAL: 'Semestral',
    MENSUAL: 'Mensual',
    ANUAL: 'Anual',
}


export const SUBSCRIPTION_NAMES = {
    TRIAL: 'Trial'
}


export const DatabaseStates = {
    ACTIVE: 1,
    DISABLED: 0
}
