import { useEffect, useState } from "react";
import { FaWindowRestore } from "react-icons/fa";
import { config } from "src/config";
import { useAppDispatch, useAppSelector } from "src/hooks/useRedux";
import { notificationDate } from "src/lib/datesLib";
import { INotification, updateViewedNotificationsThunk } from "src/redux/slices/notifications";
import {
    LinkTag,
    NonSelectedIcon,
    NotificationContent,
    NotificationDate,
    NotificationItem,
    NotificationLayer,
    NotificationSignal,
    NotificationText,
    NotificationTitle,
    NotificationToolImage,
    QuitLayout,
    SelectedIcon
} from "./notifications.styles"
interface NotificationProps {
    showNotificationButtons: boolean;
    setShowDeleteButton: React.Dispatch<React.SetStateAction<number[]>>;
    selectedNotifications: number[];
    setSelectedNotifications: React.Dispatch<React.SetStateAction<number[]>>;
    setNotViewedNotifications: React.Dispatch<React.SetStateAction<number>>;
    selectAll: boolean;
    idUserNotification: number;
    title: string;
    text: string;
    date: Date;
    toolNotification: string;
    viewed: boolean;
    notifUrl: string;
    notifTargetUrl: string;
}

export const Notification = ({
    showNotificationButtons,
    setShowDeleteButton,
    selectedNotifications,
    setSelectedNotifications,
    setNotViewedNotifications,
    selectAll,
    idUserNotification,
    title,
    text,
    date,
    toolNotification,
    viewed,
    notifUrl,
    notifTargetUrl,
}: NotificationProps) => {

    const [selectNotification, setSelectNotification] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const { notifications } = useAppSelector(state => state.notifications);
    const dispatch = useAppDispatch();

    const dinamicStyles = {
        transform: "scale(1.02)",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
    };
    
    const toolImages = {
        copytool: "Icono_Copy.svg",
        funnels: "Icono_Funnel.svg",
        email: "Icono_Email.svg",
        link: "Icono_Link.svg",
        tienda: "Icono_Shop.svg",
        crm: "Icono_Crm.svg",
        video: "Icono_VideoTool.svg",
    }

    const handleSelecetedNotifications = () => {
        setSelectNotification(!selectNotification);

        if (!selectNotification) {
            setShowDeleteButton(prev => {
                const newArray = [...prev];
                newArray.push(1);
                return newArray;
            });

            setSelectedNotifications(prev => {
                const newArray = [...prev];
                newArray.push(idUserNotification)
                return newArray;
            });

        } else {
            setShowDeleteButton(prev => {
                const newArray = [...prev];
                newArray.pop();
                return newArray;
            });

            setSelectedNotifications(prev => {
                const newArray = [...prev];
                newArray.pop();
                return newArray;
            })
        }

    };

    const handleViewedNotifications = async () => {
        dispatch(updateViewedNotificationsThunk({ idUser: Number(dataUser.id), notificationIds: selectedNotifications }));
        const notViewed = notifications?.filter(notification => notification.viewed == false).length;
        setNotViewedNotifications(notViewed);
        setSelectedNotifications([]);
    };

    useEffect(() => {
        if (isClicked) {
            handleViewedNotifications();
        }
    }, [selectedNotifications, isClicked]);


    const toolImage = () => {
        for (let icon in toolImages) {
            if (toolImages.hasOwnProperty(toolNotification)) {
                return toolImages[toolNotification as keyof typeof toolImages];
            } else {
                return null;
            }
        }
    };

    const handleNotificationClick = () => {
        window.open(notifUrl, notifTargetUrl);
        setSelectedNotifications(prev => {
            const newArray = [...prev];
            newArray.push(idUserNotification)
            return newArray;
        });
        setIsClicked(true);
    }

    return (
        <NotificationItem
            style={
                (selectNotification && showNotificationButtons) || selectAll ? dinamicStyles : {}
            }
        >
            <NotificationSignal
                style={{ display: `${viewed ? "none" : "block"}` }}
            />
            <NotificationDate>{notificationDate(date)}</NotificationDate>
            <NotificationToolImage>
                <img src={`${toolImage() ? `${config.urls.res}/platform/home/icons/${toolImage()}` : "https://images.vexels.com/media/users/3/301545/isolated/preview/ccb40cb18d5bc05eadab63fcb377233b-gradient-letter-m-alphabet.png"}`} alt="" />
            </NotificationToolImage>
            <NotificationContent>
                <NotificationTitle>{title}</NotificationTitle>
                <NotificationText>{text}</NotificationText>
            </NotificationContent>
            {
                showNotificationButtons ?
                    <NotificationLayer onClick={() => handleSelecetedNotifications()}>
                        {selectNotification || selectAll ?

                            <SelectedIcon />
                            :
                            <NonSelectedIcon />}

                    </NotificationLayer>

                    :

                    <></>
            }
            {!showNotificationButtons && <LinkTag
                onClick={handleNotificationClick}
            />}
        </NotificationItem>
    )
}
