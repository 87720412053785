import { config } from "../../config";

interface TourCard {
  title?: string;
  content?: string;
  left?: string;
  top?: string;
  right?: string;
  bottom?: string;
  src?: string;
  link: string;
  width?: string;
  height?: string;
  margin?: string;
}

export const tourData: TourCard[] = [
  {
    title: "¡BIENVENIDOS A MASTERTOOLS!",
    content:
      "¿Estás preparado? ¡Acabas de encontrar la suite de herramientas de marketing más grande para vender en Internet! En este recorrido te mostraremos lo que debes conocer para comenzar a revolucionar el mercado.",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    margin: "auto",
    src: `${config.urls.res}/platform/tour/Gift-Bienvenida-Mastertools.mp4`,
    width: "20%",
    link: "/home",
    height: "min-content",
  },
  {
    title: "CONOCE LAS HERRAMIENTAS",
    content:
      "En la página de inicio encontrarás las herramientas necesarias para que empieces a crear embudos de ventas, automatizaciones de email marketing, optimizar tus campañas y muchos más.",
    width: "50%",
    right: "0",
    left: "0",
    margin: "auto",
    top: "16px",
    link: "/home",
  },
  {
    title: "TOOTORIALES",
    content:
      "¿Te sientes un poco perdido? En esta sección podrás ver los TOOTORIALES que preparamos para ti para que estudies y estés más preparado para lo que se viene. Échales un vistazo.",

    left: "0",
    top: "32px",
    width: "20%",
    src: `${config.urls.res}/platform/tour/Gift-Tootoriales.mp4`,
    link: "/tootorials",
  },
  {
    title: "MENÚ LATERAL",
    content:
      "Acá puedes irte directamente al inicio de la página o empezar a desarrollar las herramientas de marketing. ¡No te quedes atrás!",
    left: "128px",
    top: "64px",
    width: "20%",
    src: `${config.urls.res}/platform/tour/Gift-Menú-Mastertools.mp4`,
    link: "/tootorials",
  },
  {
    left: "0",
    right: "0",
    bottom: "0",
    top: "0",
    margin: "auto",
    width: "50%",
    height: "min-content",
    src: `${config.urls.res}/platform/tour/Tour-planes.mp4`,
    link: "/plans",
  },
  {
    title: "COMUNIDAD",
    content:
      "No te pierdas la información y eventos más importantes de tu comunidad. ¡Agéndate!",
    left: "16px",
    top: "16px",
    width: "20%",
    src: `${config.urls.res}/platform/tour/Video-Comunidad-Mastertools.mp4`,
    link: "/community",
  },
  // {
  //   title: "CHAT",
  //   content:
  //     "¿Tienes preguntas? No dudes en consultarnos cualquier cosa, te ayudaremos a resolver tus dudas. Queremos que avances y logres tus objetivos, estamos a tu servicio.",
  //   left: "16px",
  //   top: "16px",
  //   width: "20%",
  //   src: `${config.urls.res}/platform/tour/Video-Comunidad-Mastertools.mp4`,
  //   link: "/community",
  // },
  {
    title: "GRACIAS POR LLEGAR HASTA ACÁ",
    content:
      "Has finalizado el recorrido por la página de MASTERTOOLS. No esperes para comenzar, el tiempo está corriendo.",
    left: "0",
    top: "0",
    bottom: "0",
    right: "0",
    margin: "auto",
    height: "min-content",
    width: "50%",
    src: `${config.urls.res}/platform/tour/Video-Gracias-Mastertools.mp4`,
    link: "/home",
  },
];
