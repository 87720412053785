import { useLocation } from "react-router-dom";
import { BodyBackground, Title } from "../../styles/globalcomponents";
import { NewPassword } from "./newPassword/newPassword.component";
import { SendEmail } from "./sendEmail/sendEmail.component";

export const ResetPassword = () => {
  const location = useLocation();
  const paramsString = new URLSearchParams(location.search);
  const token = paramsString.get("a");
  return (
    <BodyBackground backgroundColor={"white"}>
      {token ? <NewPassword tokenUser={token} /> : <SendEmail />}
    </BodyBackground>
  );
};
