import {ToolFeature} from "../../../../../interfaces/plans";
import {useState} from "react";
import {SummaryToolLimitsList} from "./plansCheckoutSummary.styles";
import {ConfirmButton, Container, CustomText} from "../../../../../styles/globalcomponents";
import {globalstyles} from "../../../../../styles/animations/globalstyles";
import {useAppSelector} from "../../../../../hooks/useRedux";

interface SummaryToolLimitsProps {
    toolLimits?:  ToolFeature[] | string[]
}

export function SummaryToolLimits({toolLimits}: SummaryToolLimitsProps) {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true)
    const payOnlyAdditionalTools = useAppSelector((state) => state.checkout.payOnlyAdditionalTools)

    if(!toolLimits) return <></>

    const isAddonSpecifications = !("value" in toolLimits)
    if(payOnlyAdditionalTools && isAddonSpecifications) {
        return (
            <SummaryToolLimitsList collapsed={isCollapsed}>
                <ul>
                    {
                        toolLimits.map((value, index) => (
                            <li key={`tool-limit-${index}`}>
                                {value}
                            </li>
                        ))
                    }
                </ul>
                <Container justifyContent={"center"} margin={"1rem 0"}>
                    <CustomText role={"button"} onClick={() => setIsCollapsed(prev => !prev)} cursor={"pointer"} color={globalstyles.colors.primary} fontSize={"12px"} fontWeight={"700"} textDecoration={"underline"}>
                        {isCollapsed ? "Ver más" : "Ver menos"}
                    </CustomText>
                </Container>
            </SummaryToolLimitsList>
        )
    }

    return (
        <SummaryToolLimitsList collapsed={isCollapsed}>
            <ul>
                {
                    toolLimits.map((data, index) => {
                        const {value, name} = data as ToolFeature
                        return (
                            <li key={`tool-limit-${index}`}>
                                <strong>{value}</strong> {name}
                            </li>
                        );
                    })
                }
            </ul>
            <Container justifyContent={"center"} margin={"1rem 0"}>
                <CustomText role={"button"} onClick={() => setIsCollapsed(prev => !prev)} cursor={"pointer"} color={globalstyles.colors.primary} fontSize={"12px"} fontWeight={"700"} textDecoration={"underline"}>
                    {isCollapsed ? "Ver más" : "Ver menos"}
                </CustomText>
            </Container>
        </SummaryToolLimitsList>
    )
}
