import { addHours, isAfter, formatDistance } from "date-fns"
import { es } from "date-fns/locale";

export class DatesLib {
  private hoursDiff;

  constructor() {
    this.hoursDiff = 5;
  }

  isDateExpired = (date: Date | string) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return isAfter(new Date(), addHours(date, this.hoursDiff));
  }

  getRealDate = (date: Date | string) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    return addHours(date, this.hoursDiff);
  }
}

export const notificationDate = (date: Date) => {
  return formatDistance(new Date(date), new Date(), {
    addSuffix: true,
    locale: es
  })
}

export const substractDays = (date: Date, days: number) => {
  date.setDate(date.getDate() - days);

  return date.toISOString().split('T')[0];
}
export const addDays = (date: Date, days: number) => {
  date.setDate(date.getDate() + days);

  return date.toISOString().split('T')[0];
}

export function getNDaysAgoDate(days: number){
  const now = new Date();
  const date = new Date(now.getTime() - (days * 24 * 60 * 60 * 1000));
  return date.toISOString().split('T', 1)[0]
}

export function getTodayDate(){
  const now = new Date();
  const date =  new Date(now.getTime() + (24 * 60 * 60 * 1000));
  return date.toISOString().split('T', 1)[0]
}


/**
 * This function uses a trick in Javascript Dates, when you have a date and the
 * seconds are greater than 59s, Date instance carries over it, and give an
 * increment to hours
 *
 * "Individual date and time component values
 * Given at least a year and month, this form of Date() returns a Date object whose component values (year, month, day, hour, minute, second, and millisecond) all come from the following parameters. Any missing fields are given the lowest possible value (1 for day and 0 for every other component). The parameter values are all evaluated against the local time zone, rather than UTC. Date.UTC() accepts similar parameters but interprets the components as UTC and returns a timestamp.
 *
 * If any parameter overflows its defined bounds, it "carries over". For example, if a monthIndex greater than 11 is passed in, those months will cause the year to increment; if a minutes greater than 59 is passed in, hours will increment accordingly, etc. Therefore, new Date(1990, 12, 1) will return January 1st, 1991; new Date(2020, 5, 19, 25, 65) will return 2:05 A.M. June 20th, 2020.
 *
 * Similarly, if any parameter underflows, it "borrows" from the higher positions. For example, new Date(2020, 5, 0) will return May 31st, 2020."
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date
 */
export function lastDayOfMonth() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth() + 1;
  const lastDay = new Date(year, month, 0);

  return lastDay.toString()
}
