import styled from "styled-components";
import {globalstyles} from "../../styles/animations/globalstyles";

type ProfileTabProps = {
  active?: boolean;
};

export const ProfileTab = styled.div<ProfileTabProps>`
  border-radius: 25px;
  background: ${(props) => (props.active ? "#00DFB0" : "transparent")};
  color: ${(props) => (props.active ? "white" : "black")};
  padding: 12px 15px;
  margin: 0 3px 14px;
  font-weight: ${props => props.active ? "700" : "500"};
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  min-width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  text-align: center;
  letter-spacing: 1px;

  &:hover {
    background: ${(props) => (props.active ? "#00DFB0" : "#e8e8e8")};
    color: ${(props) => (props.active ? "white" : "black")};
  }

  @media (max-width: ${globalstyles.sizes.mobile}) {
    min-height: 3rem;
    font-size: 14px;
    padding: 4px 4px;
  }
`;

/* MOBILE FIRST*/
export const ProfileTabContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 1rem;
  
  & > * {
    width: 40%;
  }

  @media (min-width: ${globalstyles.sizes.desktop}) {
    & > * {
      width: auto;
    }
    justify-content: start;
    padding-left: 2rem;
    margin: 8px 32px;
    width: auto;
  }
`;

interface ButtonProps {
  active?: boolean;
  darkMode?: boolean;
  width?: string;
  height?: string;
  borderRadius?: string
  activeColor?: string
}

export const Button = styled.button<ButtonProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  background: ${(props) =>
    props.active !== undefined
      ? props.active
        ? props.activeColor ? globalstyles.colors.primary : globalstyles.colors.yellow
        : "#A5A5A5"
      : props.activeColor ? globalstyles.colors.primary : globalstyles.colors.yellow};
  color: ${(props) =>
    props.active !== undefined
      ? props.active
        ? "white"
        : props.darkMode
        ? "black"
        : "white"
      : props.darkMode
      ? "black"
      : "white"};
  border: none;
  padding: 8px;
  height: ${props => props.height ?? 'auto'};
  border-radius: ${props => props.borderRadius ?? "16px"};
  font-weight: 600;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  transition: 0.32s;
  ${(props) =>
    (props.active || props.active === undefined) &&
    `
    cursor: pointer;

    &:hover {
      background: ${props.activeColor ? globalstyles.colors.primary : globalstyles.colors.yellow};
      transition: 0.32s;
    }
  `}
`;

interface ProfileInputProps {
  width?: string;
}

export const ProfileInput = styled.input`
  border-radius: 16px;
  border: none;
  padding: 8px;
  font-size: 16px;
  font-weight: bolder;
  background: white;
  margin-bottom: 16px;
  outline: none;
  transition: 0.2s;
  width: ${(props: ProfileInputProps) => (props.width ? props.width : "97%")};

  &:focus {
    background: ${globalstyles.colors.grayLight};
  }
`;

export const PlanButton = styled(Button)`
  background: ${globalstyles.colors.yellow};
  color: black;
  margin: 8px 0;
  padding: 4px 16px;
  width: fit-content;

  &:hover {
    background: white;
  }
`;

interface labelProps {
  color?: string;
  cursor?: string;
  fontSize?: string;
  textDecoration?: string;
  darkMode?: boolean;
}

export const Label = styled.label<labelProps>`
  color: ${(props: labelProps) =>
    props.color
      ? props.darkMode
        ? "white"
        : props.color
      : props.darkMode
      ? "white"
      : globalstyles.colors.gray};
  cursor: ${(props: labelProps) => (props.cursor ? props.cursor : "select")};
  font-size: ${(props: labelProps) =>
    props.fontSize ? props.fontSize : "14px"};
  text-decoration: ${(props: labelProps) =>
    props.textDecoration ? props.textDecoration : "unset"};
`;

type BackgroundProps = {
  backgroundColor: string;
  borderRadius?: string;
  boxShadow?: boolean;
  height?: string;
  minHeight?: string;
  padding?: string;
  responsive?: string;
  responsiveLg?: string;
  width?: string;
};

export const Background = styled.div<BackgroundProps>`
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius || "unset"};
  box-shadow: ${(props) =>
    props.boxShadow ? "0 0 10px 2px #00000029" : "unset"};
  height: ${(props) => props.height || "unset"};
  min-height: ${(props) => props.minHeight || "100vh"};
  padding: ${(props) => props.padding || "128px 0 0 0"};
  width: ${(props) => props.width || "100%"};

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    ${(props: BackgroundProps) => props.responsive && props.responsive}
  }
  @media only screen and (max-width: ${globalstyles.sizes.lg}) {
    ${(props: BackgroundProps) => props.responsiveLg && props.responsiveLg}
  }
`;

