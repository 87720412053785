import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { globalstyles } from "src/styles/animations/globalstyles";
import { config } from "../../config";
import { useAppDispatch, useAppSelector } from "../../hooks/useRedux";
import { loginFormInitialState } from "../../interfaces/auth";
import { requestSSOAsync, submitLoginAsync } from "../../redux/slices/auth";
import { Input } from "../../components/inputs/Input.component";
import {
  CustomTitle,
  ErrorMessage,
  SimpleSpan,
  ToolsAnimation
} from "../../styles/globalcomponents";
import {
  LoginBackground,
  LoginContainer,
  LoginForm,
  LoginFormTitle,
  LoginLink, LoginSubmitButton,
  TextButton
} from "./login.styles";

const CND_URL = config.urls.res
const LOGIN_BACKGROUND = `${CND_URL}/platform/login/loginBackground.png`

export const Login = () => {
  const dispatch = useAppDispatch();
  const message = useAppSelector((state) => state.auth.message);
  const [loginFormState, setLoginFormState] = useState(loginFormInitialState());
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { token } = useParams<{ token: any }>();
  const { search } = useLocation()
  const ssoToken = new URLSearchParams(search).get('sso')

  function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setLoginFormState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  function keySubmit(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      onFormSubmit();
    }
  }

  async function onFormSubmit() {
    const expression = config.regex.email;
    setIsSubmitting(true)
    setEmailError("");
    setPasswordError("");

    // Validate Email
    if (loginFormState.email.trim() === "") {
      return setEmailError("Debe ingresar un correo");
    }
    if (!expression.test(String(loginFormState.email).toLowerCase())) {
      return setEmailError("Email inválido");
    }
    // Password validation
    if (loginFormState.password.trim() === "") {
      return setPasswordError("Debe ingresar una contraseña");
    }

    await dispatch(submitLoginAsync(loginFormState)).unwrap();
    setIsSubmitting(false);
  }

  useEffect(() => {
    (async () => {
      if (token) {
        localStorage.removeItem("userLogged");
        setIsSubmitting(true);
        await dispatch(submitLoginAsync({ token: token })).unwrap();
        setIsSubmitting(false);
      }

      if (ssoToken) {
        localStorage.removeItem("userLogged");
        setIsSubmitting(true);
        await dispatch(requestSSOAsync(ssoToken));
        setIsSubmitting(false);
      }
    })();
  }, []);

  return (
    <>
      <LoginBackground>
        <LoginContainer>
          <div>
            <img alt={"Tools"} src={`${CND_URL}/platform/login/tools.gif`} />
          </div>
          <LoginForm backgroundUrl={LOGIN_BACKGROUND}>
            <section>
              <LoginFormTitle>
                <img src={`${CND_URL}/platform/login/loginAvatar.png`} width={150} height={150} alt="Login avatar" />
                <h6>¡Hola,</h6>
                <h2>Tooler!</h2>
              </LoginFormTitle>
              <Input
                borderColor={"gray"}
                color={"black"}
                onChange={onInputChange}
                value={loginFormState.email}
                name="email"
                type="email"
                placeholder="Ingresa el correo electrónico"
                icon="email"
                iconColor={globalstyles.colors.primary}
              />
              <Input
                borderColor={"gray"}
                color={"black"}
                onChange={onInputChange}
                value={loginFormState.password}
                name="password"
                type="password"
                placeholder="Escribe tu contraseña"
                onKeyPress={(e) => keySubmit(e)}
                icon="password"
                iconColor={"white"}
              />
              <ErrorMessage showError={Boolean(emailError)}>{emailError}</ErrorMessage>
              <ErrorMessage showError={Boolean(passwordError)}>{passwordError}</ErrorMessage>
              <ErrorMessage showError={Boolean(!isSubmitting && message)}>{!isSubmitting && message}</ErrorMessage>
              <LoginSubmitButton
                disabled={isSubmitting}
                onClick={onFormSubmit}
              >
                Ingresar
              </LoginSubmitButton>
              <CustomTitle
                color={"white"}
                fontSize={"13px"}
                fontWeight={"500"}
                textAlign={"left"}
              >
                <Link to={"/resetPassword"}>¿Olvidaste tu contraseña?</Link>
              </CustomTitle>
              <TextButton justifyContent={"left"} margin={"10px 0"}>
                <SimpleSpan>¿Eres nuevo en MasterTools?
                  <LoginLink>
                    <Link to={"/begin"}>Activa tu cuenta</Link>
                  </LoginLink>
                </SimpleSpan>
              </TextButton>
              <TextButton justifyContent={"left"} margin={"0 0 20px 0"}>
                <SimpleSpan>
                  Si aún no tienes una cuenta, actívala
                  <LoginLink>
                    <a href={"https://mastertools.com"} target={"_blank"}>Créala aquí</a>
                  </LoginLink>
                </SimpleSpan>
              </TextButton>
            </section>
          </LoginForm>
        </LoginContainer>
      </LoginBackground>
    </>
  );
};







// ivanTestDev1@hotmail.com

// #MasterTools2023
