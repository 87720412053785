import {AiOutlineArrowLeft} from "react-icons/ai";
import styled, {css} from "styled-components";
import {flicker} from "./animations/globalAnimations";
import {globalstyles} from "./animations/globalstyles";

export const ReturnButton = styled(AiOutlineArrowLeft)`
  position: fixed;
  left: 8px;
  top: 108px;
  background: ${globalstyles.colors.grayDarkness};
  padding: 16px;
  border-radius: 50%;
  font-size: 32px;
  color: white;
`;

interface IFrameProps {
  hasNavbar: boolean
  isModal?: boolean
  width?: string
  height?: string
  padding?: string
}

export const IFrame = styled.iframe<IFrameProps>`
  border: none;
  scrollbar-gutter: stable;
  padding: ${(props) => props.padding ? props.padding : ""};
  width: 100vw;
  height: ${(props) => props.hasNavbar ? "calc(100vh - 3.6rem)" : "99vh"};
  ${(props) => props.isModal && css<IFrameProps>`
    width: 100%;
    height: 75vh;

    @media only screen and (min-width: ${globalstyles.sizes.lg}) {
      & {
        width: 80vw;
        height: 80vh;
      }
    }
  `
  }
`;



export const ClickAway = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 90;
`;

interface LogoProps {
  borderRadius?: boolean;
  margin?: boolean;
  width?: string;
}

export const Logo = styled.img<LogoProps>`
  border-radius: ${(props) =>
    props.borderRadius && globalstyles.borders.cardImages};
  margin: ${(props) => props.margin && "32px 0"};
  width: ${(props) => (props.width ? props.width : "250px")};

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    & {
      width: 100%;
    }
  }
`;

export const ToolsAnimation = styled.video`
  display: none;
  border-radius: 1rem;
  margin: auto;
  width: 90%;

  @media only screen and (min-width: ${globalstyles.sizes.lg}) {
    display: flex;
  }
`;

interface ButtonProps {
  primary?: boolean;
  animation?: boolean;
  active?: boolean;
  borderRadius?: string;
  blocked?: boolean;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  hasHover?: boolean;
  letterSpacing?: string;
  margin?: string;
  padding?: string;
  typeButton?: number;
  width?: string;
}

export const Button = styled.button<ButtonProps>`
  ${(props) =>
    props.typeButton === 1
      ? css`
          padding: ${props.padding || "12px"};
          color: black;
          font-weight: ${props.fontWeight || "500"};
          font-size: ${props.fontSize || "13px"};
          transition: ${globalstyles.animations.low};

          ${props.active === true || props.active === undefined
            ? css`
                background: ${globalstyles.colors.yellow};
                &:hover {
                  background: ${globalstyles.colors.lightYellow};
                }
              `
            : css`
                background: #c7c7c7;
                cursor: default !important;
              `}
        `
      : props.typeButton === 2
      ? css`
          animation: ${props.animation &&
          css`
            ${flicker} 2s linear both
          `};
          background-color: ${globalstyles.colors.primary};
          color: white;
          font-weight: ${props.fontWeight || "500"};
          font-size: ${props.fontSize || "13px"};
          transition: ${globalstyles.animations.low};
          padding: ${props.padding || "12px"};

          ${props.active === true || props.active === undefined
            ? css`
                background: ${globalstyles.colors.primary};
                &:hover {
                  background: ${globalstyles.colors.lightPurple};
                }
              `
            : css`
                background: #c7c7c7;
                cursor: default !important;
              `}
        `
      : props.typeButton === 3
      ? css`
          background-color: ${props.backgroundColor};
          color: ${globalstyles.colors.primary};
          font-weight: ${props.fontWeight || "700"};
          font-size: ${props.fontSize || "12px"};
          transition: ${globalstyles.animations.low};
          padding: ${props.padding || "12px"};

          ${props.active === true || props.active === undefined
            ? css`
                &:hover {
                  background: #f4f4f4;
                }
              `
            : css`
                background: #c7c7c7;
                cursor: default !important;
              `}
        `
      : css`
          background: linear-gradient(
            90deg,
            rgba(251, 101, 68, 1) 0%,
            rgba(60, 0, 148, 1) 100%
          );
          padding: ${props.padding || "8px"};
          color: white;
          font-size: 18px;
        `};
  border: none;
  border-radius: ${(props) => props.borderRadius || "8px"};
  cursor: ${(props) => (props.blocked ? "not-allowed" : "pointer")};
  margin: ${(props) => props.margin || "4px 0 4px 0px"};
  width: ${(props) => props.width || "-webkit-fill-available"};

  &:hover {
    transition: all ease-in-out 0.1s;
    transform: ${(props) => (props.hasHover ? "scale(1.05)" : "")};
  }

  @media (max-width: ${globalstyles.sizes.mobile}) {
    font-size: ${globalstyles.fontSize.mobile.buttonLabel};
  }
`;

type SelectButtonProps = {
  selected?: boolean;
};

export const SelectButton = styled.button<SelectButtonProps>`
  background: ${(props) =>
    props.selected ? globalstyles.colors.yellow : "white"};
  padding: 12px;
  color: black;
  font-weight: 900;
  font-size: 18px;
  transition: ${globalstyles.animations.low};
  border: none;
  border-radius: 8px;
  width: -webkit-fill-available;
  margin: 4px 0 4px 0px;
  cursor: pointer;

  &:hover {
    background: ${globalstyles.colors.lightYellow};
  }
`;

export const InputFile = styled.input`
  display: none;
`;

export const InputContainer = styled.div`
  align-items: center;
  border-radius: 5px;
  border: dashed 2px grey;
  color: gray;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  height: 30px;
  width: 100%;
  margin: 16px 0;
`;

interface TextareaProps {
  width?: string;
  height?: string;
}

export const Textarea = styled.textarea<TextareaProps>`
  border-radius: 16px;
  font-family: "Gotham", sans-serif !important;
  border: none;
  padding: 8px;
  font-size: 12px;
  width: ${(props) => (props.width ? props.width : "98%")};
  height: ${(props) => (props.height ? props.height : "128px")};
  margin: 4px 0px 4px 0px;
  outline: none;
  resize: none;
  background: rgb(220, 230, 255);
`;

interface ErrorMessageProps {
  showError?: boolean
}


export const ErrorMessage = styled.div<ErrorMessageProps>`
  color: ${globalstyles.colors.danger};
  display: ${(props) => props.showError ? 'block' : 'none'};
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 8px 0;
  text-align: center;
`;

export const ErrorInput = styled.div`
  color: ${globalstyles.colors.danger};
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 8px 0;
  text-align: center;
`;

interface InputProps {
  width?: string;
  height?: string;
}

export const Input = styled.input<InputProps>`
  border-radius: 16px;
  border: none;
  padding: 8px;
  font-size: 12px;
  width: ${(props) => (props.width ? props.width : "-webkit-fill-available")};
  ${(props) => props.height && `height: ${props.height};`}
  margin: 4px 0px 4px 0px;
  outline: none;
  background: rgb(220, 230, 255);
`;

interface LabelProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
}

export const Label = styled.label<LabelProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  color: ${(props) =>
    props.color ? props.color : globalstyles.colors.grayDarkness};
`;

interface ContainerItemProps {
  display?: string;
  backgroundColor?: string;
  flexDirection?: string
  alignItems?: string;
  cursor?: string;
  float?: string;
  height?: string;
  justifyContent?: string;
  margin?: string;
  padding?: string;
  mobileHidden?: boolean;
  overflow?: string;
  responsive?: string;
  responsiveLg?: string;
  responsiveXl?: string;
  width?: string;
  borderRadius?: string;
  gap?: string;
  colGap?: string;
  rowGap?: string;
  gridTemplateColumns?: string
  gridTemplateRows?: string
  placeContent?: string
}

export const ContainerItem = styled.div<ContainerItemProps>`
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  cursor: ${(props) => (props.cursor ? props.cursor : "unset")};
  ${(props) => props.height && `height: ${props.height};`}
  display: ${(props) => props.display ? props.display : "flex" };
  flex-direction: ${props => props.flexDirection ?? 'column'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "unset"};
  ${(props) => props.margin && `margin: ${props.margin};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  width: ${(props) => (props.width ? props.width : "100%")};
  background-color: ${(props) => props.backgroundColor ?? 'transparent'};
  border-radius: ${(props) => props.borderRadius ?? "0px"};
  margin: ${(props) => props.margin ?? ""};
  padding: ${(props) => props.padding ?? ""};
  place-content: ${(props) => props.placeContent};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  column-gap: ${(props) => props.colGap ? props.colGap : "0"};
  row-gap: ${(props) => props.rowGap ? props.rowGap : "0"};
  gap: ${(props) => props.gap ?? ""};
  place-content: ${(props) => props.placeContent};

  @media (max-width: ${globalstyles.sizes.desktop}) {
    margin: auto;
    display: ${(props) => props.mobileHidden && "none"};
    width: 100%;
    ${(props: ContainerItemProps) => props.responsive && props.responsive}
  }

  @media only screen and (min-width: ${globalstyles.sizes.lg}) {
    ${(props: ContainerProps) => props.responsiveLg && props.responsiveLg}
  }
  @media only screen and (min-width: ${globalstyles.sizes.xl}) {
    ${(props: ContainerProps) => props.responsiveXl && props.responsiveXl}
  }
`;

export const SeparatorStyled = styled.div`
  width: 1px;
  height: auto;
  border-left: 1px solid black;  
`

interface ContainerProps {
  alignItems?: string;
  backgroundColor?: string;
  borderRadius?: string;
  gap?: string;
  width?: string;
  height?: string;
  overflow?: string;
  responsive?: string;
  responsiveLg?: string;
  responsiveXl?: string;
  responsiveMargin?: string;
  margin?: string;
  display?: string;
  flexDirection?: string;
  flexGrow?: string;
  justifyContent?: string;
  padding?: string;
  position?: string;
  placeContent?: string;
  boxShadow?: string;
  textAlign?: string;
  animation?: boolean;
  right?: string;
  left?: string;
  bottom?: string;
  gridTemplateColumns?: string
  gridTemplateRows?: string
  colGap?: string
  rowGap?: string
  maxWidth?: string
  flexWrap?: string
}

export const Container = styled.div<ContainerProps>`
  animation-name: ${(props) => props.animation && "magic"};
  animation-duration: 3s;
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "unset"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "unset"};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "none"};
  gap: ${(props) => (props.gap ? props.gap : "")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "unset"};
  width: ${(props) => (props.width ? props.width : "100%")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "none")};
  ${(props) => props.height && `height: ${props.height};`}
  margin: ${(props) => (props.margin ? props.margin : "auto")};
  display: ${(props) => (props.display ? props.display : "flex")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "unset")};
  ${(props) => props.flexDirection && `flex-direction: ${props.flexDirection};`}
  ${(props) => props.overflow && `overflow: ${props.overflow};`}
  padding: ${(props) => (props.padding ? props.padding : "unset")};
  position: ${(props) => props.position && props.position};
  column-gap: ${(props) => props.colGap && props.colGap};
  row-gap: ${(props) => props.rowGap && props.rowGap};
  right: ${(props) => props.right && props.right};
  flex-grow: ${(props) => props.flexGrow && props.flexGrow};
  left: ${(props) => props.left && props.left};
  bottom: ${(props) => props.bottom && props.bottom};
  place-content: ${(props) => props.placeContent};
  grid-template-columns: ${(props) => props.gridTemplateColumns};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  place-content: ${(props) => props.placeContent};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "unset"};
  flex-direction: ${(props) => props.flexWrap ? props.flexWrap : "none"};

  @media (max-width: ${globalstyles.sizes.desktop}) {
    margin: ${(props) => props.responsiveMargin && props.responsiveMargin};
  }

  @media only screen and (max-width: ${globalstyles.sizes.desktop}) {
    flex-direction: column;
    ${(props: ContainerProps) => props.responsive && props.responsive}
  }
  
  @media only screen and (min-width: ${globalstyles.sizes.lg}) {
    ${(props: ContainerProps) => props.responsiveLg && props.responsiveLg}
  }
  
  @media only screen and (min-width: ${globalstyles.sizes.large}) {
    ${(props: ContainerProps) => props.responsiveXl && props.responsiveXl}
  }

  @keyframes magic {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

interface CardContentProps {
  padding?: string;
  paddingRes?: string;
}

export const CardContent = styled.div<CardContentProps>`
  padding: ${(props) => (props.padding ? props.padding : "32px")};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    padding: ${(props) => props.paddingRes};
  }
`;

interface CardProps {
  backgroundColor?: string;
  borderRadius?: string;
  boxShadow?: boolean;
  centerContent?: boolean;
  height?: string;
  overflow?: string;
  image?: string;
  margin?: string;
  padding?: string;
  position?: string;
  responsive?: string;
  responsivePadding?: string;
  selected?: boolean;
  width?: string;
}

export const Card = styled.div<CardProps>`
  margin: ${(props) => (props.margin ? props.margin : "")};
  background: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : 'white'};
  ${(props) => props.padding && `padding: ${props.padding};`}
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "32px"};
  background-position: center;
  background-size: cover;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "auto")};
  position: ${(props) => (props.position ? props.position : "unset")};
  overflow: ${(props) => props.overflow ? props.overflow : "hidden"};
  ${(props) =>
    props.centerContent &&
    css`
      display: grid;
      place-items: center;
    `}

  ${(props) =>
    props.boxShadow &&
    `box-shadow: 0 0 8px 0px ${
      props.selected ? "#ADADAD" :"rgba(173,173,173,0.33)"
    };`}

  ${(props) =>
    props.image &&
    css`
      background-image: url("${props.image}");
      background-size: cover;
    `}

  @media (max-width: ${globalstyles.sizes.mobile}) {
    border-radius: ${globalstyles.borders.mobile.mainCard};
    padding: ${(props) => props.responsivePadding && props.responsivePadding};
  }

  @media only screen and (max-width: ${globalstyles.sizes.mobile}) {
    & {
      ${(props) => props.responsive && props.responsive}
    }
  }
`;

interface CustomTitleProps {
	textAlign?: string;
	color?: string;
	fontSize?: string;
	fontWeight?: string;
	fontStyle?: string;
	shadow?: boolean;
	margin?: string;
	textDecoration?: string;
	cursor?: string;
	lineHeight?: string;
	height?: string;
}

export const CustomTitle = styled.div<CustomTitleProps>`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  font-weight: ${(props) => props.fontWeight ? props.fontWeight : 600};
  margin: ${(props) => props.margin || "16px 0"};
  text-shadow: ${(props) => props.shadow && "0 0 8px black"};
  
  & > a {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
  }
`;

export const CustomText = styled.div<CustomTitleProps>`
  text-align: ${(props) => (props.textAlign ? props.textAlign : "start")};
  color: ${(props) => (props.color ? props.color : "black")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
  margin: ${(props) => props.margin || "0"};
  text-shadow: ${(props) => props.shadow && "0 0 8px black"};
  text-decoration: ${(props) => props.textDecoration || "none"};
  cursor: ${(props) => props.cursor || "auto"};
  font-style: ${(props) => props.fontStyle || "none"};
  height: ${(props) => props.height || "auto"};
  line-height: ${(props) => props.lineHeight || "inherit"};
  
  & a {
    color: inherit;
    text-decoration: none;
  }
`;

interface TitleProps {
  color?: string;
  fontSize?: string;
  fontWeight?: number;
  lineHeight?: string;
  margin?: string;
  shadow?: boolean;
  responsive?: string;
  textAlign?: string;
}

export const Title = styled.div<TitleProps>`
  text-align: ${(props) => props.textAlign || "center"};
  color: ${(props) =>
    props.color ? props.color : globalstyles.colors.grayDarkness};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "32px")};
  font-weight: ${(props) => props.fontWeight || 700};
  margin: ${(props) => props.margin || "32px auto"};
  text-shadow: ${(props) =>
    props.shadow ? "0 0 4px black !important" : "none !important"};
  line-height: ${(props) => props.lineHeight || "32px"};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    ${(props: TitleProps) => props.responsive && props.responsive}
    font-size: ${globalstyles.fontSize.mobile.title};
  }
`;

interface TextProps {
  color?: string;
  fontSize?: string;
  lineHeight?: string;
  textAlign?: string;
  shadow?: boolean;
}

export const Text = styled.div<TextProps>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "11px")};
  color: ${(props) => (props.color ? props.color : globalstyles.colors.text)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "13px")};
  text-shadow: ${(props) => props.shadow && "0px 0px 8px black"};
`;

export const BackgroundGradient = styled.div`
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 31%,
    rgba(78, 78, 78, 1) 100%
  );
  width: 100%;
  height: 100vh;
  margin-bottom: 100px;
  position: fixed;
  min-height: 200px;
  overflow: scroll;
`;

interface BodyBackgroundProps {
  img?: string;
  backgroundColor?: string;
  backgroundSize?: string;
  backgroundPositionY?: string;
  borderRadius?: boolean;
  height?: string;
  minHeight?: string;
  maxWidth?: string;
  mobileHeight?: string;
  marginTop?: string;
  marginInline?: string;
  overflow?: string;
  padding?: string;
  responsive?: string;
  width?: string;
}

export const BodyBackground = styled.div<BodyBackgroundProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "black"};
  background-image: url("${(props) => props.img}");
  background-size: ${(props) =>
    props.backgroundSize ? props.backgroundSize : "100%"};
  background-repeat: no-repeat;
  background-position-y: ${(props) =>
    props.backgroundPositionY && props.backgroundPositionY};
  border-radius: ${(props) =>
    props.borderRadius && globalstyles.borders.cardImages};
  height: ${(props) => (props.height ? props.height : "unset")};
  min-height: ${(props) => (props.minHeight ? props.minHeight : "90vh")};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "1440px")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "100px")};
  margin-inline: ${(props) => (props.marginInline ? props.marginInline : "auto")};
  padding: ${(props) => (props.padding ? props.padding : "")};
  overflow: ${(props) => props.overflow ? props.overflow : "auto"};
  user-select: none;
  width: ${(props) => (props.width ? props.width : "100%")};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    background-position-y: 0px;
    height: ${(props) => props.mobileHeight && props.mobileHeight};
    min-height: unset !important;
    ${(props: BodyBackgroundProps) => props.responsive && props.responsive};
  }
`;

interface SimpleSpanProps {
  color?: string;
}

export const SimpleSpan = styled.span<SimpleSpanProps>`
  display: inline;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "black")};
`;

interface ButtonProps {
  primary?: boolean;
  animation?: boolean;
  active?: boolean;
  borderRadius?: string;
  blocked?: boolean;
  backgroundColor?: string;
  fontSize?: string;
  fontWeight?: string;
  fontFamily?: string;
  hasHover?: boolean;
  isSecondary?: boolean;
  letterSpacing?: string;
  margin?: string;
  padding?: string;
  typeButton?: number;
  width?: string;
  height?: string;
  maxWidth?: string;
  whiteSpace?: string;
}

export const ConfirmButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.isSecondary ? globalstyles.colors.yellow : globalstyles.colors.primary};
  color: ${(props) => props.isSecondary ? "black" : "white"};
  font-weight: ${(props) => props.fontWeight || "500"};
  font-size: ${(props) => props.fontSize || "12px"};
  font-family: ${(props) => props.fontFamily || "'Montserrat',sans-serif"};
  transition: ${globalstyles.animations.low};
  padding: ${(props) => props.padding || "12px 20px"};
  border-radius: ${(props) => props.borderRadius || "28px"};
  margin: ${(props) => props.margin ?? ""};
  width: ${(props) => props.width ?? "auto"};
  max-width: ${(props) => props.maxWidth ?? "auto"};
  height: ${(props) => props.height ?? "auto"};
  white-space: ${(props) => props.whiteSpace ? props.whiteSpace : "nowrap"};
  border: 0 solid transparent;
  animation: ${(props) => props.animation && `${flicker} background-color 2s linear both`}
  background: ${globalstyles.colors.primary};

  &:hover {
    background: ${(props) => props.isSecondary ? globalstyles.colors.lightYellow : globalstyles.colors.lightPurple};
    cursor: pointer;
  }

  & > svg {
    margin-right: .5rem;
  }

  &[disabled] {
    filter: grayscale(5);
    opacity: .7;
    cursor: not-allowed;
  }
`

export const DeleteButton = styled(ConfirmButton)`
  background-color: ${globalstyles.colors.danger};

  &:hover {
    background: ${globalstyles.colors.lightDanger};
  }
`

export const PageTitleStyled = styled.h2`
  font-weight: 800;
  margin: 0;
`
