import styled from "styled-components";
import { ContainerItem } from "../../../../styles/globalcomponents";
import { globalstyles } from "../../../../styles/animations/globalstyles";

export const ImageSettingButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: .7rem;
  padding: 15px 15px;
  border-radius: 28px;
  border: 1px solid rgba(165, 165, 165, 0.67);
  background-color: white;
  transition: background-color ease-in-out 0.3s;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  white-space: nowrap;
  
  &:hover {
    background-color: #6300FF;
    color: white;

    & svg > g {
      color: white;
    }
  }
`;

export const ImageUploadButton = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: .7rem;
  padding: 15px 15px;
  border-radius: 28px;
  border: 1px solid rgba(165, 165, 165, 0.67);
  background-color: white;
  transition: background-color ease-in-out 0.3s;
  font-weight: 500;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;

  &:hover {
    background-color: #6300FF;
    color: white;

    & svg > g {
      color: white;
    }
  }
`

export const DeleteImageButton = styled(ImageSettingButton)`
  border-radius: 0px 8px 8px 0px;
`;

interface ImageProfileProps {
    width?: string
    height?: string
}

export const ImageProfile = styled.img<ImageProfileProps>`
  border-radius: 50%;
  width: ${(props) => props.width ?? "80%"};
  height: ${(props) => props.height ?? "auto"};
  aspect-ratio: 1;
  margin: 0 auto;
  border: 11px solid #F9F9F9;
  object-fit: cover;

  &.webcam {
    width: 16rem;
    height: 16rem;

    @media (min-width: ${globalstyles.sizes.xl}) {
      width: 300px;
      height: 300px;
    }
  }
`;

export const ImageLegend = styled.h2`
  font-weight: 600;
  text-align: center;
  margin: .5rem 0 2rem;
  font-size: 1.2rem;
`

export const ImageSmallLegend = styled.small`
  text-align: center;
  margin: 16px 0 10px;
  font-style: italic;
  font-weight: 300;
  font-size: 11px;
`
