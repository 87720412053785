import { instance } from "../axios/axiosConfig";
import jwt from "jwt-decode";
import {
  ActivationAccountForm,
  FreeTrialForm,
  LoginForm,
} from "../interfaces/auth";
import axios from "axios";
import { config } from "src/config";

export class AuthService {
  async uploadProfilePhoto(formData: FormData) {
    const response = await instance.post("/app/users/image", formData, {
      headers: {
        "Content-Type": "form-data",
      },
    });
    return response.data;
  }

  async deleteProfilePhoto(idUser: string) {
    const response = await instance.post("/app/users/deleteImage", {
      idUser,
    });
    return response.data;
  }

  async changeUserInfo(id: string, name: string, lastName: string) {
    const response = await instance.post(`/app/users/info/change/${id}`, {
      name,
      lastName,
    });

    return response.data;
  }

  async getUser(email: string) {
    const response = await instance.get(`/app/users/info/${email}`);
    return response.data;
  }

  async signIn(user: LoginForm) {
    const response = await instance.post("/app/auth/signin", {
      emailUser: user.email,
      passwordUser: user.password,
    });
    return response.data;
  }

  async signInBypass(authToken: any) {
    const tokenInfo: any = jwt(authToken);
    const { email } = tokenInfo;
    const { token } = tokenInfo;
    const response = await axios.post(`${config.axios.url}/app/auth/signinbypass`,
      {
        emailUser: email,
        repeatEmailUser: email,
      },
      {
        headers: {
          "admin-access-token": token,
        },
      }
    );

    return response.data;
  }

  async singleSignOn(token: string) {
    const request = await instance.post('/app/auth/signinSSO', { token })
    return request.data
  }

  async activateAccount(user: ActivationAccountForm) {
    const response = await instance.post("/app/users/activate", {
      email: user.email,
      codeActivation: user.codeActivation,
      password: user.password,
    });

    return response.data;
  }

  async createFreeTrialAccount(form: FreeTrialForm) {
    const queryStringBody = new URLSearchParams({
      aff: "",
      transaction: config.accounts.defaultCodeActivation,
      off: "kxjuer9tvu",
      name_subscription_plan: "Basic",
      email: form.email,
      first_name: form.name,
      last_name: form.lastName,
      doc: "",
      phone_number: form.phoneNumber,
      trialMastertools: "true",
      password: form.password,
      ref: form.ref || "",
      extra: form.extra || "",
    }).toString();

    const response = await instance.post("/app/hotmart/trial", {
      body: queryStringBody,
    });

    return response.data;
  }

  async updateUserProfiler(email: string) {
    const response = await instance.put("/app/users/showUserProfiler", {
      email: email,
    });

    return response.data;
  }
}
