import styled from "styled-components";
import {globalstyles} from "../../../../../styles/animations/globalstyles";

export const TabContainer = styled.div`
  overflow: hidden;
  width: 17rem;
    
  & ul {
    margin: 0;
    display: flex;
    width: 100%;
    padding: 1rem 0;
    overflow: auto;
    scroll-snap-type: x mandatory;
    
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  & li {
    display: flex;
    position: relative;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    min-width: 70%;
    white-space: nowrap;
    justify-content: center;
    padding: 1rem 0.5rem 0.7rem;
    border-top: 4px solid ${globalstyles.colors.lightGreen};
    cursor: pointer;
    user-select: none;
    transition: border-top 300ms ease-in;
    font-size: .7rem;
  }

  & li.active ~ li {
    border-top: 4px solid ${globalstyles.colors.lightGreen};
  }

  & li::before {
    content: '○';
    margin-right: 0.5rem;
    color: #00dfb0;
    font-size: 1.5rem;
    line-height: .7rem;
  }

  & li.active::before {
    content: '●';
  }
  
  & li.passed::before {
    content: '●';
  }
  
  & li.active {
    border-top: 4px solid ${globalstyles.colors.darkenGreen};
  } 
  
  & li.passed {
    border-top: 4px solid ${globalstyles.colors.softGreen};
  }  
  
  @media (min-width: ${globalstyles.sizes.desktop}) {
    & {
      width: auto;
    }
    
    & ul {
      display: flex;
      justify-content: center;
      padding: 1rem 2rem;
      overflow: visible;
      scroll-snap-type: none;
    }

    & li {
      min-width: 0;
      white-space: nowrap;
      justify-content: center;
      padding: 1rem 1rem 0.7rem;
      border-top: 4px solid #7bffde9b;
      cursor: pointer;
      user-select: none;
      transition: border-top 300ms ease-in;
    }
  }
`
