import axios from "axios";
import { config } from "../config";

const copyToolInstance = axios.create({
  baseURL: `${config.axios.copyToolUrl}/api/` 
}) 

export class CopyToolService {
  login = async (id: string) => {
    const origin = window.location.hostname;
    const response = await copyToolInstance.post(
      "auth/login",
      {
        idUser: id,
        origin
      }
    );

    return response.data;
  };
}
