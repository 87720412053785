import styled from 'styled-components'


export const CategoryBarWrapper = styled.div`
    display: flex;
    height: .7rem;
    width: 100%;
    border-radius: 2px;
    overflow: hidden;
`

interface CategoryBarSegmentProps {
    percentage: number
    color: string
}
export const CategoryBarSegment = styled.div<CategoryBarSegmentProps>`
  width: ${(props) => `${props.percentage}%`};
  background-color: ${(props) => props.color ? `${props.color}` : 'purple'};
`
