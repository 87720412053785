import { PlanFrequency } from "src/interfaces/plans";
import {config} from "../../../../../config";

export const TOOLS_ICONS = [
    `${config.urls.res}/platform/icons/PNG/Icono-copy.png`,
    `${config.urls.res}/platform/icons/PNG/Icono-funnels.png`,
    `${config.urls.res}/platform/icons/PNG/Icono-emailtool.png`,
    `${config.urls.res}/platform/icons/PNG/Icono-link.png`,
    `${config.urls.res}/platform/icons/PNG/Icono-tienda.png`,
    `${config.urls.res}/platform/icons/PNG/Icono-connecttool.png`,
];

export const PLANS_BANNERS: any = {
    basic: `${config.urls.res}/platform/new_banners/banner_basic.png`,
    lite: `${config.urls.res}/platform/new_banners/banner_lite.png`,
    master: `${config.urls.res}/platform/new_banners/banner_master.png`,
    plus: `${config.urls.res}/platform/new_banners/banner_plus.png`,
    trial: `${config.urls.res}/platform/new_banners/banner_trial.png`,
    starter: `${config.urls.res}/platform/new_banners/banner_starter.png`,
}

export const PLANS_PERIODICITY_HIERARCHY: Record<PlanFrequency, number> = {
	Mensual: 1,
	Trimestral: 2,
	Semestral: 3,
	Anual: 4,
};

export const PLANS_HIERARCHY: any = {
    "Lite": 1,
    "Starter": 2,
    "Basic": 3,
    "Plus": 4,
    "Master": 5,
}

