import {IFrame} from "../../styles/globalcomponents";

export const Tootorials = () => {

  return (
    <IFrame src={`https://mastertools.com/tootoriales`}
      hasNavbar={false}
      padding={"2rem 0 0 0"}
      allow="clipboard-read; clipboard-write"
      allowFullScreen
    />
  );
};
