import { config } from "../config";

export const validateEmail = (email: string) => {
  if (email === "") {
    return "Debe ingresar un email";
  }

  if (!config.regex.email.test(email)) {
    return "Debe agregar un email válido";
  }

  return "";
}

export const validatePassword = (password: string) => {

  if (password === "") {
    return "Debe ingresar una contraseña";
  }
  if (password.length < 9) {
    return "Su contraseña debe tener al menos 8 caracteres";
  }
  if (!config.regex.hasLowercase.test(password)) {
    return "Su contraseña debe tener al menos una minúscula";
  }
  if (!config.regex.hasUppercase.test(password)) {
    return "Su contraseña debe tener al menos una mayúscula";
  }
  if (!config.regex.hasNumber.test(password)) {
    return "Su contraseña debe tener al menos un número";
  }

  return "";
}