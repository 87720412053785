import styled from "styled-components";
import {globalstyles} from "../../styles/animations/globalstyles";

interface LayoutContainerProps {
    isLoginRoute: boolean
}

export const LayoutContainer = styled.div<LayoutContainerProps>`
    position: relative;
    height: auto;
    min-height: 100vh;
    background-color: ${(props) => props.isLoginRoute ? 'white' : globalstyles.colors.background};
`
