import { center } from "src/styles/mixins/minxins";
import styled from "styled-components";
import { globalstyles } from "../../../styles/animations/globalstyles";

export const ToolBlockedText = styled.div`
  background-color: ${globalstyles.colors.yellow};
  border-radius: 2rem;
  color: ${globalstyles.colors.grayDarkness};
  font-size: 9px;
  font-weight: 700;
  margin: auto;
  opacity: 0;
  padding: .2rem .5rem;
  position: absolute;
  text-align: center;
  transition: opacity ease-in-out ${globalstyles.animations.low};
  width: fit-content;
  top: -16px;
  left: 0;
  right: 0;
`;
interface ToolNameProps {
  color?: string;
  fontSize?: string;
}

export const ToolName = styled.div<ToolNameProps>`
  color: ${(props) =>
    props.color ? props.color : globalstyles.colors.grayDarkness};
  font-size: ${(props) =>
    props.fontSize ? props.fontSize : globalstyles.fontSize.paragraph};
  font-weight: bolder;
  transition: opacity ease-in-out ${globalstyles.animations.low};
  text-align: center;
`;

interface ToolButtonComponent {
  available: boolean;
}

export const ToolQuickAccess = styled.img`
  border-radius: 20%;
  display: block;
  margin: 10px auto;
  width: 5rem;
  aspect-ratio: 1;
  transition: transform ease-in-out ${globalstyles.animations.low};

  &:hover {
    filter: ${(props: ToolButtonComponent) =>
      props.available ? "" : "grayscale(100%)"};
  }
`;


export const ToolContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1fr, 2);
  place-content: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  position: relative;
  aspect-ratio: 1;
  border-radius: 15px;
  transition: background-color 250ms ease-in;
  
  &:hover {
    background-color: ${globalstyles.colors.background};
  }
  
  &:hover #toolButton {
    transition: transform cubic-bezier(0.14, 0.9, 1, 1) ${globalstyles.animations.fast};
    transform: scale(1.05);
  }
  
  &:hover #toolBlocked {
    opacity: 1;
  }
`;

export const ToolsListContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2,1fr);
  row-gap: .2rem;
  column-gap: 1rem;
  width: 100%;


  @media (min-width: ${globalstyles.sizes.lg}) {
    grid-template-columns: repeat(4,1fr);
    row-gap: 1rem;
    column-gap: 1rem;
    width: 100%;
  }
`;

interface IToolsList {
  mobileTools?: boolean;
}

export const ToolsList = styled.div<IToolsList>`
  margin: ${(props) => props.mobileTools ? "0" : "0 0 7rem 0"};
  width: 100%;
`;
