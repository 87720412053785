import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

type BoxProps = {
  checked: boolean;
};

export const Box = styled.div<BoxProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 3px solid white;
  padding: 2px;
  background-color: ${(props) =>
    props.checked ? globalstyles.colors.yellow : "white"};
  box-shadow: 0 0 0px 1px #cdcdcd;
  aspect-ratio: 1;
  &:hover {
    cursor: pointer;
  }
`;

export const BoxLabel = styled.div`
  font-size: 11px;
  font-weight: 500;
`;

type CheckboxProps = {
  flexDirection: "column" | "row";
};

export const CheckboxContainer = styled.div<CheckboxProps>`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.1rem;
  flex-direction: ${(props) => props.flexDirection || "unset"};
`;
