import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginReducer } from "../../interfaces/redux";
import {LocalStorageKeys} from "../localStorageKeys";

const sessionSlice = createSlice({
  name: "session",
  initialState: {
    canShowPromotion: false,
    canShowTootorialsEffect: true,
    canShowScheduleAppointment: true,
    darkMode: (JSON.parse(
      localStorage.getItem(LocalStorageKeys.userLoggedInfo) as string
    ) as LoginReducer)
      ? (
          JSON.parse(
            localStorage.getItem(LocalStorageKeys.userLoggedInfo) as string
          ) as LoginReducer
        ).darkMode
      : false,
  },
  reducers: {
    setShowPromotion: (state, action: PayloadAction<boolean>) => {
      state.canShowPromotion = action.payload;
  },
    setShowToototialsEffect: (state, action: PayloadAction<boolean>) => {
      state.canShowTootorialsEffect = action.payload;
    },
    setShowScheduleAppointment: (state, action: PayloadAction<boolean>) => {
      state.canShowScheduleAppointment = action.payload;
    },
    setDarkMode: (state, action: PayloadAction<boolean>) => {
      state.darkMode = action.payload;
      const storage = JSON.parse(
        localStorage.getItem(LocalStorageKeys.userLoggedInfo) as string
      ) as LoginReducer;
      storage.darkMode = action.payload;
      localStorage.setItem(LocalStorageKeys.userLoggedInfo, JSON.stringify(storage));
    },
  },
});

export const {
  setShowPromotion,
  setShowScheduleAppointment,
  setShowToototialsEffect,
  setDarkMode,
} = sessionSlice.actions;

export default sessionSlice.reducer;
