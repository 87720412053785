import { LimitsTool, Plan } from "../plans";

export interface ToolInfo {
  name: string;
  dataConfig: IDataConfig;
}
export interface IDataConfig {
  id?: string
  projectId: number;
  subdomain: string;
  websiteId: number;
  email: number;
  token: number;
  account?: string;
  password: string;
  result_cod?: number;
  result_message?: string;
  result_output?: string;
  username: string;
  payload?: IActivePayload;
}

export interface IActivePayload {
  account: string;
  password: string;
  username: string;
  account_id: number;
  invoice_id: number;
}

export interface EmailContact {
  idAddons:         number;
  suscriptionAddon: string;
  quantity:         number;
  idUser:           number;
  expired_time:     Date;
  creation_time:    Date;
  state:            number;
  isDowngradeScheduled: boolean;
  isCancellationScheduled: boolean;
  isCanceled: boolean;
}


export interface DataUser {
  id: string;
  cellphone: string;
  email: string;
  rol: "student" | "admin";
  toolsInfo: ToolInfo[];
  stateUser: number;
  isEmailMarketing: number;
  nameUser: string;
  lastUser: string;
  url_image: string;
  existPaytoolLogic: number;
  websiteFunnelActive: number | undefined | null;
  onBoarding: number;
  cancelRequest: number;
  isSeminario: number | undefined | null;
  isAffiliate: number | undefined | null;
  isRafa: number | undefined | null;
  isCrecel: number | undefined | null;
  isCopyNation: number | undefined | null;
  isGIS: number | undefined | null;
  isCrecelMex: number | undefined | null;
  isBeta: number;
  showUserProfiler: number | undefined;
  suscription: {
    idSuscription: number;
    limitsTool: LimitsTool;
    name: "Pioneros" | "Trial" | "Basic" | "Plus" | "Master" | "Lite" | "Starter" | "";
    seller: string;
    expired_time: string;
    create_time: string;
    typeSuscription: string;
    isDowngradeScheduled: boolean;
    isCancellationScheduled: boolean;
    isCanceled: boolean;
  };
  addonsInfo: {
    emailContacts: EmailContact[];
  }
  promotionInfo: {
    idPromotion?: number;
    idUser?: number;
    promotionState?: boolean;
    promotionUrl?: string;
  };
}

export interface SessionReducer {
  canShowPromotion: boolean;
  canShowTootorialsEffect: boolean;
  canShowScheduleAppointment: boolean;
}

export interface TourReducer {
  i: number;
  onTour: boolean;
}

export interface LoginReducer {
  dataUser: DataUser;
  message: string;
  token: string;
  darkMode: boolean;
  plans: Plan[];
}

export interface Reducers {
  loginReducer: LoginReducer;
  tourReducer: TourReducer;
  sessionReducer: SessionReducer;
}

export const tourReducerInitialState = (): TourReducer => ({
  i: 0,
  onTour: false,
});

export const loginReducerInitialState = (): LoginReducer => ({
  dataUser: {
    id: "",
    cellphone: "",
    stateUser: 0,
    email: "",
    lastUser: "",
    nameUser: "",
    rol: "student",
    toolsInfo: [],
    websiteFunnelActive: 0,
    onBoarding: 0,
    url_image: "",
    cancelRequest: 0,
    isSeminario: 0,
    isEmailMarketing: 0,
    isAffiliate: 0,
    isRafa: 0,
    isCrecel: 0,
    isCopyNation: 0,
    isGIS: 0,
    isCrecelMex: 0,
    isBeta: 0,
    showUserProfiler: undefined,
    existPaytoolLogic: 0,
    promotionInfo: {
      idPromotion: 0,
      idUser: 0,
      promotionState: false,
      promotionUrl: "",
    },
    suscription: {
      idSuscription: 0,
      create_time: "",
      limitsTool: {
        appointmentsReservations: {
          name: "",
          value: "",
          visible: 0,
        },
        bandwidth: {
          name: "",
          value: "",
          visible: 0,
        },
        emailMarketingSuscribers: {
          name: "",
          value: "",
          visible: 0,
        },
        funnels: {
          name: "",
          value: "",
          visible: 0,
        },
        linkAnalyticsClicks: {
          name: "",
          value: "",
          visible: 0,
        },
        linkAnalyticsDomains: {
          name: "",
          value: "",
          visible: 0,
        },
        linkAnalyticsLinks: {
          name: "",
          value: "",
          visible: 0,
        },
        linkedDomains: {
          name: "",
          value: "",
          visible: 0,
        },
        storeProducts: {
          name: "",
          value: "",
          visible: 0,
        },
        linkAnalyticsPixels: {
          name: "",
          value: "",
          visible: 0,
        },
        operatorsReservations: {
          name: "",
          value: "",
          visible: 0,
        },
        copyProjects: {
          name: "",
          value: "",
          visible: 0,
        },
      },
      expired_time: "",
      seller: "",
      name: "",
      typeSuscription: "",
      isDowngradeScheduled: false,
      isCancellationScheduled: false,
      isCanceled: false,
    },
    addonsInfo:{
      emailContacts: [],
    },
  },
  message: "",
  token: "",
  plans: [],
  darkMode: false,
});
