import {Container, Title, CustomTitle} from "../../../../styles/globalcomponents";
import {globalstyles} from "../../../../styles/animations/globalstyles";
import {CancelButtonStyled, ConfirmInputStyled, Text} from "./unsubscribe.styles";
import {Input} from "../../../../components/inputs/Input.component";
import {useState} from "react";
import {CloseableModal} from "../../../../components/ui/modal/closeable-modal.component";
import {ToastService} from "../../../../services/toastService";
import {PrecautionIcon} from "../../../../components/icons";

interface AccountCancellationAlertProps {
    onUnsubscribeUser: () => void
    isOpen: boolean
    onClose: () => void
    isEmailCancellation: boolean
}

const toastService = new ToastService()

export function AccountCancellationModal({onUnsubscribeUser, onClose, isOpen, isEmailCancellation}: AccountCancellationAlertProps) {
    const [confirmation, setConfirmation] = useState<string>("")
    const isConfirmationValid = confirmation === 'CONFIRMAR'

    function handleCancellation() {
        if(isConfirmationValid) {
            onUnsubscribeUser()
        }else {
            toastService.error('Debes escribir "CONFIRMAR"');
        }
    }

    function handleCloseCleanup() {
        setConfirmation("")
    }

    return (
        <>
            <CloseableModal isOpen={isOpen} closeModal={onClose} onCloseCleanup={handleCloseCleanup}>
                <Container flexDirection={"column"} alignItems={"center"}>
                    <PrecautionIcon width={"100px"} height={"100px"} viewBox={"0 0 100 100"}/>
                    <CustomTitle
                        color={globalstyles.colors.grayDarkness}
                        fontSize={"25px"}
                        fontWeight={"700"}
                        shadow={false}
                    >
                        ¿Estás 100% Seguro?
                    </CustomTitle>
                    <Text margin="2rem 0 1rem 0" fontSize={"0.7rem"}>
                        Escribe <strong>CONFIRMAR</strong> en el cuadro de texto para cancelar tu cuenta.
                    </Text>
                    <ConfirmInputStyled
                        value={confirmation}
                        onChange={({target}) => setConfirmation(target.value)}
                        placeholder="Escribe aquí..."
                    />
                    <CancelButtonStyled disabled={!isConfirmationValid} onClick={handleCancellation} className={"mt-2"}>
                        {isEmailCancellation ? "Desactivar herramienta" : "Proceder con la cancelación"}
                    </CancelButtonStyled>
                </Container>
            </CloseableModal>
        </>
    )
}
