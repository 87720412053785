import { BiSearch } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import styled from "styled-components";
import { globalstyles } from "../../styles/animations/globalstyles";

interface SearchBarProps {
  margin?: string;
  width?: string;
}

export const Searchbar = styled.div<SearchBarProps>`
  border-radius: 16px;
  border: 1px solid ${globalstyles.colors.gray};
  height: 32px;
  margin: ${(props) => props.margin ? props.margin : "auto"};
  position: relative;
  width: ${props => props.width ? props.width : "40%"};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 80%;
  }

  svg {
    fill: ${globalstyles.colors.gray};
  }
`;

interface ISearchInput {
  width?: string;
}

export const SearchInput = styled.input<ISearchInput>`
  border: none;
  resize: none;
  background: transparent;
  position: absolute;
  margin: 0 5px;
  right: 8px;
  top: 0;
  bottom: 0;
  color: ${globalstyles.colors.grayDarkness};
  outline: none;
  font-size: 16px;
  width: ${(props) => props.width ? props.width : "93%"};
`;

export const SearchIcon = styled(BiSearch)`
  position: absolute;
  left: 8px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 20px;
  height: 20px;
  color: white;
`;

export const ClearSearchInputIcon = styled(MdClose)`
  width: 25px;
  height: 25px;
  color: white;
  position: absolute;
  right: 4px;
  top: 0;
  bottom: 0;
  margin: auto 8px;
  cursor: pointer;
`