import { config } from "../../../config";
import { Tool } from "../../../interfaces/tools";

const toolsIconsPath = `${config.urls.res}/platform/home/icons`;

export const toolsDataMock: Tool[] = [
  {
    name: "CopyTool",
    slug: "GENERADOR-DE-COPY",
    urlImage: `${toolsIconsPath}/Icono_Copy.svg`,
    available: true,
  },
  {
    name: "FunnelTool",
    slug: "FUNNELS",
    urlImage: `${toolsIconsPath}/Icono_Funnel.svg`,
    available: true,
  },
  {
    name: "EmailTool",
    slug: "EMAIL-MARKETING",
    urlImage: `${toolsIconsPath}/Icono_Email.svg`,
    available: true,
  },
  {
    name: "LinkTool",
    slug: "LINK-ANALYTICS",
    urlImage: `${toolsIconsPath}/Icono_Link.svg`,
    available: true,
  },
  {
    name: "CRM",
    slug: "CRM",
    urlImage: `${toolsIconsPath}/Icono_Crm.svg`,
    available: true,
  },
  {
    name: "ShopTool",
    slug: "TIENDA",
    urlImage: `${toolsIconsPath}/Icono_Shop.svg`,
    available: true,
  },
  {
    name: "ConnecTool",
    slug: "CONNECTOOL",
    urlImage: `${toolsIconsPath}/Icono_connect.svg`,
    available: true,
  },
  {
    name: "VideoTool",
    slug: "VIDEOTOOL",
    urlImage: `${toolsIconsPath}/Icono_VideoTool.svg`,
    available: false,
  },
];
