import { keyframes } from "styled-components";
import { globalstyles } from "./globalstyles";

export const openUpPopUp = keyframes`
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
`;

export const rotateBox = keyframes`
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
`;

export const rotate = keyframes`
  0% { transform: rotateX(0)}
  12.5% { transform: rotateX(90deg)}
  25% { transform: rotateX(90deg)}
  37.5% { transform: rotateX(180deg)}
  50% { transform: rotateX(180deg)}
  62.5% { transform: rotateX(270deg)}
  75% { transform: rotateX(270deg)}
  87.5% { transform: rotateX(360deg)}
  100% { transform: rotateX(360deg)}
`;

export const openMenu = keyframes`
 0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
    opacity: 1;
  }
`;

export const initState = (background: string, degrees: number) => keyframes`
 0% {
    transform: scale(1);
    -webkit-transform: rotate(0deg);
  }
  100% {
    background-color: ${background};
    transform: scale(1.5);
    -webkit-transform: rotate(-${degrees}deg);
  }
`;

export const finalState = (degrees: number) => keyframes`
  0% {
    -webkit-transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(${degrees}deg);
  }
`;

export const SlideInLeft = keyframes`
  0% {
    -webkit-transform: translateX(-1000px);
            transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
`;

export const SlideInRight = keyframes`
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
`;

export const BounceInBottom = keyframes`
  0% {
    -webkit-transform: translateY(500px);
            transform: translateY(500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(63px);
            transform: translateY(63px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(28px);
            transform: translateY(28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
`;

export const openFromLeft = keyframes`
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(35px);
            filter: blur(35px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
`;

export const openFromRight = keyframes`
  0% {
    -webkit-transform: translateY(-500px);
            transform: translateY(-500px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateY(-65px);
            transform: translateY(-65px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateY(-28px);
            transform: translateY(-28px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
`;

export const flicker = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  10.1% {
    opacity: 1;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  10.2% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  20% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  20.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary};
  }
  20.6% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  30% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  30.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  30.5% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  30.6% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  45% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  45.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  50% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  55% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  55.1% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  57% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  57.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  60% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary};
  }
  60.1% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  65% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  65.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 10px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 10px ${globalstyles.colors.primary};
  }
  75% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 10px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 10px ${globalstyles.colors.primary};
  }
  75.1% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  77% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  77.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
  }
  85% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
  }
  85.1% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  86% {
    opacity: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  86.1% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
  }
  100% {
    opacity: 1;
    -webkit-box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
            box-shadow: 0 0 3px ${globalstyles.colors.primary}, 0 0 8px ${globalstyles.colors.primary}, 0 0 35px ${globalstyles.colors.lightPurple}, 0 0 10px ${globalstyles.colors.primary};
  }
`;

