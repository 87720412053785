import {ChangeEvent, useState} from "react";
import {ArrowIcon, CalendarIcon} from "../../../../components/icons";
import {DateFilterButton, DateFilterWrapper} from "./statsFilter.styles";
import {
    DropdownContentItemStyled,
    DropdownContentStyled,
    DropdownTriggerStyled,
    DropdownWrapperStyled
} from "../../../../components/dropdown/dropdown.styles";
import {ConfirmButton} from "../../../../styles/globalcomponents";
import {FilterTitles, getFiltersDatesByIndex} from "../../consts";
import {DateFilter} from "../../../../interfaces/stats";

interface StatsFilterProps {
    setValueToFilter: (value: any) => void
}

export function StatsFilter({setValueToFilter}: StatsFilterProps) {

    return (
        <>
            <FilterDropdown setValueToFilter={setValueToFilter}/>
        </>
    )
}

function FilterDropdown({setValueToFilter}: StatsFilterProps) {
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [showOptions, setShowOptions] = useState<boolean>(false)
    const [customSelectedDates, setCustomSelectedDates] = useState<DateFilter>({
        startDate: '',
        finalDate: ''
    })

    function updateCustomSelectedDates(startDate: string, finalDate: string){
        setCustomSelectedDates({
            startDate,
            finalDate
        })
        setValueToFilter({
            startDate,
            finalDate
        })
    }

    function handleSelectFilter(index: number) {
        const customDateFilterIndex = FilterTitles.length - 1
        setSelectedIndex(index)
        if(index === customDateFilterIndex) return
        const datesToFilter = getFiltersDatesByIndex(index, customSelectedDates.startDate, customSelectedDates.finalDate)
        setValueToFilter(datesToFilter)
    }

    return (
        <>
            <DropdownWrapperStyled>
                <DropdownTriggerStyled onClick={() => setShowOptions(prev => !prev)}>
                    <CalendarIcon className={"mr-1/2"} />{FilterTitles[selectedIndex]} <ArrowIcon className="ml-1/2" direction={showOptions ? 'top' : 'bottom'}/>
                </DropdownTriggerStyled>
                <DropdownContentStyled active={showOptions}>
                    <DropdownContentItemStyled onClick={() => handleSelectFilter(0)}>
                        Últimos 7 días
                    </DropdownContentItemStyled>
                    <DropdownContentItemStyled onClick={() => handleSelectFilter(1)}>
                        Últimos 14 días
                    </DropdownContentItemStyled>
                    <DropdownContentItemStyled onClick={() => handleSelectFilter(2)}>
                        Últimos 30 días
                    </DropdownContentItemStyled>
                    <DropdownContentItemStyled onClick={() => handleSelectFilter(3)}>
                        <DateSelector updateCustomSelectedDates={updateCustomSelectedDates}/>
                    </DropdownContentItemStyled>
                </DropdownContentStyled>
            </DropdownWrapperStyled>
        </>
    )
}

function DateSelector({updateCustomSelectedDates}: {updateCustomSelectedDates: (startDate: string, finalDate: string) => void }) {
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [selectedDates, setSelectedDates] = useState<DateFilter>({
        startDate: '',
        finalDate: ''
    })

    function handleEmitDates() {
        updateCustomSelectedDates(selectedDates.startDate, selectedDates.finalDate)
    }

    function handleOnChangeDatePickers({target}: ChangeEvent<HTMLInputElement>) {
        setSelectedDates(prev => ({
            ...prev,
            [target.name]: target.value
        }))
    }

    return (
        <>
            {
                showDatePicker &&
                  <DateFilterWrapper>
                    <div>Fecha inicial <input onChange={handleOnChangeDatePickers} type="date" name="startDate" id="startDate" /></div>
                    <div>Fecha final <input onChange={handleOnChangeDatePickers} type="date" name="finalDate" id="finalDate" /></div>
                    <ConfirmButton padding={".4rem .5rem"} fontSize={"13px"} onClick={handleEmitDates}>
                      Buscar
                    </ConfirmButton>
                  </DateFilterWrapper>
            }
            <DateFilterButton className={showDatePicker ? 'mt-1' : ''} onClick={() => setShowDatePicker(prevState => !prevState)}>
                <strong>{showDatePicker ? 'Remover' : 'Personalizar' }</strong>
            </DateFilterButton>
        </>
    )
}
