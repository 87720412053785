import styled from "styled-components";
import {globalstyles} from "../../styles/animations/globalstyles";


export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const ProgressBarWrapper = styled.div`
  display: flex;
  background-color: ${globalstyles.colors.darkenGreen};
  border-radius: 10px;
  width: 100%;
  height: .6rem;
  margin: .2rem 0;
  overflow: hidden;
  
`

interface ProgressBarLineProps {
    percentage: number
}
export const ProgressBarLine = styled.div<ProgressBarLineProps>`
  width: ${(props) => `${props.percentage}%`};
  background-color: ${globalstyles.colors.secondary};
  border-radius: 10px;
`
export const ProgressBarNumberIndicator = styled.div`
  display: flex;
  justify-content: center;
  font-size: .7rem;
  border-radius: 10px;
`
