import { instance } from "../axios/axiosConfig";
import { FunnelUser } from "../interfaces/funnels";
export class SimvolyService {
  async login(funnelUser: FunnelUser) {
    const response = await instance.post(
      `/app/funnels/signin/${funnelUser.idUser}`,
      {
        idProject: funnelUser.idProject,
      }
    );

    return response.data;
  }
  async activatePlan(funnelUser: FunnelUser) {
    const response = await instance.post(
      `/app/users/activatePlanFunnel`,
      {
        projectId: funnelUser.idProject,
      },
      {
        headers: {
          Authorization: `Bearer ${funnelUser.userToken}`,
        },
      }
    );

    return response.data;
  }
}
