import styled from "styled-components";
import {globalstyles} from "../../../styles/animations/globalstyles";

interface DialogStyledProps {
    isOpen: boolean
}

export const DialogStyled = styled.div<DialogStyledProps>`
    position: relative;
    border: none;
    border-radius: 28px;
    width: 96%;
    min-height: 10rem;
    background-color: #FBFBFB;
    overflow: hidden;
    padding: 2rem 2.5rem;
  
    @media(min-width: ${globalstyles.sizes.desktop}) {
      max-width: fit-content;
    }
`

export const DialogBackdropStyled = styled.div<DialogStyledProps>`
    display: ${(props) => props.isOpen ? "flex": "none"};
    position: fixed;
    background-color: rgba(0, 0, 0, 0.25);
    inset: 0;
    z-index: 101;
    justify-content: center;
    align-items: center;
`

export const DialogContentStyled = styled.div`
    overflow-y: auto;
    max-height: 99vh;

  @media (min-width: ${globalstyles.sizes.lg}) {
    max-height: 88vh;
  }
`

export  const CloseButtonStyled = styled.button`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
  background-color: transparent;
  color: black;
  font-style: italic;
  border: none;
  cursor: pointer;

  &:hover {
    border-color: transparent;
  }
  
`
