import styled from "styled-components";
import { center } from "src/styles/mixins/minxins";
import { globalstyles } from "src/styles/animations/globalstyles";
import {
  finalState,
  initState,
  openMenu,
} from "src/styles/animations/globalAnimations";

export const FloatTools = styled.div`
  display: none;
  
  @media (max-width: ${globalstyles.sizes.mobile}) {
    display: flex;
  }
`;

interface IFloatButton {
  activeMenu: boolean;
}

export const FloatButton = styled.div<IFloatButton>`
  animation: ${(props) => (props.activeMenu ? initState(globalstyles.colors.orange, 360) : finalState(360))} 1s
    cubic-bezier(0.94, 0.46, 0.3, 0.94) both;
  ${center.centerDesktop};
  background-color: ${globalstyles.colors.yellow};
  border-radius: 50%;
  bottom: 4rem;
  font-size: 1.5rem;
  height: 60px;
  position: fixed;
  right: 1rem;
  width: 60px;
  z-index: 200;
  `;

export const FloatMenu = styled.div`
  background-color: #ffffffde;
  bottom: 0;
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  justify-content: center;
  align-items: center;
`;

export const ToolsLayer = styled.div`
  ${center.centerDesktop};
  animation: ${openMenu} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  -webkit-animation: ${openMenu} 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
`;
