import { useEffect, useState } from "react";
import { substractDays } from "src/lib/datesLib";
import { DateTag } from "./dateSubscriptionInfo.styles";
import {CustomText} from "../../styles/globalcomponents";

interface IDateSubscriptionInfo {
  expired: Boolean | undefined;
  canceledPlan: boolean;
  expirationDate: string;
  subtractDays?: boolean
}

const DateSubscriptionInfo = ({ expired, canceledPlan, expirationDate, subtractDays }: IDateSubscriptionInfo) => {
  let realPaymentDay = expirationDate;
  if(subtractDays) {
    realPaymentDay = substractDays(new Date(expirationDate), 4);
  }

  return (
    <DateTag expired={expired}>
      <p>
        {
          expired ?
            <CustomText fontSize={"12px"} fontWeight={"700"} color={"white"}>
              Suscripción expirada
            </CustomText>
            :
            canceledPlan ?
              <>Disponible hasta: <strong>{realPaymentDay}</strong></>
              :
              <>Fecha de expiración: <strong>{realPaymentDay}</strong></>
        }
      </p>
    </DateTag>
  );
};

export default DateSubscriptionInfo;
