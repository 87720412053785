import { Account } from "src/pages/profile/account/account.component";
import { Preferences } from "src/pages/profile/preferences/preferences.component";
import { instance } from "src/axios/axiosConfig";
import {
  IBillingData,
  IBillingResume,
} from "src/interfaces/profile/profileInterface";
import Billing from "src/pages/profile/billing/billing.component";
import {SubscriptionTab} from "../pages/profile/subscription/subscriptionTab.component";

export const profileTabs = [
  {
    name: "Mi perfil",
    hash: "#account",
    comp: <Account />,
  },
  {
    name: "Mis suscripciones",
    hash: "#my-subscriptions",
    comp: <SubscriptionTab />,
  },
  {
    name: "Facturación",
    hash: "#billing",
    comp: <Billing />,
  },
  {
    name: "Preferencias",
    hash: "#preferences",
    comp: <Preferences />,
  },
];

export const billingResume = async (email: string) => {
  let res: IBillingResume | IBillingData[] | undefined;
  try {
    const response = await instance.get(`/app/paytool/history/${email}`);
    res = response.data.idUser;
  } catch (error) {
    res = undefined;
  }

  return res;
};
