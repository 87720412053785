import styled, {css} from 'styled-components'
import {globalstyles} from "../../../../styles/animations/globalstyles";


export const OrderDetailsListStyled = styled.ul`
    appearance: none;
    list-style: none;
    padding: .3rem .2rem;
    max-height: 8rem;
    overflow-y: auto;
    scrollbar-gutter: stable;
`

interface OrderDetailsListItemStyledProps {
    legendColor?: string
}

export const OrderDetailsListItemStyled = styled.li<OrderDetailsListItemStyledProps>`
  display: grid;
  grid-template-columns: ${(props) => props.legendColor !== undefined ? "7% 1fr 10%" : "1fr 10%" };
  align-items: center;
  font-size: 13px;
  color: ${globalstyles.colors.gray};
  letter-spacing: -0.13px;
  padding: .3rem 0;

  ${(props) => props.legendColor !== undefined && css`
    &::before {
      content: "";
      display: block;
      justify-self: center;
      width: .8em;
      border-radius: 2px;
      aspect-ratio: 1;
      background-color: ${props.legendColor};
    }
  
  `}
  
  &:not(:last-child) {
    border-bottom: 1px solid ${globalstyles.colors.gray};
  }
  
`
