import {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hooks/useRedux";
import {setUserAsync} from "../../../../redux/slices/auth";
import {AuthService} from "../../../../services/authService";
import {ResetPasswordService} from "../../../../services/resetPasswordService";
import {ToastService} from "../../../../services/toastService";
import {Input} from "../../../../components/inputs/Input.component";
import {
    ConfirmButton,
    Container,
    ContainerItem,
} from "../../../../styles/globalcomponents";
import {Label} from "../../profile.styles";

export const AccountForm = () => {
    const dataUser = useAppSelector((state) => state.auth.dataUser);
    const darkMode = useAppSelector((state) => state.session.darkMode);
    const dispatch = useAppDispatch();
    const [form, setForm] = useState({
        name: dataUser.nameUser,
        lastname: dataUser.lastUser,
        password: "",
        newPassword: "",
        repeatPassword: "",
    });
    const [formInitialState, setFormInitialState] = useState({
        name: dataUser.nameUser,
        lastname: dataUser.lastUser,
        password: "",
        newPassword: "",
        repeatPassword: "",
    });
    const isDisabledUpdateUserInfo = !(form.name !== formInitialState.name || form.lastname !== formInitialState.lastname)
    const isDisabledUpdatePassword = !(form.password !== "" && form.newPassword !== "" && form.repeatPassword !== "" && form.newPassword === form.repeatPassword)

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const updatePassword = async () => {
        const resetPasswordService = new ResetPasswordService();
        const toastService = new ToastService();
        try {
            await resetPasswordService.changePassword(
                dataUser.email,
                form.password,
                form.newPassword
            );
            toastService.success("Se ha actualizado su contraseña");
        } catch (error) {
            toastService.error("No se ha podido actualizar su contraseña");
        }
    };

    const updateInfo = async () => {
        const authService = new AuthService();
        const toastService = new ToastService();
        try {
            if (isDisabledUpdateUserInfo) {
                await authService.changeUserInfo(dataUser.id, form.name, form.lastname);
                await dispatch(setUserAsync());
                toastService.success("Sus datos fueron cambiados correctamente");
                return setFormInitialState((prevState) => ({
                    ...prevState,
                    name: form.name,
                    lastname: form.lastname,
                }));
            }
            toastService.warning("No han habido cambios en el formulario");
        } catch (error) {
            toastService.error("Ha ocurrido un error al cambiar sus datos");
        }
    };

    return (
        <>
            <Container
                flexDirection={"column"}
                justifyContent={"space-between"}
                height={"100%"}
                gap={"10px"}
            >
                <Container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    borderRadius={"36px"}
                    backgroundColor={"white"}
                    padding="20px 35px"
                    margin={"0"}
                    height={"100%"}
                >
                    <Container flexDirection={"column"} gap={"15px"}>
                        <ContainerItem
                            display={"grid"}
                            colGap={"1rem"}
                            flexDirection={"column"}
                            responsiveLg={"grid-template-columns: 1fr 1fr"}
                        >
                            <Container flexDirection={"column"}>
                                <Label darkMode={darkMode}>Nombre</Label>
                                <Input
                                    borderColor={"#A5A5A5"}
                                    backgroundColor={"white"}
                                    padding={"10px 15px"}
                                    placeholder="ingresa tu nombre"
                                    value={form.name}
                                    name="name"
                                    icon="profile"
                                    onChange={onChange}
                                    theme={darkMode ? "darkMode" : "dark"}
                                />
                            </Container>
                            <Container flexDirection={"column"}>
                                <Label darkMode={darkMode}>Apellido</Label>
                                <Input
                                    placeholder="ingresa tu apellido"
                                    borderColor={"#A5A5A5"}
                                    backgroundColor={"white"}
                                    padding={"10px 15px"}
                                    name="lastname"
                                    icon="userProfile"
                                    value={form.lastname}
                                    onChange={onChange}
                                    theme={darkMode ? "darkMode" : "dark"}
                                />
                            </Container>
                        </ContainerItem>
                        <ContainerItem>
                            <Label darkMode={darkMode}>Correo</Label>
                            <Input
                                placeholder="ingresa tu correo"
                                defaultValue={dataUser.email}
                                borderColor={"#A5A5A5"}
                                backgroundColor={"white"}
                                padding={"10px 15px"}
                                readOnly
                                icon="email"
                                theme={darkMode ? "darkMode" : "dark"}
                            />
                        </ContainerItem>
                    </Container>
                    <Container height={"100%"} justifyContent={"center"} alignItems={"center"} margin={"15px 0 0"}>
                        <ConfirmButton
                            disabled={isDisabledUpdateUserInfo}
                            onClick={updateInfo}
                        >
                            Actualizar
                        </ConfirmButton>
                    </Container>
                </Container>
                <Container
                    display={"grid"}
                    gridTemplateColumns={"1fr"}
                    borderRadius={"36px"}
                    backgroundColor={"white"}
                    padding="20px 35px"
                    margin={"0"}
                    height={"100%"}
                >
                    <Container flexDirection={"column"} gap={".7rem"}>
                        <Container
                            display={"grid"}
                            colGap={"1rem"}
                            rowGap={"20px"}
                            flexDirection={"column"}
                            responsiveLg={"grid-template-columns: 1fr 1fr"}
                        >
                            <ContainerItem>
                                <Label darkMode={darkMode}>Nueva contraseña</Label>
                                <Input
                                    autoComplete={"off"}
                                    color={"black"}
                                    placeholder="Ingresa tu nueva contraseña"
                                    borderColor={"#A5A5A5"}
                                    backgroundColor={"white"}
                                    padding={"8px 15px"}
                                    icon="password"
                                    name="newPassword"
                                    type="password"
                                    onChange={onChange}
                                    value={form.newPassword}
                                    theme={darkMode ? "darkMode" : "dark"}
                                />
                            </ContainerItem>
                            <ContainerItem>
                                <Label darkMode={darkMode}>Confirmar contraseña</Label>
                                <Input
                                    autoComplete={"off"}
                                    color={"black"}
                                    placeholder="Confirma tu nueva contraseña"
                                    borderColor={"#A5A5A5"}
                                    backgroundColor={"white"}
                                    padding={"8px 15px"}
                                    icon="password"
                                    name="repeatPassword"
                                    type="password"
                                    onChange={onChange}
                                    value={form.repeatPassword}
                                    theme={darkMode ? "darkMode" : "dark"}
                                />
                            </ContainerItem>
                            <Container>
                                <ContainerItem flexDirection={"column"}>
                                    <Label darkMode={darkMode}>Contraseña actual</Label>
                                    <Input
                                        autoComplete={"off"}
                                        color={"black"}
                                        placeholder="Ingresa tu contraseña actual"
                                        width="100%"
                                        borderColor={"#A5A5A5"}
                                        backgroundColor={"white"}
                                        padding={"8px 15px"}
                                        icon="password"
                                        name="password"
                                        type="password"
                                        onChange={onChange}
                                        value={form.password}
                                        theme={darkMode ? "darkMode" : "dark"}
                                    />
                                </ContainerItem>
                            </Container>
                        </Container>
                    </Container>
                    <Container height={"100%"} justifyContent={"center"} margin={"15px 0 0"} alignItems={"center"}>
                        <ConfirmButton
                            disabled={isDisabledUpdatePassword}
                            onClick={updatePassword}
                        >
                            Actualizar
                        </ConfirmButton>
                    </Container>
                </Container>
            </Container>
        </>
    );
};
