import styled from "styled-components";
import { globalstyles } from "../../../styles/animations/globalstyles";

type SeparatorProps = {
  darkMode?: boolean;
}

export const Separator = styled.div<SeparatorProps>`
  font-size: 18px;
  font-weight: bolder;
  margin: auto 8px;
  color: ${props => props.darkMode ? "white" : "black"};
`;

type PreferenceLinkProps = {
  darkMode?: boolean;
}

export const PreferenceLink = styled.div<PreferenceLinkProps>`
  font-size: 14px;
  margin-left: 8px;
  font-weight: bolder;
  border-bottom: 1px solid ${props => props.darkMode ? "white" : "black"};
  text-align: center;
  cursor: pointer;
  color: ${props => props.darkMode ? "white" : "black"};
  @media (max-width: ${globalstyles.sizes.mobile}) {
    font-size: 12px;
  }
`;

type PreferenceTextProps = {
  darkMode?: boolean;
}

export const PreferenceText = styled.div<PreferenceTextProps>`
  font-size: 14px;
  margin-left: 8px;
  font-weight: bolder;
  color: ${props => props.darkMode ? "white" : "black"};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    font-size: 12px;
  }
`;

type PreferenceContainerProps = {
  darkMode: boolean;
}

export const PreferenceContainer = styled.div<PreferenceContainerProps>`
  background-color: ${props => props.darkMode ? "#4d4d4d" : "#f7f7f7"};
  border-radius: 32px;
  padding: 8px;
  display: flex;
  align-items: center;
  margin: 8px 0;
`;
