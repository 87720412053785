import { Widget } from "@typeform/embed-react";
import { AuthService } from "src/services/authService";
import { useAppDispatch } from "../../hooks/useRedux";
import { setShowUserProfiler } from "../../redux/slices/auth";
import { TypeFormProfiler } from "./typeform.styles";
import "@typeform/embed/build/css/popup.css";
interface ITypeFormProps {
  idUser: string;
  email: string;
  onFormSubmit?: () => void;
}

const TypeForm = ({ idUser, email, onFormSubmit }: ITypeFormProps) => {
  const authService = new AuthService();
  const dispatch = useAppDispatch();

  const onSubmit = (event: any) => {
    onFormSubmit && onFormSubmit();
    dispatch(setShowUserProfiler(1));
    authService.updateUserProfiler(email);
  };

  return (
    <TypeFormProfiler>
      <Widget
        id="epgS8Syc"
        style={{ width: "100%", height: "100%" }}
        onSubmit={onSubmit}
        hidden={{ idUser: idUser, email: email }}
      />
    </TypeFormProfiler>
  );
};

export default TypeForm;
