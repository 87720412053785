import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlanFrequency} from "../../interfaces/plans";
import {
    AdditionalToolEmailSpecifications
} from "../../pages/profile/subscription/plan/tabs/planDescriptionTab/additionalTool/addons.data";


export interface Step {
    id: number,
    name: string,
    done: boolean
};

export type AdditionalTools = 'EmailTool'

interface AdditionalTool {
    index: number
    name: AdditionalTools,
    quantity: number,
    price: number
    code?: string
    addonPlanName?: string,
    specifications: string[]
}

interface CheckoutContext {
    payOnlyAdditionalTools: boolean
    steps: Step[];
    currentStep: number
    selectedPlan?: { id: number, frequency: PlanFrequency }
    additionalTools: AdditionalTool[] | []
    paymentId?: number,
    isLoading: boolean;
    isReadyToPay: boolean
}

export const STEPS_INDEXES = {
    selectPlan: 1,
    planDescription: 2,
    paymentMethod: 3,
}

const initialAdditionalTools: AdditionalTool[] = [
    {
        index: 0,
        name: 'EmailTool',
        quantity: 0,
        price: 0,
        specifications: AdditionalToolEmailSpecifications
    }
]

const initialState: CheckoutContext = {
    payOnlyAdditionalTools: false,
    currentStep: 1,
    additionalTools: initialAdditionalTools,
    steps: [
        {
            id: 1,
            name: "Select plan",
            done: false
        },
        {
            id: 2,
            name: "Plan description",
            done: false
        },
        {
            id: 3,
            name: "Payment method",
            done: false
        }
    ],
    isLoading: false,
    isReadyToPay: false
};

const checkoutSlice = createSlice({
    name: "checkout",
    initialState,
    reducers: {
        setPlan(state, action: PayloadAction<{id: number, frequency: PlanFrequency}>) {
            state.selectedPlan = action.payload
        },
        completeStep(state, action: PayloadAction<{ stepId: number }>) {
            const allPreviousStepsAreDone = state.steps.every(step => step.done === true)
            const stepId = action.payload.stepId
            const stepIndex = state.steps.findIndex(step => step.id === stepId)
            const hasInvalidIndex = stepIndex < 0
            if(hasInvalidIndex) return state
            state.steps[stepIndex] = { ...state.steps[stepIndex], done: true }
            state.isReadyToPay = !!allPreviousStepsAreDone;
        },
        moveToFollowingStep(state, action: PayloadAction<{stepId: number}>) {
            const stepId = action.payload.stepId
            const previousSteps = state.steps.filter(step => step.id < stepId)
            const allPreviousStepsAreDone = previousSteps.every(step => step.done === true)

            if(previousSteps.length === 0) {
                state.currentStep = 1
            }else if(state.payOnlyAdditionalTools) {
                state.currentStep = stepId
            }else if(allPreviousStepsAreDone) {
                state.currentStep = stepId
            }
        },
        addAdditionalTool(state, action: PayloadAction<{index: number, name: AdditionalTools, quantity: number, price: number, code?: string, addonPlanName?: string, specifications: string[]}>) {
            const {
                name,
                quantity,
                price,
                code,
                index,
                addonPlanName,
                specifications
            } = action.payload
            const toolIndex = state.additionalTools.findIndex((tool) => tool.name === name)
            const isToolPresent = state.additionalTools.find((tool) => tool.name === name)
            if(!isToolPresent) {
                state.additionalTools = [...(state.additionalTools), {index, name, quantity, price, code, addonPlanName, specifications}]
            }else {
                state.additionalTools[toolIndex] = {index, name, quantity, price, code, addonPlanName, specifications}
            }
            if(state.payOnlyAdditionalTools) {
                const selectPlanStateIndex = state.steps.findIndex(element => element.id === STEPS_INDEXES.selectPlan)
                state.steps[selectPlanStateIndex] = {...state.steps[selectPlanStateIndex], done: true}
            }
        },
        removeAdditionalTool(state, action: PayloadAction<{toolName: AdditionalTools}>) {
            const { toolName } = action.payload
            const isToolPresent = state.additionalTools.find((tool) => tool.name === toolName)
            const toolIndex = state.additionalTools.findIndex((tool) => tool.name === toolName)

            if(isToolPresent && toolIndex !== -1) {
                const baseSpecifications = initialAdditionalTools.find(element => element.name === toolName)?.specifications
                state.additionalTools[toolIndex] = {
                    index: 0,
                    name: toolName,
                    quantity: 0,
                    price: 0,
                    specifications:  baseSpecifications ?? []
                }
            }
        },
        resetCheckoutState(state) {
            Object.assign(state, initialState)
        },
        setPayOnlyAdditionalTools(state, action: PayloadAction<{isOnlyAdditionalTools: boolean}>) {
            state.payOnlyAdditionalTools = action.payload.isOnlyAdditionalTools
        }
    }
});

export const {
    setPlan,
    completeStep,
    moveToFollowingStep,
    addAdditionalTool,
    removeAdditionalTool,
    resetCheckoutState,
    setPayOnlyAdditionalTools
} = checkoutSlice.actions
export default checkoutSlice.reducer;
