import {CloseableModal, CloseableModalProps} from "../../../../../components/ui/modal/closeable-modal.component";
import {Container} from "../../../../../styles/globalcomponents";
import {AcceptButtonStyled} from "../unsubscribe.styles";
import {PrecautionIcon, SuccessIcon} from "../../../../../components/icons";
import {useHistory} from "react-router-dom";
import {ToastService} from "../../../../../services/toastService";
import {useAppDispatch} from "../../../../../hooks/useRedux";
import {loginReducerInitialState} from "../../../../../interfaces/redux";
import {closeSession} from "../../../../../redux/slices/auth";
import {LocalStorageKeys} from "../../../../../redux/localStorageKeys";

interface ResultChangePlanModalProps extends Omit<CloseableModalProps, "children">{
    isApproved: boolean
}

const toast = new ToastService()
export function ResultChangePlanModal({isOpen, closeModal, isApproved}: ResultChangePlanModalProps) {
    const history = useHistory()
    const dispatch = useAppDispatch()

    function handleCloseModal() {
        closeModal()
        if(isApproved) {
            toast.warning("Te pediremos que vuelvas a iniciar sesión...")
        }else {
            toast.warning("Contacta a nuestro equipo de soporte si tienes algún error 😉 ...redirigiendo al perfil")
        }
        setTimeout(() => {
            history.push("/profile#account")
            localStorage.removeItem(LocalStorageKeys.userLoggedInfo);
            const closeSessionUser = loginReducerInitialState();
            dispatch(closeSession(closeSessionUser));
        }, 4000)
    }

    return (
        <>
            <CloseableModal isOpen={isOpen} closeModal={closeModal}>
                <Container flexDirection={"column"} alignItems={"center"} maxWidth={"25rem"}>
                    {
                        isApproved ?
                            <SuccessIcon width={"100px"} height={"100px"} viewBox={"0 0 110 110"}/>
                        :
                            <PrecautionIcon width={"100px"} height={"100px"} viewBox={"0 0 100 100"}/>
                    }
                    <h2 className={"text-center"}>
                        {isApproved ? "¡Todo en orden!" : "¡Houston! Tenemos un problema"}
                    </h2>
                    <p className="text-center">
                        {
                            isApproved ?
                                "Puedes estar tranquilo/a, tu nuevo plan de MasterTools ha sido actualizado!"
                            :
                                "No nos gustan las malas noticias, pero algo salió mal en la transacción! Por favor habla con nuestro equipo para encontrar la mejor solución ;)"
                        }
                    </p>
                    <AcceptButtonStyled onClick={handleCloseModal} className={"mt-2"}>
                        {
                            isApproved ?
                                "¡Qué bien!"
                                :
                                "¡Quiero ayuda!"
                        }
                    </AcceptButtonStyled>
                </Container>
            </CloseableModal>
        </>
    )
}
