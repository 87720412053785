import { current } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { Card, Container, CustomText } from "src/styles/globalcomponents";
import { BlackFridayCounterStyled, CountdownButtonStyled, CountdownSectionStyled, CountdownTitleStyled, CountdownWrapperStyled } from "./blackFridayCounter.styled";
import { config } from "src/config";

interface BlackFridayCounterProps {
    startDate: Date
    finalDate: Date
    onClickCountdown?: () => void
    onCountdownEnds?: () => void
    mode?: 'FULL_PAGE' | 'BANNER'
}

export function CiberMondayCounter({ startDate, finalDate, onCountdownEnds, onClickCountdown, mode = 'BANNER' }: BlackFridayCounterProps) {
    const [timeRemaining, setTimeRemaining] = useState<number>(0);
    const now = new Date()
    const isDateBeforeEvent = now.getTime() < startDate.getTime() && now.getTime() < finalDate.getTime()
    const isDateAfterEvent = now.getTime() > startDate.getTime() && now.getTime() > finalDate.getTime()
    const isDateBetweenEvent = now.getTime() > startDate.getTime() && now.getTime() < finalDate.getTime()

    function formatTime(time: number) {
        const seconds = Math.floor((time / 1000) % 60);
        const minutes = Math.floor((time / (1000 * 60)) % 60);
        const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
        const days = Math.floor(time / (1000 * 60 * 60 * 24));

        return (
            <BlackFridayCounterStyled mode={mode} backgroundUrl={`${config.urls.cdnOptimized}/platform/ciber-days/ciber-days-bg.png?format=png&width=500`}>
                <img width={300} height={220} src={`${config.urls.cdnOptimized}/platform/ciber-days/ciber-days-tag.png?format=png&width=300`} alt="Black Friday llegó" />
                {
                    mode === "FULL_PAGE" &&
                    <span>Estas son las últimas ofertas disponibles, solo durante el Cyber Monday</span>
                }
                <Container flexDirection="column" alignItems="center" gap="1.5rem">
                    <CountdownTitleStyled>
                        {isDateBeforeEvent && 'COMIENZA EN:'}
                        {isDateBetweenEvent && 'OFERTA DISPONIBLE HASTA:'}
                    </CountdownTitleStyled>
                    <CountdownWrapperStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{days.toString().padStart(2, "0")[0]}</div>
                                <div>{days.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Días</span>
                        </CountdownSectionStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{hours.toString().padStart(2, "0")[0]}</div>
                                <div>{hours.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Horas</span>
                        </CountdownSectionStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{minutes.toString().padStart(2, "0")[0]}</div>
                                <div>{minutes.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Minutos</span>
                        </CountdownSectionStyled>
                        <CountdownSectionStyled>
                            <article>
                                <div>{seconds.toString().padStart(2, "0")[0]}</div>
                                <div>{seconds.toString().padStart(2, "0")[1]}</div>
                            </article>
                            <span>Segundos</span>
                        </CountdownSectionStyled>
                    </CountdownWrapperStyled>
                    {
                        isDateBetweenEvent && mode === 'BANNER' &&
                        <CountdownButtonStyled onClick={onClickCountdown}>
                            Conocer Oferta
                        </CountdownButtonStyled>
                    }
                    {
                        mode === 'FULL_PAGE' &&
                        <CustomText color="white" fontSize=".7rem" textAlign="center">*Estas ofertas al tratarse de una suscripción no tienen reembolso</CustomText>
                    }
                </Container>
            </BlackFridayCounterStyled>
        );
    }

    useEffect(() => {
        const countdownInterval = setInterval(() => {
            const currentTime = new Date().getTime()

            if (isDateBeforeEvent) {
                const eventTime = startDate.getTime()
                let remainingTime = eventTime - currentTime

                if (remainingTime <= 0) {
                    remainingTime = 0
                    clearInterval(countdownInterval)
                }

                setTimeRemaining(remainingTime)
                return
            }
            if (isDateBetweenEvent) {
                let remainingTime = finalDate.getTime() - currentTime

                if (remainingTime <= 0) {
                    remainingTime = 0
                    clearInterval(countdownInterval)
                    onCountdownEnds?.()
                }
                setTimeRemaining(remainingTime)
                return
            }
            if (isDateAfterEvent) {
                onCountdownEnds?.()
                return
            }
        }, 1000)

        return () => clearInterval(countdownInterval)
    }, [onCountdownEnds, finalDate, startDate, isDateBeforeEvent, isDateAfterEvent, isDateBetweenEvent]);

    return (<>
        <Card onClick={() => isDateBetweenEvent && onClickCountdown?.()} style={{ cursor: "pointer", margin: mode === 'FULL_PAGE' ? '2rem 0 0' : '', overflow: 'visible' }}>
            {formatTime(timeRemaining)}
        </Card>
    </>);
}
