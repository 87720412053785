import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Integrations from "src/pages/integrations/integrations.component";
import { MainLayout } from "../components/mainLayout/mainLayout.component";
import { Begin } from "../pages/begin/begin.component";
import { Checkout } from "../pages/checkout/checkout.component";
import { Community } from "../pages/community/community.component";
import { FreeTrial } from "../pages/freeTrial/freeTrial.component";
import { HomePage } from "../pages/home/home.component";
import { Login } from "../pages/login/login.component";
import { NotFound } from "../pages/notFound/notFound.component";
import { ProfilePage } from "../pages/profile/profile.page";
import { ResetPassword } from "../pages/resetPassword/resetPassword.component";
import { Mail } from "../pages/support/mail/mail.component";
import { Support } from "../pages/support/support.component";
import { Tools } from "../pages/tools/tools.component";
import { Tootorial } from "../pages/tootorials/tootorial/tootorial.component";
import { Tootorials } from "../pages/tootorials/tootorials.component";
import { AppAuth } from "./AppAuth";
import { ShopifyIntegrationPage } from "src/pages/shopifyIntegration/shopifyIntegration.page";
import {StatsPage} from "../pages/stats/stats.page";
import {GoogleIntegrationPage} from "../pages/googleIntegration/googleIntegrationPage";

export const AppRouter = () => {
  const location = useLocation();

  if (location.pathname === "/freeTrial") {
    window.open("https://pay.hotmart.com/J55957736J?off=xeo0zgne", "_self");
    return <></>;
  }

  return (
    <MainLayout>
      <Switch>
        <AppAuth exact path={"/home"} comp={HomePage} />
        <AppAuth exact path={"/stats"} comp={StatsPage} />
        <AppAuth exact path={"/support"} comp={Support} />
        <AppAuth exact path={"/support/mail"} comp={Mail} />
        <AppAuth exact path={"/begin"} comp={Begin} />
        <AppAuth exact path={"/resetPassword"} comp={ResetPassword} />
        <AppAuth exact path={"/profile"} comp={ProfilePage} />
        <AppAuth exact path={"/tootorials"} comp={Tootorials} />
        <AppAuth exact={false} path={"/tootorials/:id"} comp={Tootorial} />
        <AppAuth exact path={"/community"} comp={Community} />
        <AppAuth exact path={"/shopify-integration/:token"} comp={ShopifyIntegrationPage} />
        <AppAuth exact path={"/google-integration/:token"} comp={GoogleIntegrationPage} />
        <AppAuth exact path={"/checkout"} comp={Checkout} />
        <AppAuth exact path={"/freeTrial"} comp={FreeTrial} />
        <AppAuth exact path={"/funnels"} comp={Tools} />
        <AppAuth exact path={"/integrations"} comp={Integrations} />
        <AppAuth exact path={"/shop"} comp={Tools} />
        <AppAuth exact path={"/email"} comp={Tools} />
        <AppAuth exact path={"/email/initial/version"} comp={Tools} />
        <AppAuth exact path={"/crm"} comp={Tools} />
        <AppAuth exact path={"/linktool"} comp={Tools} />
        <AppAuth exact path={"/copy"} comp={Tools} />
        <AppAuth exact path={"/connect"} comp={Tools} />
        <AppAuth exact path={"/login/:token"} comp={Login} />
        <AppAuth exact path={"/login"} comp={Login} />
        <Route exact path={"/"}>
          <Redirect to={"/login"} />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </MainLayout>
  );
};
