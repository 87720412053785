import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

export const ReminderContainer = styled.div`
  align-items: center;
  background-color: ${globalstyles.colors.secundaryOrange};
  border-radius: 10px;
  display: flex;
  height: 40px;
  justify-content: space-between;
  margin: 0 auto 10px auto;
  padding: 0 1rem;
  top: 0;
  width: 80%;

  h4 {
    color: white !important;
  }

  @media (max-width: ${globalstyles.sizes.mobile}) {
    border-radius: 1rem;
    flex-direction: column;
    justify-content: center;
    margin: 1rem auto;
    padding: 4.5rem 0.5rem;
    width: 87%;

    span {
      flex-direction: column;
      margin: 5px 0;
    }

    h4 {
      margin: 5px 0 !important;
    }
  }
`;

export const MainBannerText = styled.span`
  align-items: center;
  color: white;
  display: flex;
`;

export const CallToAction = styled.span`
  border: 1px solid white;
  border-radius: 1rem;
  color: white;
  cursor: pointer;
  font-size: 12px;
  margin: 0 5px;
  padding: 6px 10px;
`;

export const PopUpCallToAction = styled.span`
  cursor: pointer;
  text-decoration: underline;
  margin: 0 5px;
`;

export const BlackweekTag = styled.span`
  background-color: black;
  color: white;
  font-weight: bold;
  margin: 0 5px;
  padding: 0.3rem;
`;
