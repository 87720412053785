import {DialogHTMLAttributes, ReactNode, useEffect, useRef} from 'react';
import {DialogBackdropStyled, DialogContentStyled, DialogStyled} from "./modal.styles";

interface Props extends DialogHTMLAttributes<HTMLDialogElement> {
    isOpen: boolean;
    children: ReactNode;
    closeModal: () => void;
    onCloseCleanup?: () => void
}

type isPositionIntoModalArea = {
    clickX: number;
    clickY: number;
};

/**
 * @param isOpen is a boolean that open or close the modal depends on
 * the value that you pass
 * @param closeModal is a function to close the modal, this function must be
 * interact with the last param (isOpen), to close the modal and save the
 * state in the parent component (parent is the modal caller)
 * @param onCloseCleanup is a function that calls when modal is closed
 * @param children is a ReactNode to render anything you want as modal content
 * ```
 function App() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    function handleShowModal() {
      setIsModalOpen(true);
    }

    function handleCloseModal() {
      setIsModalOpen(false);
    }

    return (
      <div className="App">
        <button onClick={handleShowModal}>Click me</button>
        <Modal
          isOpen={isModalOpen}
          closeModal={handleCloseModal}
        >
          <p>Modal content</p>
        </Modal>
      </div>
    );
  }
 * ```
 */
export function Modal({isOpen = false, children, closeModal, onCloseCleanup}: Props) {
    const modalRef = useRef<HTMLDivElement>(null);

    function changeBodyOverflow(remove: boolean) {
        remove ? document.body.classList.add("overflow-hidden") : document.body.classList.remove("overflow-hidden")
    }

    function handleCloseModal() {
        changeBodyOverflow(false)
    }

    function handleOpenModal() {
        changeBodyOverflow(true)
    }

    useEffect(() => {
        isOpen ? handleOpenModal() : handleCloseModal();
        isOpen && modalRef.current?.addEventListener('click', handleBackdropClick);

        if( !isOpen && onCloseCleanup) {
            onCloseCleanup()
        }

        function handleBackdropClick(evt: MouseEvent) {
            isPositionIntoModalArea({
                clickX: evt.clientX,
                clickY: evt.clientY,
            });
        }

        function isPositionIntoModalArea({
             clickX,
             clickY,
         }: isPositionIntoModalArea) {
            const modalRect = modalRef.current?.getBoundingClientRect();

            if (
                modalRect &&
                (clickY < modalRect?.top ||
                    clickY > modalRect?.bottom ||
                    clickX < modalRect?.left ||
                    clickX > modalRect?.right)
            ) {
                closeModal();
            }
        }

        return () => {
            isOpen && modalRef.current?.removeEventListener('click', handleBackdropClick);
        };
    }, [isOpen]);

    return (
        <DialogBackdropStyled isOpen={isOpen}>
            <DialogStyled ref={modalRef} isOpen={isOpen}>
                <DialogContentStyled>
                    {children}
                </DialogContentStyled>
            </DialogStyled>
        </DialogBackdropStyled>
    );
}
