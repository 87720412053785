import {FormCard, FormTitle, InputField, Text,} from "./unsubscribe.styles";
import {Container} from "../../../../styles/globalcomponents";
import {UnsubscribeReturningNumber} from "./numbers/numbers.component";
import type {UnsubscribeFormState} from "./unsubscribe.component";
import {Checkbox} from "../../../../components/checkbox/checkbox.component";
import {UnsubscribeFormWhyCancelQuestion} from "./questions/whyCancelQuestion.form";
import {UnsubscribeFormChangePlatformQuestion} from "./questions/changePlatformQuestion.form";

interface UnsubscribeFormProps {
    setFormData: (newState: Partial<UnsubscribeFormState>) => void
    formState: UnsubscribeFormState
    isEmailCancellation: boolean
}

export function UnsubscribeForm({setFormData, formState, isEmailCancellation}: UnsubscribeFormProps) {
    return (
        <>
            <FormCard>
                <FormTitle>
                    ¿Por qué estás cancelando tu suscripción de MasterTools?
                </FormTitle>
                <UnsubscribeFormWhyCancelQuestion setFormData={setFormData}/>
            </FormCard>
            <FormCard>
                <FormTitle>¿Vas a migrar hacia otra herramienta?</FormTitle>
                <UnsubscribeFormChangePlatformQuestion setFormData={setFormData}/>
            </FormCard>
            <FormCard>
                <FormTitle>¿Qué tan probable es que regreses en el futuro?</FormTitle>
                <Text fontSize={"0.7rem"} margin={"1rem 0"} textAlign={"left"}>
                  Siendo 0 poco probable y 10 altamente probable
                </Text>
                <UnsubscribeReturningNumber setForm={setFormData} form={formState}/>
            </FormCard>
            <FormCard>
                <FormTitle>
                  ¿Cuéntanos cuál fue la situación que te llevó a tomar esta decisión?
                </FormTitle>
                <InputField
                    onChange={({target}) =>{
                        setFormData({mainReason: target.value})
                    }}
                    placeholder="Comparte tu experiencia"
                    value={formState.mainReason}
                />
            </FormCard>
            <FormCard>
              <FormTitle>
                ¿Qué cambio o cambios implementarías en la herramienta que te harían cambiar de opinión?
              </FormTitle>
              <InputField
                onChange={({target}) =>{
                  setFormData({feedback: target.value})
                }}
                placeholder="Comparte tu feedback"
                value={formState.feedback}
              />
            </FormCard>
            <Container width="80%">
                <Checkbox
                    checked={formState.hasAcceptedTerms}
                    onClick={() =>
                        setFormData({ hasAcceptedTerms: !formState.hasAcceptedTerms })
                    }
                    label={
                        isEmailCancellation
                            ? "Entiendo que perderé todos los contactos creados, automatizaciones, campañas y toda la relación que has construido con tus suscriptores"
                            : "Entiendo que perderé todos mis funnels creados, dominios y subdominios conectados, los clientes, links acortados, leads y todos los copys generados"
                    }
                />
            </Container>
        </>
    )
}
