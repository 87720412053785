import { globalstyles } from "src/styles/animations/globalstyles";
import styled from "styled-components";

export const CardButton = styled.div`
  width: 100%;
  border-top: 1px solid white;
  padding: 8px 0;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.2s;
  color: white;

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    color: black;
  }
`;

type CardProps = {
    padding?: string;
    position?: string;
}

export const Card = styled.div<CardProps>`
  border-radius: 16px;
  box-shadow: 0 0 15px 2px #0000003b;
  background-color: white;
  min-width: 500px;
  max-width: 600px;
  overflow: hidden;
  padding: ${(props) => props.padding && props.padding};
  position: ${(props) => props.position && props.position};

  @media (max-width: ${globalstyles.sizes.mobile}) {
    width: 90%;
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transition: ${globalstyles.animations.low};
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 200;
`;

export const CancelLink = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  
  p {
    color: blue;
    cursor: pointer;
    text-decoration: underline;
  }
`;
