import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActivationAccountForm } from "../../interfaces/auth";
import { Plan } from "../../interfaces/plans";
import { LoginReducer, loginReducerInitialState } from "../../interfaces/redux";
import { AuthService } from "../../services/authService";
import { ToastService } from "../../services/toastService";
import { RootState } from "../store";
import { LocalStorageKeys } from "../localStorageKeys";

export const setUserAsync = createAsyncThunk<
  LoginReducer,
  undefined,
  {
    state: RootState;
  }
>("auth/setUserAsync", async (_arg, thunkAPI) => {
  const authService = new AuthService();
  const state = thunkAPI.getState();
  let newStateLoginReducer = state.auth;
  try {
    const { dataUser } = await authService.getUser(state.auth.dataUser.email);

    newStateLoginReducer = {
      dataUser: Object.assign({}, state.auth.dataUser, dataUser), //state.auth.dataUser,
      message: "",
      token: state.auth.token,
      darkMode: state.session.darkMode,
      plans: [],
    };
    localStorage.setItem(LocalStorageKeys.userLoggedInfo, JSON.stringify(newStateLoginReducer));
  } catch (error) {
    console.error(error);
  }
  return newStateLoginReducer;
});

export const activateAccountAsync = createAsyncThunk(
  "auth/activateAccountAsync",
  async (state: ActivationAccountForm, thunkAPI) => {
    const authService = new AuthService();
    const toastService = new ToastService();

    try {
      await authService.activateAccount(state);
      toastService.success("Usuario activado correctamente!");
      thunkAPI.dispatch(
        submitLoginAsync({
          email: state.email,
          password: state.password,
          ip: state.ip,
        })
      );
    } catch (error: any) {
      toastService.error(
        "Ocurrió un error al activar tu cuenta, comunícate con el soporte de Mastertools"
      );
    }
  }
);

export const submitLoginAsync = createAsyncThunk(
  "auth/submitLoginAsync",
  async (submitObject: any) => {
    const authService = new AuthService();
    const newStateLoginReducer = loginReducerInitialState();

    let data;
    let message = "Ha ocurrido un error, por favor, intente mas tarde.";

    try {
      if (submitObject.token) {
        data = await authService.signInBypass(submitObject.token);
      } else {
        data = await authService.signIn(submitObject);
      }
    } catch (error: any) {
      if (error.response) {
        data = error.response.data;
      }
    }

    if (data) {
      if (data.response && data.token && data.dataUser) {
        newStateLoginReducer.dataUser = Object.assign(
          {},
          newStateLoginReducer.dataUser,
          data.dataUser
        );

        newStateLoginReducer.token = data.token;
        const toastService = new ToastService();
        toastService.success("Ha iniciado sesión correctamente");
      } else {
        if (
          data.message === "User not found" ||
          data.message === "Wrong password"
        ) {
          message = "El usuario y/o contraseña son incorrectos";
        } else if (data.message === "User not activated") {
          message = "Usuario no activado, por favor activa tu cuenta.";
        } else if (data.message === "No access to beta") {
          message = "No tienes acceso a beta";
          newStateLoginReducer.dataUser.stateUser = 3;
        }
      }
    }

    newStateLoginReducer.message = message;

    return newStateLoginReducer;
  }
);

export const requestSSOAsync = createAsyncThunk(
  "auth/requestSSOAsync",
  async (submitObject: string) => {
    const authService = new AuthService();
    const newStateLoginReducer = loginReducerInitialState();

    let data;
    let message = "Ha ocurrido un error, por favor, intente mas tarde.";

    try {
      data = await authService.singleSignOn(submitObject);
    } catch (error: any) {
      if (error.response) {
        data = error.response.data;
      }
    }

    if (data) {
      if (data.dataUser && !Array.isArray(data.dataUser)) {
        newStateLoginReducer.dataUser = Object.assign(
          {},
          newStateLoginReducer.dataUser,
          data.dataUser
        );

        newStateLoginReducer.token = data.token;
        const toastService = new ToastService();
        toastService.success("Ha iniciado sesión correctamente");
      } else {
        message = "User not found"
      }
    }
    newStateLoginReducer.message = message;
    return newStateLoginReducer;
  }
)

const authSlice = createSlice({
  name: "auth",
  initialState: (JSON.parse(
    localStorage.getItem(LocalStorageKeys.userLoggedInfo) as string
  ) as LoginReducer)
    ? (JSON.parse(localStorage.getItem(LocalStorageKeys.userLoggedInfo) as string) as LoginReducer)
    : loginReducerInitialState(),
  reducers: {
    closeSession: (state, { payload }: PayloadAction<LoginReducer>) => {
      state.dataUser = payload.dataUser;
      state.message = payload.message;
      state.token = payload.token;
    },
    setMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    setOnboarding: (state, { payload }: PayloadAction<number>) => {
      state.dataUser.onBoarding = payload;
    },
    setPlans: (state, { payload }: PayloadAction<Plan[]>) => {
      state.plans = payload;
    },
    setShowUserProfiler: (state, { payload }: PayloadAction<number>) => {
      state.dataUser.showUserProfiler = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitLoginAsync.fulfilled, (state, { payload }) => {
      localStorage.setItem(
        "userLogged",
        JSON.stringify(payload)
      );
      state.dataUser = payload.dataUser;
      state.message = payload.message;
      state.token = payload.token;
    });

    builder.addCase(requestSSOAsync.fulfilled, (state, { payload }) => {
      localStorage.setItem(
        "userLogged",
        JSON.stringify(payload)
      );
      state.dataUser = payload.dataUser;
      state.message = payload.message;
      state.token = payload.token;
    });

    builder.addCase(setUserAsync.fulfilled, (state, { payload }) => {
      state.dataUser = payload.dataUser;
      state.message = payload.message;
      state.token = payload.token;
    });
  },
});

export const { closeSession, setMessage, setOnboarding, setPlans, setShowUserProfiler } =
  authSlice.actions;

export default authSlice.reducer;
